import React, {Component} from "react";

import InputComponent from "./InputComponent";
import _ from "lodash";
import {connect} from 'react-redux';
import {Registration} from "../../proptypes";

import {
    createNewUserRegistration,
    uploadRegistrationImage
} from '../../actions';

import PropTypes from "prop-types";
import {Button, Form, Input, Label, FormGroup, FormText} from "reactstrap";
import {withRouter} from "react-router";
import {getCurrentUserRegistration} from "../../reducers/UserRegistrationReducer";
import {notEmpty} from "../../util/ValidFn";
import ModalComponent from "../Modals/ModalComponent";
import {PATH_LOGIN_PAGE} from "../../routePaths";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

class UserRegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registration: this.getInitRegistration(),
            file: null,
            captcha: "",
            modals: {
                failure: false,
                success: false
            },
        };
        this.uploadRegistrationImage = this.uploadRegistrationImage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        loadCaptchaEnginge(6, 'grey', 'lightblue');
    }

    doSubmit = () => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;

        return validateCaptcha(user_captcha_value) === true;
    };

    getInitRegistration() {
        return {
            gender: "MALE",
            firstName: '',
            lastName: '',
            customerNumber: '',
            customerName: '',
            addressLine1: '',
            postalCode: '',
            city: '',
            phone: '',
            email: '',
        }
    }

    handleRegistrationBasicDataChange(event) {
        const registration = Object.assign({}, this.state.registration);
        const name = event.target.name;
        let value = event.target.value;
        registration[name] = value;
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.registration = registration;
            return nextState;
        });
    }

    createRegistration(event, registration) {
        if (!this.doSubmit()) {
            alert("Die Sicherheitsabfrage ist nicht korrekt! Bitte versuchen Sie es erneut.")
        } else {
                this.props.dispatch(createNewUserRegistration(registration // chained promise
            )).then(() => {
                this.uploadRegistrationImage();
            }).then(() => {
                this.showModal('success');
            }).catch(() => {
                this.showModal('failure');
            });
        }
    }

    setFile(e) {
        this.setState({file: e.target.files[0]});
    }

    uploadRegistrationImage() {
        const file = this.state.file;
        if (file) {
            const customerNumber = this.state.registration.customerNumber;
            return this.props.dispatch(uploadRegistrationImage(customerNumber, file));
        }
        return false;
    }

    isFileSizeOk() {
        const fileSize = this.state.file.size;
        if (fileSize <= 4000000) {
            return true;
        } else {
            alert('Die Datei ist größer als 4 MB! Wählen Sie bitte eine andere Datei aus.')
            return false;
        }
    }

    isCaptchaFull() {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        return user_captcha_value.length === 6;

    }

    isRequiredNotEmpty() {
        const file = this.state.file;
        const registration = this.state.registration;
        return notEmpty(file) && notEmpty(registration.lastName) && notEmpty(registration.customerName)
            && notEmpty(registration.customerNumber) && notEmpty(registration.addressLine1)
            && notEmpty(registration.postalCode) && notEmpty(registration.city)
            && notEmpty(registration.phone) && notEmpty(registration.email)
    }

    isFormComplete() {
        return this.isRequiredNotEmpty();
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    render() {
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {this.props.create &&
                            <Button
                                href=""
                                className="btn-block"
                                onClick={(e) => this.createRegistration(e, this.state.registration)}
                                disabled={!this.isFormComplete() || !this.isFileSizeOk() || !this.isCaptchaFull()}
                            >
                                Registrierung abschicken
                            </Button>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-start flex-md-row col-12 mt-2">
                    <Form className="col-12 col-md-4">
                        <h2>Firmendaten</h2>
                        <InputComponent title="registration.form.companyNameTitle" type="text" name="customerName"
                                        placeholder={"registration.form.companyNamePlaceholder"}
                                        value={this.state.registration.customerName}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        formFeedback={"registration.form.companyNameFeedback"}
                                        maxLength={64}
                                        min={3}
                                        required
                        />
                        <InputComponent title="customer.form.numberTitle" type="text" name="customerNumber"
                                        placeholder={"registration.form.companyNumberPlaceholder"}
                                        value={this.state.registration.customerNumber}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={64}
                                        min={3}
                                        formFeedback={"customer.form.numberFormFeedback"}
                                        required
                        />
                        <InputComponent title='customer.form.addressLine1Title' name='addressLine1' type="text"
                                        placeholder="customer.form.addressLine1Placeholder"
                                        formFeedback="customer.form.addressLine1FormFeedback"
                                        value={this.state.registration.addressLine1}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={35}
                                        required
                        />
                        <InputComponent title="customer.form.postalCodeTitle" name='postalCode' type="text"
                                        placeholder="customer.form.postalCodePlaceholder"
                                        formFeedback="customer.form.postalCodeFormFeedback"
                                        value={this.state.registration.postalCode}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={35}
                                        required
                        />
                        <InputComponent title="customer.form.cityTitle" name='city' type="text"
                                        placeholder="customer.form.cityPlaceholder"
                                        formFeedback="customer.form.cityFormFeedback"
                                        value={this.state.registration.city}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={35}
                                        required
                        />
                    </Form>
                    <Form className="col-12 col-md-4">
                        <h2>Kontaktdaten</h2>
                        <FormGroup>
                            <Label for="gender">Geschlecht</Label>
                            <Input
                                type="select" name="gender" id="gender" placeholder="Geschlecht des Benutzers"
                                value={this.state.registration.gender}
                                onChange={this.handleRegistrationBasicDataChange.bind(this)}
                                maxLength={64}
                            >
                                <option value="MALE">Männlich</option>
                                <option value="FEMALE">Weiblich</option>
                            </Input>
                        </FormGroup>
                        <InputComponent title="user.form.firstNameTitle" name='firstName' type="text"
                                        value={this.state.registration.firstName}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={64}
                        />
                        <InputComponent title="user.form.lastNameTitle" name='lastName' type="text"
                                        value={this.state.registration.lastName}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={64}
                                        formFeedback="user.form.lastNameFormFeedback"
                                        required
                        />
                        <InputComponent title="customer.form.phoneTitle" name='phone' type="tel"
                                        placeholder="customer.form.phonePlaceholder"
                                        formFeedback="customer.form.phoneFormFeedback"
                                        value={this.state.registration.phone}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={64}
                                        required
                        />
                        <InputComponent title="customer.form.emailTitle" name='email' type="email"
                                        placeholder="customer.form.emailPlaceholder"
                                        formFeedback="customer.form.emailFormFeedback"
                                        value={this.state.registration.email}
                                        handleChange={this.handleRegistrationBasicDataChange.bind(this)}
                                        maxLength={64}
                                        required
                        />
                    </Form>
                    <Form className="col-md-6">
                        <h2>Rechnung</h2>
                        <Label for="registrationImage">Rechnung hochladen*</Label>
                        <FormGroup className="text-left">
                            <Input type="file" accept="image/jpeg,image/png,application/pdf" name="registrationImage" id="registrationImage" onChange={(e) => this.setFile(e)} />
                            <br/>
                            <FormText className="text-left">
                                > Für den Upload sind nur PNG, PDF oder JPEG gültig.<br/>
                                > Ihr Name und Kundennummer muss deutlich lesbar sein. <br/>
                                > Die Rechnung darf nicht älter als drei Monate sein.<br/>
                                > Maximale Datei Größe beträgt 4MB.
                            </FormText>
                        </FormGroup>
                        <h2>Sicherheitsabfrage</h2>
                        <div className="container">
                            <div className="form-group">
                                <div className="col mt-3">
                                    <LoadCanvasTemplate reloadText={"Neu laden"} reloadColor={"rosybrown"}/>
                                </div>
                                <div className="col mt-3">
                                    <div><input title='Sicherheitsabfrage*' placeholder="Bitte Code eingeben" id="user_captcha_input"
                                                name="user_captcha_input" type="text" onChange={this.handleRegistrationBasicDataChange.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.failure}
                    toggle={() =>
                        this.showModal('failure')
                    }
                    hide={() =>
                        this.hideModal('failure')
                    }
                    color="danger"
                    title="Registrierung fehlgeschlagen:"
                    message="registration.form.create.failure.message"
                    onSuccess={() => {
                        this.hideModal('failure')
                    }}
                    successText={'Verstanden'}
                />
                <ModalComponent
                    isOpen={this.state.modals.success}
                    toggle={() =>
                        this.showModal('success')
                    }
                    hide={() =>
                        this.hideModal('success')
                    }
                    color="success"
                    title="Registrierung abgeschlossen:"
                    message="registration.form.create.success.message"
                    onSuccess={() => {
                        this.hideModal('success')
                        this.props.history.push(PATH_LOGIN_PAGE)
                    }}
                    successText={'Zurück zum Login'}
                />
            </div>
        )
    }
}

UserRegistrationForm.propTypes = {
    registration: PropTypes.shape(Registration).isRequired,
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
}).isRequired
};

function mapStateToProps(state) {
    return {
        registration: getCurrentUserRegistration(state)
    };
}

export default withRouter(connect(mapStateToProps)(UserRegistrationForm));
