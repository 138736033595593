import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {addCrossSellProducts, cleanSearchProduct, getProductImageUrl, removeCrossSellProducts, searchProductByNumber} from '../../actions';

import {getCurrentMessage} from '../../reducers/MessageReducer';

import {Button, Form, FormGroup, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {getCurrentProduct, getSearchProduct} from '../../reducers/ProductReducer';
import {getCrossSellProducts} from '../../reducers/ProductsReducer';
import {Message, Product, ProductGroups} from "../../proptypes";

class CrossSellProductModifyList extends Component {

  static productName(product) {
    return product ? product.names["DE"] : '';
  }

  static productNameFormat(product) {
    if (product) {
      let itemName = product.data.formatDefinition;
      if (!itemName) {
        itemName = product.data.specifiedSize;
      }
      return !itemName ? '' : ` - ${itemName}`;
    }
    return '';
  }

  static productsList(productGroup) {
    const products = productGroup.products;
    const productList = _.map(products, product => {
      return product;
    });

    return _.filter(productList, (product) => {
      return product.data.forSales["DE"];
    });
  }

  constructor(props) {
    super(props);

    this.removeCrossSellProduct = _.throttle(this.removeCrossSellProduct.bind(this), 500, {trailing: false});
    this.addCrossSellProduct = _.throttle(this.addCrossSellProduct.bind(this), 500, {trailing: false});
    this.productList = this.productList.bind(this);
    this.loadProductSearch = this.loadProductSearch.bind(this);
    this.resetSearchTerm = this.resetSearchTerm.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);

    this.state = {
      searchTerm: ''
    };
  }

  componentDidMount() {
    this.resetSearchTerm();
  }

  productList(crossSellProductGroups) {
    if (crossSellProductGroups && crossSellProductGroups.productGroups ) {
      const groups = crossSellProductGroups.productGroups;
      const groupList = _.map(groups, group => {
        return group;
      });

      const productGroups = _.filter(groupList, (group) => {
        const productList = CrossSellProductModifyList.productsList(group);
        return productList.length > 0 ? productList : [];
      });

      const allProducts = [];
      productGroups.map((productGroup) => {
        return CrossSellProductModifyList.productsList(productGroup)
          .map((product) => {
            if (product.data.forSales["DE"]) {
              allProducts.push(product);
            }
            return product;
          });
      });
      return allProducts;
    }
    return [];
  }

  removeCrossSellProduct(event, crossSellProduct) {
    event.preventDefault();

    const originProduct = this.props.originProduct;
    if (crossSellProduct && originProduct) {
      const productNumbers = [crossSellProduct.productNumber];
      this.props.dispatch(removeCrossSellProducts(originProduct, productNumbers));
    }
  }

  addCrossSellProduct(event, crossSellProduct) {
    event.preventDefault();

    const originProduct = this.props.originProduct;
    if (crossSellProduct && originProduct) {
      const productNumbers = [crossSellProduct.productNumber];
      this.props.dispatch(addCrossSellProducts(originProduct, productNumbers));

      this.resetSearchTerm();
    }
  }

  handleSearchInput(event) {
    const search = event.target.value;
    this.setState({
      searchTerm: search
    });
  }

  loadProductSearch(event) {
    event.preventDefault();

    const search = this.state.searchTerm;
    this.setState({
      searchTerm: search
    });

    this.props.dispatch(searchProductByNumber(search));

    return false;
  }

  resetSearchTerm() {
    this.setState({
      searchTerm: ''
    });

    this.props.dispatch(cleanSearchProduct());
  }

  render() {
    if (!this.props.crossSellProductGroups) {
      return <div className="text-center"><span>Es sind keine Cross-Sell Produkte hinterlegt.</span></div>;
    }

    return (
      <div >
        {/* loop over all cross sell products */
          this.productList(this.props.crossSellProductGroups)
            .map((product, i) => {
              return (
                <div className="row mb-4 mt-4" key={i}>
                  <div className="col-2">
                    <img
                      alt={CrossSellProductModifyList.productNameFormat(product)} className="ml-3 mr-3"
                      src={getProductImageUrl(product.productNumber)} style={{height: '50px'}}
                    />
                  </div>

                  <div className="col-2">
                    Art-Nr. {product.productNumber}
                  </div>
                  <div className="col-5">
                    {CrossSellProductModifyList.productName(product)} {CrossSellProductModifyList.productNameFormat(product)}
                  </div>

                  <div className="col-3 text-right">
                    <Button color="secondary" onClick={(e) => this.removeCrossSellProduct(e, product)}>
                      <span className="oi oi-trash" /> entfernen
                    </Button>
                  </div>
                </div>
              );
            })}

        <div className="row">
          <div className="col-4">
            <Form onSubmit={(e) => this.loadProductSearch(e)}>
              <FormGroup className="form-inline mt-1">
                <InputGroup>
                  <Input
                    type="text" name="searchTerm" placeholder="Artikelnummer" value={this.state.searchTerm}
                    onChange={(e) => this.handleSearchInput(e)}
                  />
                  <InputGroupAddon addonType="prepend">
                    <Button><span className="oi oi-magnifying-glass" /> Suchen</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Form>
          </div>
          <div className="col-8">
            {(this.props.searchProduct && this.props.searchProduct.data.forSales["DE"]) &&
              <div className="row">
                <div className="col-9">
                  {CrossSellProductModifyList.productName(this.props.searchProduct)} {CrossSellProductModifyList.productNameFormat(this.props.searchProduct)}
                </div>

                <div className="col-3 text-right">
                  <Button color="primary" onClick={(e) => this.addCrossSellProduct(e, this.props.searchProduct)}>
                    hinzufügen <span className="oi oi-plus mr-2" />
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

CrossSellProductModifyList.propTypes = {
  crossSellProductGroups: PropTypes.shape(ProductGroups),
  originProduct: PropTypes.shape(Product).isRequired,
  currentProduct: PropTypes.shape(Product),
  searchProduct: PropTypes.shape(Product),
  dispatch: PropTypes.func.isRequired,
  currentMessage: PropTypes.shape(Message)
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    currentMessage: getCurrentMessage(state),
    currentProduct: getCurrentProduct(state),
    searchProduct: getSearchProduct(state),
    crossSellProductGroups: getCrossSellProducts(state)
  };
}

export default withRouter(connect(mapStateToProps)(CrossSellProductModifyList));
