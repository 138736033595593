import React, {Component} from 'react';
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import {FormattedMessage} from "react-intl";
import Input from "reactstrap/es/Input";
import InputComponent from "../components/Form/InputComponent";
import {Alert} from "reactstrap";
import Button from "reactstrap/es/Button";
import Form from "reactstrap/es/Form";
import PropTypes from "prop-types";
import {Message, User} from "../proptypes";
import {getCurrentUser} from "../reducers/UserReducer";
import {getCurrentMessage} from "../reducers/MessageReducer";
import {connect} from "react-redux";
import {updateCredentials} from "../actions";
import ErrorModal from "../components/Modals/ErrorModalComponent";
import SuccessModal from "../components/Modals/SuccessModalComponent";

class ChangeCredentialsPage extends Component {
    constructor() {
        super();
        this.state = {
            newEmail: undefined,
            newEmailRepeat: undefined
        }
    }
    handleChange(e){
        const targetName = e.target.name;
        const value = e.target.value;
        this.setState({[targetName]:value});
    }
    updateEmailCredential(e){
        e.preventDefault();
        const credentials = {email:this.state.newEmail};
        this.props.dispatch(updateCredentials(this.props.currentUser.id,credentials))
    }
    render() {
        return (
            <div className="page-content">
                <div className="container offset-md-4 col-md-4 ">
                    <h1 className="h2 text-center mb-4">
                        Email-Adresse Ändern
                    </h1>
                    <Form>
                    <FormGroup>
                        <Label for="email"><FormattedMessage id="settings.pw.change.email.label"/></Label>
                        <Input
                            type="text" name="email" id="email" disabled
                            defaultValue={this.props.currentUser.credentials.email}
                        />
                    </FormGroup>
                    <InputComponent
                        title={"Neue E-Mail Adresse"}
                        name='newEmail' type="email"
                        value={this.state.newEmail}
                        handleChange={this.handleChange.bind(this)}
                        formFeedback="customer.form.emailFormFeedback"
                    />
                    <InputComponent
                        title={"E-Mail Adresse wiederholen"}
                        name='newEmailRepeat' type="email"
                        value={this.state.newEmailRepeat}
                        handleChange={this.handleChange.bind(this)}
                        formFeedback="customer.form.emailFormFeedback"
                    />
                    {this.state.newEmail && this.state.newEmail.length > 1 && this.state.newEmailRepeat !== this.state.newEmail &&
                        <Alert className="alert-warning">Die Email-Adressen stimmen nicht überein!</Alert>
                    }
                    {this.props.currentUser.credentials.email === this.state.newEmail &&
                        <Alert className="alert-warning">Die neue Email-Adresse darf nicht identisch mit der alten Email-Adresse sein!</Alert>
                    }
                    <FormGroup>
                        <Button
                            className="btn btn-primary btn-block" type="submit" color="primary"
                            disabled={(this.props.currentUser.credentials.email === this.state.newEmail)||(this.state.newEmail !== this.state.newEmailRepeat) || (this.state.newEmail && this.state.newEmail.length < 3)}
                            onClick={(e) => this.updateEmailCredential(e)}
                        >
                            Email Adresse ändern
                        </Button>
                    </FormGroup>
                    </Form>
                </div>
                <ErrorModal source='CHANGE_EMAIL_CREDENTIALS'/>
                <SuccessModal source='CHANGE_EMAIL_CREDENTIALS'/>
            </div>
        );
    }
}
ChangeCredentialsPage.propTypes = {
    currentUser: PropTypes.shape(User),
    currentMessage: PropTypes.shape(Message)
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default connect(mapStateToProps)(ChangeCredentialsPage);
