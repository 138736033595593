import React, {Component} from 'react';
import Label from "reactstrap/es/Label";
import Select from "react-virtualized-select";
import {FormGroup} from "reactstrap";
import {injectIntl} from "react-intl";

class SelectComponent extends Component {
    getFormattedTitle(title) {
        if(title){
            return this.props.required ? this.props.intl.formatMessage({id: title}) + '*'
                : this.props.intl.formatMessage({id: title});
        }else
            return undefined
    }
    isEmpty() {
        return this.props.value == null;
    }
    render() {
        const title = this.props.title;
        const formattedTitle = this.getFormattedTitle(title);
        const style = this.props.style;
        const name = this.props.name;
        const options = this.props.options ||[];

        const labelKey = this.props.labelKey;
        const optionRenderer = this.props.optionRenderer;
        const disabled = this.props.disabled;
        const placeholder = this.props.placeholder || "Auswählen";
        return (
            <FormGroup>
                {formattedTitle && <Label for={this.props.name}>{formattedTitle}</Label>}
                <Select style={style} name={name} id={name} options={options}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        labelKey={labelKey}
                        optionRenderer = {optionRenderer}
                        disabled = {disabled}
                        placeholder={placeholder}
                />
                {this.props.required && this.props.errorMessage && this.isEmpty() &&
                    <div className="invalid-feedback" style={{display: 'block'}}>{this.props.errorMessage}</div>}
            </FormGroup>
        );
    }


}

SelectComponent.propTypes = {};

export default injectIntl(SelectComponent)
