import React from 'react';
import classnames from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";
function FavoriteComponent(props) {
    const throttleOnClick = _.throttle(props.onFavoriteClick,500);
    return (
        <div className="col-12 col-md-1 align-self-center" style={{cursor: "pointer"}}
             onClick={throttleOnClick}
        >
            <span
                className={classnames("oi oi-star favorite-icon", {colorGrey: !props.favorite}, {colorPrimary: props.favorite})}/>
        </div>
    );
}

FavoriteComponent.propTypes = {
    onClick: PropTypes.func,
    favorite: PropTypes.any
};

export default FavoriteComponent;
