import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCurrentProject} from "../../reducers/ProjectReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {Cart, Message, Project, User} from "../../proptypes";
import {
    PATH_CART_SUMMARY_PAGE,
    PATH_HOME_PAGE,
    PATH_NEW_CART_PAGE,
    PATH_NEW_CART_SCHEDULE_PAGE,
    PATH_PRODUCTS_PAGE
} from "../../routePaths";
import {loadFacilities, loadMaxTours} from "../../reducers/OrderReducer";
import {getCurrentCart} from "../../reducers/CartReducer";
import CartValidator from "../../util/cartValidation";
import {
    fetchOrderSettings,
    getAllFacilities,
    removeCurrentMessage,
    setDeliveryCart,
    setPickupCart,
    loadCurrentProject
} from "../../actions";
import SelectComponent from "../../components/Form/SelectComponent";
import OrderAdress from "../../components/Address/OrderAdress";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";

class NewCartPage extends Component {

    constructor(props) {
        super(props);

        this.toggleDeliveryActive = this.toggleDeliveryActive.bind(this);
        this.deliveryActiveStyle = this.deliveryActiveStyle.bind(this);
        this.pickupActiveStyle = this.pickupActiveStyle.bind(this);
        this.deliveryFormActiveStyle = this.deliveryFormActiveStyle.bind(this);
        this.pickupFormActiveStyle = this.pickupFormActiveStyle.bind(this);
        this.activateScheduleBtn = this.activateScheduleBtn.bind(this);
        this.switchToPage = this.switchToPage.bind(this);
        this.setDeliveryCart = this.setDeliveryCart.bind(this);
        this.setPickupCart = this.setPickupCart.bind(this);
        this.submitDeliveryAddressForm = this.submitDeliveryAddressForm.bind(this);
        this.submitPickupFacilityForm = this.submitPickupFacilityForm.bind(this);
        this.hasSelectedFacility = this.hasSelectedFacility.bind(this);
        this.showOrderTypeModal = this.showOrderTypeModal.bind(this);
        this.hideOrderTypeModal = this.hideOrderTypeModal.bind(this);
        this.setAdditionalInformation = this.setAdditionalInformation.bind(this);
        this.setFacility = this.setFacility.bind(this);

        this.state = {
            deliveryActive: true,
            facilitySelected: false,
            orderTypeModal: false,
            facility: undefined
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.currentCart) {
            const cart = this.props.currentCart;
            this.toggleDeliveryActive(cart);

            const delivery = cart.delivery;
            if (delivery) {
                if (delivery.additionalInformation) {
                    const additionalInformation = delivery.additionalInformation;
                    this.setAdditionalInformation(null, additionalInformation);
                }
            }

            const pickup = cart.pickup;
            if (pickup) {
                if (pickup.additionalInformation) {
                    const additionalInformation = pickup.additionalInformation;
                    this.setAdditionalInformation(null, additionalInformation);
                }

                const facility = pickup.facility;
                if (facility) {
                    this.setFacility(facility);
                }
            }

            const vSelected = this.hasSelectedFacility('Musterstadt');


            if (vSelected) {
                this.activateScheduleBtn();
            }
        }
        if(this.props.currentProject){
            this.props.dispatch(loadCurrentProject(this.props.currentProject));
        }
        this.props.dispatch(getAllFacilities())
        if (this.props.maxTours === undefined) {
            this.props.dispatch(fetchOrderSettings());
        }
    }

    componentWillReceiveProps(nextProps) {
        const cart = nextProps.currentCart;
        if (cart) {
            this.toggleDeliveryActive(cart);
        }
        const nextMessage = nextProps.currentMessage;
        if (nextMessage) {
            this.showOrderTypeModal();
        }
    }

    setDeliveryCart(cart, additionalInformation) {
        if (additionalInformation) {
            this.props.dispatch(setDeliveryCart(cart, {additionalInformation}));
        } else {
            this.props.dispatch(setDeliveryCart(cart, {}));
        }
    }

    setPickupCart(cart, facilityId, additionalInfo) {
        let additionalInformation = null;
        if (additionalInfo) {
            additionalInformation = additionalInfo;
        }
        this.props.dispatch(setPickupCart(cart, facilityId, {additionalInformation}));
    }

    setAdditionalInformation(event, persistedAdditionalInformation) {
        let additionalInformation = persistedAdditionalInformation;
        if (event) {
            additionalInformation = event.target.value;
        }
        this.setState({additionalInformation })
    }

    setFacility(persistedFacilty) {
        this.setState({facility:persistedFacilty})
    }

    toggleDeliveryActive(cart) {
        const deliveryActive = Boolean(cart.delivery);
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);
            nextState.deliveryActive = deliveryActive;

            return nextState;
        });
    }

    deliveryActiveStyle() {
        if (this.state.deliveryActive) {
            return (
                'oi oi-check ml-2'
            );
        }
        return (
            'oi oi-check d-none ml-2'
        );
    }

    pickupActiveStyle() {
        if (this.state.deliveryActive) {
            return (
                'oi oi-check d-none ml-2'
            );
        }
        return (
            'oi oi-check ml-2'
        );
    }

    deliveryFormActiveStyle() {
        if (this.state.deliveryActive) {
            return (
                ''
            );
        }
        return (
            'd-none'
        );
    }

    pickupFormActiveStyle() {
        if (this.state.deliveryActive) {
            return (
                'd-none'
            );
        }
        return (
            ''
        );
    }

    activateScheduleBtn() {
        this.setState({
            facilitySelected: true
        });
    }

    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }

    submitDeliveryAddressForm(event, cart) {
        event.preventDefault();
        let additionalInformation = this.state.additionalInformation;

        if (!additionalInformation) {
            // send empty additionalInformation to clear field via API
            additionalInformation = ' ';
        }

        const delivery = cart.delivery;
        if (delivery) {
            const propInformation = delivery.additionalInformation;
            const stateInformation = this.state.additionalInformation;

            if (propInformation !== stateInformation) {
                this.setDeliveryCart(cart, additionalInformation);
            }
        }
        this.switchToPage(event, PATH_NEW_CART_SCHEDULE_PAGE);
    }

    submitPickupFacilityForm(event, cart) {
        event.preventDefault();
        let additionalInformation = this.state.additionalInformation;

        if (!additionalInformation) {
            // send empty additionalInformation to clear field via API
            additionalInformation = ' ';
        }

        const pickup = cart.pickup;
        if (pickup) {
            const facility = this.state.facility;
            const propInformation = pickup.additionalInformation;
            const stateInformation = this.state.additionalInformation;

            if (propInformation === stateInformation) {
                // do not overwrite additional information if nothing has changed
                additionalInformation = null;
            }

            if (facility) {
                this.setPickupCart(cart, facility.id, additionalInformation);
            }
        }
        this.switchToPage(event, PATH_NEW_CART_SCHEDULE_PAGE);
    }

    hasSelectedFacility(facilityName) {
        if (this.props.currentCart) {
            const cart = this.props.currentCart;
            if (cart.pickup) {
                const facility = cart.pickup.facility;
                if (facility && facility.name === facilityName) {
                    return true;
                }
            }
        }
        return false;
    }

    showOrderTypeModal() {
        this.setState({
            orderTypeModal: true
        });
    }

    hideOrderTypeModal() {
        this.setState({
            orderTypeModal: false
        });

        this.props.dispatch(removeCurrentMessage());
    }

    isDeliveryDisabled() {
        return false;
    }
    getWorkerByWorkerNumber(workerNumber) {
        const customerWorkers = this.props.customerWorkers;
        if (customerWorkers && workerNumber)
            return _.find(customerWorkers, {'number': workerNumber});
    }

    getAllFilteredFacilities() {
        const facilities = _.sortBy(this.props.facilities,['name']) || [];
        if (this.props.currentUser.role !== "CUSTOMER_USER") {
            const filteredStandardFacilities = _.filter(facilities, f => !f.customerId && !f.expires);
            const filteredCustomerFacilities = _.filter(facilities, f => f.customerId === this.props.currentUser.customerId && !f.expires)
            return filteredStandardFacilities.concat(filteredCustomerFacilities);
        } else {
            return _.filter(facilities, f => f.customerId === this.props.currentUser.customerId && !f.expires)
        }
    }

    isFacilityDisabled(){
        const facilities = this.getAllFilteredFacilities();
        return facilities.size === 0;
    }

    render() {
        if (!this.props.currentUser) {
            return this.switchToPage(null, PATH_HOME_PAGE);
        }

        const role = this.props.currentUser.role;
        if (!role || role === 'ANONYMOUS' || role === 'SCHEDULER') {
            return this.switchToPage(null, PATH_HOME_PAGE);
        }

        if (!this.props.currentCart) {
            return <div className="page-content text-center"><span>Bitte auf Warenkorb warten ...</span></div>;
        }

        if (CartValidator.isCartFixed(this.props.currentCart)) {
            return this.switchToPage(null, PATH_CART_SUMMARY_PAGE);
        }

        if (!this.props.currentProject) {
            return <div className="page-content text-center"><span>Bitte auf Objekt warten ...</span></div>;
        }

        const filteredFacilities = this.getAllFilteredFacilities();
        return (
            <div className="page-content">
                <div className="container">
                    <div className="step-navigation d-flex flex-column flex-md-row text-center">
                        <div className="col-12 col-md-4 active">
                          <span onClick={(e) => this.switchToPage(e, PATH_NEW_CART_PAGE)}>
                              <span>Versandauswahl</span>
                          </span>
                        </div>
                        <div className="col-12 col-md-4">
                            <span onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>
                                zur Wunschterminauswahl <span className="oi oi-arrow-circle-right mr-2"/>
                            </span>
                        </div>
                        <div className="col-12 col-md-4">
                            <span onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>
                                zur Produktauswahl <span className="oi oi-arrow-circle-right mr-2"/>
                            </span>
                        </div>
                    </div>

                    <span className="pt-2">
                        <h6 className="text-muted text-center m-3">
                        <OrderAdress currentProject={this.props.currentProject} currentCart={this.props.currentCart}
                                     workerByWorkerNumber={this.getWorkerByWorkerNumber(this.props.currentCart.workerNumber)}/>
                    </h6>
                    </span>


                    <h1 className="h2 text-center mb-4">
                        <span className="d-none d-sm-inline">Schritt</span> <span>1: Versandauswahl</span>
                    </h1>
                    <p className="text-center">Bitte wählen Sie die Versandart:</p>

                    <div className="row border-0">
                        <div className="col-md">
                            <Button
                                className="btn-block btn-lg" disabled={this.isDeliveryDisabled()}
                                onClick={() => this.setDeliveryCart(this.props.currentCart)}
                            >
                                Lieferung
                                {this.isDeliveryDisabled() &&
                                <span> (derzeit nicht möglich)</span>
                                }
                                {!this.isDeliveryDisabled() &&
                                <span className={this.deliveryActiveStyle()}/>
                                }
                            </Button>
                        </div>
                        <div className="col-md-auto text-center">
                            <p className="pp">oder</p>
                        </div>
                        <div className="col-md">
                            <Button
                                className="btn-block btn-lg"
                                onClick={() => this.setPickupCart(this.props.currentCart, filteredFacilities[0].id)}
                                disabled={this.isFacilityDisabled()}
                            >
                                Abholung
                                <span className={this.pickupActiveStyle()}/>
                            </Button>
                        </div>
                    </div>

                    <br/>

                    <div className={this.deliveryFormActiveStyle()}>
                        <Form onSubmit={(event) => this.submitDeliveryAddressForm(event, this.props.currentCart)}>
                            <div className="row border-0">
                                <div className="col-3"/>
                                <div className="col-6 text-center">
                                    <p>
                                        <strong>Lieferadresse:</strong>
                                        <br/>
                                        {this.props.currentProject.name}
                                        <br/>
                                        {this.props.currentCart.deliveryAddress.addressLine1}
                                        <br/>
                                        {this.props.currentCart.deliveryAddress.postalCode} {this.props.currentCart.deliveryAddress.city}
                                    </p>
                                </div>
                                <div className="col-3"/>
                            </div>
                            <div className="row border-0">
                                <div className="col-12">
                                    <Label for="deliveryTextarea">
                                        Lieferhinweise <br/>(z.B. Abladeplatz, Zufahrtshinweis, abweichende
                                        Lieferadresse)
                                    </Label>
                                    <Input
                                        type="textarea" name="additional-delivery-info" id="additional-delivery-info"
                                        rows="3" maxLength="130" style={{resize: 'none'}}
                                        value={this.state.additionalInformation}
                                        onChange={this.setAdditionalInformation.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="row border-0 mt-4">
                                <div className="col-md-6 mb-3 order-2 order-md-1">
                                    <Button tag="a" color="secondary" className="btn-block" href=""
                                            onClick={(e) => this.switchToPage(e, PATH_HOME_PAGE)}>
                                        <span className="oi oi-chevron-left mr-2"/>
                                        zur Lieferadresse
                                    </Button>
                                </div>
                                <div className="col-md-6 mb-3 order-1 order-md-2">
                                    <Button color="primary" className="btn-block" type="submit"
                                            disabled={this.isDeliveryDisabled()}
                                    >
                                        zur Wunschterminauswahl
                                        <span className="oi oi-chevron-right ml-2"/>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className={this.pickupFormActiveStyle()}>
                        <Form onSubmit={(event) => this.submitPickupFacilityForm(event, this.props.currentCart)}>
                            <div className="row border-0">
                                <div className="offset-3 col-6 text-center">
                                        <SelectComponent
                                            title={'Bitte wählen Sie das Werk: '}
                                            placeholder={'auswählen'}
                                            name={"facility"}
                                            options={filteredFacilities}
                                            value={this.state.facility}
                                            onChange={this.setFacility.bind(this)}
                                            labelKey={"name"}
                                            required
                                        />
                                </div>
                            </div>
                            <div className="row border-0">
                                <div className="col-10 mt-2 mb-2">
                                    <Label for="deliveryTextarea">
                                        Hinweise zur Abholung:<br/>(z.B. Spedition, Uhrzeit, Sonstiges)
                                    </Label>
                                    <Input
                                        type="textarea" name="additional-pickup-info" id="additional-pickup-info"
                                        rows="3" maxLength="130" style={{resize: 'none'}}
                                        value={this.state.additionalInformation}
                                        onChange={this.setAdditionalInformation.bind(this)}
                                    />
                                </div>
                                <div className="col-1"/>
                            </div>
                            <div className="row border-0 mt-4">
                                <div className="col-md-6 mb-3 order-2 order-md-1">
                                    <Button tag="a" color="secondary" className="btn-block" href=""
                                            onClick={(e) => this.switchToPage(e, PATH_HOME_PAGE)}>
                                        <span className="oi oi-chevron-left mr-2"/>
                                        zur Lieferadresse
                                    </Button>
                                </div>
                                <div className="col-md-6 mb-3 order-1 order-md-2">
                                    <Button color="primary" className="btn-block"
                                            disabled={!this.state.facility}
                                            type="submit">
                                        zur Wunschterminauswahl
                                        <span className="oi oi-chevron-right ml-2"/>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <Modal isOpen={this.state.orderTypeModal} fade={false} toggle={() => this.showOrderTypeModal()}>
                        <ModalHeader toggle={() => this.hideOrderTypeModal()}/>
                        <ModalBody className="text-center">
                            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR') &&
                            <div className="alert alert-danger">
                                {this.props.currentMessage.msg}
                            </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.hideOrderTypeModal()}>Schließen</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

NewCartPage.propTypes = {
    currentCart: PropTypes.shape(Cart),
    currentProject: PropTypes.shape(Project),
    currentUser: PropTypes.shape(User),
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    maxTours: PropTypes.number
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        currentCart: getCurrentCart(state),
        currentProject: getCurrentProject(state),
        currentMessage: getCurrentMessage(state),
        maxTours: loadMaxTours(state),
        facilities: loadFacilities(state),
        customerWorkers:getCustomerWorkers(state)
    };
}

export default withRouter(connect(mapStateToProps)(NewCartPage));
