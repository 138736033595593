import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Address} from "../../proptypes";

class FullAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <span>
        {this.props.address &&
          <span>
          {this.props.address.company &&
            <span>
              <br />
              {this.props.address.company}
            </span>
          }
          {this.props.address.addressLine1 &&
            <span>
              <br />
              {this.props.address.addressLine1}
            </span>
          }
          {this.props.address.addressLine2 &&
            <span>
              <br />
              {this.props.address.addressLine2}
            </span>
          }
          {this.props.address.addressLine3 &&
            <span>
              <br />
              {this.props.address.addressLine3}
            </span>
          }
          <br />
          {this.props.address.postalCode} {this.props.address.city}

          {this.props.address.additionalInformation &&
            <span>
              <br /><br />
              {this.props.address.additionalInformation}
            </span>
          }
        </span>
      }
      </span>
    );
  }
}


FullAddress.propTypes = {
  address: PropTypes.shape(Address)
};

export default FullAddress;
