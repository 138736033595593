import {LOAD_CUSTOMERS, LOGOUT} from '../actions/types';

const initialState = {
  stateCustomers: null
};

const CustomersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateCustomers: null
      };
    }
    case LOAD_CUSTOMERS: {
      const actionCustomers = action.customers.customers;

      if (!actionCustomers) {
        return {
          ...state,
          stateCustomers: null
        };
      }

      return {
        ...state,
        stateCustomers: actionCustomers
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCustomers = (state) => state.customers.stateCustomers;

export default CustomersReducer;
