import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import ProjectForm from "../../components/Form/ProjectForm";
import Auth from "../../util/Auth";

class CustomerCreateProjectPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h1 className="h2 text-center mb-4">
                        Lieferadresse erstellen
                    </h1>
                    <ProjectForm create admin={Auth.isAdmin()}/>
                </div>
            </div>
        );
    }
}

CustomerCreateProjectPage.propTypes = {};

export default CustomerCreateProjectPage;
