import React, {Component} from "react";
import {Collapse, ListGroup, ListGroupItem} from "reactstrap";
import PropTypes from "prop-types";
import {getScaledPrice} from "../../util/priceHelper";

class ScalePriceChart extends Component {
    render() {
        const product = this.props.product;
        const priceMapForProduct = product.data.prices;
        const country = this.props.country;
        return <div className="small">
            <Collapse isOpen={this.props.openScaleChart === this.props.j}>
                <ListGroup>
                    {
                        Object.keys(priceMapForProduct[country]).map((startRange, index) => {
                            return (
                                <ListGroupItem
                                    key={index}>ab {startRange} {this.props.packagingLabel} {getScaledPrice(product, startRange, country)}</ListGroupItem>
                            )
                        })
                    }
                </ListGroup>
            </Collapse>
        </div>
    }
}

ScalePriceChart.propTypes = {
    openScaleChart: PropTypes.any,
    j: PropTypes.any,
    product: PropTypes.any,
    country: PropTypes.any,
    packagingLabel:PropTypes.string
};

export default ScalePriceChart
