import React, {Component} from 'react';

class CategoryEditPage extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

CategoryEditPage.propTypes = {};

export default CategoryEditPage;
