import React, {Component} from 'react';
import {
    PATH_ADMIN_MANAGE_CUSTOMER_PAGE,
    PATH_CUSTOMER_FACILITY_CREATE_PAGE,
    PATH_USER_SETTINGS_PAGE
} from "../../routePaths";
import Button from "reactstrap/lib/Button";
import SearchBar from "../../components/SearchBar";
import {
    getCustomerFacilities,
    loadCurrentUser
} from "../../actions";
import _ from "lodash";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {getCustomerCarts} from "../../reducers/CartsReducer";
import {getCustomerOrders} from "../../reducers/OrdersReducer";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Customer, Facilities, Facility, Message, User} from "../../proptypes";
import {getCustomFacilities} from "../../reducers/CustomerFacilitiesReducer";
import CustomerFacilityList from "../../components/CustomerFacilityList";
import {Spinner} from "reactstrap";
import Auth from "../../util/Auth";
import BackButton from "../../components/Buttons/BackButton";

class CustomerFacilityOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: undefined,
            filteredCustomerFacilities: undefined,
            selectedFacility: undefined,
            modals:{
                deleteFacility:false
            }
        }
    }

    componentDidMount() {
        const user = this.props.currentUser;
        const customer = this.props.customer;
        if (customer) {
            this.props.dispatch(getCustomerFacilities(customer.id))
        }
        if (!user) {
            this.props.dispatch(loadCurrentUser());
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customer !== this.props.customer) {
            this.props.dispatch(getCustomerFacilities(this.props.customer.id))
        }
    }

    filteredFacilityList(searchTerm, facilities) {
        let localFacilities = facilities;
        if (searchTerm) {
            localFacilities = _.filter(facilities, (facility) => {
                console.log(facility);
                return facility.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1; // || facility.id.indexOf(searchTerm) !== -1;
            });
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filteredCustomerFacilities = localFacilities;
            return nextState;
        });
    }

    resetSearch() {
        this.setState({searchTerm: ""})
    }

    goToPage(path) {
        this.props.history.push(path);
    }

    handleSearchInput(event) {
        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            searchTerm: search
        });
        this.filteredFacilityList(search, this.props.facilities);
    }

    render() {
        const beforeRender = this.props.customer && this.props.currentUser
        return (
            <div className="page-content">
                <div className="container">
                    {Auth.isAdmin() &&
                        <BackButton path={PATH_ADMIN_MANAGE_CUSTOMER_PAGE}/>
                    }
                    {!Auth.isAdmin() &&
                        <BackButton path={PATH_USER_SETTINGS_PAGE}/>
                    }
                    {this.props.customer && <h4 className="text-muted text-center">{this.props.customer.name}</h4>}
                    <h1 className="h2 text-center mb-4">
                        Lager und Werkverwaltung
                    </h1>
                    <div className="float-right mb-2">
                        <Button color="primary" onClick={() => this.goToPage(PATH_CUSTOMER_FACILITY_CREATE_PAGE)}
                                                      size="sm"><span className="oi oi-plus" /> Neues Lager / Werk anlegen</Button>
                    </div>
                    <SearchBar
                        filter
                        handleSearch={this.handleSearchInput.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                    />
                    {!beforeRender &&
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    }
                    {beforeRender &&
                        <CustomerFacilityList dispatch={this.props.dispatch}
                                              resetSearch={this.resetSearch.bind(this)}
                                              facilities={this.state.filteredCustomerFacilities || this.props.facilities}
                        />
                    }
                </div>
            </div>
        );
    }
}

CustomerFacilityOverview.propTypes = {
    currentUser: PropTypes.shape(User),
    currentCustomer: PropTypes.shape(Customer),
    currentFacility: PropTypes.shape(Facility),
    facilities: PropTypes.shape(Facilities),
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        facilities: getCustomFacilities(state),
        currentUser: getCurrentUser(state),
        customerCarts: getCustomerCarts(state),
        customerOrders: getCustomerOrders(state),
    };
}

export default withRouter(connect(mapStateToProps)(CustomerFacilityOverview));
