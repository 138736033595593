import React, {Component, Fragment} from "react";
import * as PropTypes from "prop-types";
import {PACKAGINGTYPES} from "../../enums/PackagingTypes";
import {injectIntl} from "react-intl";

class ProductUnits extends Component {
    render() {
        const order = this.props.order ||false
        const productQuantity = this.props.productQuantity;
        return <Fragment>
            {productQuantity > 1 &&
                <span>{order && <span>{productQuantity}</span>}{' ' + this.props.pluralPackagingTypeLabel}</span>
            }
            {productQuantity === '1' &&
            <span>{order && <span>{productQuantity}</span>} {' ' + this.props.singularPackagingTypeLabel}</span>
            }
            <br/>
            {this.props.leastUnitsPackage > 1 && productQuantity > 1 &&
                <span>
                    <small>({this.props.formattedUnits} {productQuantity > 1 && " " + this.props.intl.formatMessage({id:PACKAGINGTYPES['ST'].label})})</small>
                </span>
            }
        </Fragment>
    }
}

ProductUnits.propTypes = {
    formattedUnits: PropTypes.func,
    productQuantity: PropTypes.string,
    pluralPackagingTypeLabel: PropTypes.string,
    singularPackagingTypeLabel: PropTypes.string,
    leastUnitsPackage: PropTypes.number
};

export default injectIntl(ProductUnits)
