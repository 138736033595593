import React, {Component} from 'react';
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import {Form, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";

class SearchBar extends Component {
    render() {
        return (
            <Form onSubmit={(e) => this.props.handleSearch(e)} className="mb-2">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText><span className="oi oi-magnifying-glass" /></InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name="searchTerm" placeholder={this.props.placeholder} value={this.props.searchTerm} onChange={(e) => this.props.onChange(e)} />
                    { (!this.props.filter) &&
                        <InputGroupAddon addonType="append">
                            <Button><span className="oi oi-magnifying-glass" /> suchen</Button>
                        </InputGroupAddon>
                    }
                    { (!this.props.filter && this.props.searchTerm) &&
                        <InputGroupAddon addonType="append">
                            <Button onClick={this.props.onReset}><span className="oi oi-delete" /></Button>
                        </InputGroupAddon>
                    }
                </InputGroup>
            </Form>
        );
    }
}

SearchBar.propTypes = {};

export default SearchBar;
