import React, {Component} from 'react';

import BackButton from "../../components/Buttons/BackButton";
import {getCustomer} from "../../reducers/CustomerReducer";
import {connect} from "react-redux";
import _ from 'lodash';
import {
    attachWorkerToCustomer,
    detachWorkerFromCustomer,
    loadCustomerWorkers,
    loadWorkers,
    removeCurrentMessage
} from "../../actions";
import TransferList from "../../components/Form/TransferList";
import {Button} from "reactstrap";
import {getWorkers} from "../../reducers/WorkersReducer";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";
import ModalComponent from "../../components/Modals/ModalComponent";
import {
    PATH_ADMIN_MANAGE_CUSTOMER_PAGE
} from "../../routePaths";
import {withRouter} from "react-router";

class AdminManageCustomerWorkers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            src: [],
            modals:{
                edit:false
            }
        }
    }

    componentDidMount() {
        const customerId = this.props.customer.id;
        this.props.dispatch(loadWorkers());
        this.props.dispatch(loadCustomerWorkers(customerId))
    }
    componentWillReceiveProps(nextProps) {
        const nextMessage = nextProps.currentMessage;
        if (nextMessage) {
            if (nextMessage.source === 'UPDATE_PRODUCT') {
                this.showModal('edit');
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.workers !== this.props.workers) {
            this.setSource(this.props.workers)
        }
        const customerWorkers = this.props.customerWorkers;
        if(prevProps.customerWorkers !== customerWorkers){
            this.setTransferListState(customerWorkers)
        }
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }
    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }
    setTransferListState(customerWorkers){
        this.setSelected(customerWorkers)
        const workers = this.props.workers;
        this.setSource(_.differenceBy(workers,customerWorkers,(item)=>{ return item.number}))
    }
    setSelected(select){
        this.setState({selected:select})
    }
    onSelect(selectedItem) {
        const targetList = Object.assign([], this.state.selected);
        const srcList = Object.assign([], this.state.src);

        const idIndex = _.findIndex(srcList,{"number":selectedItem.number});

        targetList.push(srcList.splice(idIndex, 1)[0]);
        this.setState({selected: targetList})
        this.setState({src: srcList})
    }

    onDeSelect(deSelectedItem) {
        const targetList = Object.assign([], this.state.selected);
        const srcList = Object.assign([], this.state.src);

        const idIndex = _.findIndex(targetList,{"number":deSelectedItem.number});

        srcList.push(targetList.splice(idIndex, 1)[0])
        this.setState({selected: targetList})
        this.setState({src: srcList})
    }

    setSource(src) {
        this.setState({src})
    }
    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }
    saveCustomerWorkers() {
        const added = _.differenceBy(this.state.selected, this.props.customerWorkers,(item)=>{ return item.number});
        const removed = _.differenceBy(this.props.customerWorkers, this.state.selected,(item)=>{ return item.number});
        const customer = this.props.customer;
        added.forEach((add) => {
            this.props.dispatch(attachWorkerToCustomer(add.number, customer.id))
        })
        removed.forEach((rm) => {
            this.props.dispatch(detachWorkerFromCustomer(rm.number, customer.id))
        })
        this.showModal('edit')
    }
    isDifference(){
        return _.differenceBy(this.state.selected, this.props.customerWorkers,(item)=>{ return item.number}).length > 0 || _.differenceBy(this.props.customerWorkers, this.state.selected,(item)=>{ return item.number}).length > 0;
    }
    render() {
        const customer = this.props.customer || {};
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h4 className="text-muted text-center">{customer.name}</h4>
                    <h1 className="headline">
                        Handwerker zuweisen
                    </h1>
                    <div className="ml-auto col-2 mb-2 align-items-end">
                        <Button
                            disabled={!this.isDifference()}
                            color="success" href="" className="btn-block"
                            onClick={() => this.saveCustomerWorkers()}
                        >
                            aktualisieren
                        </Button>
                    </div>
                    <TransferList
                        src={this.state.src}
                        target={this.state.selected}
                        srcTitle={'Verfügbar'}
                        targetTitle={'Ausgewählt'}
                        onSelect={this.onSelect.bind(this)}
                        onDeSelect={this.onDeSelect.bind(this)}
                    />
                </div>
                <ModalComponent
                    isOpen={this.state.modals.edit}
                    toggle={() => this.showModal('edit')}
                    hide={() => this.hideModal('edit')}
                    color="success"
                    title="Handwerker Liste aktualisiert"
                    message={'Handwerker Liste für Händler aktualisiert'}
                    onSuccess={(e) => {
                        this.switchToPage(e, PATH_ADMIN_MANAGE_CUSTOMER_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />
            </div>
        );
    }
}

AdminManageCustomerWorkers.propTypes = {};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        workers: getWorkers(state),
        customerWorkers: getCustomerWorkers(state)
    };
}

export default withRouter(connect(mapStateToProps)(AdminManageCustomerWorkers));
