import React, {Component} from 'react';
import _ from "lodash";

import {Button, Form, FormGroup, FormText, Input, InputGroup} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {connect} from "react-redux";
import {getCurrentProduct} from "../../reducers/ProductReducer";
import {getCrossSellProducts, loadAllProductNumbers} from "../../reducers/ProductsReducer";
import InputComponent from "./InputComponent";
import Label from "reactstrap/es/Label";
import ModalComponent from "../Modals/ModalComponent";
import {withRouter} from "react-router";
import {
    attachProductToCategory, createProduct, detachProductToCategory, fetchCrossSellProducts,
    getAllCountries,
    getAllProductNumbers, getProductImageUrl, loadRootCategoryTreeByCountry,
    removeCurrentMessage,
    setProduct, updateProduct
} from "../../actions";
import {PATH_ADMIN_PRODUCT_EDIT_PAGE, PATH_ADMIN_PRODUCT_OVERVIEW_PAGE} from "../../routePaths";
import {notEmpty} from "../../util/ValidFn";
import {getCategories, getDECategories, getPLCategories} from "../../reducers/CategoryReducer";
import SelectComponent from "./SelectComponent";
import {PACKAGINGTYPES} from "../../enums/PackagingTypes";
import {getCountries} from "../../reducers/I18NReducer";
import Utils from "../../util/Utils";
import CrossSellProductModifyList from "../Product/CrossSellProductModifyList";


class ProductForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: this.getInitProductState(),
            file: undefined,
            taxOptions: [
                {name: 'Normal (19%)', value: 'NORMAL'},
                {name: 'Ermäßigt (7%)', value: 'REDUCED'},
                {name: 'Frei (0%)', value: 'FREE'}
            ],
            modals: {
                create: false,
                change: false,
                error: false,
                changeCountry: false
            },
            brand: undefined,
            category: undefined,
            subCategory: undefined,
            categories: [],
            subCategories: [],
            country: 'DE',
            packagingTypes: _.values(PACKAGINGTYPES).map((type) => {
                return {
                    label: this.props.intl.formatMessage({id: type.label}),
                    value: type.value
                }
            })
        };

        this.createProduct = this.createProduct.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(loadRootCategoryTreeByCountry(this.props.country));
        this.props.dispatch(getAllProductNumbers());
        this.props.dispatch(getAllCountries());
        this.setActiveCountry(this.props.country)
        if (this.props.edit) {
            const product = this.props.currentProduct;
            if (product) {
                this.setProduct(product);
                const productNumbers = [product.productNumber]
                this.props.dispatch(fetchCrossSellProducts(productNumbers));
            }
        } else {
            // this.clearCategories()
        }
    }

    componentWillReceiveProps(nextProps) {
        const nextUser = nextProps.userToManage;
        if (nextUser && this.props.edit) {
            this.setUserToManage(nextUser);
        }
        if(nextProps.currentProduct !== this.props.currentProduct){
                this.setProduct(nextProps.currentProduct);
            const productNumbers = [nextProps.currentProduct.productNumber]
            this.props.dispatch(fetchCrossSellProducts(productNumbers));
        }
        const nextMessage = nextProps.currentMessage;
        if (nextMessage) {
            if (nextMessage.source === 'UPDATE_PRODUCT') {
                this.showModal('change');
            }
            if (nextMessage.source === 'CREATE_PRODUCT') {
                this.showModal('create')
            }
            if (nextMessage.type === "ERROR") {
                this.showModal('error')
            }
        }
        const nextBrands = nextProps.brands ||nextProps.deCategories ||nextProps.plCategories
        if (nextBrands && !this.state.brand) {
            const product = this.state.product;
            const country = this.props.country;
            if(country ==='DE'){
                this.setState({brands:nextProps.deCategories})
            }else if(country ==='PL'){
                this.setState({brands:nextProps.plCategories})
            }
            if (product.categoryNumbers && product.categoryNumbers[0]){
                const categoryMap = Utils.getCategoryMap(country);
                product.categoryNumbers.forEach((cn)=>{
                    if(categoryMap[cn]){
                        this.setInitStateCategories(cn)
                    }
                })
            }

        }

    }

    setActiveCountry(country) {
        this.setState({'country': country})
    }

    setCategories(brand) {
        if(brand){
        const categories = brand.children ||[];
        // eslint-disable-next-line array-callback-return
        categories.map((category) => {
            if (!category.label)
                return category.label = category.name;
        })
        this.setState({categories: categories})
        }else{
           this.clearCategories();
        }
    }

    setSubCategories(category) {
        const subCategories = category.children || [];
        // eslint-disable-next-line array-callback-return
        subCategories.map((category) => {
            if (!category.label)
                return category.label = category.name;
        })
        this.setState({subCategories: subCategories})
    }

    clearCategories() {
        this.setState({
            brand: undefined,
            category: undefined,
            subCategory: undefined
        })
    }

    setProduct(nextProduct) {
       this.setState({product:nextProduct});
    }

    getInitProductState() {
        return {
            availabilities: {
                'DE': undefined,
                'PL': undefined
            },
            sortIndex: undefined,
            productGroup: undefined,
            productNumber: undefined,
            categoryNumbers: [],
            packaging: {
                type: PACKAGINGTYPES.PAL.value,
                capacity: undefined,
                palettesBundled: undefined
            },
            detail: {
                additionalInfos: {
                    'DE': undefined,
                    'PL': undefined
                },
                descriptions: {
                    'DE': undefined,
                    'PL': undefined
                },
                externalUrls: {
                    'DE': undefined,
                    'PL': undefined
                },
                imageExists: false,
                searchTags: undefined,
                shortDescriptions: {
                    'DE': undefined,
                    'PL': undefined
                },
            },
            discountCode: undefined,
            ean: null,
            names: {
                'DE': undefined,
                'PL': undefined
            },
            data: {
                formatDefinitions:  {
                    'DE': undefined,
                    'PL': undefined
                },
                forSales: {
                    'DE': undefined,
                    'PL': undefined
                },
                leastUnitsPackage: undefined,
                leastUnitsPalette: undefined,
                unitsPerPackage: undefined,
                load: false,
                taxTypes: {
                    'DE': undefined,
                    'PL': undefined
                },
                weight: undefined,
                materialUsageM2: undefined,
                materialUsageLFM: undefined,
                usagePerPalette: undefined,
            },
            stockTexts: {
                'DE': undefined
            },
            crossSellProductNumbers: [],
            crossSellProductGroups: []
        }
    }

    handleAddCrossSellProduct(event, crossSellProduct) {
        const productNumber = crossSellProduct.productNumber;
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product.crossSellProductGroups.push(crossSellProduct);
            nextState.product.crossSellProductNumbers.push(productNumber);
            this.props.dispatch(fetchCrossSellProducts(nextState.product.crossSellProductNumbers));
            return nextState;
        });
    }
    handleRemoveCrossSellProduct(event, crossSellProduct) {
        const productNumber = crossSellProduct.productNumber;
        const deleteIndex = this.state.product.crossSellProductNumbers.indexOf(productNumber);
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product.crossSellProductNumbers.splice(deleteIndex, 1);
            return nextState;
        });
    }
    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }

    setBrandByNumber(number) {
        const brands = this.state.brands;
        const brand = _.find(brands, {number});
        return new Promise((resolve) => this.setState({brand}, resolve));
    }

    setCategoryByNumber(number) {
        const category = _.find(this.state.brand.children, {number});
        this.setState({category});
    }

    setInitStateCategories(categoryNumber){
        const country = this.props.country;
        //leafId:{0:brand,1:categoryID}
        const sq = Utils.getLeafSq(country)[categoryNumber]
        if(!sq){
            console.error('category sequence not found')
        }
        const brand = Utils.getCategoryMap(country)[sq[0]];
        const category = Utils.getCategoryMap(country)[sq[1]];
        const subCategory = Utils.getCategoryMap(country)[categoryNumber];
        this.setState({brand,category,subCategory})
        this.setCategories(brand)
        this.setSubCategories(category)
    }
    setSubCategoryByNumber(number) {
        if (this.state.brand) {
            let found = false;
            const categories = this.state.brand.children;
            const size = categories.length;
            let i = 0;
            let subCategory = undefined
            //search for subCategory Object and then add Category because categoryId is not delivered by backend (thanks)
            while (!found && i < size) {
                subCategory = _.find(categories[i].children, {number})
                if (subCategory) {
                    found = true;
                    this.setState({category: categories[i]})
                }
                i++
            }
            this.setState({subCategory})
        }
    }

    createProduct(event) {
        event.preventDefault();
        const product = this.state.product;

        this.props.dispatch(createProduct(product, this.state.subCategory, this.state.file))
            .then((persistedProduct) => {
                this.switchToEditProduct(event, persistedProduct);
            });
    }

    switchToEditProduct(event, product) {
        this.props.dispatch(setProduct(product));
        this.switchToPage(event, PATH_ADMIN_PRODUCT_EDIT_PAGE);
    }

    isRequiredNotEmpty() {
        const product = this.state.product;
        const data = product.data;
        const packaging = product.packaging;
        const country = this.state.country;
        return notEmpty(product.productNumber) && notEmpty(product.names[country]) && notEmpty(product.productGroup)
            && notEmpty(data.leastUnitsPalette) && notEmpty(data.leastUnitsPackage) && notEmpty(packaging.capacity)
            && notEmpty(packaging.palettesBundled) && notEmpty(data.unitsPerPackage)
            && notEmpty(data.weight)
    }

    switchForSale(event) {
        event.preventDefault();
        const country = this.state.country;

        const saleInvert = !this.state.product.data.forSales[country];

        const product = Object.assign({}, this.state.product);
        product.data.forSales[country] = saleInvert;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product = product;
            return nextState;
        });
    }

    isProductNumberUnique() {
        let productNumbers = this.props.productNumbers || [];
        let productNumber = this.state.product.productNumber;
        return this.unique(productNumber, productNumbers)
    }

    isNewFormComplete() {
        return this.isRequiredNotEmpty() && this.isProductNumberUnique();
    }

    isEditFormComplete() {
        return this.isRequiredNotEmpty();
    }

    handleProductBasicDataChange(event) {
        const product = Object.assign({}, this.state.product);
        const targetName = event.target.name;
        const country = this.state.country;

        if (this.isCountryBasedAttribute(targetName)) {
            product[targetName][country] = event.target.value;
        } else {
            product[targetName] = event.target.value;
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product = product;
            return nextState;
        });
        this.props.changed();
    }

    handleProductDetailChange(event) {
        const detail = Object.assign({}, this.state.product.detail);
        const country = this.state.country;
        const targetName = event.target.name;

        if (this.isCountryBasedAttribute(targetName)) {
            detail[targetName][country] = event.target.value;
        } else {
            detail[targetName] = event.target.value;
        }

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product.detail = detail;
            return nextState;
        });
        this.props.changed();
    }

    handleProductDataChange(event) {
        const data = Object.assign({}, this.state.product.data);
        const targetName = event.target.name;

        if (this.isCountryBasedAttribute(targetName)) {
            const country = this.state.country;
            data[targetName][country] = event.target.value;
        } else {
            data[targetName] = event.target.value;
        }

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product.data = data;
            return nextState;
        });
        this.props.changed();
    }

    handleProductDataLoadChange(event) {
        const data = Object.assign({}, this.state.product.data);
        const target = event.target;
        const value = target.name === 'load' ? target.checked : target.value;
        const targetName = target.name;
        data[targetName] = value;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product.data = data;
            return nextState;
        });
        this.props.changed();
    }

    handlePackagingDataChange(event) {
        const packaging = Object.assign({}, this.state.product.packaging);
        const targetName = event.target.name;
        const country = this.state.country;
        if (this.isCountryBasedAttribute(targetName)) {
            packaging[targetName][country] = event.target.value;
        } else {
            packaging[targetName] = event.target.value;
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.product.packaging = packaging;
            return nextState;
        });
        this.props.changed();
    }

    setImageFile(e) {
        this.setState({file: e.target.files[0]});
    }

    isCountryBasedAttribute(attrName) {
        const countryBasedAttributes = [
            "availabilities",
            "names",
            "forSales",
            "formatDefinitions",
            "taxTypes",
            "stockTexts",
            "additionalInfos",
            "descriptions",
            "externalUrls",
            "shortDescriptions"
        ]
        return _.indexOf(countryBasedAttributes, attrName) >= 0;
    }

    updateProduct(event) {
        event.preventDefault();

        const product = this.state.product;
        const image = this.state.file || undefined;
        this.props.dispatch(updateProduct(product, image));
        const oldProductState = this.props.currentProduct;
        const country = this.props.country;
        const leafMap = Utils.getCategoryMap(country);
        const actualSubCategory = this.state.subCategory;
        const oldCategoryNumbers = oldProductState.categoryNumbers;
        if(oldCategoryNumbers && !oldCategoryNumbers.includes(actualSubCategory.number)){
            //detach old category
            oldCategoryNumbers.forEach((catNumber) => {
                if(leafMap[catNumber]){
                    this.props.dispatch(detachProductToCategory(catNumber, [product.productNumber]))
                }
            })
            //attach new category
            this.props.dispatch(attachProductToCategory(actualSubCategory.number, [product.productNumber]))
        }
        if(!oldCategoryNumbers ||oldCategoryNumbers.length === 0){
            this.props.dispatch(attachProductToCategory(actualSubCategory.number, [product.productNumber]))
        }
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    handleBrand(brand) {
        this.setState({brand, category: undefined, subCategory: undefined});
        this.setCategories(brand)
        // this.handleProductBasicDataChange(
        //     {
        //         target: {
        //             name: 'brandNumber',
        //             value: brand ? brand.number : undefined
        //         }
        //     }
        // )
    }

    handleCategory(category) {
        this.setState({category, subCategory: undefined});
        this.setSubCategories(category)
    }

    handleSubCategory(subCategory) {
        this.setState({subCategory})
        this.handleProductBasicDataChange(
            {
                target: {
                    name: 'categoryNumbers',
                    value: subCategory?[subCategory.number]:[]
                }
            }
        )
    }

    unique(value, compareArray) {
        if (compareArray) {
            return !compareArray.includes(value);
        }
        return false;
    }

    locateImageUrl(productNumber) {
        let url = this.state.imageUrl;
        if (!url && productNumber) {
            url = `${getProductImageUrl(productNumber)}`;

            this.setState(currentState => {
                const nextState = _.cloneDeep(currentState);
                nextState.imageUrl = url;
                return nextState;
            });
        }
        return this.state.imageUrl;
    }

    handlePackagingType(type) {
        this.handlePackagingDataChange(
            {
                target: {
                    name: 'type',
                    value: type.value || undefined
                }
            }
        )
    }



    render() {
        const brands = this.state.brands|| [];
        const categories = this.state.categories || [];
        const subCategories = this.state.subCategories || [];
        let productNumber = this.state.product.productNumber;
        let productData = this.state.product.data;
        let productDetail = this.state.product.detail;
        const country = this.props.country ||'DE';
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mt-2
                     mb-2">
                        {this.props.edit &&
                        <Button
                            color="success" href="" className="btn-block"
                            onClick={(e) => this.updateProduct(e)}
                            disabled={!this.isEditFormComplete()}
                        >
                            <FormattedMessage id="product.form.button.updateProduct"/>
                        </Button>
                        }
                        {this.props.create &&
                        <Button
                            href=""
                            className="btn-block"
                            onClick={(e) => this.createProduct(e)}
                            disabled={!this.isNewFormComplete()}
                        >
                            <FormattedMessage id="product.form.button.createProduct"/>
                        </Button>
                        }
                    </div>
                </div>

                <Form>
                    <div className="d-flex flex-column flex-md-row col-12 mt-2">
                        <div className="col-12 col-md-6">
                            <h2><FormattedMessage id="product.form.headline.baseData"/></h2>
                            <InputComponent
                                title="product.form.productNumber.title" type="text" name="productNumber"
                                value={productNumber}
                                handleChange={this.handleProductBasicDataChange.bind(this)}
                                formFeedback="product.form.productNumber.formFeedback"
                                required
                                disabled={this.props.edit}
                            />
                            {!this.isProductNumberUnique() && this.props.create &&
                            <div className="invalid-feedback" style={{display: 'block'}}><FormattedMessage id="product.form.invalid.artnr"/></div>}
                            <SelectComponent
                                title="product.form.select.brand.title"
                                name={"brand"}
                                options={brands}
                                value={this.state.brand}
                                onChange={this.handleBrand.bind(this)}
                                errorMessage={<FormattedMessage id="product.form.select.brand.error" />}
                                required
                            />
                            <SelectComponent
                                title="product.form.select.category.title"
                                name={"category"}
                                options={categories}
                                value={this.state.category}
                                onChange={this.handleCategory.bind(this)}
                                errorMessage={<FormattedMessage id="product.form.select.category.error"/>}
                                required
                            />
                            <SelectComponent
                                title="product.form.select.subcategory.title"
                                name={"subCategory"}
                                options={subCategories}
                                value={this.state.subCategory}
                                onChange={this.handleSubCategory.bind(this)}
                                errorMessage={<FormattedMessage id="product.form.select.subcategory.error" />}
                                required
                            />
                            <InputComponent
                                title="product.form.ean.title" type="ean13" name="ean"
                                value={this.state.product.ean}
                                handleChange={this.handleProductBasicDataChange.bind(this)}
                                maxLength={13}
                                disabled={this.props.edit}
                                formFeedback='Bitte geben Sie eine korrekte EAN an!'
                            />
                            <InputComponent
                                title="product.form.name.title" type="text" name="names"
                                value={this.state.product.names?.[country]}
                                handleChange={this.handleProductBasicDataChange.bind(this)}
                                maxLength={64}
                                formText='product.form.name.formText'
                                formFeedback='product.form.name.formFeedback'
                                required
                            />
                            <InputComponent
                                title="product.form.productGroup.title" type="number" name="productGroup"
                                value={this.state.product.productGroup}
                                handleChange={this.handleProductBasicDataChange.bind(this)}
                                formText='product.form.productGroup.formText'
                                formFeedback='product.form.productGroup.feedback'
                                min={0}
                                required
                            />
                            <InputComponent
                                title="Rabattstufe" type="text" name="discountCode"
                                value={this.state.product.discountCode}
                                handleChange={this.handleProductBasicDataChange.bind(this)}
                                maxLength={64}
                            />
                            <InputComponent
                                title="Webseite" type="text" name="externalUrls"
                                value={this.state.product.detail.externalUrls?.[country]}
                                handleChange={this.handleProductDetailChange.bind(this)}
                                maxLength={64}
                            />
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        name="load"
                                        type="checkbox"
                                        checked={this.state.product.data.load}
                                        onChange={this.handleProductDataLoadChange.bind(this)}/>
                                    {' '}
                                    <FormattedMessage id="product.form.load.text"/>
                                </Label>
                            </FormGroup>
                        </div>
                        <div className="col-12 col-md-6">
                            {this.state.product && productDetail && productDetail.imageExists &&
                            <FormGroup className="text-center">
                                <img alt={this.state.product.name} className="ml-3 mr-3"
                                     src={this.locateImageUrl(this.state.product.productNumber)}
                                     style={{height: '135px'}}/>
                            </FormGroup>
                            }
                            <InputComponent
                                title="product.form.image.title" type="file" name="productImage"
                                accept="image/x-png"
                                handleChange={this.setImageFile.bind(this)}
                                maxLength={64}
                                formText='product.form.image.formText'
                            />
                            <InputComponent
                                title="product.form.search.title" type="text" name="searchTags"
                                value={this.state.product.detail?.searchTags}
                                handleChange={this.handleProductDetailChange.bind(this)}
                                maxLength={64}
                                formText="product.form.search.formText"
                            />
                            <h2><FormattedMessage id="product.form.GroupAttributes.subTitle"/></h2>
                            <InputComponent
                                title="product.form.sortIndex.title" type="number" name="sortIndex"
                                value={this.state.product.sortIndex}
                                handleChange={this.handleProductBasicDataChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column  flex-md-row col-12 mt-2">
                        <div className="col-12 col-md-6">
                            <h2><FormattedMessage id="product.form.information.subTitle"/></h2>
                            <InputComponent
                                title="product.form.shortDesc.title" type="text" name="shortDescriptions"
                                value={this.state.product.detail.shortDescriptions?.[country]}
                                handleChange={this.handleProductDetailChange.bind(this)}
                                maxLength={128}
                            />
                            <InputComponent
                                title="product.form.description.title" type="textarea" name="descriptions"
                                value={this.state.product.detail.descriptions?.[country]}
                                handleChange={this.handleProductDetailChange.bind(this)}
                            />
                            <InputComponent
                                title="product.form.additionalInfo.title" type="text" name="additionalInfos"
                                value={this.state.product.detail.additionalInfos?.[country] || undefined}
                                handleChange={this.handleProductDetailChange.bind(this)}
                                maxLength={128}
                                formText="product.form.additionalInfo.formText"
                            />
                            <FormGroup>
                                <Label for="forSale"><FormattedMessage id="product.form.forsale.label"/></Label>
                                <InputGroup>
                                    <Button className="btn-block"
                                            color={productData.forSales[country] ? 'success' : 'danger'}
                                            onClick={(e) => this.switchForSale(e)}>
                                        <span>{productData.forSales[country] ? <FormattedMessage id="product.form.forsale.open.sale"/> : <FormattedMessage id="product.form.forsale.closed.sale"/>}</span>
                                    </Button>
                                </InputGroup>
                                <FormText>
                                    <FormattedMessage id="product.form.forsale.info"/>
                                </FormText>
                            </FormGroup>
                        </div>
                        <div className="col-12 col-md-6">
                            <span>
                                <h2><FormattedMessage id="product.form.CalculationParameters.subTitle"/></h2>
                                <InputComponent
                                    title="product.form.leastUnitsPalette.title" type="number" name="leastUnitsPalette"
                                    value={productData.leastUnitsPalette}
                                    handleChange={this.handleProductDataChange.bind(this)}
                                    formText="product.form.leastUnitsPalette.formText"
                                    formFeedback="product.form.leastUnitsPalette.formFeedback"
                                    min={1}
                                    required
                                />
                                <InputComponent
                                    title="product.form.leastUnitsPackage.title" type="number" name="leastUnitsPackage"
                                    value={productData.leastUnitsPackage}
                                    handleChange={this.handleProductDataChange.bind(this)}
                                    formText="product.form.leastUnitsPackage.formText"
                                    formFeedback="product.form.leastUnitsPackage.formFeedback"
                                    min={1}
                                    required
                                />
                                <InputComponent
                                    title="product.form.capacity.title" type="number" name="capacity"
                                    value={this.state.product.packaging?.capacity}
                                    handleChange={this.handlePackagingDataChange.bind(this)}
                                    formText="product.form.capacity.formText"
                                    formFeedback="product.form.capacity.formFeedback"
                                    min={0}
                                    step={0.1}
                                    required
                                />
                                <InputComponent
                                    title="product.form.palettesBundled.title" type="number" name="palettesBundled"
                                    value={this.state.product.packaging?.palettesBundled}
                                    handleChange={this.handlePackagingDataChange.bind(this)}
                                    formText="product.form.palettesBundled.formText"
                                    formFeedback="product.form.palettesBundled.formFeedback"
                                    min={0}
                                    step={0.01}
                                    required
                                />
                        </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row col-12 mt-2">
                        <div className="col-12 col-md-6">
                            <h2><FormattedMessage id="product.form.ProductAttributes.subTitle"/></h2>
                            <InputComponent
                                title="product.form.formatDefinition.title" type="text" name="formatDefinitions"
                                value={productData.formatDefinitions?.[country]}
                                handleChange={this.handleProductDataChange.bind(this)}
                            />
                            <SelectComponent
                                title="product.form.select.packagingtypes.title"
                                name={"type"}
                                options={this.state.packagingTypes}
                                labelKey={'label'}
                                onChange={this.handlePackagingType.bind(this)}
                                value={this.state.product.packaging?.type}
                                required
                                formFeedback="product.form.select.packagingtypes.feedback"
                            />
                            <InputComponent
                                title="product.form.unitsPerPackage.title" type="number" name="unitsPerPackage"
                                value={productData.unitsPerPackage}
                                handleChange={this.handleProductDataChange.bind(this)}
                                formText="product.form.unitsPerPackage.formText"
                                formFeedback="product.form.unitsPerPackage.formFeedback"
                                min={1}
                                required
                            />
                            <InputComponent
                                title="product.form.materialUsageM2.title" type="number" name="materialUsageM2"
                                value={productData.materialUsageM2}
                                handleChange={this.handleProductDataChange.bind(this)}
                                formText="product.form.materialUsageM2.formText"
                                formFeedback="product.form.materialUsageM2.formFeedback"
                                min={0}
                                step={0.1}
                            />
                            <InputComponent
                                title="product.form.materialUsageLFM.title" type="number" name="materialUsageLFM"
                                value={productData.materialUsageLFM}
                                handleChange={this.handleProductDataChange.bind(this)}
                                formText="product.form.materialUsageLFM.formText"
                                formFeedback="product.form.materialUsageLFM.formFeedback"
                                min={0}
                                step={0.1}
                            />
                            <InputComponent
                                title="product.form.usagePerPalette.title" type="number" name="usagePerPalette"
                                value={this.state.product.data.usagePerPalette}
                                handleChange={this.handleProductDataChange.bind(this)}
                                formFeedback="product.form.usagePerPalette.formFeedback"
                                disabled
                                min={0}
                                step={0.01}
                                formText="product.form.usagePerPalette.formText"
                            />
                            <InputComponent
                                title="product.form.weight.title" type="number" name="weight"
                                value={productData.weight}
                                handleChange={this.handleProductDataChange.bind(this)}
                                formText="product.form.weight.formText"
                                formFeedback="product.form.weight.formFeedback"
                                min={0}
                                step={0.01}
                                required
                            />
                        </div>
                    </div>
                </Form>
                <div className="row mt-5">
                    <div className="col">
                        <h2 className="text-center">Cross-Sell Produkte</h2>
                        <div className="productList">
                            <CrossSellProductModifyList dispatch={this.props.dispatch} originProduct={this.props.currentProduct} />
                        </div>
                    </div>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() =>
                        this.showModal('create')
                    }
                    hide={() =>
                        this.hideModal('create')
                    }
                    color="success"
                    title="product.form.modal.create.success.title"
                    message="product.form.modal.create.success.message"
                    onSuccess={(e) => {
                        this.switchToPage(e, PATH_ADMIN_PRODUCT_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText="product.form.modal.create.success.successText"
                />

                <ModalComponent
                    isOpen={this.state.modals.change}
                    toggle={() => this.showModal('change')}
                    hide={() => this.hideModal('change')}
                    color="success"
                    title="product.form.modal.update.success.title"
                    message="product.form.modal.update.success.message"
                    onSuccess={(e) => {
                        this.switchToPage(e, PATH_ADMIN_PRODUCT_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText="product.form.modal.update.success.successText"
                />

                {this.props.currentMessage &&
                <ModalComponent
                    isOpen={this.state.modals.error}
                    toggle={() => this.showModal('error')}
                    hide={() => this.hideModal('error')}
                    color="danger"
                    title="product.form.modal.error.title"
                    message={this.props.currentMessage.msg}
                    onSuccess={() => {
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('error')
                    }}
                    successText="product.form.modal.error.successText"
                />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        brands: getCategories(state).stateCategories || [],
        deCategories: getDECategories(state),
        plCategories: getPLCategories(state),
        currentUser: getCurrentUser(state),
        currentProduct: getCurrentProduct(state),
        currentMessage: getCurrentMessage(state),
        crossSellProductGroups: getCrossSellProducts(state),
        productNumbers: loadAllProductNumbers(state),
        countries: getCountries(state)
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(ProductForm)));
