import React, {Component} from 'react';
import {Button} from "reactstrap";
import {withRouter} from "react-router";

class BackButton extends Component {
    constructor(props) {
        super(props);
        this.switchToPage = this.switchToPage.bind(this);
    }

    switchToPage(path) {
        if (path) {
            this.props.history.push(path);
        } else {
            this.props.history.goBack()
        }
    }

    render() {
        const path = this.props.path || undefined
        return (
            <Button onClick={() => {
                this.switchToPage(path)
            }} color="white"><span className="oi oi-chevron-left"/> <span>Zurück</span></Button>
        );
    }
}

BackButton.propTypes = {};

export default withRouter(BackButton);
