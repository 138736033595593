import {callSecureApi} from '../../util/apiCaller';

import {SET_OFFER, SET_OFFERS, SET_CHECKLIST} from '../types';
import {setCurrentMessage} from '../MessagesAction';

export function getOffersFromCustomer(customerId) {
    return dispatch => {
        callSecureApi({
            endpoint: 'priceservice/v1/offer/customer/{customerId}',
            method: 'get',
            query: {customerId}
        }).then(res => {
            if (res && res.offers)
                dispatch(setOffers(res.offers))
            else
                dispatch(setOffers([]))
        }).catch(err => {
            const message = {
                type: 'ERROR',
                msg: `Angebote konnten nicht geladen werden!`,
                source: 'OFFER_OVERVIEW'
            };
            dispatch(setCurrentMessage(message))
            console.error('unable to get offers ', err)
        })
    }
}

export function fetchOffer(offerNumber) {
    return dispatch => {
        callSecureApi({
            endpoint: 'priceservice/v1/offer/{offerNumber}',
            method: 'get',
            query: {offerNumber}
        }).then(res => {
            if (res)
                dispatch(setOffer(res))
            else
                dispatch(setOffer([]))
        }).catch(err => {
            console.error('unable to get offer ',offerNumber, err)
        })
    }
}
export function setOffers(offers){
    return {
        type: SET_OFFERS,
        offers
    }
}
export function setOffer(offer){
    return {
        type: SET_OFFER,
        offer
    }
}
export function setCheckList(checklist){
    return {
        type:SET_CHECKLIST,
        checklist
    }
}
