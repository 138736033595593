/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';

function nestedObject(f) {
  return function l(...args) {
    return f.apply(this, args);
  };
}

const UserCredentials = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string
};

const Contact = {
  email: PropTypes.string,
  fax: PropTypes.string,
  mobile: PropTypes.string,
  phone: PropTypes.string.isRequired
};

const CustomerServiceUser = {
  contact: PropTypes.shape(Contact).isRequired,
  credentials: PropTypes.shape(UserCredentials),
  customerId: PropTypes.string,
  expires: PropTypes.number,
  firstName: PropTypes.string,
  gender: PropTypes.string,
  id: PropTypes.number,
  language: PropTypes.string.isRequired,
  lastLogin: PropTypes.number,
  lastName: PropTypes.string.isRequired,
  projectIds: PropTypes.arrayOf(PropTypes.number),
  role: PropTypes.oneOf(['SUPER', 'ADMIN', 'USER', 'ANONYMOUS', 'SYSTEM', 'CUSTOMER_USER']),
  title: PropTypes.string,
};
const OrderServiceUser = {
  contact: PropTypes.shape(Contact),
  customerId: PropTypes.string,
  firstName: PropTypes.string,
  gender: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string.isRequired,
  title: PropTypes.string,
};
export const User = PropTypes.oneOfType([PropTypes.shape(CustomerServiceUser),PropTypes.shape(OrderServiceUser)])
export const SalesOrganization = {
  contact: PropTypes.shape(Contact).isRequired,
  customerNumbers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired
}
export const Users = {
  users: PropTypes.arrayOf(PropTypes.shape(User))
};

export const UserDetails = {
  accountNonExpired: PropTypes.bool,
  accountNonLocked:	PropTypes.bool,
  authorities: PropTypes.arrayOf(PropTypes.object),
  credentialsNonExpired: PropTypes.bool,
  enabled:PropTypes.bool,
  password:	PropTypes.string,
  username: PropTypes.string

}
export const ProductServicePackaging = {
  capacity: PropTypes.number.isRequired,
  palettesBundled:PropTypes.number.isRequired,
  type: PropTypes.oneOf([ 'PAL', 'SA', 'TO', 'EIM', 'KG', 'KGR', 'PAK', 'ST', 'KAR', 'KT', 'KAN', 'L', 'VPE', 'BEU', 'BEC', 'LTR', 'FLA', 'DOS', 'M2', 'M3', 'SET', 'M', 'FAS', 'ROL', 'PAA' ]).isRequired
};

export const Packaging = {
  type: PropTypes.oneOf([ 'PAL', 'SA', 'TO', 'EIM', 'KG', 'KGR', 'PAK', 'ST', 'KAR', 'KT', 'KAN', 'L', 'VPE', 'BEU', 'BEC', 'LTR', 'FLA', 'DOS', 'M2', 'M3', 'SET', 'M', 'FAS', 'ROL', 'PAA' ]).isRequired
};

const ProductData = {
  cargos: PropTypes.object,
  forSales: PropTypes.object.isRequired,
  formatDefinitions: PropTypes.string.isRequired,
  leastUnitsPackage: PropTypes.number.isRequired,
  leastUnitsPalette: PropTypes.number.isRequired,
  load:PropTypes.bool,
  materialUsageLFM: PropTypes.number,
  materialUsageM2: PropTypes.number,
  prices: PropTypes.object,
  grossPrices: PropTypes.object,
  grossCargos: PropTypes.object,
  unitsPerPackage: PropTypes.number.isRequired,
  usagePerPalette: PropTypes.number,
  weight: PropTypes.number.isRequired
};

const ProductDetail = {
  additionalInfo: PropTypes.string,
  admission: PropTypes.string,
  bookletURL: PropTypes.string,
  brickwork: PropTypes.string,
  conductivity: PropTypes.string,
  density: PropTypes.string,
  description: PropTypes.string,
  energyStandard: PropTypes.string,
  imageURL: PropTypes.string,
  notch: PropTypes.string,
  shortDescription: PropTypes.string,
  searchTags: PropTypes.string,
  strength: PropTypes.string,
  strengthClass: PropTypes.string,
  technicalWhitePaperURL: PropTypes.string
};

const NestedOrderItem = nestedObject(() => {
  return OrderItem;
});


const NestedProduct = nestedObject(() => {
  return Product;
});


const NestedOrderItemMultiplierProduct = nestedObject(() => {
  return OrderItemMultiplierProduct;
});

export const OrderItemMultiplierProduct = {
  option: PropTypes.shape(NestedOrderItemMultiplierProduct),
  orderItem: PropTypes.shape(NestedOrderItem)
};

const NestedMultiplierProduct = nestedObject(() => {
  return MultiplierProduct;
});

export const MultiplierProduct = {
  product: PropTypes.shape(NestedProduct),
  option: PropTypes.shape(NestedMultiplierProduct)
};
export const PriceServiceProduct = {
  organizationNumber: PropTypes.string,
  prices: PropTypes.object,
  productNumber: PropTypes.string,
  workerNumber: PropTypes.string
}
export const ProductServiceProduct = {
  availabilities: PropTypes.object.isRequired,
  categoryNumbers: PropTypes.array,
  data: PropTypes.shape(ProductData).isRequired,
  detail: PropTypes.shape(ProductDetail),
  ean: PropTypes.string,
  sortIndex: PropTypes.number,
  names: PropTypes.object.isRequired,
  packaging: PropTypes.shape(Packaging).isRequired,
  productGroup: PropTypes.number.isRequired,
  productNumber: PropTypes.string.isRequired,
  stockTexts: PropTypes.object
};
export const Product = PropTypes.oneOfType([PropTypes.shape(ProductServiceProduct),PropTypes.shape(PriceServiceProduct)])


export const ProductGroup = {
  imageProductNumber: PropTypes.string,
  imageProductName: PropTypes.string,
  productGroup: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape(Product))
};

export const ProductGroups = {
  products: PropTypes.arrayOf(PropTypes.shape(ProductGroup))
};

const NestedCategory = nestedObject(() => {
  return Category;
});

export const Category = {
  children: PropTypes.arrayOf(PropTypes.shape(NestedCategory)),
  description: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  parent: PropTypes.shape(NestedCategory),
  productGroups: PropTypes.arrayOf(PropTypes.number),
  productNumbers: PropTypes.arrayOf(PropTypes.string),
  sort: PropTypes.number
};

export const Categories = {
  categories: PropTypes.arrayOf(PropTypes.shape(Category))
};

export const Address = {
  additionalInformation: PropTypes.string,
  addressLine1: PropTypes.string.isRequired,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  city: PropTypes.string.isRequired,
  company: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string.isRequired
};

export const Accounting = {
  accountant: PropTypes.string.isRequired,
  bank: PropTypes.string,
  bic: PropTypes.string.isRequired,
  iban: PropTypes.string.isRequired,
  jurisdiction: PropTypes.string,
  registerNumber: PropTypes.string,
  saleTaxId: PropTypes.string,
  taxNumber: PropTypes.string
};

export const Worker = {
  accounting: PropTypes.shape(Accounting).isRequired,
  address: PropTypes.shape(Address).isRequired,
  blocked: PropTypes.bool,
  contact: PropTypes.shape(Contact).isRequired,
  customerNumbers: PropTypes.array,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired
};
export const Workers = {
  workers: PropTypes.arrayOf(PropTypes.shape(Worker))
}

export const Project = {
  address: PropTypes.shape(Address).isRequired,
  authority: PropTypes.shape(User),
  customerId: PropTypes.string.isRequired,
  expires: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  number: PropTypes.string,
  temporary: PropTypes.bool,
  worker: PropTypes.shape(Worker),
  userIds: PropTypes.arrayOf(PropTypes.number)
};

export const Projects = {
  projects: PropTypes.arrayOf(PropTypes.shape(Project))
};

export const OrderItem = {
  capacity: PropTypes.number,
  data: PropTypes.shape(ProductData),
  name: PropTypes.string.isRequired,
  packaging: PropTypes.shape(Packaging).isRequired,
  palettes: PropTypes.number,
  productNumber: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  units:PropTypes.number
};

export const Facility = {
  address: PropTypes.shape(Address),
  contact: PropTypes.shape(Contact),
  name: PropTypes.string
};

export const Facilities = {
  facilities: PropTypes.arrayOf(PropTypes.shape(Facility))
}

export const Tour = {
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  type: PropTypes.oneOf(['MORNING', 'NOON', 'END_OF_BUSINESS', 'DAY_TIME'])
};

export const Carrier = {
  contact: PropTypes.shape(Contact),
  id: PropTypes.number,
  name: PropTypes.string
};

export const Truck = {
  id: PropTypes.number,
  capacityInKilo: PropTypes.number,
  capacityPalettes: PropTypes.number,
  type: PropTypes.oneOf(['CRANE', 'CRANE_34', 'ARTICULATED', 'ARTICULATED_34', 'TRAILER', 'TRAILER_34', 'CUSTOM']),
  licensePlate: PropTypes.string
};

export const Trucks = {
  retailers: PropTypes.arrayOf(PropTypes.shape(Truck))
};

export const CommissioningData = {
  carrier: PropTypes.shape(Carrier),
  confirmedTruck: PropTypes.shape(Truck)
};

export const Delivery = {
  additionalInformation: PropTypes.string,
  alternativeTour: PropTypes.shape(Tour),
  commissioningData: PropTypes.shape(CommissioningData),
  confirmed: PropTypes.bool,
  confirmedTour: PropTypes.shape(Tour),
  eta: PropTypes.number,
  finalDelivery: PropTypes.bool,
  handLiftTruckRequired: PropTypes.bool,
  machineOnly: PropTypes.bool,
  reloadRequired: PropTypes.bool,
  scheduledTour: PropTypes.shape(Tour),
  soloFee: PropTypes.bool,
  soloPassable: PropTypes.bool,
  trackingCode: PropTypes.string,
  truck: PropTypes.shape(Truck)
};

export const Pickup = {
  additionalInformation: PropTypes.string,
  alternativeTour: PropTypes.shape(Tour),
  confirmedTour: PropTypes.shape(Tour),
  facility: PropTypes.shape(Facility),
  scheduledTour: PropTypes.shape(Tour),
  truck: PropTypes.shape(Truck)
};

export const ReturnItems = {
  bigBags: PropTypes.number,
  palettesEmpty: PropTypes.number,
  palettesFull: PropTypes.number
};

export const Cart = {
  cartId: PropTypes.number,
  createdAt: PropTypes.number,
  customerId: PropTypes.string,
  delivery: PropTypes.shape(Delivery).isRequired,
  deliveryAddress: PropTypes.shape(Address),
  issuedBy: PropTypes.shape(User),
  locked: PropTypes.bool,
  offerNumber:PropTypes.string,
  orderId: PropTypes.number,
  orderItems: PropTypes.arrayOf(PropTypes.shape(OrderItem)),
  payment:PropTypes.string,
  pickup: PropTypes.shape(Pickup).isRequired,
  projectId: PropTypes.number,
  returnItems: PropTypes.shape(ReturnItems),
  totalPalettes: PropTypes.number.isRequired,
  totalWeight: PropTypes.number.isRequired,
  totalGrossPriceSum: PropTypes.number,
  totalGrossCargoSum: PropTypes.number,
  totalNetPriceSum: PropTypes.number,
  totalNetCargoSum: PropTypes.number,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.shape(User),
  workerNumber:PropTypes.string
};

export const Carts = {
  carts: PropTypes.arrayOf(PropTypes.shape(Cart))
};

export const Message = {
  msg: PropTypes.string,
  type: PropTypes.oneOf(['INFO', 'WARN', 'ERROR']),
  source: PropTypes.string,
  productQuantity: PropTypes.number,
  productNumber: PropTypes.string
};
export const CustomerServiceCustomer = {
  address: PropTypes.shape(Address).isRequired,
  company: PropTypes.bool,
  contact: PropTypes.shape(Contact).isRequired,
  creditLine: PropTypes.number,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  projectIds: PropTypes.arrayOf(PropTypes.string),
  salesOrganization: PropTypes.shape(SalesOrganization),
  userIds: PropTypes.arrayOf(PropTypes.number)
};
export const OrderServiceCustomer = {
  address: PropTypes.shape(Address).isRequired,
  company: PropTypes.bool,
  contact: PropTypes.shape(Contact),
  creditLine: PropTypes.number,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  salesOrganization: PropTypes.shape(SalesOrganization),
};
export const PriceServiceCustomer = {
  createdAt: PropTypes.number,
  discounts: PropTypes.object,
  name: PropTypes.string,
  number: PropTypes.string,
  organizationNumber: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape(Product)),
  projects: PropTypes.arrayOf(PropTypes.shape(Project)),
  updatedAt: PropTypes.number,
  workerProducts:PropTypes.arrayOf(PropTypes.shape(Product))
};

export const Customer = PropTypes.oneOfType([PropTypes.shape(PriceServiceCustomer),PropTypes.shape(OrderServiceCustomer),PropTypes.shape(CustomerServiceCustomer)])
export const Customers = {
  customers: PropTypes.arrayOf(PropTypes.shape(Customer))
};

export const Order = {
  billingAddress: PropTypes.shape(Address).isRequired,
  cartId: PropTypes.number.isRequired,
  createdAt: PropTypes.number,
  customer: PropTypes.shape(OrderServiceCustomer).isRequired,
  delivery: PropTypes.shape(Delivery).isRequired,
  deliveryAddress: PropTypes.shape(Address),
  issuedBy: PropTypes.shape(User),
  locked: PropTypes.bool,
  offerNumber:PropTypes.string,
  orderId: PropTypes.number.isRequired,
  orderItems: PropTypes.arrayOf(PropTypes.shape(OrderItem)),
  orderNumber: PropTypes.string,
  payment: PropTypes.string,
  pickup: PropTypes.shape(Pickup).isRequired,
  processedBy: PropTypes.shape(User),
  processedAt: PropTypes.number,
  project: PropTypes.shape(Project).isRequired,
  shippingNumber: PropTypes.string,
  status: PropTypes.oneOf(
    ['NEW', 'SCHEDULED', 'ACCEPTED', 'REJECTED', 'PROCESSING', 'CANCELLED', 'ADJUSTED', 'READY_FOR_SHIPMENT', 'FINALIZED', 'SHIPPED', 'DELIVERED', 'DELETION']
  ),
  totalGrossCargoSum: PropTypes.number,
  totalGrossPriceSum: PropTypes.number,
  totalNetCargoSum: PropTypes.number,
  totalNetPriceSum:PropTypes.number,
  totalPalettes: PropTypes.number,
  totalWeight: PropTypes.number,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.shape(User),
  version: PropTypes.number,
  workerNumber: PropTypes.string
};

export const Orders = {
  orders: PropTypes.arrayOf(PropTypes.shape(Order))
};

export const BlockableWeekday = {
  weekday: PropTypes.number.isRequired,
  blocked: PropTypes.bool.isRequired
};

export const FederalState = {
  id: PropTypes.number,
  name: PropTypes.string
};

export const BlockedDate = {
  date: PropTypes.instanceOf(Date),
  id: PropTypes.string
};

export const Countries = {
  countries: PropTypes.oneOf(["DE","PL"])
}

export const Languages = {
  languages: PropTypes.oneOf(["DE","PL"])
}
export const Locales = {
  locales: PropTypes.string
}

export const SalesOrganizations = {
  salesOrganization: PropTypes.arrayOf(PropTypes.shape(SalesOrganization))
}

export const Registration = {
  gender: PropTypes.oneOf(["MALE", "FEMALE"]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  customerNumber: PropTypes.string,
  customerName: PropTypes.string,
  addressLine1: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string.isRequired,
}
