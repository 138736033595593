export function isValidEmail(value){
    const email = value;
    if(email.length < 6){
        return false
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailLower = String(email).toLowerCase();
    return emailRegex.test(emailLower);
}

export function isValidPhone(value) {
    const phone = value;
    if (phone.length < 1) {
        return false;
    }
    const phoneRegex = /^(((((((00|\+)49[ \-/]?)|0)[1-9][0-9]{1,4})[ \-/]?)|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)[ \-/]?))[0-9]{1,7}([ \-/]?[0-9]{1,5})?)$/;
    return phoneRegex.test(phone);
}

export function isValidNumber(value) {
    const number = value;
    const numberRegex = /^[1-9]\d*$/;
    return numberRegex.test(number);
}

export function notEmpty(value){
    return value!== undefined && value!== null && value.toString().length > 0;
}
