import {SET_CUSTOMER_WORKERS} from '../actions/types';

const initialState = {
    customerWorkers: {}
};

const CustomerWorkersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CUSTOMER_WORKERS:{
            return {
                ...state,
                customerWorkers:action.workers
            };
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getCustomerWorkers = (state) => state.customerWorkers.customerWorkers

// Export Reducer
export default CustomerWorkersReducer;
