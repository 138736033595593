import React, {Component} from 'react';
import Button from "reactstrap/es/Button";
import {loadCurrentUser, removeCurrentMessage, suspendAccount, changePassword} from "../actions";
import {getCurrentUser} from "../reducers/UserReducer";
import {connect} from "react-redux";
import {Customer, Message, User} from "../proptypes";
import PropTypes from "prop-types"
import {withRouter} from "react-router";
import ModalComponent from "../components/Modals/ModalComponent";
import {
    PATH_ADMIN_PROJECT_SETTINGS_OVERVIEW_PAGE,
    PATH_CHANGE_CREDENTIALS,
    PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE,
    PATH_LOGIN_PAGE,
    PATH_USER_EDIT_CUSTOMER_PAGE,
    PATH_USER_MANAGE_USER_PAGE
} from "../routePaths";
import Form from "reactstrap/es/Form";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Input from "reactstrap/es/Input";
import FormFeedback from "reactstrap/es/FormFeedback";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import {getCurrentMessage} from "../reducers/MessageReducer";
import {FormattedMessage} from "react-intl";
import {getCustomer} from "../reducers/CustomerReducer";

class UserSettingsPage extends Component {

    constructor(props) {
        super(props);

        this.deactivateAccount = this.deactivateAccount.bind(this);
        this.showSuspendAccountModal = this.showSuspendAccountModal.bind(this);
        this.hideSuspendAccountModal = this.hideSuspendAccountModal.bind(this);
        this.suspendAccount = this.suspendAccount.bind(this);
        this.changePassword = this.changePassword.bind(this);

        this.state = {
            showSuspendAccountModal: false,
            password: '',
            repeat: '',
            showPasswordChangeModal: false,
            newEmail: undefined,
            newEmailRepeat: undefined
        }
    }

    componentDidMount() {
        this.props.dispatch(loadCurrentUser())
    }

    componentWillReceiveProps(nextProps) {
        const nextMessage = nextProps.currentMessage;
        if (nextMessage && nextMessage.source === 'CHANGE_PASSWORD') {
            this.showPasswordChangeModal();

            if (nextMessage.type === 'INFO') {
                this.setState({
                    password: '',
                    repeat: ''
                });
            }
        }
    }

    showPasswordChangeModal() {
        this.setState({

            showPasswordChangeModal: true
        });
    }

    hidePasswordChangeModal() {
        this.setState({
            showPasswordChangeModal: false
        });

        this.props.dispatch(removeCurrentMessage());
    }

    isPasswordEmpty() {
        const password = this.state.password;
        return password ? password.length === 0 : true;
    }

    isPasswordValid() {
        if (!this.isPasswordEmpty()) {
            const password = this.state.password;
            return password.length > 3;
        }
        return false;
    }

    isRepeatPasswordEmpty() {
        const repeatPass = this.state.repeat;
        return repeatPass ? repeatPass.length === 0 : true;
    }

    isRepeatPasswordValid() {
        if (this.isPasswordValid() && !this.isRepeatPasswordEmpty()) {
            const password = this.state.password;
            const repeat = this.state.repeat;
            return password === repeat;
        }
        return false;
    }

    handlePasswordChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'password') {
            this.setState({
                password: value
            });
        }
        if (name === 'repeat') {
            this.setState({
                repeat: value
            });
        }
    }

    changePassword(event) {
        event.preventDefault();

        const user = this.props.currentUser;
        const email = user.credentials.email;
        const password = this.state.password;
        const credentials = {email, password};

        this.props.dispatch(changePassword(user, credentials));
    }

    deactivateAccount(e) {
        e.preventDefault();
        this.showSuspendAccountModal();
    }

    showSuspendAccountModal() {
        this.setState({
            showSuspendAccountModal: true
        });
    }

    hideSuspendAccountModal() {
        this.setState({
            showSuspendAccountModal: false
        });
    }

    suspendAccount() {
        const userId = this.props.currentUser.id;
        this.props.dispatch(suspendAccount(userId));
    }

    goToPage(e, path) {
        e.preventDefault();
        this.props.history.push(path);
    }

    render() {
        return (
            <div className="page-content">
                <div className="container ">
                    <h1 className="h2 text-center mb-4">
                        <FormattedMessage id="settings.menu.headline"/>
                    </h1>
                    <div className="offset-md-3 col-md-6 col-12 ">
                        <Form>
                            <h5>
                                <FormattedMessage id="settings.pw.change.headline"/>
                            </h5>
                            <FormGroup>
                                <Label for="password"><FormattedMessage id="settings.pw.change.newpw.label" /></Label>
                                <Input
                                    type="password" name="password" id="password"
                                    invalid={!this.isPasswordEmpty() && !this.isPasswordValid()}
                                    value={this.state.password} onChange={this.handlePasswordChange.bind(this)}
                                />
                                <FormFeedback>
                                    <FormattedMessage id="settings.pw.change.newpw.formfeedback" />
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="repeat"><FormattedMessage id="settings.pw.change.repeatpw.label" /></Label>
                                <Input
                                    type="password" name="repeat" id="repeat"
                                    invalid={!this.isRepeatPasswordEmpty() && !this.isRepeatPasswordValid()}
                                    value={this.state.repeat} onChange={this.handlePasswordChange.bind(this)}
                                />
                                <FormFeedback><FormattedMessage id="settings.pw.change.repeatpw.formfeedback" /></FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    className="btn btn-primary btn-block" type="submit" color="primary"
                                    disabled={!this.isRepeatPasswordValid()} onClick={(e) => this.changePassword(e)}
                                >
                                    <FormattedMessage id="settings.pw.change.changepw.button" />
                                </Button>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="d-flex flex-column offset-md-3 col-md-6 col-12">
                        <span className="pb-3 text-center">
                            <Button className="w-100" onClick={(e) => {
                                this.goToPage(e, PATH_CHANGE_CREDENTIALS)
                            }}>
                                E-Mail Adresse ändern
                            </Button>
                        </span>
                        <span className="pb-3 text-center">
                            <Button className="w-100" onClick={(e) => {
                                this.goToPage(e, PATH_ADMIN_PROJECT_SETTINGS_OVERVIEW_PAGE)
                            }}>
                                <FormattedMessage id="settings.project.menu" />
                            </Button>
                        </span>
                        {this.props.customer.salesRepresentative && this.props.currentUser.role !== "CUSTOMER_USER" &&
                            <span className="pb-3 text-center">
                            <Button className="w-100" onClick={(e) => {
                                this.goToPage(e, PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE)
                            }}>
                                <FormattedMessage id="settings.facility.menu"/>
                            </Button>
                        </span>
                        }
                        {this.props.currentUser.role !== "CUSTOMER_USER" &&
                            <span className="pb-3 text-center">
                            <Button className="w-100" onClick={(e) => {
                                this.goToPage(e, PATH_USER_MANAGE_USER_PAGE)
                            }}>
                                <FormattedMessage id="settings.usermanage.menu"/>
                            </Button>
                        </span>
                        }
                        {this.props.currentUser.role !== "CUSTOMER_USER" &&
                            <span className="pb-3 text-center">
                            <Button className="w-100" onClick={(e) => {
                                this.goToPage(e, PATH_USER_EDIT_CUSTOMER_PAGE)
                            }}>
                                 <FormattedMessage id="settings.retailer.menu"/>
                            </Button>
                        </span>
                        }
                        <span className="pb-3 text-center">
                            <Button className="w-100" onClick={(e) => {
                                this.deactivateAccount(e)
                            }}>
                               <FormattedMessage id="settings.deactivate.account.menu" />
                            </Button>
                        </span>
                    </div>

                </div>

                {/* Suspend Account Modal*/}
                <ModalComponent
                    isOpen={this.state.showSuspendAccountModal}
                    toggle={this.showSuspendAccountModal}
                    hide={this.hideSuspendAccountModal}
                    color="danger"
                    title="Account deaktivieren"
                    message="Sind Sie sicher, dass Sie ihren Account deaktivieren möchten?"
                    onSuccess={(e) => {
                        this.hideSuspendAccountModal();
                        this.suspendAccount();
                        this.goToPage(e, PATH_LOGIN_PAGE)
                    }}
                    onCancel={() => this.hideSuspendAccountModal()}
                    cancelText="Nein"
                    successText="Account deaktivieren"
                />

                <Modal isOpen={this.state.showPasswordChangeModal} fade={false} toggle={() => this.showPasswordChangeModal()}>
                    <ModalHeader toggle={() => this.hidePasswordChangeModal()}/>
                    <ModalBody className="text-center">
                        {(this.props.currentMessage && this.props.currentMessage.type === 'INFO' && this.props.currentMessage.source === 'CHANGE_PASSWORD') &&
                        <div className="alert alert-success">
                            {this.props.currentMessage.msg}
                        </div>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'CHANGE_PASSWORD') &&
                        <div className="alert alert-danger">
                            {this.props.currentMessage.msg}
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.hidePasswordChangeModal()}>Schließen</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

UserSettingsPage.propTypes = {
    currentUser: PropTypes.shape(User),
    customer: PropTypes.shape(Customer),
    currentMessage: PropTypes.shape(Message)
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        customer: getCustomer(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(UserSettingsPage));
