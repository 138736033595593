import {combineReducers} from 'redux';

import UserRegistrationReducer from "./reducers/UserRegistrationReducer";
import CustomerFacilityReducer from "./reducers/CustomerFacilityReducer";
import CustomerFacilitiesReducer from "./reducers/CustomerFacilitiesReducer";
import UserReducer from './reducers/UserReducer';
import SettingsReducer from './reducers/SettingsReducer';
import MessageReducer from './reducers/MessageReducer';
import CustomersReducer from './reducers/CustomersReducer';
import CustomerReducer from './reducers/CustomerReducer';
import UsersReducer from './reducers/UsersReducer';
import ProductReducer from "./reducers/ProductReducer";
import CategoryReducer from "./reducers/CategoryReducer";
import ProductsReducer from "./reducers/ProductsReducer";
import ProjectReducer from "./reducers/ProjectReducer";
import ProjectsReducer from "./reducers/ProjectsReducer";
import WorkerReducer from "./reducers/WorkerReducer";
import CustomerWorkersReducer from "./reducers/CustomerWorkersReducer";
import WorkersReducer from "./reducers/WorkersReducer";
import CartReducer from "./reducers/CartReducer";
import CartsReducer from "./reducers/CartsReducer";
import TourReducer from "./reducers/TourReducer";
import OrderReducer from "./reducers/OrderReducer";
import OrdersReducer from "./reducers/OrdersReducer";
import OrganizationReducer from "./reducers/OrganizationReducer";
import OrganizationsReducer from "./reducers/OrganizationsReducer";
import OffersReducer from "./reducers/OffersReducer";
import I18NReducer from "./reducers/I18NReducer";
import PriceReducer from "./reducers/PriceReducer";
import {LOGOUT} from "./actions";


const appReducer = combineReducers({
  registration: UserRegistrationReducer,
  facility: CustomerFacilityReducer,
  facilities: CustomerFacilitiesReducer,
  user: UserReducer,
  settings:SettingsReducer,
  message: MessageReducer,
  customers: CustomersReducer,
  customer: CustomerReducer,
  users: UsersReducer,
  product: ProductReducer,
  productGroups: ProductsReducer,
  category: CategoryReducer,
  projects: ProjectsReducer,
  project: ProjectReducer,
  worker: WorkerReducer,
  customerWorkers:CustomerWorkersReducer,
  workers: WorkersReducer,
  cart: CartReducer,
  carts: CartsReducer,
  tour: TourReducer,
  order: OrderReducer,
  orders: OrdersReducer,
  organization: OrganizationReducer,
  organizations: OrganizationsReducer,
  offers:OffersReducer,
  i18n:I18NReducer,
  price:PriceReducer
});
const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT) {
    state.user = undefined;
    // state.settings;
    state.facilities= undefined;
    state.facility= undefined;
    state.message= undefined;
    state.customers = undefined;
    state.customer = undefined;
    state.users = undefined;
    state.product = undefined;
    state.productGroups = undefined;
    state.category = undefined;
    state.categories = undefined;
    state.projects = undefined;
    state.project = undefined;
    state.worker = undefined;
    state.customerWorkers = undefined;
    state.workers = undefined;
    state.cart = undefined;
    state.carts = undefined;
    state.tour = undefined;
    state.order = undefined;
    state.orders = undefined;
    state.organization = undefined;
    state.organizations = undefined;
    state.offers = undefined;
    // state.i18n;
    state.price = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
