import {CLEAR_CUSTOMER_FACILITY, LOAD_CUSTOMER_FACILITY, LOGOUT, SET_CURRENT_FACILITY} from "../actions/types";

const initialState = {
    stateCustomerFacility: null
};

const CustomerFacilityReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGOUT: {
            return {
                ...state,
                stateCustomerFacility: null
            };
        }
        case LOAD_CUSTOMER_FACILITY: {
            return {
                ...state,
                stateCustomerFacility: action.facility
            };
        }
        case CLEAR_CUSTOMER_FACILITY: {
            return {
                ...state,
                stateCustomerFacility: null
            };
        }
        case SET_CURRENT_FACILITY: {
            return {
                ...state,
                stateCustomerFacility: action.facility
            }
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getCustomerFacility = (state) => (state.facility ? state.facility.stateCustomerFacility : null);

export default CustomerFacilityReducer;
