import {callSecureApi} from '../../util/apiCaller';
import {isBadRequest, isConflict, isUnauthorized} from '../../util/http-errors';
import {PATH_NEW_CART_PAGE} from '../../routePaths';
import {goToPage} from '../../util/page';

import {
    SET_CURRENT_CART,
    REMOVE_EARLIEST_TOUR,
    LOAD_CUSTOMER_CARTS,
    SET_EARLIEST_TOUR,
    REMOVE_CURRENT_CART,
    LOAD_FEDERAL_STATES,
    LOAD_CUSTOMER_TRUCKS,
} from '../types';
import {logout} from '../AuthentificationActions';
import {setCurrentMessage} from '../MessagesAction';
import {loadCurrentProject} from "../ProjectActions";

function getFormattedWeightTonnes(weightKilo) {
    const weightTonnes = weightKilo / 1000;
    const options = {
        minimumFractionDigits: 1, maximumFractionDigits: 1
    };
    return new Intl.NumberFormat('de-DE', options).format(weightTonnes);
}

export function setDeliveryCart(cart, body) {
    return dispatch => {
        if (!cart) {
            console.info('skip setting delivery cart, cart is null');
            return;
        }

        console.info('setting delivery cart cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/type/delivery',
            query: {'cartId': cart.cartId},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set delivery cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Versandart Lieferung ist nicht wählbar. Bitte reduzieren Sie die Bestellmenge im Warenkorb auf maximal 36 Paletten oder 22t.`
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function setPickupCart(cart, facilityId, body) {
    console.log(arguments)
    return dispatch => {
        if (!cart) {
            console.info('skip setting pickup cart, cart is null');
            return;
        }

        console.info('setting pickup cart cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/type/pickup',
            query: {'cartId': cart.cartId, 'facilityId': facilityId},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set pickup cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function setDeliveryTruck(cart, body) {
    return dispatch => {
        if (!cart) {
            console.info('skip setting delivery truck, cart is null');
            return;
        }

        console.info('setting delivery truck cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/type/delivery',
            query: {'cartId': cart.cartId},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set delivery truck for cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die gewünschte Fahrzeugart ist nicht wählbar. Bitte reduzieren Sie die Bestellmenge im Warenkorb auf maximal 36 Paletten oder 22t.`
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function setPickupTruck(cart, facilityName, body) {
    return dispatch => {
        if (!cart) {
            console.info('skip setting pickup truck for cart, cart is null');
            return;
        }

        console.info('setting pickup truck for cart cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/type/pickup',
            query: {'cartId': cart.cartId, 'facilityName': facilityName},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set pickup truck for cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Das Fahrzeug ist nicht wählbar. Bitte reduzieren Sie die Bestellmenge im Warenkorb auf maximal ${body.truck.capacityPalettes} Paletten oder ${getFormattedWeightTonnes(body.truck.capacityInKilo)} t.`
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function setConfirmedPickup(cart, body) {
    return dispatch => {
        if (!cart) {
            console.info('skip setting confirmed pickup, cart is null');
            return;
        }

        console.info('setting confirmed pickup cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/confirm/pickup',
            query: {'cartId': cart.cartId},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set confirmed pickup', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function setConfirmedDelivery(cart, body) {
    return dispatch => {
        if (!cart) {
            console.info('skip setting confirmed delivery, cart is null');
            return;
        }

        console.info('setting confirmed delivery cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/confirm/delivery',
            query: {'cartId': cart.cartId},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set confirmed delivery', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function deleteCart(cart, user) {
    return dispatch => {
        if (!cart) {
            console.info('skip deleting cart, cart is null');
            return;
        }

        console.info('deleting cart cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}',
            query: {'cartId': cart.cartId},
            method: 'delete'
        })
            .then(res => {
                dispatch(removeCurrentCart(res));
                dispatch(removeEarliestTour());
                dispatch(loadCustomerCarts(user));
            })
            .catch(err => {
                console.error('unable delete cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function setConfirmedTruckType(cart, body) {
    return dispatch => {
        if (!cart) {
            console.info('skip setting confirmed truck type, cart is null');
            return;
        }

        console.info('setting confirmed truck type cartId=' + cart.cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/confirm/delivery',
            query: {'cartId': cart.cartId},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set confirmed truck type for cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Fahrzeugart ist nicht wählbar. Die Bestellmenge im Warenkorb übersteigt die Ladekapazität des Fahrzeugs.`,
                        source: 'CONFIRM_TRUCK'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function unsetMaxOutLoading(cart, productNumber) {
    return dispatch => {
        if (!cart) {
            console.info('skip unset maxed out loading for product to cart, cart is null');
            return;
        }

        console.info('unset max out to cart cartId=' + cart.cartId + ' productNumber=' + productNumber);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/item/{productNumber}/capacity',
            query: {'cartId': cart.cartId, 'productNumber': productNumber},
            method: 'delete'
        })
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable unset product maxed out to cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function setMaxOutLoading(cart, orderItem) {
    return dispatch => {
        if (!cart || !orderItem) {
            console.info('skip set maxed out loading for product to cart, cart or orderItem is null');
            return;
        }

        console.info('set max out to cart cartId=' + cart.cartId + ' productNumber=' + orderItem.productNumber);

        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/item/{productNumber}/capacity',
            query: {'cartId': cart.cartId, 'productNumber': orderItem.productNumber},
            method: 'put'
        })
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable set product maxed out to cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }

                if (isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Der Artikel ${orderItem.name} - Art-Nr. ${orderItem.productNumber} kann nicht ausgeladen werden. Bitte prüfen Sie, ob die Ladekapazität des Fahrzeugs überschritten ist.`,
                        source: 'MAX_LOAD_FAILED'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function addToCart(cart, productNumber, quantity, load) {
    return dispatch => {
        if (!cart) {
            console.info('skip adding product to cart, cart is null');
            return;
        }
        let body = {}
        if(load){
            body = {
                load:load
            };
        }
        console.info('adding to cart cartId=' + cart.cartId + ' productNumber=' + productNumber + ' quantity=' + quantity);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/item/{productNumber}/quantity/{quantity}',
            query: {'cartId': cart.cartId, 'productNumber': productNumber, 'quantity': quantity},
            method: 'put'
        }, body)
            .then(res => {
                dispatch(setCurrentCart(res));

                const message = {
                    type: 'INFO',
                    msg: `Die Menge ${quantity} der Art-Nr. ${productNumber} wurde in den Warenkorb gelegt.`,
                    source: 'ADD_CART_ITEM'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to add product to cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    let message = {};
                    if (err.stock) {
                        message = {
                            type: 'ERROR',
                            msg: `Die Menge ${quantity} der Art-Nr. ${productNumber} übersteigt den aktuellen Lagerbestand.`,
                            source: 'ADD_CART_ITEM',
                            productQuantity: err.stock,
                            productNumber: productNumber
                        };
                    }
                    if (err.quantity) {
                        message = {
                            type: 'ERROR',
                            msg: `Die Menge ${quantity} der Art-Nr. ${productNumber} überschreitet die Ladekapazität des Fahrzeugs.`,
                            source: 'ADD_CART_ITEM',
                            productQuantity: err.quantity,
                            productNumber: productNumber
                        };
                    }
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function approveProduct(cart, productNumber, quantity) {
    return dispatch => {
        if (!cart) {
            console.info('skip approving product in cart, cart is null');
            return;
        }

        console.info('approving in cart cartId=' + cart.cartId + ' productNumber=' + productNumber + ' quantity=' + quantity);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/item/{productNumber}/approve/{quantity}',
            query: {'cartId': cart.cartId, 'productNumber': productNumber, 'quantity': quantity},
            method: 'put'
        })
            .then(res => {
                dispatch(setCurrentCart(res));

                const message = {
                    type: 'INFO',
                    msg: `Die Menge ${quantity} vom Art-Nr. ${productNumber} wurde bestätigt.`,
                    source: 'APPROVE_CART_ITEM'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to approve product in cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Menge ${quantity} vom Art-Nr. ${productNumber} kann nicht bestätigt werden. Bei im Lieferumfang enthaltenen Produkten darf die bestätigte Menge nicht die zulässige Gesamtmenge übersteigen!`,
                        source: 'APPROVE_CART_ITEM'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function removeFromCart(cart, productNumber) {
    return dispatch => {
        if (!cart) {
            console.info('skip removing product from cart, cart is null');
            return;
        }

        console.info('remove from cart cartId=' + cart.cartId + ' productNumber=' + productNumber);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/item/{productNumber}',
            query: {'cartId': cart.cartId, 'productNumber': productNumber},
            method: 'delete'
        })
            .then(res => {
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable remove product from cart', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }

                if (isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Der Artikel Art-Nr. ${productNumber} kann nicht gelöscht werden.`,
                        source: 'REMOVE_CART_ITEM'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function reuseCart(cart) {
    return dispatch => {

        if (!cart) {
            console.info('skip reusing cart, cart is null');
            return;
        }

        const cartId = cart.cartId;
        console.info('reusing cart id=' + cartId);
        return dispatch(loadCart(cartId))
    };
}

export function changeOrder(order) {
    return dispatch => {
        if (!order) {
            console.info('skip change order, order is null');
            return;
        }

        const cartId = order.cartId;
        console.info('loading cart cartId=' + cartId + ' for changing order id=' + order.orderId);
        dispatch(loadCart(cartId));
    };
}

export function loadCart(cartId) {
    console.info('loading cart id=' + cartId);
    return dispatch => {
        return new Promise(function (resolve, reject) {
            if (!cartId) {
                console.info('skip loading cart, cartId is null');
                return;
            }
            callSecureApi({
                endpoint: 'orderservice/v1/cart/{cartId}',
                query: {'cartId': cartId},
                method: 'get'
            })
                .then(res => {
                    dispatch(setCurrentCart(res));
                    dispatch(loadEarliestTourForCart(res.cartId));
                    resolve();
                })
                .catch(err => {
                    console.error('unable load cart', err);
                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                    reject();
                });
        })
    };

}

export function loadEarliestTourForCart(cartId) {
    return dispatch => {
        if (!cartId) {
            console.info('skip loading earliest tour for cart, cartId is null');
            return;
        }

        console.info('loading earliest tour date for cart cartId=' + cartId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}/earliestTour',
            query: {'cartId': cartId},
            method: 'get'
        })
            .then(res => {
                dispatch(setEarliestTour(res));
            })
            .catch(err => {
                console.error('unable to load earliest tour for cart id=' + cartId, err);
                if (isConflict(err)) {
                }
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function processOrder(order) {
    return dispatch => {
        if (!order) {
            console.info('skip processing order, order is null');
            return;
        }

        const cartId = order.cartId;
        console.info('loading cart cartId=' + cartId + ' for processing order id=' + order.orderId);
        dispatch(loadCart(cartId));
    };
}

export function loadCustomerCarts(user) {
    return dispatch => {
        if (!user) {
            console.info('skip loading customer carts, user is null');
            return;
        }

        console.info('loading customer carts customerId=' + user.customerId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/customer/{customerId}',
            query: {'customerId': user.customerId}
        })
            .then(res => {
                dispatch(setCustomerCarts(res));

            })
            .catch(err => {
                console.error('unable to load customer carts', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function createCart(project, user, isDeliveryTour) {
    return dispatch => {
        if (!project) {
            console.info('skip creating cart for project, project is null');
            return;
        }

        const projectId = project.id;

        console.info('creating cart for project id=' + projectId);
        callSecureApi({
            endpoint: 'orderservice/v1/cart/project/{projectId}',
            query: {'projectId': projectId},
            method: 'post'
        })
            .then(res => {
                // delivery order type is the default, but backend creates a pickup cart by default
                if (isDeliveryTour) {
                    dispatch(setDeliveryCart(res, {}));
                }
                dispatch(loadCustomerCarts(user));
                dispatch(setCurrentCart(res));
            })
            .catch(err => {
                console.error('unable to create cart for customer project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function refreshCart(cartId) {
    return dispatch => {
        if (!cartId) {
            console.info('skip refreshing cart, cartId is null');
            return;
        }

        callSecureApi({
            endpoint: 'orderservice/v1/cart/{cartId}',
            query: {'cartId': cartId},
            method: 'get'
        })
            .then(res => {
                dispatch(setCurrentCart(res));
                dispatch(loadEarliestTourForCart(res.cartId));
            })
            .catch(err => {
                console.error('unable refresh cart id=' + cartId, err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function getAllTrucksFromCustomer(customerId) {
    return dispatch => {
        const localCustomerNumber = customerId;
        console.info('get all trucks for customerId= ' + localCustomerNumber);
        callSecureApi({
            endpoint: 'customerservice/v1/truck/customer/{customerId}',
            query: {'customerId': localCustomerNumber},
            method: 'get'
        })
            .then(res => {
                dispatch(setTrucksFromCustomer(res));
            })
            .catch(err => {
                console.error('unable to get trucks from customer=' + localCustomerNumber, err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function createNewCartFromOffer(offerNumber,productNumbers) {
    if (!offerNumber && !productNumbers && productNumbers.length === 0) {
        console.error('createNewCartFromOffer wrong parameters!')
        return;
    }
    return dispatch => {
        callSecureApi({
                endpoint: 'orderservice/v1/cart/offer/{offerNumber}',
                query: {offerNumber},
                method: 'post'
            },
            {productNumbers})
            .then(cart => {
                dispatch(setCurrentCart(cart))
                dispatch(loadCurrentProject({id: cart.projectId}))
                goToPage(PATH_NEW_CART_PAGE)
            })
            .catch(err => {
                console.error('unabel to create new cart for offer', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    }
}
export function removeCurrentCart(cart) {
    console.info('removing current cart');
    return {
        type: REMOVE_CURRENT_CART,
        cart
    };
}
export function removeEarliestTour(tour) {
    console.info('removing earliest tour for cart');
    return {
        type: REMOVE_EARLIEST_TOUR,
        tour
    };
}

export function setEarliestTour(tour) {
    return {
        type: SET_EARLIEST_TOUR,
        tour
    };
}

export function setCustomerCarts(carts) {
    return {
        type: LOAD_CUSTOMER_CARTS,
        carts
    };
}

export function setCurrentCart(cart) {
    return {
        type: SET_CURRENT_CART,
        cart
    };
}

export function setFederalStates(states) {
    return {
        type: LOAD_FEDERAL_STATES,
        states
    };
}
export function setTrucksFromCustomer(trucks) {
    return {
        type: LOAD_CUSTOMER_TRUCKS,
        trucks
    };
}
