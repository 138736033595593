import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "reactstrap/lib/Button";
import SearchBar from "../../components/SearchBar";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCustomerProjects} from "../../reducers/ProjectsReducer";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import _ from 'lodash';
import {Customer, Message, Orders, Project, Projects, User} from "../../proptypes";
import {
    PATH_ADMIN_MANAGE_CUSTOMER_PAGE,
    PATH_ADMIN_PROJECT_CREATE_PAGE, PATH_USER_SETTINGS_PAGE
} from "../../routePaths";
import BackButton from "../../components/Buttons/BackButton";
import Auth from "../../util/Auth";
import {
    loadCurrentUser,
    loadCustomer,
    loadCustomerCarts,
    loadCustomerOrders,
    setCurrentCart,
    setProduct,
    deleteProject,
    loadCustomerProjects,
    deleteCustomerUserProject,
} from "../../actions";
import {getCurrentUser} from "../../reducers/UserReducer";
import {getCustomerCarts} from "../../reducers/CartsReducer";
import {getCustomerOrders} from "../../reducers/OrdersReducer";
import {Spinner} from "reactstrap";
import ManageProjectOverviewList from "../../components/Projects/ManageProjectOverviewList";

class ManageProjectOverviewPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: undefined,
            filteredProjects: undefined,

            deleteCartModal: false,
            deleteCartData: {},
            cancelOrderModal: false,
            cancelOrderData: {},
            createCartBtnDisabled: false,
            selectedProject: undefined,
            modals:{
                deleteProject:false
            }
        }
    }

    componentDidMount() {
        const user = this.props.currentUser;
        const customer = this.props.customer;
        if (!customer) {
            this.props.dispatch(loadCustomer(user.customerId))
        }
        if (customer) {
            this.props.dispatch(loadCustomerProjects(customer.id))
        }

        if (!user) {
            this.props.dispatch(loadCurrentUser());
        }

        if (user && customer) {
            this.props.dispatch(loadCustomerCarts(user));
        }
        if (user && customer) {
            this.props.dispatch(loadCustomerOrders(user));
        }
        this.props.dispatch(setCurrentCart(undefined));

    }

    componentDidUpdate(prevProps) {
        if (prevProps.customer !== this.props.customer) {
            this.props.dispatch(loadCustomerProjects(this.props.customer.id))
        }
    }

    highlightRow(index) {
        this.setState({highlightedRow: index})
    }

    handleSearchInput(event) {
        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            searchTerm: search
        });
        this.filteredProjectList(search, this.props.projects);
    }

    filteredProjectList(searchTerm, projects) {
        let localProjects = projects;
        if (searchTerm) {
            localProjects = _.filter(projects, (project) => {
                console.log(project);
                return project.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || project.number.indexOf(searchTerm) !== -1;
            });
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filteredProjects = localProjects;
            return nextState;
        });
    }

    resetSearch() {
        this.setState({searchTerm: ""})
    }

    goToPage(path, product) {
        if (product) {
            this.props.dispatch(setProduct(product))
        }
        this.props.history.push(path);
    }

    deleteProject(project){
        const userId = this.props.currentUser.id;
        if (this.props.currentUser.role === "CUSTOMER_USER") {
            this.props.dispatch(deleteCustomerUserProject(project, userId));
        } else {
            this.props.dispatch(deleteProject(project))
        }
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }
    render() {
        const beforeRender = this.props.customer && this.props.currentUser
        return (
            <div className="page-content">
                <div className="container">
                    {Auth.isAdmin() &&
                    <BackButton path={PATH_ADMIN_MANAGE_CUSTOMER_PAGE}/>
                    }
                    {!Auth.isAdmin() &&
                        <BackButton path={PATH_USER_SETTINGS_PAGE}/>
                    }
                    {this.props.customer && <h4 className="text-muted text-center">{this.props.customer.name}</h4>}
                    {Auth.isAdmin() &&
                        <h2 className="text-center mb-4">
                            Lieferadressenübersicht
                        </h2>
                    }
                    {Auth.isAdmin() ||
                    <h2 className="text-center mb-4">
                        Lieferadressenübersicht
                    </h2>
                    }

                    <div className="float-right mb-2">
                        <Button color="primary" onClick={() => this.goToPage(PATH_ADMIN_PROJECT_CREATE_PAGE)}
                                                  size="sm"><span className="oi oi-plus" /> Neue Lieferadresse anlegen</Button>
                    </div>
                    <SearchBar
                        filter
                        handleSearch={this.handleSearchInput.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                    />
                    {!beforeRender &&
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    }
                    {beforeRender &&
                        <ManageProjectOverviewList dispatch={this.props.dispatch}
                                                   resetSearch={this.resetSearch.bind(this)}
                                                   projects={this.state.filteredProjects || this.props.projects}
                        />
                    }
                </div>

            </div>
        );
    }
}

ManageProjectOverviewPage.propTypes = {
    currentUser: PropTypes.shape(User),
    currentCustomer: PropTypes.shape(Customer),
    currentProject: PropTypes.shape(Project),
    customerProjects: PropTypes.shape(Projects),
    customerOrders: PropTypes.shape(Orders),
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        projects: getCustomerProjects(state),
        currentUser: getCurrentUser(state),
        customerCarts: getCustomerCarts(state),
        customerOrders: getCustomerOrders(state),
    };
}

export default withRouter(connect(mapStateToProps)(ManageProjectOverviewPage));
