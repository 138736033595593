import React, {Component} from 'react';
import invLogo from "../../media/siever_inv.png"
import facebookLogo from "../../media/icons/facebook.svg"
import instaLogo from "../../media/icons/instagram.svg"
import youtubeLogo from "../../media/icons/youtube.svg"

class Footer extends Component {
    render() {
        return (
            <footer className="footer border-top container">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column flex-lg-row upper-box p-4 ">
                        <div className="col-lg-3 col-12">
                            <div className="col-12 col-lg-12 d-flex flex-column flex-lg-row link-box">
                                <div className="col-12 col-lg-12 d-flex flex-column ">
                                    <span className="link"><a rel='noreferrer noopener' target="_blank" href="./sievert_agb.pdf">AGB</a></span>
                                    <span className="link"><a rel='noreferrer noopener' target="_blank" href="./sievert_terms.pdf">Nutzungsbedingung</a></span>
                                    <span className="link"><a rel='noreferrer noopener' target="_blank"
                                                              href="https://sievert.de/shop/impressum/">Impressum</a></span>
                                    <span className="link"><a rel='noreferrer noopener' target="_blank"
                                                              href="https://sievert.de/shop/datenschutz/">Datenschutz</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 d-flex flex-column flex-lg-row right-side">
                            <div className="col-12 col-lg-6">
                                <strong>Besuchen Sie uns</strong><br/>
                                <ul className="no-bullet-list">
                                    <li className="link"><img className="m-1" style={{width:20}} src={facebookLogo}  alt={"facebook"}/><a rel='noreferrer noopener' target="_blank" href="https://www.facebook.com/pages/category/Building-Materials/Sievert-2301113303492023/">Facebook</a></li>
                                    <li className="link"><img className="m-1" style={{width:20}} src={instaLogo}  alt={"instagram"}/><a rel='noreferrer noopener' target="_blank" href="https://www.instagram.com/sievertde/?hl=de">Instagram</a></li>
                                    <li className="link"><img className="m-1" style={{width:20}} src={youtubeLogo}  alt={"youtube"}/><a rel='noreferrer noopener' target="_blank" href="https://www.youtube.com/channel/UCTfSPSoe-QrCzwXwLeFPMiQ">Youtube</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mt-2">
                            <div className="hotline-box">
                                <div className="border-bottom">HOTLINE <br/>TECHNISCHE BERATUNG <br/></div>
                                +49 541 601-601</div>
                        </div>
                    </div>

                    <div className="d-flex flex-lg-row flex-column mt-4 mb-2 bottom">
                        <div className="col-lg-2 col-12 ">
                            <img className="logo w-50 mb-1 ml-lg-5" src={invLogo} alt="Quickmix Logo"/>
                        </div>
                        <div className="col-lg-10 col-12 align-bottom" style={{'fontSize': 12}}>Sievert Baustoffe GmbH & Co. KG | Mühleneschweg 6 | 49090
                            Osnabrück | Tel.: +49 541 601 00 | Fax: +49 541 601 200 | service@shop.sievert.de | www.sievert.de
                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}

export default Footer;
