import React from 'react';
import {Route, Switch} from 'react-router';
import Login from "./pages/LoginPage";
import HomePage from "./pages/HomePage"
import CustomerOverviewPage from "./pages/Customer/CustomerOverviewPage";
import PrivateRoute from "./components/ProtectedRoutes/PrivateRoute";
import AdminRoute from "./components/ProtectedRoutes/AdminRoute";
import ManageCustomerMenu from "./pages/Customer/ManageCustomerMenuPage";
import EditCustomerPage from "./pages/Customer/EditCustomerPage";
import CreateCustomerPage from "./pages/Customer/CreateCustomerPage";
import ManageUserPage from "./pages/Customer/ManageUserPage";
import EditUserPage from "./pages/Customer/EditUserPage";
import CreateUserPage from "./pages/Customer/CreateUserPage";
import AdminSettingsPage from "./pages/AdminSettingsPage";
import UserHomePage from "./pages/UserHomePage";
import ProductOverviewPage from "./pages/Product/ProductOverviewPage";
import EditProductPage from "./pages/Product/EditProductPage";
import CreateProductPage from "./pages/Product/CreateProductPage";
import UserSettingsPage from "./pages/UserSettingsPage";
import CategoriesOverviewPage from "./pages/Category/CategoriesOverviewPage";
import CategoryCreatePage from "./pages/Category/CategoryCreatePage";
import CategoryEditPage from "./pages/Category/CategoryEditPage";
import CustomerCreateProjectPage from "./pages/Project/CustomerCreateProjectPage";
import CustomerEditProjectPage from "./pages/Project/CustomerEditProjectPage";
import AdminWorkerOverviewPage from "./pages/Worker/AdminWorkerOverviewPage";
import AdminWorkerEditPage from "./pages/Worker/AdminWorkerEditPage";
import AdminWorkerCreatePage from "./pages/Worker/AdminWorkerCreatePage";
import AdminManageCustomerWorkers from "./pages/Worker/AdminManageCustomerWorkers";
import NewCartPage from "./pages/Cart/NewCartPage";
import NewCartSchedulePage from "./pages/Cart/NewCartSchedulePage";
import ProductsPage from "./pages/Cart/ProductsPage";
import CartPage from "./pages/Cart/CartPage";
import CartSummaryPage from "./pages/Cart/CartSummaryPage";
import UserEditCustomerPage from "./pages/Customer/UserEditCustomerPage";
import OrganizationOverview from "./pages/Organization/OrganizationOverview";
import EditOrganizationPage from "./pages/Organization/EditOrganizationPage";
import CreateOrganizationPage from "./pages/Organization/CreateOrganizationPage";
import CustomerOfferOverview from "./pages/CustomerOffer/CustomerOfferOverview";
import CustomerChooseProjectWithOfferPage from "./pages/CustomerOffer/CustomerChooseProjectWithOfferPage";
import CustomerCreateOfferProjectPage from "./pages/CustomerOffer/CustomerCreateOfferProjectPage";
import UserProjectOverviewPage from "./pages/Project/UserProjectOverviewPage";
import ManageProjectOverviewPage from "./pages/Project/ManageProjectOverviewPage";
import ChangeCredentialsPage from "./pages/ChangeCredentialsPage";
import UserRegistrationPage from "./pages/UserRegistrationPage";
import CustomerFacilityOverviewPage from "./pages/Customer/CustomerFacilityOverview";
import EditCustomerFacilityPage from "./pages/Customer/EditCustomerFacilityPage";
import CreateCustomerFacilityPage from "./pages/Customer/CreateCustomerFacilityPage";
import {
        PATH_HOME_PAGE,
        PATH_LOGIN_PAGE,
        PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE,
        PATH_ADMIN_MANAGE_CUSTOMER_PAGE,
        PATH_ADMIN_EDIT_CUSTOMER_PAGE,
        PATH_ADMIN_CREATE_CUSTOMER_PAGE,
        PATH_ADMIN_USER_CUSTOMER_PAGE,
        PATH_ADMIN_USER_EDIT_PAGE,
        PATH_ADMIN_USER_CREATE_PAGE,
        PATH_ADMIN_SETTINGS_PAGE,
        PATH_ADMIN_PRODUCT_OVERVIEW_PAGE,
        PATH_ADMIN_PRODUCT_CREATE_PAGE,
        PATH_ADMIN_PRODUCT_EDIT_PAGE,
        PATH_USER_HOME_PAGE,
        PATH_USER_SETTINGS_PAGE,
        PATH_ADMIN_CATEGORIES_OVERVIEW_PAGE,
        PATH_ADMIN_CATEGORY_CREATE_PAGE,
        PATH_ADMIN_CATEGORY_EDIT_PAGE,
        PATH_MANAGE_PROJECT_OVERVIEW_PAGE,
        PATH_USER_PROJECT_OVERVIEW_PAGE,
        PATH_ADMIN_PROJECT_SETTINGS_OVERVIEW_PAGE,
        PATH_ADMIN_PROJECT_CREATE_PAGE,
        PATH_PROJECT_OFFER_CHOOSE_PAGE,
        PATH_OFFER_PROJECT_CREATE_PAGE,
        PATH_ADMIN_PROJECT_EDIT_PAGE,
        PATH_ADMIN_WORKER_OVERVIEW_PAGE,
        PATH_ADMIN_WORKER_EDIT_PAGE,
        PATH_ADMIN_WORKER_CREATE_PAGE,
        PATH_ADMIN_WORKER_MANAGE_PAGE,
        PATH_NEW_CART_PAGE,
        PATH_CART_SUMMARY_PAGE,
        PATH_PRODUCTS_PAGE,
        PATH_NEW_CART_SCHEDULE_PAGE,
        PATH_CART_PAGE,
        PATH_USER_EDIT_CUSTOMER_PAGE,
        PATH_USER_MANAGE_USER_PAGE,
        PATH_ADMIN_ORGANIZATION_OVERVIEW,
        PATH_ADMIN_ORGANIZATION_CREATE_PAGE,
        PATH_ADMIN_ORGANIZATION_EDIT_PAGE,
        PATH_OFFER_OVERVIEW,
        PATH_CHANGE_CREDENTIALS,
        PATH_USER_REGISTRATION_PAGE,
        PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE,
        PATH_CUSTOMER_FACILITY_CREATE_PAGE,
        PATH_CUSTOMER_FACILITY_EDIT_PAGE
} from './routePaths';

export default (
    <Switch>
        <PrivateRoute exact path={PATH_HOME_PAGE} component={HomePage}/>
        <PrivateRoute exact path={PATH_USER_HOME_PAGE} component={UserHomePage}/>
        <AdminRoute exact path={PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE} component={CustomerOverviewPage}/>
        <AdminRoute exact path={PATH_ADMIN_MANAGE_CUSTOMER_PAGE} component={ManageCustomerMenu}/>
        <AdminRoute exact path={PATH_ADMIN_EDIT_CUSTOMER_PAGE} component={EditCustomerPage}/>
        <AdminRoute exact path={PATH_ADMIN_CREATE_CUSTOMER_PAGE} component={CreateCustomerPage}/>
        <AdminRoute exact path={PATH_ADMIN_USER_CUSTOMER_PAGE} component={ManageUserPage}/>
        <PrivateRoute exact path={PATH_ADMIN_USER_EDIT_PAGE} component={EditUserPage}/>
        <PrivateRoute exact path={PATH_ADMIN_USER_CREATE_PAGE} component={CreateUserPage}/>
        <AdminRoute exact path={PATH_ADMIN_SETTINGS_PAGE} component={AdminSettingsPage}/>
        <PrivateRoute exact path={PATH_USER_SETTINGS_PAGE} component={UserSettingsPage}/>
        <AdminRoute exact path={PATH_ADMIN_PRODUCT_OVERVIEW_PAGE} component={ProductOverviewPage}/>
        <AdminRoute exact path={PATH_ADMIN_PRODUCT_CREATE_PAGE} component={CreateProductPage}/>
        <AdminRoute exact path={PATH_ADMIN_PRODUCT_EDIT_PAGE} component={EditProductPage}/>
        <AdminRoute exact path={PATH_ADMIN_CATEGORIES_OVERVIEW_PAGE} component={CategoriesOverviewPage}/>
        <AdminRoute exact path={PATH_ADMIN_CATEGORY_CREATE_PAGE} component={CategoryCreatePage}/>
        <AdminRoute exact path={PATH_ADMIN_CATEGORY_EDIT_PAGE} component={CategoryEditPage}/>
        <PrivateRoute exact path={PATH_MANAGE_PROJECT_OVERVIEW_PAGE} component={ManageProjectOverviewPage}/>
        <PrivateRoute exact path={PATH_USER_PROJECT_OVERVIEW_PAGE} component={UserProjectOverviewPage}/>
        <PrivateRoute exact path={PATH_ADMIN_PROJECT_CREATE_PAGE} component={CustomerCreateProjectPage}/>
        <PrivateRoute exact path={PATH_PROJECT_OFFER_CHOOSE_PAGE} component={CustomerChooseProjectWithOfferPage}/>
        <PrivateRoute exact path={PATH_OFFER_PROJECT_CREATE_PAGE} component={CustomerCreateOfferProjectPage}/>
        <PrivateRoute exact path={PATH_ADMIN_PROJECT_EDIT_PAGE} component={CustomerEditProjectPage}/>
        <PrivateRoute exact path={PATH_ADMIN_PROJECT_SETTINGS_OVERVIEW_PAGE} component={ManageProjectOverviewPage}/>
        <AdminRoute exact path={PATH_ADMIN_WORKER_OVERVIEW_PAGE} component={AdminWorkerOverviewPage}/>
        <AdminRoute exact path={PATH_ADMIN_WORKER_EDIT_PAGE} component={AdminWorkerEditPage}/>
        <AdminRoute exact path={PATH_ADMIN_WORKER_CREATE_PAGE} component={AdminWorkerCreatePage}/>
        <AdminRoute exact path={PATH_ADMIN_WORKER_MANAGE_PAGE} component={AdminManageCustomerWorkers}/>
        <PrivateRoute exact path={PATH_NEW_CART_PAGE} component={NewCartPage}/>
        <PrivateRoute exact path={PATH_NEW_CART_SCHEDULE_PAGE} component={NewCartSchedulePage}/>
        <PrivateRoute exact path={PATH_PRODUCTS_PAGE} component={ProductsPage}/>
        <PrivateRoute exact path={PATH_CART_PAGE} component={CartPage}/>
        <PrivateRoute exact path={PATH_CART_SUMMARY_PAGE} component={CartSummaryPage}/>
        <PrivateRoute exact path={PATH_USER_EDIT_CUSTOMER_PAGE} component={UserEditCustomerPage}/>
        <PrivateRoute exact path={PATH_USER_MANAGE_USER_PAGE} component={ManageUserPage}/>
        <PrivateRoute exact path={PATH_ADMIN_ORGANIZATION_OVERVIEW} component={OrganizationOverview}/>
        <PrivateRoute exact path={PATH_ADMIN_ORGANIZATION_EDIT_PAGE} component={EditOrganizationPage}/>
        <PrivateRoute exact path={PATH_ADMIN_ORGANIZATION_CREATE_PAGE} component={CreateOrganizationPage}/>
        <PrivateRoute exact path={PATH_OFFER_OVERVIEW} component={CustomerOfferOverview}/>
        <PrivateRoute exact path={PATH_CHANGE_CREDENTIALS} component={ChangeCredentialsPage}/>
        <PrivateRoute exact path={PATH_CUSTOMER_FACILITY_CREATE_PAGE} component={CreateCustomerFacilityPage}/>
        <PrivateRoute exact path={PATH_CUSTOMER_FACILITY_EDIT_PAGE} component={EditCustomerFacilityPage}/>
        <PrivateRoute exact path={PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE} component={CustomerFacilityOverviewPage}/>
        <Route exact path={PATH_USER_REGISTRATION_PAGE} component={UserRegistrationPage}/>
        <Route path={PATH_LOGIN_PAGE} component={Login}/>
        <Route component={Login}/>
    </Switch>
)
