import React, {Component, Fragment} from 'react';
import ListComponent from "./ListComponent";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import CartOrderHoc from "../CartOrderHOC";
import {getFormattedDateTime} from "../../util/TimeUtils";
import Button from "reactstrap/lib/Button";
import {loadCart, removeCurrentOrder, loadCurrentProject} from "../../actions";
import {PATH_CART_SUMMARY_PAGE} from "../../routePaths";
import {withRouter} from "react-router";

class OrderList extends Component {
    loadCart(project, order) {
        this.props.dispatch(removeCurrentOrder());
        this.props.dispatch(loadCurrentProject(this.props.project));
        this.props.dispatch(loadCart(order.cartId)).then(() => {
            this.props.history.push(PATH_CART_SUMMARY_PAGE);
        })
    }
    itemRenderer(order){
        return (
            <ListGroupItem key={order.orderNumber} className="border-0 pr-0">
                <div className="no-gutters d-flex flex-md-row flex-column">
                    <div className="col-md-3 text-left">
                        <small>Vorgang: {order.orderId}</small>
                        {order.orderNumber &&
                        <small>
                            <br/>
                            <FormattedMessage id="project.list.ordernumber.text" />: {order.orderNumber}
                        </small>
                        }
                        {order.offerNumber &&
                        <small>
                            <br/>
                            <FormattedMessage id="project.list.offernumber.text" />{order.offerNumber}
                        </small>
                        }
                    </div>
                    <div className="col-md-6 text-left">
                        {order.delivery &&
                            <span>
                                 <FormattedMessage id="project.list.delivery.text" />{this.props.getTourDate(order)}, {this.props.getFormattedTimeLabel(order)}
                            </span>
                        }
                        {order.pickup &&
                            <span>
                                <FormattedMessage id="project.list.pickup.text" /> {this.props.getTourDate(order)}, {this.props.getFormattedTimeLabel(order)}
                            </span>
                        }
                        <br/>
                        <span>
                             <FormattedMessage id="project.list.creator.text" /> {order.issuedBy.firstName} {order.issuedBy.lastName}
                        </span>
                        <br/>
                        <span>
                             <FormattedMessage id="project.list.lastchange.text" /> {getFormattedDateTime(order.updatedAt)}
                        </span>
                    </div>
                    <div className="d-flex flex-column col-md-3 text-md-right">
                        <div className="d-flex float-right flex-column flex-md-row justify-content-end">
                            <Button
                                className="mt-2 mt-md-0 "
                                onClick={() => {
                                    this.loadCart(this.props.project, order)
                                }}><FormattedMessage id="project.list.watchorder.button" />  <span
                                className="oi oi-chevron-right ml-2"/></Button>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        )
    }
    render() {
        return (
            <Fragment>
                {this.props.orders.length > 0 && <p className="offset-md-3 h6 mb-0 mt-2">Abgeschlossene Bestellungen:</p>}
                <ListComponent flush itemRenderer={this.itemRenderer.bind(this)} items={this.props.orders}/>
            </Fragment>
        );
    }
}

OrderList.propTypes = {
    orders: PropTypes.array.isRequired
};

export default withRouter(CartOrderHoc(OrderList));
