import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from "./ListComponent";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import classNames from "classnames";
import Button from "reactstrap/lib/Button";
import {FormattedMessage} from "react-intl";
import {createCart, removeCurrentOrder, setCurrentProject} from "../../actions";
import {PATH_NEW_CART_PAGE} from "../../routePaths";
import _ from "lodash";
import CartList from "./CartList";
import {SORT_STATE_DATE_DEC, SORT_STATE_DATE_INC} from "../../pages/HomePage";
import OrderList from "./OrderList";
import InfoBox from "../InfoBox";
import {goToPage} from '../../util/page';


class UserProjectOverviewList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed:undefined,
            modals:{
                cancelOrder:false
            }
        }
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }
    createNewCart(e, project) {
        if (!this.state.createCartBtnDisabled) {
            // disable create btn to prevent double click, throttle is fine to prevent the backend call, but the double click will prevent to go to next page
            this.setState({
                createCartBtnDisabled: true
            });
            this.props.dispatch(removeCurrentOrder());
            this.props.dispatch(setCurrentProject(project));
            const user = this.props.currentUser;
            // double negate to bool return
            const createDeliveryCart = !!this.props.maxTours;
            this.props.dispatch(createCart(project, user, createDeliveryCart));
        }
        goToPage(PATH_NEW_CART_PAGE)
        e.preventDefault();
    }
    isWorkerBlocked(project) {
        return project.worker && project.worker.blocked;
    }
    hasProjectCarts(project) {
        const projectCarts = this.getProjectCarts(project);
        return projectCarts.length > 0;
    }
    getProjectCarts(project) {
        const carts = this.props.carts;
        if (carts) {
            const customerCarts = carts.carts;

            return _.filter(customerCarts, (cart) => {
                return project.id === cart.projectId && !cart.hasOwnProperty('orderId');
            });
        }
        return [];
    }
    getTour(order) {
        let tour = this.getConfirmedTour(order);
        if (!tour) {
            tour = this.getScheduledTour(order);
        }
        return tour;
    }
    getScheduledTour(order) {
        if (order) {
            let tour;
            if (order.delivery) {
                tour = order.delivery.scheduledTour;
            } else {
                tour = order.pickup.scheduledTour;
            }
            return tour;
        }
        return null;
    }
    getConfirmedTour(order) {
        if (order) {
            let tour;
            if (order.delivery) {
                tour = order.delivery.confirmedTour;
            } else {
                tour = order.pickup.confirmedTour;
            }
            if (tour) {
                return tour;
            }
        }
        return null;
    }
    getProjectOrders(project, orderSorting) {
        const orders = this.props.customerOrders;
        if (orders) {
            const customerOrders = orders.orders;
            const projectOrders = _.filter(customerOrders, (order) => {
                return project.id === order.project.id && order.status !== 'DELETION';
            });
            if (orderSorting && orderSorting === SORT_STATE_DATE_INC) {
                return _.sortBy(projectOrders, (order) => {
                    const tour = this.getTour(order);
                    return tour.fromDate;
                });
            }
            if (orderSorting && orderSorting === SORT_STATE_DATE_DEC) {
                return _.sortBy(projectOrders, (order) => {
                    const tour = this.getTour(order);
                    return tour.fromDate;
                }).reverse();
            }
            return projectOrders;
        }
        return [];
    }
    itemRenderer(project,index){
        let collapsed = this.state.collapsed;
        return (
            <ListGroupItem className="no-gutters pt-4 pb-4">
                <div className="flex-md-row flex-column d-flex no-gutters">
                    <div className="flex-row col-md-3 no-gutters row" onClick={()=>{
                        this.toggleRow(index)
                    }}>
                        <div className="col-md-2" >
                            {(this.hasProjectOrders(project) && this.getCollapseChevron(collapsed, index)) || <div className="col-md-2"/>}
                        </div>
                        <div className="col-md-10">
                            {this.getNumberBox(project, index)}
                        </div>
                    </div>
                    <div className="col-md-5 offset-1 offset-md-0 text-md-left">
                        <div>{project.name}</div>
                        <div><small>{project.worker && project.worker.name}</small></div>
                    </div>
                    <div className="col-md-4 text-md-right">
                        <div className="d-flex float-right flex-column flex-md-row">
                            {!this.hasProjectCarts(project) &&
                                <Button color="primary"
                                        disabled={ this.isWorkerBlocked(project)}
                                        onClick={(e) => {
                                            this.createNewCart(e, project)
                                        }}><span className="oi oi-plus ml-1"/>
                                    <FormattedMessage id="project.list.neworder.button" />
                                </Button>
                            }
                        </div>
                    </div>
                </div>
                {this.isWorkerBlocked(project)&&
                <div>
                    <InfoBox>
                        <span className="oi oi-info"/>
                        <FormattedMessage id="userprojectlist.workerblocked.info"/>
                    </InfoBox>
                </div>
                }
                <CartList currentUser={this.props.currentUser} dispatch={this.props.dispatch} project={project} carts={this.getProjectCarts(project)} />
                {collapsed === index &&
                <OrderList currentUser={this.props.currentUser} dispatch={this.props.dispatch} project={project} orders={this.getProjectOrders(project, SORT_STATE_DATE_INC)}/>}
            </ListGroupItem>
        )

    }
    toggleRow(index) {
        if(!this.props.offerSelect){
            if (this.state.collapsed !== index) {
                this.setState({collapsed: index})
            } else {
                this.setState({collapsed: undefined})
            }
        }
    }
    hasProjectOrders(project) {
        const projectOrders = this.getProjectOrders(project);
        return projectOrders.length > 0;
    }
    getNumberBox(item, index) {
        return <div className="d-flex flex-md-column flex-row no-gutters text-left">
            <div className="pl-2 pl-md-2">{item.number}</div>
            <div className="pl-2 pl-md-0">
                {this.hasProjectOrders(item) &&
                    <Button color="link" className="mt-0 pt-1" onClick={() => {
                        this.toggleRow(index)
                    }}>
                        <FormattedMessage id="project.list.orderhistory.button"/>
                    </Button>
                }
            </div>
        </div>;
    }

    getCollapseChevron(collapsed, index) {
        return <span onClick={()=>{
            this.toggleRow(index)
        }}
            className={classNames("pl-2 float-left oi", {"oi-chevron-right": collapsed !== index}, {"oi-chevron-bottom": collapsed === index})}/>;
    }

    render() {
        const projects = this.props.projects || [];
        return (
            <ListComponent  itemRenderer={this.itemRenderer.bind(this)} items={projects} />
        );
    }
}

UserProjectOverviewList.propTypes = {
    projects: PropTypes.array.isRequired,
    carts: PropTypes.array.isRequired,
    customerOrders: PropTypes.array
};

export default UserProjectOverviewList;
