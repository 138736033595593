import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {addToCart, getProductImageUrl, removeCurrentMessage} from '../../actions';

import {getCurrentMessage} from '../../reducers/MessageReducer';


import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import ProductGridDetail from './ProductGridDetail';
import {Cart, Category, Message, ProductGroups} from "../../proptypes";
import ScalePriceChart from "./ScalePriceChart";
import {PACKAGINGTYPES} from "../../enums/PackagingTypes";
import {injectIntl} from "react-intl";
import ProductList from "./ProductList";
import {MAX_AMOUNT_OF_UNITS_ADD_TO_CART} from "../Cart/CartItemsList";

class ProductGridList extends Component {

  static productName(product) {
    return product ? product.names['DE'] : '';
  }

  static productNameFormat(product) {
    if (product) {
      let itemName = product.data.formatDefinition;
      if (!itemName) {
        itemName = product.data.specifiedSize;
      }
      return !itemName ? '' : ` - ${itemName}`;
    }
    return '';
  }


  static quantityChoice() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
  }

  static productsList(productGroup) {
    const products = productGroup.products;
    const productList = _.map(products, product => {
      return product;
    });

    return _.filter(productList, (product) => {
      return product.data.forSales["DE"] === true;
    });
  }

  constructor(props) {
    super(props);

    this.getProductQuantity = this.getProductQuantity.bind(this);
    this.showQuantityModal = this.showQuantityModal.bind(this);
    this.hideQuantityModal = this.hideQuantityModal.bind(this);
    this.submitModalFormQuantity = this.submitModalFormQuantity.bind(this);
    this.removeProductQuantity = this.removeProductQuantity.bind(this);
    this.resetProductQuantity = this.resetProductQuantity.bind(this);
    this.submitModalButtonQuantity = this.submitModalButtonQuantity.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.setModalQuantity = this.setModalQuantity.bind(this);
    this.updateModalQuantity = this.updateModalQuantity.bind(this);
    this.setModalQuantityLength = this.setModalQuantityLength.bind(this);
    this.setModalQuantitySquare = this.setModalQuantitySquare.bind(this);
    this.productInCurrentCart = this.productInCurrentCart.bind(this);
    this.productInCurrentCartQuantity = this.productInCurrentCartQuantity.bind(this);

    this.state = {
      quantityModal: false,
      productQuantity: {},
      modalProduct: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const productGroups = this.productGroupList(nextProps.propProductGroups);
    if (productGroups) {
      productGroups.map((productGroup) => {
        ProductGridList.productsList(productGroup)
          .map((product) => {
            const quantity = this.productInCurrentCartQuantity(product);

            const productQuantity = this.getProductQuantity(product);
            if (!productQuantity) {
              this.setProductQuantity(product.productNumber, quantity);
            }
            return true;
          });
        return true;
      });
    }
  }

  setProductQuantity(productNumber, quantity) {
    this.setState(currentState => {
      // deep copy current state, in order to not modify the current state object
      const nextState = _.cloneDeep(currentState);

      nextState.productQuantity[productNumber] = quantity;

      return nextState;
    });
  }

  setModalQuantity(event) {
    const quantity = event.target.value;
    this.updateModalQuantity(quantity);
  }

  setModalQuantityLength(event) {
    const length = event.target.value;
    if (length > 0) {
      const product = this.state.modalProduct;
      if (product) {
        const quantity = ProductList.calculateUsageLFM(product, length);
        if (quantity) {
          this.updateModalQuantity(quantity);
        }
      }
    }
  }
  static isPaletteProduct(product) {
    if (product) {
      const data = product.data;
      return data.leastUnitsPackage >= 1 && data.leastUnitsPackage === data.unitsPerPackage;
    }
    return false;
  }
  setModalQuantitySquare(event) {
    const square = event.target.value;
    if (square > 0) {
      const orderItem = this.state.modalProduct;
      if (orderItem) {
        const quantity = ProductList.calculateUsageM2(orderItem, square);
        if (quantity) {
          this.updateModalQuantity(quantity);
        }
      }
    }
  }

  getProductQuantity(product) {
    let quantity = '';
    if (product) {
      quantity = this.state.productQuantity[product.productNumber];
      if (!quantity) {
        // a controlled input must not be initialized with undefined or null
        quantity = '';
      }
    }
    return quantity;
  }

  updateModalQuantity(quantity) {
    if (quantity) {
      this.setState(currentState => {
        // deep copy current state, in order to not modify the current state object
        const nextState = _.cloneDeep(currentState);

        nextState.modalQuantity = quantity;

        return nextState;
      });
    }
  }

  showQuantityModal(product) {
    this.setState(currentState => {
      // deep copy current state, in order to not modify the current state object
      const nextState = _.cloneDeep(currentState);

      nextState.quantityModal = !nextState.quantityModal;

      if (product) {
        nextState.modalProduct = product;
      }

      return nextState;
    });
  }

  hideQuantityModal() {
    this.setState({
      quantityModal: false,
      modalProduct: null
    });

    this.props.dispatch(removeCurrentMessage());
  }

  productGroupList(propProductGroups) {
    if (propProductGroups) {
      const groups = propProductGroups.productGroups;
      const groupList = _.map(groups, group => {
        return group;
      });

      return _.filter(groupList, (group) => {
        const products = ProductGridList.productsList(group);
        return products.length > 0;
      });
    }
    return [];
  }

  productList(propProductGroups) {
    if (propProductGroups) {
      const groups = propProductGroups.productGroups;
      const groupList = _.map(groups, group => {
        return group;
      });

      const productGroups = _.filter(groupList, (group) => {
        const productList = ProductGridList.productsList(group);
        return productList.length > 0 ? productList : [];
      });

      const allProducts = [];
      productGroups.map((productGroup) => {
        return ProductGridList.productsList(productGroup)
          .map((product) => {
            if (product.data.forSales["DE"]) {
              allProducts.push(product);
            }
            return product;
          });
      });
      return allProducts;
    }
    return [];
  }

  submitModalFormQuantity(event) {
    event.preventDefault();
    let quantity = this.state.modalQuantity;
    if (quantity > MAX_AMOUNT_OF_UNITS_ADD_TO_CART) {
      quantity = MAX_AMOUNT_OF_UNITS_ADD_TO_CART;
    }

    if (quantity > 0) {
      const orderItem = this.state.modalProduct;
      this.addToCart(event, orderItem, quantity);

      this.toggleQuantityModal();
    }

    this.setState({
      modalQuantity: 0
    });
  }


  removeProductQuantity(event) {
    event.preventDefault();

    const product = this.state.modalProduct;
    this.setProductQuantity(product.productNumber, null);

    this.updateModalQuantity(0);
    this.hideQuantityModal();
  }

  resetProductQuantity(event) {
    event.preventDefault();

    const product = this.state.modalProduct;

    const quantity = this.productInCurrentCartQuantity(product);
    this.setProductQuantity(product.productNumber, quantity);

    this.updateModalQuantity(quantity);
    this.hideQuantityModal();
  }

  submitModalButtonQuantity(event, quantity) {
    if (event) {
      event.preventDefault();
      this.hideQuantityModal();

      const product = this.state.modalProduct;
      if (product) {
        this.setProductQuantity(product.productNumber, quantity);
        this.addToCart(event, product, quantity);
      }
    }
  }

  addToCart(e, product, overrideQuantity) {
    e.preventDefault();

    if (product) {
      let quantity = 0;
      if (overrideQuantity) {
        quantity = overrideQuantity;
      } else {
        quantity = this.getProductQuantity(product);
      }
      if (quantity) {
        const cart = this.props.currentCart;
        if (cart) {
          const productNumber = product.productNumber;
          this.props.dispatch(addToCart(cart, productNumber, quantity));
          this.setProductQuantity(productNumber, quantity);
        }
      }
    }
    return false;
  }

  productInCurrentCart(product) {
    const currentCart = this.props.currentCart;
    if (currentCart && product) {
      const products = _.filter(currentCart.orderItems, (orderItem) => {
        return product.productNumber === orderItem.productNumber;
      });
      if (products.length > 0) {
        return products[0];
      }
    }
    return null;
  }

  productInCurrentCartQuantity(product) {
    const orderItemInCart = this.productInCurrentCart(product);
    if (orderItemInCart) {
      let quantity = orderItemInCart.quantityApproved;
      if (!quantity) {
        quantity = orderItemInCart.maxedOutQuantity;
      }
      if (!quantity) {
        quantity = orderItemInCart.quantity;
      }
      if (quantity) {
        return quantity;
      }
    }
    return 0;
  }
  getPluralPackagingTypeLabel(product, prefix) {
    if (product.packaging && product.packaging.type) {
      const pluralLabel = this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label + '.plural'});
      if (prefix) {
        return prefix + pluralLabel
      }
      return pluralLabel
    }
    if (prefix) {
      return prefix + 'Verpackungseinheiten'
    }
    return 'Verpackungseinheiten'
  }
  static isSingleProduct(product) {
    if (product) {
      const data = product.data;
      return data.leastUnitsPackage === 1 && data.leastUnitsPackage < data.unitsPerPackage;
    }
    return false;
  }
  getQuantityModalHeader(product) {
    if (product) {
      return this.getPluralPackagingTypeLabel(product, 'Anzahl der ');
    }
    return '';
  }

  getQuantityModalLabel(product) {
    if (product) {
      return this.getPluralPackagingTypeLabel(product, '');
    }
    return '';
  }
  getFullPalettesQuantity(product, quantity) {
    if (product && quantity) {
      let unitsPalettesQuantity;
      if (ProductGridList.isPaletteProduct(product)) {
        unitsPalettesQuantity = quantity;
      } else {
        unitsPalettesQuantity = quantity * product.data.unitsPerPackage
      }
      return unitsPalettesQuantity;
    }
    return null;
  }
  setModalQuantityPalettes(event) {
    const palettes = event.target.value;
    if (palettes > 0) {
      const product = this.state.modalProduct;
      if (product) {
        const quantity = this.getFullPalettesQuantity(product, palettes);
        if (quantity) {
          this.updateModalQuantity(quantity);
        }
      }
    }
  }
  toggleQuantityModal(product) {
    this.setState(currentState => {
      // deep copy current state, in order to not modify the current state object
      const nextState = _.cloneDeep(currentState);

      nextState.quantityModal = !nextState.quantityModal;

      if (product) {
        nextState.modalProduct = product;
      }

      return nextState;
    })
  }
  render() {
    if (!this.props.propProductGroups) {
      return <span>Bitte wählen Sie eine Kategorie aus der Preisliste.</span>;
    }

    return (
      <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
        {/* loop over all products */
          this.productList(this.props.propProductGroups)
            .map((product, i) => {
              return (
                <div className="d-flex flex-column align-items-center mb-3 col-sm-12 col-md-6 col-lg-4" key={i}>

                    <img alt={product.name}  src={getProductImageUrl(product.productNumber)} />

                    <p className="mt-2 mb-0">
                      {ProductGridList.productName(product)}
                    </p>

                    <ProductGridDetail propProduct={product} />
                    <div className="d-flex flex-row mt-2 col-4 justify-content-between">

                          <Button color="secondary" onClick={() => window.open(product.detail.externalUrls["DE"], "_blank")}>
                            <span className="oi oi-info" />
                          </Button>


                        <Button color="primary" onClick={() => this.showQuantityModal(product)}>
                          <span className="oi oi-cart" />
                        </Button>
                    </div>
                </div>
              );
            })}

        <Modal isOpen={this.state.quantityModal} fade={false} toggle={() => this.toggleQuantityModal()}>
          <ModalHeader tag="h5" toggle={() => this.toggleQuantityModal()}>
            Bitte wählen Sie die {this.getQuantityModalHeader(this.state.modalProduct)} <br/>
            <span className="h6 text-muted text-right">
                        {ProductGridList.productName(this.state.modalProduct)} {ProductGridList.productNameFormat(this.state.modalProduct)}
                    </span>
          </ModalHeader>
          <ModalBody className="text-center">
            <div className="row mb-3">
              {/* loop over all quantity choices */
                ProductGridList.quantityChoice()
                    .map((quantity, k) => {
                      return (
                          <div key={k} className="col-4 col-md-2 mb-2">
                            <Button key={k} className="btn-outline-secondary btn-block"
                                    onClick={(event) => this.submitModalButtonQuantity(event, quantity)}>
                              {quantity}
                            </Button>
                          </div>
                      );
                    })}
            </div>
            <Form className="form-inline" onSubmit={(event) => this.submitModalFormQuantity(event)}>
              <Label className="mt-2 mr-2"
                     for="modal-quantity">{this.getQuantityModalLabel(this.state.modalProduct)}</Label>
              <Input
                  className="ml-xs-2 mt-2" type="number" min="0" name="quantity" id="modal-quantity"
                  onChange={this.setModalQuantity.bind(this)}
                  defaultValue={this.getProductQuantity(this.state.modalProduct)}
              />
              <div className="col pr-0 text-right">
                <Button color="primary" className="btn-primary mt-2 mr-2"
                        type="submit">Übernehmen</Button>
                {!this.productInCurrentCart(this.state.modalProduct) &&
                <Button color="secondary" className="btn-secondary mt-2"
                        onClick={(event) => this.removeProductQuantity(event)}>
                  <span className="oi oi-trash"/>
                </Button>
                }
                {this.productInCurrentCart(this.state.modalProduct) &&
                <Button color="secondary" className="btn-secondary mt-2"
                        onClick={(event) => this.resetProductQuantity(event)}>
                  <span className="oi oi-trash"/>
                </Button>
                }
              </div>
            </Form>

            {(this.state.modalProduct && (this.state.modalProduct.data.materialUsageLFM || this.state.modalProduct.data.materialUsageM2)) &&
            <hr/>
            }

            {(this.state.modalProduct && this.state.modalProduct.data.materialUsageLFM) &&
            <Form className="form-inline text-left"
                  onSubmit={(event) => this.submitModalFormQuantity(event)}>
              <Label className="mt-2 mr-2" for="modal-length">Länge:</Label>
              <Input
                  className="ml-xs-2 mt-2" type="number" min="0" step="0.1" name="length"
                  id="modal-length"
                  onChange={this.setModalQuantityLength.bind(this)}
              />
              <div className="col pr-0 text-right">
                <Button color="primary" className="btn-primary mt-2" type="submit"><span
                    className="pl-1">lfm</span> übernehmen</Button>
              </div>
            </Form>
            }
            {(this.state.modalProduct && this.state.modalProduct.data.materialUsageM2) &&
            <Form className="form-inline text-left"
                  onSubmit={(event) => this.submitModalFormQuantity(event)}>
              <Label className="mt-2 mr-2" for="modal-square">Fläche:</Label>
              <Input
                  className="ml-xs-2 mt-2" type="number" min="0" step="0.1" name="square"
                  id="modal-square"
                  onChange={this.setModalQuantitySquare.bind(this)}
              />
              <div className="col pr-0 text-right">
                <Button color="primary" className="btn-primary mt-2"
                        type="submit"><span>m<sup>2</sup></span> übernehmen</Button>
              </div>
            </Form>
            }
            {(this.state.modalProduct && ProductGridList.isSingleProduct(this.state.modalProduct)) &&
            <Form className="form-inline text-left"
                  onSubmit={(event) => this.submitModalFormQuantity(event)}>
              <Label className="mt-2 mr-2" for="modal-palettes">Paletten</Label>
              <Input
                  className="ml-xs-2 mt-2" type="number" min="0" step="1" name="palettes"
                  id="modal-palettes"
                  onChange={this.setModalQuantityPalettes.bind(this)}
              />
              <div className="col pr-0 text-right">
                <Button color="primary" className="btn-primary mt-2"
                        type="submit"><span>Paletten</span> übernehmen</Button>
              </div>
            </Form>
            }
            { this.state.modalProduct && this.state.modalProduct.data.prices && this.state.modalProduct.data.prices[this.state.country] && this.isScalePrice(this.state.modalProduct.data.prices) &&
            <div className="pt-4">
              <ScalePriceChart
                  openScaleChart={0}
                  j={0}
                  product={this.state.modalProduct}
                  country={this.state.country}
                  packagingLabel={this.getSingularPackagingTypeLabel(this.state.modalProduct)}
              />
            </div>
            }
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ProductGridList.propTypes = {
  propProductGroups: PropTypes.shape(ProductGroups),
  propCategory: PropTypes.shape(Category),
  currentCart: PropTypes.shape(Cart),
  dispatch: PropTypes.func.isRequired,
  currentMessage: PropTypes.shape(Message)
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    currentMessage: getCurrentMessage(state)
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(ProductGridList)));
