import {Route, Redirect, withRouter} from 'react-router';
import Auth from '../../util/Auth';
import React from 'react';
import {PATH_LOGIN_PAGE} from "../../routePaths";
import * as PropTypes from "prop-types";
import {SessionStorageKeys} from "../../util/apiCaller";
import {getCurrentUser} from "../../reducers/UserReducer";
import {connect} from "react-redux";
import {loadCurrentUser} from "../../actions";

class AdminRoute extends React.Component {
    async tokenLogin(){
        const authToken = sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN);
        if(authToken){
            await this.props.dispatch(loadCurrentUser());
        }
    }

    render() {
        let {component: Component, ...rest} = this.props;
        // if(!Auth.getUser()){
        //     this.tokenLogin().then((res) =>   this.props.history.push(PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE))
        // }
        return (
            <Route
                {...rest}
                render={props =>
                    Auth.getUser() && Auth.isAdmin() ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: PATH_LOGIN_PAGE
                            }}
                        />
                    )
                }
            />
        );
    }
}

AdminRoute.propTypes = {component: PropTypes.any}

function mapStateToProps(state){
    return {
        currentUser:getCurrentUser(state)
    }
}

export default withRouter(connect(mapStateToProps)(AdminRoute))
