import React, {Component} from 'react';
import ModalComponent from "./ModalComponent";
import {removeCurrentMessage} from "../../actions";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Message} from "../../proptypes";

class SuccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidUpdate(prevProps) {
        const currentMessage = this.props.currentMessage;
        if (currentMessage !== prevProps.currentMessage && currentMessage) {
            const {type, source} = currentMessage;
            if (type === "INFO" && source === this.props.source) {
                this.showModal()
            }
        }
    }

    showModal() {
        this.setState({isOpen: true})
    }

    hideModal() {
        this.setState({isOpen: false})
    }

    render() {
        const currentMessage = this.props.currentMessage ||  {msg:undefined};
        return (
            <ModalComponent
                isOpen={this.state.isOpen}
                toggle={() => this.showModal()}
                hide={() => this.hideModal()}
                color="info"
                title="Info"
                message={currentMessage.msg}
                onSuccess={() => {
                    this.props.dispatch(removeCurrentMessage());
                    this.hideModal()
                }}
                successText={'Ok'}
            />
        );
    }
}
function mapStateToProps(state) {
    return {
        currentMessage: getCurrentMessage(state)
    }
}
SuccessModal.propTypes = {
    source: PropTypes.string.isRequired,
    currentMessage: PropTypes.shape(Message)
};
export default connect(mapStateToProps)(SuccessModal);
