import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {Form, FormGroup, Input, Label} from 'reactstrap';
// http://react-day-picker.js.org
import DayPicker from 'react-day-picker';
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
import {getHolidays, isHoliday} from 'feiertagejs';

import {getCurrentCart} from '../../reducers/CartReducer';
import {getEarliestTour} from "../../reducers/TourReducer";
import {BlockableWeekday, BlockedDate, Cart, Tour} from "../../proptypes";
import {
  loadBlockedDeliveryDays,
  loadBlockedDeliveryTours, loadBlockedIndividualDays,
  loadBlockedWeekdays,
  loadHolidays,
  loadState
} from "../../reducers/OrderReducer";
import {
  fetchBlockedDeliveryDays,
  fetchBlockedIndividualDays,
  fetchOrderSettings,
  setDeliveryCart,
  setPickupCart
} from "../../actions";

export const REGION_BAVARIA = 'BY';

class DatePicker extends Component {

  static getConfirmedTourFromDate(cart) {
    const tour = DatePicker.getConfirmedTour(cart);
    return tour ? new Date(tour.fromDate) : null;
  }

  static getFormattedConfirmedTimeLabel(cart) {
    const tour = DatePicker.getConfirmedTour(cart);
    if (tour) {
      return DatePicker.getHourLabel(tour);
    }
    return '';
  }

  static getTourLabelHour(hour) {
    let label;
    if (hour <= DatePicker.getStartOfWorkHour()) {
      label = '07 - 11 Uhr';
    } else if (hour <= DatePicker.getNoonWorkHour()) {
        label = '11 - 14 Uhr';
    } else if (hour <= DatePicker.getEndOfWorkHour()) {
          label = '14 - 17 Uhr';
    }
    return label;
  }

  static getTourLabelDate(date) {
    const hour = new Date(date).getHours();
    return DatePicker.getTourLabelHour(hour);
  }

  static getHourLabel(tour) {
    if (tour) {
      const fromDate = tour.fromDate;
      const fromHours = new Date(fromDate).getHours();
      const toDate = tour.toDate;
      const toHours = new Date(toDate).getHours();

      if (fromHours === DatePicker.getStartOfWorkHour() && toHours === DatePicker.getEndOfWorkHour()) {
        return 'Laufe des Tages';
      }
      return DatePicker.getTourLabelDate(new Date(fromDate));
    }
    return '';
  }

  static truncateToDay(date) {
    const day = new Date(date);
    return new Date(day.setHours(0, 0, 0, 0));
  }

  static isDeliveryConfirmed(cart) {
    return Boolean(cart.delivery && cart.delivery.confirmed);
  }

  static isPickupConfirmed(cart) {
    return Boolean(cart.pickup && DatePicker.getConfirmedTour(cart));
  }

  static getConfirmedTour(cart) {
    if (cart) {
      let tour;
      if (cart.delivery) {
        tour = cart.delivery.confirmedTour;
      } else {
        tour = cart.pickup.confirmedTour;
      }
      if (tour) {
        return tour;
      }
    }
    return null;
  }

  static getStartOfWorkHour() {
    return 7;
  }

  static getEndOfWorkHour() {
    return 17;
  }

  static getNoonWorkHour() {
    return 11;
  }

  static getEndOfDayHour() {
    return 20;
  }

  static getTour(cart) {
    if (cart) {
      let tour;
      if (cart.delivery) {
        tour = cart.delivery.scheduledTour;
      } else {
        tour = cart.pickup.scheduledTour;
      }
      return tour;
    }
    return null;
  }

  static getTourFromDate(cart) {
    const tour = DatePicker.getTour(cart);
    return tour ? new Date(tour.fromDate) : null;
  }

  static getFormattedTimeLabel(cart) {
    const tour = DatePicker.getTour(cart);
    if (tour) {
      return DatePicker.getHourLabel(tour);
    }
    return '';
  }

  static hasValidScheduleDate(cart, earliestTour) {
    if (!earliestTour) {
      return false;
    }

    let tour = DatePicker.getConfirmedTour(cart);
    if (!tour) {
      tour = DatePicker.getTour(cart);
    }

    if (tour) {
      const tourFromDate = new Date(tour.fromDate);
      const holiday = DatePicker.isHoliday(tourFromDate);
      if (holiday) {
        return false;
      }

      const truncatedTourDay = DatePicker.truncateToDay(new Date(tourFromDate.getTime()));
      const nowDay = DatePicker.truncateToDay(new Date());

      if (Boolean(truncatedTourDay < nowDay)) {
        return false;
      }

      const minSelectDate = new Date(earliestTour.fromDate);
      const truncatedMinSelectedDay = DatePicker.truncateToDay(new Date(minSelectDate.getTime()));

      if (Boolean(truncatedTourDay < truncatedMinSelectedDay)) {
        return false;
      }
      if (Boolean(truncatedTourDay > truncatedMinSelectedDay)) {
        return true;
      }

      const tourToDate = new Date(tour.toDate);
      const tourToDateHours = DatePicker.getHours(tourToDate);
      const tourFromHours = DatePicker.getHours(tourFromDate);
      const minDateHours = DatePicker.getHours(minSelectDate);

      const eod = DatePicker.getEndOfWorkHour();
      // console.info('tourFromHours=' + tourFromHours + ' tourToDateHours=' + tourToDateHours + ' minDateHours=' + minDateHours + ' eod=' + eod);
      return Boolean(tourFromHours <= eod && minDateHours <= tourToDateHours && minDateHours <= eod);
    }
    return false;
  }

  static addHoursToSelectedDate(selectedDate, hours) {
    if (selectedDate) {
      const selectedDateHours = DatePicker.truncateToDay(new Date(selectedDate.getTime()));
      selectedDateHours.setHours(hours);
      return selectedDateHours;
    }
    return selectedDate;
  }

  static getDisabledHolidayDates(year) {
    const bavarianHolidays = getHolidays(year, REGION_BAVARIA);

    const dates = [];
    _.each(bavarianHolidays, (holiday) => {
      dates.push(new Date(holiday.dateString));
    });
    return dates;
  }

  static isHoliday(date) {
    if (date) {
      return isHoliday(date, REGION_BAVARIA);
    }
    return false;
  }

  static getYear(date) {
    return date.getFullYear();
  }

  static getMonth(date) {
    return date.getMonth();
  }

  static getDay(date) {
    return date.getDate();
  }

  static getHours(date) {
    return date.getHours();
  }

  static getFormattedDate(date) {
    if (date) {
      const options = {
        year: 'numeric', month: '2-digit', day: '2-digit'
      };
      return new Intl.DateTimeFormat('de-DE', options).format(new Date(date));
    }
    return '';
  }

  constructor(props) {
    super(props);

    this.handleDayClick = this.handleDayClick.bind(this);
    this.submitTourDateBtn = this.submitTourDateBtn.bind(this);
    this.submitTourDate = this.submitTourDate.bind(this);
    this.isDateTimeDisabled = this.isDateTimeDisabled.bind(this);
    this.isDateTimeChecked = this.isDateTimeChecked.bind(this);
    this.showTimeSelection = this.showTimeSelection.bind(this);
    this.getBlockedDays = this.getBlockedDays.bind(this);

    this.state = {
      selectedDate: undefined
    };
  }

  componentDidMount() {
    const cart = this.props.currentCart;
    if (cart) {
      this.setSelectedDay(cart);
    }
    this.props.dispatch(fetchBlockedIndividualDays());
    this.props.dispatch(fetchOrderSettings());
    this.props.dispatch(fetchBlockedDeliveryDays());
  }

  getBlockedDays() {
    let blockedDays = [];

    let blockedIndividualDays = this.props.blockedIndividualDays;
    if (blockedIndividualDays) {
      blockedIndividualDays = blockedIndividualDays.map(blockedDate => {
        return new Date(blockedDate.date);
      });
      blockedDays = blockedDays.concat(blockedIndividualDays);
    }
    let weekdays = this.props.blockedWeekdays;
    if (weekdays) {
      weekdays = weekdays.filter(day => {
        return !!day.blocked;
      })
        .map(day => {
          return day.weekday;
        });
      blockedDays = blockedDays.concat({daysOfWeek: weekdays});
    }

    const holidays = this.props.blockedHolidays;
    if (holidays) {
      blockedDays = blockedDays.concat(holidays);
    }

    const delivery = this.props.currentCart.delivery;
    if (delivery) {
      const blockedDeliveryDays = this.props.blockedDeliveryDays;
      if (blockedDeliveryDays) {
        const deliveryDaysBlocked = blockedDeliveryDays.map(blockedDate => {
          return new Date(blockedDate.date);
        });

        blockedDays = blockedDays.concat(deliveryDaysBlocked);
      }
    }
    return blockedDays;
  }

  setSelectedDay(cart) {
    const tourFromDate = DatePicker.getTourFromDate(cart);

    this.setState({
      selectedDate: tourFromDate
    });
  }

  showTimeSelection(selectedDate, hours) {
    const cart = this.props.currentCart;
    const tour = this.props.earliestTour;
    if (!cart || !tour || !selectedDate) {
      return true;
    }

    const minSelectDate = new Date(tour.fromDate);
    const truncatedMinSelectedDay = DatePicker.truncateToDay(new Date(minSelectDate.getTime()));
    const truncatedSelectedDay = DatePicker.truncateToDay(new Date(selectedDate.getTime()));

    if (truncatedMinSelectedDay.getTime() === truncatedSelectedDay.getTime()) {
      // same day check
      const selectedDateHours = DatePicker.addHoursToSelectedDate(selectedDate, hours);
      return Boolean(selectedDateHours >= minSelectDate);
    }
    return true;
  }

  submitTourDateBtn(event) {
    const hours = event.target.value;
    this.submitTourDate(hours);
  }

  submitTourDate(hours) {
    if (hours) {
      if (this.state.selectedDate) {
        const cart = this.props.currentCart;
        if (cart) {
          let scheduledTour;
          // console.info('hours=' + hours + " eod=" + DatePicker.getEndOfDayHour().toString());
          if (hours !== DatePicker.getEndOfDayHour()
            .toString()) {
            const selectedDateHours = DatePicker.addHoursToSelectedDate(this.state.selectedDate, hours);
            // console.info('selectedDateHours=' + selectedDateHours);
            scheduledTour = {fromDate: selectedDateHours.getTime(), toDate: selectedDateHours.getTime()};
          } else {
            const fromDateHours = DatePicker.addHoursToSelectedDate(this.state.selectedDate, DatePicker.getStartOfWorkHour());
            const toDateHours = DatePicker.addHoursToSelectedDate(this.state.selectedDate, DatePicker.getEndOfWorkHour());
            // console.info('fromDateHours=' + fromDateHours + ' toDateHours=' + toDateHours);
            scheduledTour = {fromDate: fromDateHours.getTime(), toDate: toDateHours.getTime()};
          }

          if (cart.delivery) {
            this.props.dispatch(setDeliveryCart(cart, {scheduledTour}));
          } else {
            const facility = cart.pickup.facility;
            this.props.dispatch(setPickupCart(cart, facility.name, {scheduledTour}));
          }
        }
      }
    }
  }

  handleDayClick(day, {disabled}) {
    if (disabled) {
      return;
    }

    this.setState({
      selectedDate: day
    });
  }

  isBlockedDeliveryDate(date) {
    let found = false;
    if (date) {
      const blockedDeliveryDays = this.props.blockedDeliveryDays;
      if (blockedDeliveryDays) {
        const normalizedDate = date.setHours(0);

        blockedDeliveryDays.forEach(blockedDeliveryDate => {
          const normalizedBlockedDate = blockedDeliveryDate.date.setHours(0);
          if (!found && normalizedBlockedDate === normalizedDate) {
            found = true;
          }
        });
      }
    }
    return found;
  }

  isDateTimeDisabled(hours) {
    const selectedDate = this.state.selectedDate;
    if (!selectedDate) {
      return false;
    }

    const delivery = this.props.currentCart.delivery;
    if (delivery) {
      if (this.isBlockedDeliveryDate(selectedDate)) {
        return true;
      }

      const blockedDeliveryTours = this.props.blockedDeliveryTours;
      if (blockedDeliveryTours) {
        const deliveryTourStr = blockedDeliveryTours.map(blockedDate => {
          const blockedTourDate = new Date(blockedDate.date);
          return `${DatePicker.truncateToDay(blockedTourDate)}_${DatePicker.getTourLabelDate(blockedTourDate)}`;
        });

        const selectedTourStr = `${DatePicker.truncateToDay(selectedDate)}_${DatePicker.getTourLabelHour(hours)}`;
        return deliveryTourStr.includes(selectedTourStr);
      }
    }

    const holiday = DatePicker.isHoliday(selectedDate);
    if (holiday) {
      return true;
    }

    const earliestTour = this.props.earliestTour;
    if (!earliestTour) {
      return true;
    }

    const truncatedSelectedDay = DatePicker.truncateToDay(new Date(selectedDate.getTime()));
    const minSelectDate = new Date(earliestTour.fromDate);
    const truncatedMinSelectedDay = DatePicker.truncateToDay(new Date(minSelectDate.getTime()));

    if (Boolean(truncatedSelectedDay < truncatedMinSelectedDay)) {
      return true;
    }

    const showTimeSelection = this.showTimeSelection(selectedDate, hours);
    // console.info('hours=' + hours + ' showTimeSelection=' + showTimeSelection);
    return !showTimeSelection;
  }

  isDateTimeChecked(lowHour, highHour) {
    if (this.isDateTimeDisabled(highHour)) {
      // console.info('disabled=' + highHour);
      return false;
    }

    const selectedDate = this.state.selectedDate;
    if (selectedDate) {
      const tour = DatePicker.getTour(this.props.currentCart);
      if (tour) {
        const tourFromDate = new Date(tour.fromDate);
        const truncatedTourDay = DatePicker.truncateToDay(new Date(tourFromDate.getTime()));
        const truncatedSelectedDay = DatePicker.truncateToDay(new Date(selectedDate.getTime()));

        // console.info('truncatedTour=' + truncatedTour + ' truncatedSelected=' + truncatedSelected);
        if (Boolean(truncatedTourDay < truncatedSelectedDay)) {
          return false;
        }
        if (Boolean(truncatedTourDay > truncatedSelectedDay)) {
          return false;
        }

        const tourToDate = new Date(tour.toDate);
        const tourToHours = DatePicker.getHours(tourToDate);
        const tourFromHours = DatePicker.getHours(tourFromDate);

        // console.info('tourFromHours=' + tourFromHours + ' lowHour=' + lowHour + ' tourToHours=' + tourToHours + ' highHour=' + highHour);
        if (tourFromHours <= lowHour && highHour <= tourToHours) {
          return true;
        }
      }
    }
    // console.info('selectedDate=' + selectedDate);
    return false;
  }

  render() {
    if (!this.props.earliestTour) {
      return <span>...</span>;
    }

    const now = new Date();
    const year = DatePicker.getYear(now);
    const month = DatePicker.getMonth(now);
    const nextFullYear = new Date(year + 1, 11);

    const minSelectDate = new Date(this.props.earliestTour.fromDate);
    const minSelectedYear = DatePicker.getYear(minSelectDate);
    const minSelectedMonth = DatePicker.getMonth(minSelectDate);
    const minSelectedDay = DatePicker.getDay(minSelectDate);

    const tourDate = DatePicker.getTourFromDate(this.props.currentCart);
    const tourYear = DatePicker.getYear(tourDate);
    const tourMonth = DatePicker.getMonth(tourDate);

    const initialYear = tourDate < minSelectDate ? minSelectedYear : tourYear;
    const initialMonth = tourDate < minSelectDate ? minSelectedMonth : tourMonth;

    const disabledDays = [
      {
        before: new Date(minSelectedYear, minSelectedMonth, minSelectedDay)
      }
    ].concat(this.getBlockedDays());
    return (
      <div>
        {DatePicker.isDeliveryConfirmed(this.props.currentCart) &&
          <div className="pt-4">
            <div className="pt-3 alert alert-warning">
              <span className="oi oi-warning mr-2" />
              Der Liefertermin ist nicht mehr änderbar. Die Lieferung ist zum&nbsp;
              {DatePicker.getFormattedDate(DatePicker.getConfirmedTourFromDate(this.props.currentCart))}, {DatePicker.getFormattedConfirmedTimeLabel(this.props.currentCart)}&nbsp;
              bestätigt.
            </div>
          </div>
        }
        {DatePicker.isPickupConfirmed(this.props.currentCart) &&
          <div className="pt-4">
            <div className="pt-3 alert alert-warning">
              <span className="oi oi-warning mr-2" />
              Der Termin zur Abholung ist nicht mehr änderbar. Die Abholung ist zum&nbsp;
              {DatePicker.getFormattedDate(DatePicker.getConfirmedTourFromDate(this.props.currentCart))}, {DatePicker.getFormattedConfirmedTimeLabel(this.props.currentCart)}&nbsp;
              bestätigt.
            </div>
          </div>
        }

        {!DatePicker.getConfirmedTour(this.props.currentCart) &&
          <Form>
            <div className="row border-0 align-items-center">
              <div className="col-md-1" />

              <div className="col-md-4">
                <FormGroup>
                  <DayPicker
                    showWeekNumbers
                    localeUtils={MomentLocaleUtils} locale={'de'}
                    fromMonth={new Date(year, month)}
                    toMonth={nextFullYear}
                    initialMonth={new Date(initialYear, initialMonth)}
                    selectedDays={this.state.selectedDate}
                    onDayClick={this.handleDayClick}
                    disabledDays={disabledDays}
                    showOutsideDays
                  />
                </FormGroup>
              </div>

              <div className="col-md-2" />

              <div className="col-md-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio" name="dateTime" value={DatePicker.getStartOfWorkHour()
                      .toString()}
                      onClick={this.submitTourDateBtn.bind(this)}
                      disabled={this.isDateTimeDisabled(DatePicker.getStartOfWorkHour())}
                      checked={this.isDateTimeChecked(DatePicker.getStartOfWorkHour(), DatePicker.getStartOfWorkHour())}
                    />
                    {this.isDateTimeDisabled(DatePicker.getStartOfWorkHour()) &&
                      <span className="text-secondary">{DatePicker.getFormattedDate(this.state.selectedDate)} von 07 - 11 Uhr</span>
                    }
                    {!this.isDateTimeDisabled(DatePicker.getStartOfWorkHour()) &&
                      <span>{DatePicker.getFormattedDate(this.state.selectedDate)} von 07 - 11 Uhr</span>
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio" name="dateTime" value={DatePicker.getNoonWorkHour()
                      .toString()}
                      onClick={this.submitTourDateBtn.bind(this)}
                      disabled={this.isDateTimeDisabled(DatePicker.getNoonWorkHour())}
                      checked={this.isDateTimeChecked(DatePicker.getNoonWorkHour(), DatePicker.getNoonWorkHour())}
                    />
                    {this.isDateTimeDisabled(DatePicker.getNoonWorkHour()) &&
                      <span className="text-secondary">{DatePicker.getFormattedDate(this.state.selectedDate)} von 11 - 14 Uhr</span>
                    }
                    {!this.isDateTimeDisabled(DatePicker.getNoonWorkHour()) &&
                      <span>{DatePicker.getFormattedDate(this.state.selectedDate)} von 11 - 14 Uhr</span>
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio" name="dateTime" value={DatePicker.getEndOfWorkHour()
                      .toString()}
                      onClick={this.submitTourDateBtn.bind(this)}
                      disabled={this.isDateTimeDisabled(DatePicker.getEndOfWorkHour())}
                      checked={this.isDateTimeChecked(DatePicker.getEndOfWorkHour(), DatePicker.getEndOfWorkHour())}
                    />
                    {this.isDateTimeDisabled(DatePicker.getEndOfWorkHour()) &&
                      <span className="text-secondary">{DatePicker.getFormattedDate(this.state.selectedDate)} von 14 - 17 Uhr</span>
                    }
                    {!this.isDateTimeDisabled(DatePicker.getEndOfWorkHour()) &&
                      <span>{DatePicker.getFormattedDate(this.state.selectedDate)} von 14 - 17 Uhr</span>
                    }
                  </Label>
                </FormGroup>
              </div>

              <div className="col-md-1" />
            </div>
          </Form>
        }

        {!DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour) &&
          <div>
            {(!DatePicker.isDeliveryConfirmed(this.props.currentCart) && this.props.currentCart.delivery) &&
              <div className="pt-3 alert alert-warning">
                <span className="oi oi-warning mr-2" />
                Der aktuelle Liefertermin&nbsp;
                {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {DatePicker.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                ist nicht mehr realisierbar. Bitte wählen sie ein Datum.
              </div>
            }
            {(!DatePicker.isPickupConfirmed(this.props.currentCart) && this.props.currentCart.pickup) &&
              <div className="pt-3 alert alert-warning">
                <span className="oi oi-warning mr-2" />
                Der aktuelle Termin zur Abholung&nbsp;
                {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {DatePicker.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                ist nicht mehr realisierbar. Bitte wählen sie ein Datum.
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

DatePicker.propTypes = {
  currentCart: PropTypes.shape(Cart),
  earliestTour: PropTypes.shape(Tour),
  dispatch: PropTypes.func.isRequired,
  blockedIndividualDays: PropTypes.arrayOf(PropTypes.shape(BlockedDate)),
  blockedWeekdays: PropTypes.arrayOf(PropTypes.shape(BlockableWeekday)),
  blockedHolidays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  blockedDeliveryTours: PropTypes.arrayOf(PropTypes.shape(BlockedDate)),
  blockedDeliveryDays: PropTypes.arrayOf(PropTypes.shape(BlockedDate))
};

function mapStateToProps(state) {
  return {
    currentCart: getCurrentCart(state),
    earliestTour: getEarliestTour(state),
    blockedIndividualDays: loadBlockedIndividualDays(state),
    blockedWeekdays: loadBlockedWeekdays(state),
    blockedHolidays: loadHolidays(state),
    selectedState: loadState(state),
    blockedDeliveryTours: loadBlockedDeliveryTours(state),
    blockedDeliveryDays: loadBlockedDeliveryDays(state)
  };
}

export default withRouter(connect(mapStateToProps)(DatePicker));
