import {
  LOGOUT,
  LOAD_USERS,
  LOAD_CUSTOMER_USERS
} from '../actions/types';

// Initial State
const initialState = {
  stateUsers: null,
  stateCustomerUsers: null
};

const UsersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateUsers: null,
        stateCustomerUsers: null
      };
    }
    case LOAD_CUSTOMER_USERS: {
      const actionCustomerUsers = action.users;
      if (!actionCustomerUsers.users) {
        return {
          ...state,
          stateCustomerUsers: null
        };
      }

      return {
        ...state,
        stateCustomerUsers: actionCustomerUsers
      };
    }
    case LOAD_USERS: {
      const actionUsers = action.users;
      if (!actionUsers.users) {
        return {
          ...state,
          stateUsers: null
        };
      }

      return {
        ...state,
        stateUsers: actionUsers
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCustomerUsers = (state) => state.users.stateCustomerUsers;
export const getAllUsers = (state) => state.users.stateUsers;

// Export Reducer
export default UsersReducer;
