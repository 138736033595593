import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE, PATH_USER_HOME_PAGE} from "../routePaths";
import {Redirect, Route} from "react-router";
import Auth from '../util/Auth';

export const SORT_STATE_DATE_INC = 'date_inc';
export const SORT_STATE_DATE_DEC = 'date_dec';

class HomePage extends Component {
    render() {
        return (
            <Route>
                {
                    Auth.isAdmin() ?
                        <Redirect to={PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE}/>
                    :   <Redirect to={PATH_USER_HOME_PAGE}/>
                }
            </Route>
        );
    }
}

export default connect()(HomePage);
