import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {
    attachUserToCustomer,
    clearUserToManage,
    createUser,
    removeCurrentMessage,
    resetPassword,
    updateUser
} from '../../actions';
import {Button, Form, FormFeedback, FormGroup, FormText, Input, Label} from 'reactstrap';

import {Customer, Message, User} from '../../proptypes';

import {getCurrentUser, getUserToManage} from '../../reducers/UserReducer';
import {getCurrentMessage} from '../../reducers/MessageReducer';
import {getCustomer} from '../../reducers/CustomerReducer';

import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import "react-day-picker/lib/style.css";
import InputComponent from "./InputComponent";
import ModalComponent from "../Modals/ModalComponent";
import {
    PATH_ADMIN_USER_CUSTOMER_PAGE,
    PATH_USER_MANAGE_USER_PAGE
} from "../../routePaths";
import {isValidEmail, isValidPhone, notEmpty} from "../../util/ValidFn";
import {injectIntl} from "react-intl";
import Auth from "../../util/Auth";

class ManageUserPage extends Component {

    static isUserExpiredSet(user) {
        const expires = user.expires;
        return expires !== undefined && expires > 0;
    }

    static isAdminUser(user) {
        return user.role === 'ADMIN';
    }

    static canUserNotChangeUserToManage(currentUser, userToManage) {
        if (ManageUserPage.isAdminUser(userToManage)) {
            return Boolean(currentUser.id !== userToManage.id);
        }
        return false;
    }

    constructor(props) {
        super(props);
        this.state = {
            userToManage: this.getInitUser(),
            modals: {
                create: false,
                change: false,
                error: false
            },
            showPasswordReset: false,
        };

        this.handleUserBasicDataChange = this.handleUserBasicDataChange.bind(this);
        this.handleUserContactDataChange = this.handleUserContactDataChange.bind(this);
        this.handleUserCredentialsDataChange = this.handleUserCredentialsDataChange.bind(this);
        this.setUserToManage = this.setUserToManage.bind(this);
        this.setCustomer = this.setCustomer.bind(this);
        this.createUser = _.throttle(this.createUser.bind(this), 1000, {trailing: false});
        this.updateUser = _.throttle(this.updateUser.bind(this), 1000, {trailing: false});
        this.resetPassword = _.throttle(this.resetPassword.bind(this), 1000, {trailing: false});
        this.switchToPage = this.switchToPage.bind(this);
        this.clearFieldsOnNewUser = this.clearFieldsOnNewUser.bind(this);
        this.showPasswordReset = this.showPasswordReset.bind(this);
        this.getMinExpiresDate = this.getMinExpiresDate.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.edit) {
            const nextCustomer = this.props.currentCustomer;
            this.props.dispatch(removeCurrentMessage());
            if (nextCustomer) {
                this.setCustomer(nextCustomer);
            }
            if (this.props.userToManage) {
                this.setUserToManage(this.props.userToManage);
            }
        } else {
            this.clearFieldsOnNewUser()
        }
    }

    componentWillReceiveProps(nextProps) {
        const nextUser = nextProps.userToManage;

        if (nextUser && this.props.edit) {
            this.setUserToManage(nextUser);
        }

        const nextMessage = nextProps.currentMessage;
        if (nextMessage) {
            if (nextMessage.source === 'CHANGE_USER') {
                this.showModal('change');
            }
            if (nextMessage.source === 'CREATE_USER') {
                this.showModal('create')
            }
            if (nextMessage.type === "ERROR") {
                this.showModal('error')
            }
            if (nextMessage.source === 'RESET_PASSWORD') {

            }
        }
    }

    setUserToManage(user) {
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.userToManage = user;
                nextState.userToManage.credentials.password = '';
                return nextState;
            }
        );
    }

    setCustomer(nextCustomer) {
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.customer = nextCustomer;
                return nextState;
            }
        );
    }

    getInitUser() {
        return {
            contact: {
                email: '',
                fax: '',
                mobile: '',
                phone: ''
            },
            credentials: {
                email: '',
                password: ''
            },
            firstName: '',
            gender: 'MALE',
            lastName: '',
            title: '',
            expires: null,
            language: 'DE',
            role: "USER"
        }
    }

    getMinExpiresDate() {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const userToManage = this.props.userToManage;
        if (userToManage) {
            const expires = userToManage.expires;
            return expires && expires < yesterday ? new Date(expires) : yesterday;
        }
        return yesterday;
    }

    isManagedAdmin() {
        return this.props.userToManage.role === 'ADMIN';
    }

    isSelf() {
        return this.props.currentUser.id === this.props.userToManage.id;
    }

    handleUserBasicDataChange(event) {
        const user = Object.assign({}, this.state.userToManage);
        user[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.userToManage = user;
            return nextState;
        });
    }

    handleUserContactDataChange(event) {
        const contact = Object.assign({}, this.state.userToManage.contact);
        contact[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.userToManage.contact = contact;
            return nextState;
        });
    }

    handleUserCredentialsDataChange(event) {
        const credentials = Object.assign({}, this.state.userToManage.credentials);
        credentials[event.target.type] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.userToManage.credentials = credentials;
            return nextState;
        });
    }

    createUser(event, user, customer) {
        if (customer) {
            this.props.dispatch(attachUserToCustomer(user, customer));
        } else {
            this.props.dispatch(createUser(user));
        }
    }

    updateUser(event, user) {
        this.setState({
            showPasswordReset: false
        });

        // copy origin user without credentials
        const userCopy = Object.assign({}, user);
        delete userCopy.credentials;

        this.props.dispatch(updateUser(userCopy));
    }

    resetPassword(event) {
        event.preventDefault();

        const userToManage = this.props.userToManage;
        if (userToManage) {
            const email = userToManage.credentials.email;
            this.props.dispatch(resetPassword(email));
        }

        this.setState({
            reset: false
        });
    }

    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }

    clearFieldsOnNewUser(event) {
        this.props.dispatch(clearUserToManage());
        this.props.dispatch(removeCurrentMessage());

        this.setUserToManage(this.getInitUser())
        this.setState({
            showPasswordReset: false,
        });

        if (event) {
            event.preventDefault();
        }
    }

    showPasswordReset(event) {
        event.preventDefault();
        this.setState({
            showPasswordReset: !this.state.showPasswordReset
        });
    }

    handleDayClick(day, {selected}) {
        const expires = day;
        expires.setHours(23, 59, 59, 0);

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);
            nextState.userToManage.expires = selected ? new Date(0).getTime() : expires.getTime();
            return nextState;
        });
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    isRequiredNotEmpty() {
        const userToManage = this.state.userToManage;
        return notEmpty(userToManage.lastName)
    }

    isRequiredValid() {
        const userToManage = this.state.userToManage;
        return isValidEmail(userToManage.contact.email) && isValidPhone(userToManage.contact.phone)
    }

    isFormComplete() {
        return this.isRequiredValid() && this.isRequiredNotEmpty()
    }

    render() {
        const userId = this.props.userToManage ? this.props.userToManage.id : false;
        const isSelf = this.props.currentUser.id === userId;
        const loginComponentClassName = this.props.currentCustomer.salesRepresentative ? "col-md 4" : "offset-md-4 col-md-4";
        return (
            <div>
                <div className="d-flex flex-column flex-md-row col-12 mb-4 align-items-end">
                    <div className="ml-auto mb-2">
                        {this.props.edit && (this.isSelf() || !this.isManagedAdmin()) &&
                        <Button
                            color="success" href="" className="btn-block"
                            onClick={(e) => this.updateUser(e, this.state.userToManage)}
                            disabled={!this.isFormComplete()}
                        >
                            Benutzer aktualisieren
                        </Button>
                        }
                        {this.props.create &&
                        <Button
                            color="success" href="" className="btn-block"
                            onClick={(e) => this.createUser(e, this.state.userToManage, this.props.currentCustomer)}
                            disabled={!this.isFormComplete()}
                        >
                            neuen Benutzer anlegen
                        </Button>
                        }
                    </div>
                </div>
                <div className="d-flex flex-md-row mt-4">
                    {(this.props.currentCustomer.salesRepresentative === true) &&
                        <Form className="col-md-4">
                            <h2>
                                Nutzerberechtigung
                            </h2>
                            <FormGroup>
                                <Label for="role">Rolle</Label>
                                <Input
                                    type="select" name="role" id="role" placeholder="Die Rolle des Nutzers"
                                    value={this.state.userToManage.role}
                                    onChange={this.handleUserBasicDataChange.bind(this)}
                                    maxLength={64}
                                    disabled={this.props.edit}
                                >
                                    <option value="CUSTOMER_USER">Endkunde</option>
                                    <option value="USER">Händler</option>
                                </Input>
                            </FormGroup>
                        </Form>
                    }
                    <Form className={loginComponentClassName}>
                        <h2>
                            Logindaten
                        </h2>
                        <InputComponent title="user.form.accountTitle" name='new-email' type="email"
                                        placeholder="user.form.accountPlaceholder"
                                        formFeedback="user.form.accountFormFeedback"
                                        value={this.state.userToManage.credentials.email}
                                        handleChange={this.handleUserCredentialsDataChange.bind(this)}
                                        maxLength={64}
                                        formText="user.form.accountFormText"
                                        required
                        />
                        {!this.props.userToManage && (Auth.isAdmin() && this.props.create) &&
                        <InputComponent title="user.form.passwordTitle" name='new-password' type="password"
                                        formFeedback="user.form.passwordFormFeedback"
                                        value={this.state.userToManage.credentials.password}
                                        handleChange={this.handleUserCredentialsDataChange.bind(this)}
                                        maxLength={64}
                                        formText="user.form.passwordFormText"
                        />
                        }

                        {this.props.userToManage && (!isSelf) &&
                        <Button
                            color="none" href="" className="btn-block" tag="a"
                            onClick={(e) => this.showPasswordReset(e)}
                            hidden={this.props.userToManage ? ManageUserPage.canUserNotChangeUserToManage(this.props.currentUser, this.props.userToManage) : false}
                        >
                            <u>Nutzerpasswort zurücksetzen</u>
                        </Button>
                        }

                        {(this.props.userToManage && this.state.showPasswordReset) &&
                        <FormGroup className="pt-3">
                            <Button
                                className="btn btn-primary btn-block" color="primary"
                                onClick={(e) => this.resetPassword(e)}
                            >
                                Passwort zurück setzen!
                            </Button>
                            <FormText className="text-center h6 pt-1">Ein neues Passwort wird
                                an {this.props.userToManage.contact.email} versendet.</FormText>
                        </FormGroup>
                        }
                    </Form>
                    {(this.props.currentCustomer.salesRepresentative === true) &&
                        <Form className="col-md-4"> </Form>
                    }
                </div>

                <div className="d-flex flex-column flex-md-row align-items-baseline mt-4">
                    <Form className="col-md-4">
                        <h2>
                            Basisdaten
                        </h2>
                        <InputComponent title="user.form.titleTitle" name='title' type="text"
                                        placeholder="user.form.titlePlaceholder"
                                        value={this.state.userToManage.title}
                                        handleChange={this.handleUserBasicDataChange.bind(this)}
                                        maxLength={64}
                        />

                        <InputComponent title="user.form.firstNameTitle" name='firstName' type="text"
                                        value={this.state.userToManage.firstName}
                                        handleChange={this.handleUserBasicDataChange.bind(this)}
                                        maxLength={64}
                        />
                        <InputComponent title="user.form.lastNameTitle" name='lastName' type="text"
                                        value={this.state.userToManage.lastName}
                                        handleChange={this.handleUserBasicDataChange.bind(this)}
                                        maxLength={64}
                                        formFeedback="user.form.lastNameFormFeedback"
                                        required
                        />
                        <FormGroup>
                            <Label for="gender">Geschlecht</Label>
                            <Input
                                type="select" name="gender" id="gender" placeholder="Geschlecht des Benutzers"
                                value={this.state.userToManage.gender}
                                onChange={this.handleUserBasicDataChange.bind(this)}
                                maxLength={64}
                                disabled={this.props.userToManage ? ManageUserPage.canUserNotChangeUserToManage(this.props.currentUser, this.props.userToManage) : false}
                            >
                                <option value="MALE">Männlich</option>
                                <option value="FEMALE">Weiblich</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="language">Sprache</Label>
                            <Input
                                type="select" name="language" id="language" placeholder="Sprache des Benutzers"
                                value={this.state.userToManage.language}
                                onChange={this.handleUserBasicDataChange.bind(this)}
                                maxLength={64}
                                disabled={this.props.userToManage ? ManageUserPage.canUserNotChangeUserToManage(this.props.currentUser, this.props.userToManage) : false}
                            >
                                <option value="DE">{this.props.intl.formatMessage({id: 'lang.select.de'})}</option>
                                <option value="PL">{this.props.intl.formatMessage({id: 'lang.select.pl'})}</option>
                            </Input>
                        </FormGroup>
                    </Form>
                    <Form className="col-md-4">
                        <h2>
                            Kontakt
                        </h2>
                        <InputComponent title="user.form.emailTitle" name='email' type="email"
                                        placeholder="user.form.emailPlaceholder"
                                        formFeedback="user.form.emailFormFeedback"
                                        value={this.state.userToManage.contact.email}
                                        handleChange={this.handleUserContactDataChange.bind(this)}
                                        maxLength={64}
                                        formText="user.form.emailFormText"
                                        required
                        />
                        <InputComponent title="customer.form.phoneTitle" name='phone' type="tel"
                                        placeholder="customer.form.phonePlaceholder"
                                        formFeedback="customer.form.phoneFormFeedback"
                                        value={this.state.userToManage.contact.phone}
                                        handleChange={this.handleUserContactDataChange.bind(this)}
                                        maxLength={64}
                                        required
                        />
                        <InputComponent title="customer.form.mobileTitle" name='mobile' type="tel"
                                        placeholder="customer.form.mobilePlaceholder"
                                        formFeedback="customer.form.mobileFormFeedback"
                                        value={this.state.userToManage.contact.mobile}
                                        handleChange={this.handleUserContactDataChange.bind(this)}
                                        maxLength={64}
                        />
                        <InputComponent title="customer.form.faxTitle" name='fax' type="tel"
                                        placeholder="customer.form.faxPlaceholder"
                                        formFeedback="customer.form.faxFormFeedback"
                                        value={this.state.userToManage.contact.fax}
                                        handleChange={this.handleUserContactDataChange.bind(this)}
                                        maxLength={64}
                        />
                        <div className="mt-3">
                            * Pflichtfeld
                        </div>
                    </Form>
                    <Form className="col-md-4">
                        <h2>
                            Zugriff Sperren
                        </h2>
                        <FormGroup>
                            <DayPicker
                                selectedDays={new Date(this.state.userToManage.expires)}
                                localeUtils={MomentLocaleUtils} locale={'de'}
                                initialMonth={this.getMinExpiresDate()}
                                onDayClick={this.handleDayClick.bind(this)}
                                showOutsideDays
                            />
                            {!ManageUserPage.isUserExpiredSet(this.state.userToManage) &&
                            <FormText>Der Nutzer kann sich bis zum Ende des gewählten Tages einloggen. Am nächsten Tag
                                ist kein Login mehr möglich.</FormText>
                            }
                            <Input type="hidden" valid/>
                            <FormFeedback valid={ManageUserPage.isUserExpiredSet(this.state.userToManage)}>
                                Der Nutzer kann sich bis
                                zum {new Date(this.state.userToManage.expires).toLocaleDateString()}&nbsp;
                                um {new Date(this.state.userToManage.expires).toLocaleTimeString()} einloggen.
                            </FormFeedback>
                        </FormGroup>
                    </Form>
                </div>

                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() => this.showModal('create')}
                    hide={() => this.hideModal('create')}
                    title="Benutzer anlegen erfolgreich"
                    color="success"
                    message={'Anlegen des Benutzers erfolgreich. Möchten Sie einen weiteren Benutzer anlegen?'}
                    onCancel={(e) => {
                        if (Auth.isAdmin())
                            this.switchToPage(e, PATH_ADMIN_USER_CUSTOMER_PAGE);
                        else
                            this.switchToPage(e, PATH_USER_MANAGE_USER_PAGE)
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    onSuccess={(e) => {
                        this.clearFieldsOnNewUser(e);
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('create')
                    }}
                    successText={'Ja'}
                    cancelText={'Nein'}
                />

                <ModalComponent
                    isOpen={this.state.modals.change}
                    toggle={() => this.showModal('change')}
                    hide={() => this.hideModal('change')}
                    color="success"
                    title="Benutzer aktualisiert"
                    message={'Benutzer wurde aktualisiert.'}
                    onSuccess={(e) => {
                        if (Auth.isAdmin())
                            this.switchToPage(e, PATH_ADMIN_USER_CUSTOMER_PAGE);
                        else
                            this.switchToPage(e, PATH_USER_MANAGE_USER_PAGE)
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />

                {this.props.currentMessage &&
                <ModalComponent
                    isOpen={this.state.modals.error}
                    toggle={() => this.showModal('error')}
                    hide={() => this.hideModal('error')}
                    color="danger"
                    title="Fehler aufgetreten"
                    message={this.props.currentMessage.msg}
                    onSuccess={() => {
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('error')
                    }}
                    successText={'Ok'}
                />
                }
            </div>
        );
    }
}

ManageUserPage.propTypes = {
    currentUser: PropTypes.shape(User),
    userToManage: PropTypes.shape(User),
    currentCustomer: PropTypes.shape(Customer),
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        userToManage: getUserToManage(state),
        currentCustomer: getCustomer(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(ManageUserPage)));
