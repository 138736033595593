import {
  LOGOUT,
  LOAD_CATEGORIES,
  LOAD_DE_CATEGORIES,
  LOAD_PL_CATEGORIES,
  LOAD_PRICE_LIST_CATEGORY
} from '../actions/types';

// Initial State
const initialState = {
  stateCategories: null,
  stateDECategories: null,
  statePLCategories:null
};

const CategoryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateCategories: null
      };
    }
    case LOAD_CATEGORIES: {
      return {
        ...state,
        stateCategories: action.category
      };
    }
    case LOAD_DE_CATEGORIES: {
      return {
        ...state,
        stateDECategories: action.category
      };
    }
    case LOAD_PL_CATEGORIES: {
      return {
        ...state,
        statePLCategories: action.category
      };
    }
    case LOAD_PRICE_LIST_CATEGORY: {
      return {
        ...state,
        statePriceListCategory: action.category
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCategories = (state) => state.category;
export const getPriceListCategory = (state) => state.category.statePriceListCategory;
export const getDECategories = (state) => state.category.stateDECategories;
export const getPLCategories = (state) => state.category.statePLCategories;
// Export Reducer
export default CategoryReducer;
