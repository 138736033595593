import React,{Component} from 'react'
import {getCustomer} from "../reducers/CustomerReducer";
import {connect} from "react-redux";
import {getCurrentUser} from "../reducers/UserReducer";
import {loadCustomer} from "../actions";
import {getAllProductPrices} from "../actions";
import UserProjectOverviewPage from "./Project/UserProjectOverviewPage";

class UserHomePage extends Component {
    componentDidMount() {
        this.props.dispatch(loadCustomer(this.props.currentUser.customerId))
        this.props.dispatch(getAllProductPrices())
    }

    render() {
        return (
            (this.props.customer &&
                <UserProjectOverviewPage user/>
            )
        );
    }
}


function mapStateToProps(state) {
    return{
        customer:getCustomer(state),
        currentUser:getCurrentUser(state)
    }
}
export default connect(mapStateToProps)(UserHomePage)
