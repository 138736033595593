
// Initial State
import {LOAD_ORDER_PRODUCTS, LOGOUT, REMOVE_CURRENT_CART, SET_CURRENT_CART} from '../actions/types';

const initialState = {
  stateCart: null
};

const CartReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateCart: null
      };
    }
    case REMOVE_CURRENT_CART: {
      return {
        ...state,
        stateCart: null
      };
    }
    case SET_CURRENT_CART: {
      return {
        ...state,
        stateCart: action.cart
      };
    }
    case LOAD_ORDER_PRODUCTS: {
      return {
        ...state,
        cartProducts: action.products
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCurrentCart = (state) => state.cart.stateCart;
export const getCartProducts = (state) => state.cart.cartProducts;

// Export Reducer
export default CartReducer;
