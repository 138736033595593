import {LOAD_CUSTOMER_CARTS, LOGOUT} from '../actions/types';

// Initial State
const initialState = {
  stateCustomerCarts: null
};

const CartsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateCustomerCarts: null
      };
    }
    case LOAD_CUSTOMER_CARTS: {
      const actionCarts = action.carts;
      if (!actionCarts.carts) {
        return {
          ...state,
          stateCustomerCarts: null
        };
      }
      // console.info('actionCarts=' + JSON.stringify(actionCarts));

      return {
        ...state,
        stateCustomerCarts: actionCarts
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCustomerCarts = (state) => state.carts.stateCustomerCarts;

// Export Reducer
export default CartsReducer;
