import {FormattedMessage} from "react-intl";
import React from "react";
import PropTypes from 'prop-types';

export function CartHeadline(props) {
    return <>
        <h1 className="h2 text-center mb-4"><FormattedMessage id={props.headline}/></h1>
            {props.offerNumber &&
                <h6 className="text-muted text-center m-3">
                  <span>
                      <strong><FormattedMessage id="project.list.offernumber.text" /> {props.offerNumber}</strong>
                      <br/>
                  </span>
                </h6>
            }
        </>
}
CartHeadline.propTypes = {
    headline: PropTypes.string.isRequired,
    offerNumber: PropTypes.string
};

