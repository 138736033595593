import moment from 'moment';

import {callSecureApi} from '../../util/apiCaller';
import {isUnauthorized} from '../../util/http-errors';

import {
    LOAD_MAX_TOURS,
    LOAD_BLOCKED_DELIVERY_DAYS,
    LOAD_HOLIDAYS,
    LOAD_BLOCKED_INDIVIDUAL_DAYS,
    LOAD_BLOCKED_WEEKDAY,
    LOAD_BLOCKED_DELIVERY_TOURS
} from '../types';
import {logout} from '../AuthentificationActions';

export function fetchOrderSettings() {
    return dispatch => {
        callSecureApi({
            endpoint: 'orderservice/v1/settings',
            method: 'GET'
        })
            .then(res => {
                // const federalState = res.federalState;
                // const federalState = {id:15};
                // dispatch(setState(federalState));
                // dispatch(fetchHolidays(federalState.id, [new Date().getFullYear(), new Date().getFullYear() + 1]));
                // const maxTourCount = res.maxTourCount;
                // dispatch(setMaxTourCount(maxTourCount));
                dispatch(setMaxTourCount(10));
            })
            .catch(err => {
                console.error('unable to get settings', err);
            });
    };
}

export function fetchHolidays(stateId, years) {
    return dispatch => {
        return new Promise((resolve, reject) => {
                callSecureApi({
                    endpoint: 'orderservice/v1/blocked/holidays',
                    method: 'get',
                    query: {stateId: stateId, years}
                })
                    .then(res => {
                        const holidayDates = res.holidayDates || [];
                        const holidays = holidayDates.map(holiday => {
                            return moment(holiday.date)
                                .toDate();
                        });
                        dispatch(setHolidays(holidays));
                    })
                    .catch(err => {
                        reject();
                        console.error('unable to fetch holidays', err);
                    });
            }
        );
    };
}

export function fetchBlockedDeliveryTours() {
    return dispatch => {
        console.info('load all blocked delivery tours');
        callSecureApi({
            endpoint: 'orderservice/v1/blocked/tours',
            method: 'get'
        })
            .then((res) => {
                const blockedDates = res.blockedDates || [];
                const convertedDates = blockedDates.map(function (curr) {
                    return {
                        date: moment(curr.date)
                            .toDate()
                    };
                });
                dispatch(setBlockedDeliveryTours(convertedDates));
            })
            .catch(err => {
                    console.error('unable to load delivery tours', err);
                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                }
            );
    };
}

export function fetchBlockedIndividualDays() {
    return dispatch => {
        callSecureApi({
            endpoint: 'orderservice/v1/blocked',
            method: 'get'
        })
            .then(res => {
                let individualDates = res.individualDates || [];
                individualDates = individualDates.map(function (curr) {
                    return {
                        date: moment(curr.date)
                            .toDate()
                    };
                });
                dispatch(setBlockedIndividualDays(individualDates));
                const weekdays = res.blockedWeekdays || [];
                dispatch(setBlockedWeekdays(weekdays));
            })
            .catch(err => {
                console.error('unable to fetch blocked dates', err);
            });
    };
}

export function fetchBlockedDeliveryDays() {
    return dispatch => {
        callSecureApi({
            endpoint: 'orderservice/v1/blocked/days',
            method: 'get'
        })
            .then(res => {
                let blockedDates = res.blockedDates || [];
                blockedDates = blockedDates.map(function (curr) {
                    return {
                        date: moment(curr.date)
                            .toDate()
                    };
                });
                dispatch(setBlockedDeliveryDays(blockedDates));
            })
            .catch(err => {
                console.error('unable to fetch blocked delivery dates', err);
            });
    };
}

export function setBlockedWeekdays(weekday) {
    return {
        type: LOAD_BLOCKED_WEEKDAY,
        weekday
    };
}
export function setBlockedDeliveryDays(dates) {
    return {
        type: LOAD_BLOCKED_DELIVERY_DAYS,
        dates
    };
}
export function setHolidays(holidays) {
    return {
        type: LOAD_HOLIDAYS,
        holidays
    };
}
export function setBlockedIndividualDays(dates) {
    return {
        type: LOAD_BLOCKED_INDIVIDUAL_DAYS,
        dates
    };
}
export function setMaxTourCount(maxTourCount) {
    return {
        type: LOAD_MAX_TOURS,
        maxTourCount
    };
}
export function setBlockedDeliveryTours(blockedDeliveryTours) {
    return {
        type: LOAD_BLOCKED_DELIVERY_TOURS,
        blockedDeliveryTours
    };
}
