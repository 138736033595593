import {SET_COUNTRIES} from '../actions/types';

const initialState = {
    countries: [],
    selectedCountry: 'DE'
};

const I18NReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_COUNTRIES: {
            return {
                ...state,
                countries: action.countries
            };
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getCountries = (state) => state.i18n.countries
export const getSelectedCountry = (state) => state.i18n.selectedCountry;

// Export Reducer
export default I18NReducer;
