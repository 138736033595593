import {LOGOUT, REMOVE_MESSAGE, SET_MESSAGE} from '../actions/types';

// Initial State
const initialState = {
  stateMessage: null
};

const MessageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateMessage: null
      };
    }
    case REMOVE_MESSAGE: {
      return {
        ...state,
        stateMessage: null
      };
    }
    case SET_MESSAGE: {
      return {
        ...state,
        stateMessage: action.message
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCurrentMessage = (state) => state.message.stateMessage;

// Export Reducer
export default MessageReducer;
