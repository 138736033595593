import React, {Component} from 'react';
import Button from "reactstrap/lib/Button";
import _ from "lodash";
import {
    PATH_ADMIN_ORGANIZATION_CREATE_PAGE,
    PATH_ADMIN_ORGANIZATION_EDIT_PAGE,
} from "../../routePaths";
import Row from "reactstrap/lib/Row";
import Table from "reactstrap/es/Table";
import classNames from "classnames";
import {deleteOrganization, getAllOrganizations, removeCurrentMessage, setOrganization} from "../../actions";
import {getOrganizations} from "../../reducers/OrganizationsReducer";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import ModalComponent from "../../components/Modals/ModalComponent";

class OrganizationOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: undefined,
            modals: {
                delete: false,
                error: false
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(getAllOrganizations())
    }

    handleSearchInput(event) {
        let search = this.state.search;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            targetSearchTerm: search
        });
        this.filterList(search, this.props.target);
    }

    filterList(searchTerm, sources) {
        let localSrc = sources;
        if (searchTerm) {
            localSrc = _.filter(sources, (src) => {
                return src.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || src.number.indexOf(searchTerm) !== -1;
            });
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filterList = localSrc;
            return nextState;
        });
    }

    highlightRow(index) {
        this.setState({highlightedRow: index})
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    goToPage(path, organization) {
        if (organization) {
            this.props.dispatch(setOrganization(organization))
        }
        this.props.history.push(path);
    }

    render() {
        const organizations = (this.props.organizations && this.props.organizations.organizations) ? this.props.organizations.organizations : []
        return (
            <div className="page-content">
                <div className="container">
                    <h1 className="headline">
                        VKOrg Übersicht
                    </h1>
                    <div className="mb-4">
                        <Button onClick={() => this.goToPage(PATH_ADMIN_ORGANIZATION_CREATE_PAGE)} size="sm">
                            Neue Organisation anlegen
                        </Button>
                    </div>
                    <Row>
                        <Table>
                            <thead>
                            <tr>
                                <th> #</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {organizations.map((organization, index) => {
                                return (
                                    <tr style={{height: 67}}
                                        key={index}
                                        className={classNames({highlight: (this.state.highlightedRow === index)})}
                                        onMouseEnter={() => this.highlightRow(index)}>
                                        <th scope="row">{organization.number}</th>
                                        <td>{organization.name}</td>
                                        <td>{(this.state.highlightedRow === index) &&

                                        <div className="float-right">
                                            <span>
                                                <Button onClick={() => {
                                                    this.goToPage(PATH_ADMIN_ORGANIZATION_EDIT_PAGE, organization);
                                                }}>
                                                    <span className="oi oi-pencil"/> bearbeiten
                                                </Button>
                                            </span>
                                            <span className="ml-2">
                                                <Button  onClick={() => {
                                                    this.showModal('delete')
                                                    this.setState({'selectedOrganization': organization.number})
                                                }}><span className={"oi oi-trash"}/> löschen</Button>
                                            </span>
                                        </div>
                                        }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Row>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.delete}
                    toggle={() =>
                        this.showModal('delete')
                    }
                    hide={() =>
                        this.hideModal('delete')
                    }
                    color="danger"
                    title="VKOrg löschen"
                    message={'Wollen Sie diese VKOrg wirklich löschen?'}
                    onSuccess={(e) => {
                        this.props.dispatch(deleteOrganization(this.state.selectedOrganization))
                        this.props.dispatch(removeCurrentMessage())
                        this.hideModal('delete')
                        e.preventDefault();
                    }
                    }
                    successText={'VKOrg löschen'}
                    cancelText={'Abbrechen'}
                    onCancel={() => this.hideModal('delete')}
                />
            </div>
        );
    }
}

OrganizationOverview.propTypes = {};

function mapStateToProps(state) {
    return {
        organizations: getOrganizations(state),
    };
}

export default withRouter(connect(mapStateToProps)(OrganizationOverview));
