import React, {Component} from 'react';
import {getCustomer} from "../../reducers/CustomerReducer";
import {getWorker} from "../../reducers/WorkerReducer";
import {connect} from "react-redux";
import BackButton from "../../components/Buttons/BackButton";
import WorkerForm from "../../components/Form/WorkerForm";

class AdminWorkerCreatePage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    {/*<h4 className="text-muted text-center">{this.props.customer.name}</h4>*/}
                    <h1 className="headline">
                        Handwerker Erstellen
                    </h1>
                    <WorkerForm create/>
                </div>
            </div>
        );
    }
}

AdminWorkerCreatePage.propTypes = {};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        worker: getWorker(state)
    }
}

export default connect(mapStateToProps)(AdminWorkerCreatePage);
