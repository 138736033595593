import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CustomerForm from "../../components/Form/CustomerForm";
import {Customer} from "../../proptypes";
import {withRouter} from "react-router";
import {getCustomer} from "../../reducers/CustomerReducer";
import {connect} from "react-redux";
import BackButton from "../../components/Buttons/BackButton";

class EditCustomerPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h4 className="text-muted text-center">{this.props.customer.name}</h4>
                    <h1 className="h2 text-center mb-4">
                        Händler bearbeiten
                    </h1>
                    <CustomerForm edit/>
                </div>
            </div>
        );
    }
}

EditCustomerPage.propTypes = {
    customer: PropTypes.shape(Customer).isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
function mapStateToProps(state) {
    return {
        customer: getCustomer(state)
    }
}

export default withRouter(connect(mapStateToProps)(EditCustomerPage));
