
// Initial State
import {
  LOAD_BLOCKED_DELIVERY_DAYS,
  LOAD_BLOCKED_DELIVERY_TOURS,
  LOAD_BLOCKED_INDIVIDUAL_DAYS,
  LOAD_BLOCKED_WEEKDAY,
  LOAD_FEDERAL_STATES,
  LOAD_HOLIDAYS,
  LOAD_MAX_TOURS,
  LOAD_STATE,
  LOGOUT,
  REMOVE_CURRENT_ORDER,
  SET_CURRENT_ORDER,
  SET_FACILITIES
} from '../actions/types';

const initialState = {
  stateOrder: null
};

const OrderReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateOrder: null
      };
    }
    case REMOVE_CURRENT_ORDER: {
      return {
        ...state,
        stateOrder: null
      };
    }
    case SET_CURRENT_ORDER: {
      return {
        ...state,
        stateOrder: action.order
      };
    }
    case LOAD_BLOCKED_INDIVIDUAL_DAYS: {
      return {
        ...state,
        blockedIndividualDays: action.dates
      };
    }
    case LOAD_BLOCKED_WEEKDAY: {
      return {
        ...state,
        blockedWeekdays: action.weekday
      };
    }
    case LOAD_FEDERAL_STATES: {
      return {
        ...state,
        federalState: action.states
      };
    }
    case LOAD_HOLIDAYS: {
      return {
        ...state,
        holidays: action.holidays
      };
    }
    case LOAD_STATE: {
      return {
        ...state,
        selectedState: action.state
      };
    }
    case LOAD_MAX_TOURS: {
      return {
        ...state,
        maxTours: action.maxTourCount
      };
    }
    case LOAD_BLOCKED_DELIVERY_TOURS: {
      return {
        ...state,
        blockedDeliveryTours: action.blockedDeliveryTours
      };
    }
    case LOAD_BLOCKED_DELIVERY_DAYS: {
      return {
        ...state,
        blockedDeliveryDays: action.dates
      };
    }
    case SET_FACILITIES:{
      return {
        ...state,
        facilities: action.facilities
      }
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCurrentOrder = (state) => state.order.stateOrder;
export const loadBlockedIndividualDays = (state) => state.order.blockedIndividualDays;
export const loadBlockedWeekdays = (state) => state.order.blockedWeekdays;
export const loadFederalState = (state) => state.order.federalState;
export const loadHolidays = (state) => state.order.holidays;
export const loadState = (state) => state.order.selectedState;
export const loadMaxTours = (state) => state.order.maxTours;
export const loadBlockedDeliveryTours = (state) => state.order.blockedDeliveryTours;
export const loadBlockedDeliveryDays = (state) => state.order.blockedDeliveryDays;
export const loadFacilities = (state) => state.order.facilities;

// Export Reducer
export default OrderReducer;
