import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {getCartProducts, getCurrentCart} from '../../reducers/CartReducer';
import {getCurrentMessage} from '../../reducers/MessageReducer';

import {getCustomer} from '../../reducers/CustomerReducer';
import {Cart, Customer, Message, Product} from "../../proptypes";
import {
    addToCart,
    getProductImageUrl,
    removeCurrentMessage,
    removeFromCart,
    setMaxOutLoading,
    unsetMaxOutLoading
} from "../../actions";
import {PACKAGINGTYPES} from "../../enums/PackagingTypes";
import {injectIntl} from "react-intl";
import {germanCurrency} from "../../util/priceHelper";
import ProductUnits from "../Product/ProductUnitsComponent";
import ProductList from "../Product/ProductList";

export const MAX_AMOUNT_OF_UNITS_ADD_TO_CART = 4000;

class CartItemsList extends Component {

  static isPaletteProduct(product) {
    if (product) {
      const data = product.data;
      return data.leastUnitsPackage >= 1 && data.leastUnitsPackage === data.unitsPerPackage;
    }
    return false;
  }

  static isSingleProduct(product) {
    if (product) {
      const data = product.data;
      return data.leastUnitsPackage === 1 && data.leastUnitsPackage < data.unitsPerPackage;
    }
    return false;
  }

  getQuantityModalHeader(product) {
    if (product) {
      return this.getPluralPackagingTypeLabel(product, 'Anzahl der ');
    }
    return '';
  }

  getQuantityModalLabel(product) {
    if (product) {
      return this.getPluralPackagingTypeLabel(product, '');
    }
    return '';
  }

  static calculateUsageLFM(orderItem, length) {
    if (orderItem) {
      const data = orderItem.data;
      if (data.leastUnitsPackage === data.unitsPerPackage) {
        const usagePalette = data.usagePerPalette;
        return Math.ceil(length / usagePalette);
      }

      const usageLFM = data.materialUsageLFM;
      return Math.ceil(length / (1 / usageLFM));
    }
    return null;
  }


  static optionOrderItemList(bundledOrderItem) {
    if (bundledOrderItem) {
      const bundles = bundledOrderItem.bundles;
      if (bundles) {
        const options = [];
        _.each(bundles, (bundle) => {
          if (bundle.option) {
            options.push(bundle.option.orderItem);
          }
        });
        return options;
      }
    }
    return [];
  }

  static hasOptionOrderItems(bundledOrderItem) {
    return CartItemsList.optionOrderItemList(bundledOrderItem).length > 0;
  }

  static orderItemList(cart) {
    if (cart) {
      const orderItems = cart.orderItems;
      if (orderItems) {
        const orderItemList = _.map(orderItems, orderItem => {
          return orderItem;
        });

        return _.filter(orderItemList, (oi) => {
          return oi.quantity >= 0;
        });
      }
    }
    return [];
  }

  static bundledOrderItemList(orderItem) {
    const forSale = orderItem.data.forSale;
    if (forSale) {
      const bundles = orderItem.bundles;
      if (bundles) {
        return _.map(bundles, bundle => {
          if (bundle.orderItem) {
            return bundle.orderItem;
          }
          return null;
        });
      }
    }
    return [];
  }

  static quantityChoice() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
  }

  static orderItemName(orderItem) {
    return orderItem ? orderItem.name : '';
  }

  static orderItemNameFormat(orderItem) {
    if (orderItem) {
      let itemName = orderItem.data.formatDefinition;
      if (!itemName) {
        itemName = orderItem.data.specifiedSize;
      }
      return !itemName ? '' : ` - ${itemName}`;
    }
    return '';
  }

  constructor(props) {
    super(props);
    this.getProductQuantity = this.getProductQuantity.bind(this);
    this.getFullPalettesQuantity = this.getFullPalettesQuantity.bind(this);
    this.toggleQuantityModal = this.toggleQuantityModal.bind(this);
    this.submitModalFormQuantity = this.submitModalFormQuantity.bind(this);
    this.submitModalButtonQuantity = this.submitModalButtonQuantity.bind(this);
    this.setOrderItemQuantities = this.setOrderItemQuantities.bind(this);
    this.addToCart = this.addToCart.bind(this);
    // Invoke `remove from cart` when the click event is fired, but not more than once every 500 ms.
    this.removeFromCart = _.throttle(this.removeFromCart.bind(this), 500, {trailing: false});
    this.setMaxOutLoading = this.setMaxOutLoading.bind(this);
    this.hasMaxedOutCapacity = this.hasMaxedOutCapacity.bind(this);
    this.showAddToCartModal = this.showAddToCartModal.bind(this);
    this.hideAddToCartModal = this.hideAddToCartModal.bind(this);
    this.setModalQuantity = this.setModalQuantity.bind(this);
    this.updateModalQuantity = this.updateModalQuantity.bind(this);
    this.setModalQuantityLength = this.setModalQuantityLength.bind(this);
    this.setModalQuantitySquare = this.setModalQuantitySquare.bind(this);

    this.state = {
      quantityModal: false,
      addToCartModal: false,
      productQuantity: {},
      modalOrderItem: null,
      products: []
    };
  }

  componentDidMount() {
    const cart = this.props.currentCart;
    this.setOrderItemQuantities(cart);
  }

  componentWillReceiveProps(nextProps) {
    const nextCart = nextProps.currentCart;
    if (nextCart) {
      this.setOrderItemQuantities(nextCart);
    }

    const nextMessage = nextProps.currentMessage;
    if (nextMessage && (nextMessage.source === 'ADD_CART_ITEM' || nextMessage.source === 'REMOVE_CART_ITEM' || nextMessage.source === 'MAX_LOAD_FAILED')) {
      this.showAddToCartModal();

      if (nextMessage.type === 'INFO') {
        // auto close message on info message
        window.setTimeout(() => {
          this.hideAddToCartModal();
        }, 1500);
      }
    }
  }

  setOrderItemQuantities(cart) {
    if (cart) {
      CartItemsList.orderItemList(cart)
        .map((orderItem) => {
          return this.setOrderItemQuantity(orderItem);
        });
    }
  }

  setOrderItemQuantity(orderItem) {
    const productNumber = orderItem.productNumber;
    let quantity = orderItem.quantityApproved;
    if (quantity === undefined) {
      quantity = orderItem.maxedOutQuantity === undefined ? orderItem.quantity : orderItem.maxedOutQuantity;
    }

    this.setState(currentState => {
      // deep copy current state, in order to not modify the current state object
      const nextState = _.cloneDeep(currentState);

      nextState.productQuantity[productNumber] = quantity;

      return nextState;
    });
  }

  setModalQuantity(event) {
    const quantity = event.target.value;
    this.updateModalQuantity(quantity);
  }

  setModalQuantityLength(event) {
    const length = event.target.value;
    if (length > 0) {
      const orderItem = this.state.modalOrderItem;
      if (orderItem) {
        const quantity = CartItemsList.calculateUsageLFM(orderItem, length);
        if (quantity) {
          this.updateModalQuantity(quantity);
        }
      }
    }
  }

  setModalQuantitySquare(event) {
    const square = event.target.value;
    if (square > 0) {
      const orderItem = this.state.modalOrderItem;
      if (orderItem) {
        const quantity = ProductList.calculateUsageM2(orderItem, square);
        if (quantity) {
          this.updateModalQuantity(quantity);
        }
      }
    }
  }

  setModalQuantityPalettes(event) {
    const palettes = event.target.value;
    if (palettes > 0) {
      const product = this.state.modalOrderItem;
      if (product) {
        const quantity = this.getFullPalettesQuantity(product, palettes);
        if (quantity) {
          this.updateModalQuantity(quantity);
        }
      }
    }
  }

  setMaxOutLoading(orderItem) {
    const cart = this.props.currentCart;
    if (cart) {
      if (orderItem.maxedOutQuantity && orderItem.maxedOutQuantity > 0) {
        this.props.dispatch(unsetMaxOutLoading(cart, orderItem.productNumber));
      } else {
        this.props.dispatch(setMaxOutLoading(cart, orderItem));
      }
    }
  }

  getProductQuantity(orderItem) {
    let quantity = undefined;
    if (orderItem) {
      quantity = this.state.productQuantity[orderItem.productNumber];
    }
    if (quantity === undefined) {
      // a controlled input must not be initialized with undefined or null
      quantity = '';
    }
    return quantity;
  }

  getFullPalettesQuantity(orderItem, quantity) {
    if (orderItem && quantity) {
      let unitsPalettesQuantity;
      if (CartItemsList.isPaletteProduct(orderItem)) {
        unitsPalettesQuantity = quantity;
      } else {
        unitsPalettesQuantity = quantity * orderItem.data.unitsPerPackage
      }
      return unitsPalettesQuantity;
    }
    return null;
  }


  updateModalQuantity(quantity) {
    if (quantity) {
      this.setState(currentState => {
        // deep copy current state, in order to not modify the current state object
        const nextState = _.cloneDeep(currentState);

        nextState.modalQuantity = quantity;

        return nextState;
      });
    }
  }

  hasMaxedOutCapacity(orderItem) {
    const cart = this.props.currentCart;
    if (cart) {
      const cartOrderItems = _.filter(cart.orderItems, (coi) => {
        return coi.productNumber === orderItem.productNumber;
      });

      if (cartOrderItems && cartOrderItems.length > 0) {
        const cartOrderItem = cartOrderItems[0];
        if (cartOrderItem.maxedOutQuantity) {
          return Boolean(cartOrderItem.maxedOutQuantity > 0);
        }
      }
    }
    return false;
  }

  addToCart(e, orderItem, quantity) {
    const product = orderItem || {productNumber:this.props.currentMessage.productNumber};

    if (quantity) {
      const cart = this.props.currentCart;
      if (cart) {
        const productNumber = product.productNumber;
        this.props.dispatch(addToCart(cart, productNumber, quantity));
      }
    }
    e.preventDefault();
    this.props.onModifyCartItems();
    return false;
  }

  removeFromCart(e, productNumber) {
    const cart = this.props.currentCart;
    if (cart) {
      this.props.dispatch(removeFromCart(cart, productNumber));
    }
    e.preventDefault();
    return false;
  }

  submitModalFormQuantity(event) {
    event.preventDefault();
    let quantity = this.state.modalQuantity;
    if (quantity > MAX_AMOUNT_OF_UNITS_ADD_TO_CART) {
      quantity = MAX_AMOUNT_OF_UNITS_ADD_TO_CART;
    }

    if (quantity > 0) {
      const orderItem = this.state.modalOrderItem;
      this.addToCart(event, orderItem, quantity);

      this.toggleQuantityModal();
    }

    this.setState({
      modalQuantity: 0
    });
  }

  submitModalButtonQuantity(event, quantity) {
    if (event) {
      const orderItem = this.state.modalOrderItem;
      this.addToCart(event, orderItem, quantity);

      this.toggleQuantityModal();
    }
  }

  toggleQuantityModal(orderItem) {
    this.setState(currentState => {
      // deep copy current state, in order to not modify the current state object
      const nextState = _.cloneDeep(currentState);

      nextState.quantityModal = !nextState.quantityModal;

      if (orderItem) {
        nextState.modalOrderItem = orderItem;
      }

      return nextState;
    });
  }

  showAddToCartModal() {
    this.setState({
      addToCartModal: true
    });
  }

  hideAddToCartModal() {
    this.setState({
      addToCartModal: false
    });

    this.props.dispatch(removeCurrentMessage());
  }

  isProductInStock(orderItem) {
    const cartProducts = this.props.cartProducts;
    if (cartProducts) {
      const product = _.find(cartProducts, (o) => {
        return o.productNumber === orderItem.productNumber;
      });

      if (product) {
        return product.availability !== 'OUT_OF_STOCK';
      }
    }
    return true;
  }

  getPluralPackagingTypeLabel(product, prefix) {
    if (product.packaging && product.packaging.type) {
      const pluralLabel = this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label + '.plural'});
      if (!prefix)
        return pluralLabel
      else
        return prefix + pluralLabel
    }
    if (!prefix)
      return 'Verpackungseinheiten'
    else
      return prefix + 'Verpackungseinheiten'
  }

  getSingularPackagingTypeLabel(product) {
    if (product.packaging && product.packaging.type) {
      return this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label})
    }
    return 'Verpackungseinheiten'
  }

  render() {
    if (!this.props.currentCart.orderItems) {
      return (
        <div className="pt-4 pb-4 text-center">
          <span className="text-center">
            <strong>keine Produkte im Warenkorb</strong>
          </span>
        </div>
      );
    }

    return (
      <div>
        {/* loop over all order items */
          CartItemsList.orderItemList(this.props.currentCart)
            .map((orderItem, i) => {
              return (
                <div className="row mb-4 mt-4" key={i}>
                  <div style={{height: '100px'}}  className="text-center col-xl-2 order-xl-1 col-md-5 order-md-1 col-4 order-1 mb-2 overflow-hidden">
                    <img
                      alt={CartItemsList.orderItemName(orderItem)} className="mh-100 mw-100"
                      src={getProductImageUrl(orderItem.productNumber)}
                    />
                  </div>

                  <div className="col-xl-3 order-xl-2 col-md-7 col-sm-6 order-md-2 col-12 order-2 mb-2">
                    <strong>{CartItemsList.orderItemName(orderItem)}</strong>
                    <br />
                    {orderItem.data && orderItem.data.load &&
                    <span>Variante: {orderItem.data.load}</span>
                    }
                    <br />
                    Art-Nr. {orderItem.productNumber} <small>{CartItemsList.orderItemNameFormat(orderItem)}</small>
                    {(orderItem) &&
                    CartItemsList.bundledOrderItemList(orderItem)
                      .map((bundledOrderItem, j) => {
                        return (
                          <span key={j}>
                            <br />
                            {bundledOrderItem.quantity > 0 &&
                              <small>
                                {bundledOrderItem.quantity} x {CartItemsList.orderItemName(bundledOrderItem)} - Art-Nr. {bundledOrderItem.productNumber} enthalten
                              </small>
                            }
                            {bundledOrderItem.quantity === undefined &&
                              <small>
                                wird nicht geliefert: {CartItemsList.orderItemName(bundledOrderItem)} - Art-Nr. {bundledOrderItem.productNumber}
                              </small>
                            }
                            {bundledOrderItem.quantity === 0 &&
                              <span>
                                {/* loop over all options of a bundled order item */
                                  CartItemsList.optionOrderItemList(orderItem)
                                    .map((optionOrderItem, o) => {
                                      return (
                                        <small key={o}>
                                          {CartItemsList.orderItemName(bundledOrderItem)} - Art-Nr. {bundledOrderItem.productNumber} ersetzt durch
                                          <br />
                                          {CartItemsList.orderItemName(optionOrderItem)} - Art-Nr. {optionOrderItem.productNumber}
                                        </small>
                                      );
                                    })
                                }
                              </span>
                            }
                          </span>
                        );
                      })}
                  </div>

                  {this.isProductInStock(orderItem) &&
                    <div className="col-xl-2 order-xl-4 col-md-5 order-md-3 col-9 order-4">
                      <Form className="form-inline mt-1">
                        <Label for={orderItem.productNumber}>Menge:</Label>
                        <Input
                          type="tel" id={orderItem.productNumber} name="quantity" className="form-control ml-2 mr-2" style={{width: '75px'}}
                          value={this.getProductQuantity(orderItem)}
                          onClick={() => this.toggleQuantityModal(orderItem)}
                          onChange={() => this.setOrderItemQuantities(this.props.currentCart)}
                          disabled={!orderItem.data.forSale}
                        />
                      </Form>

                      <div className="mt-2">
                        <small>
                          {(this.getProductQuantity(orderItem) > 0 && (orderItem.data.materialUsageM2 || orderItem.data.materialUsageLFM)) &&
                            <span>
                              {ProductList.getFormattedUnits(orderItem,this.getProductQuantity(orderItem))}&nbsp;
                              {orderItem.quantity > 1 && this.getPluralPackagingTypeLabel(orderItem)}
                              {orderItem.quantity === 1 && this.getSingularPackagingTypeLabel(orderItem)}
                              &nbsp;({ProductList.getFormattedUsage(orderItem, this.getProductQuantity(orderItem))}
                              {orderItem.data.materialUsageM2 && <span> m<sup>2</sup>)</span>}
                              {orderItem.data.materialUsageLFM && <span> lfm)</span>}
                            </span>
                          }
                          {(this.getProductQuantity(orderItem) > 0 && !(orderItem.data.materialUsageM2 || orderItem.data.materialUsageLFM)) &&
                          <span>
                            <ProductUnits order
                                formattedUnits={ProductList.getFormattedUnits(orderItem,this.getProductQuantity(orderItem))}
                                productQuantity={orderItem.quantity}
                                pluralPackagingTypeLabel={this.getPluralPackagingTypeLabel(orderItem)}
                                singularPackagingTypeLabel={this.getSingularPackagingTypeLabel(orderItem)}
                                leastUnitsPackage={orderItem.data.leastUnitsPackage}
                            />
                            </span>
                          }
                        </small>
                      </div>
                    </div>
                  }
                  {!this.isProductInStock(orderItem) &&
                    <div className="col-xl-3 order-xl-3 col-md-6">
                      <span className="align-middle text-center text-danger">Derzeit nicht verfügbar</span>
                    </div>
                  }
                  <div className="col-xl-2 order-3 small font-weight-bold">
                    {this.getProductQuantity(orderItem) > 0 && orderItem.data.price > 0  &&
                      <span>
                        Warenpreis: {germanCurrency(orderItem.data.price)}*<br />
                      </span>
                    }
                  </div>
                  {orderItem.quantity > 0 &&
                    <div className="col-xl-1 order-xl-5 col-md-1 order-md-5 col-3 order-5 text-right">
                      <Button href="" color="secondary" onClick={(e) => this.removeFromCart(e, orderItem.productNumber)}>
                        <span className="oi oi-trash" />
                      </Button>
                    </div>
                  }
                </div>
              );
            })}

        <Modal isOpen={this.state.quantityModal} fade={false} toggle={() => this.toggleQuantityModal()}>
          <ModalHeader tag="h5" toggle={() => this.toggleQuantityModal()}>
            Bitte wählen Sie die {this.getQuantityModalHeader(this.state.modalOrderItem)} <br />
            <span className="h6 text-muted text-right">
              {CartItemsList.orderItemName(this.state.modalOrderItem)} {CartItemsList.orderItemNameFormat(this.state.modalOrderItem)}
            </span>
          </ModalHeader>
          <ModalBody className="text-center">
            <div className="row mb-3">
              {/* loop over all quantity choices */
                CartItemsList.quantityChoice()
                  .map((quantity, k) => {
                    return (
                      <div key={k} className="col-4 col-md-2 mb-2">
                        <Button key={k} className="btn-outline-secondary btn-block" onClick={(event) => this.submitModalButtonQuantity(event, quantity)}>
                          {quantity}
                        </Button>
                      </div>
                    );
                  })}
            </div>
            <Form className="form-inline" onSubmit={(event) => this.submitModalFormQuantity(event)}>
              <Label className="mt-2 mr-2" for="modal-quantity">{this.getQuantityModalLabel(this.state.modalOrderItem)}</Label>
              <Input
                className="ml-xs-2 mt-2" type="number" min="0" name="quantity" id="modal-quantity"
                onChange={this.setModalQuantity.bind(this)} defaultValue={this.getProductQuantity(this.state.modalOrderItem)}
              />
              <div className="col pr-0 text-right">
                <Button color="primary" className="btn-primary mt-2" type="submit">Übernehmen</Button>
              </div>
            </Form>

            {(this.state.modalOrderItem && (this.state.modalOrderItem.data.materialUsageLFM || this.state.modalOrderItem.data.materialUsageM2)) &&
              <hr />
            }

            {(this.state.modalOrderItem && this.state.modalOrderItem.data.materialUsageLFM) &&
              <Form className="form-inline text-left" onSubmit={(event) => this.submitModalFormQuantity(event)}>
                <Label className="mt-2 mr-2" for="modal-length">Länge:</Label>
                <Input
                  className="ml-xs-2 mt-2" type="number" min="1" name="length" id="modal-length"
                  onChange={this.setModalQuantityLength.bind(this)}
                />
                <div className="col pr-0 text-right">
                  <Button color="primary" className="btn-primary mt-2" type="submit"><span className="pl-1">lfm</span> übernehmen</Button>
                </div>
              </Form>
            }
            {(this.state.modalOrderItem && this.state.modalOrderItem.data.materialUsageM2) &&
              <Form className="form-inline text-left" onSubmit={(event) => this.submitModalFormQuantity(event)}>
                <Label className="mt-2 mr-2" for="modal-square">Fläche:</Label>
                <Input
                  className="ml-xs-2 mt-2" type="number" min="1" name="square" id="modal-square"
                  onChange={this.setModalQuantitySquare.bind(this)}
                />
                <div className="col pr-0 text-right">
                  <Button color="primary" className="btn-primary mt-2" type="submit"><span>m<sup>2</sup></span> übernehmen</Button>
                </div>
              </Form>
            }
            {(this.state.modalOrderItem && CartItemsList.isSingleProduct(this.state.modalOrderItem)) &&
            <Form className="form-inline text-left" onSubmit={(event) => this.submitModalFormQuantity(event)}>
              <Label className="mt-2 mr-2" for="modal-palettes">Paletten</Label>
              <Input
                  className="ml-xs-2 mt-2" type="number" min="0" step="1" name="palettes" id="modal-palettes"
                  onChange={this.setModalQuantityPalettes.bind(this)}
              />
              <div className="col pr-0 text-right">
                <Button color="primary" className="btn-primary mt-2"
                        type="submit"><span>Paletten</span> übernehmen</Button>
              </div>
            </Form>
            }
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.addToCartModal} fade={false} toggle={() => this.showAddToCartModal()}>
          <ModalHeader toggle={() => this.hideAddToCartModal()} />
          <ModalBody className="text-center">
            {(this.props.currentMessage && this.props.currentMessage.type === 'INFO') &&
              <div className="alert alert-success">
                {this.props.currentMessage.msg}
              </div>
            }
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR'
              && (this.props.currentMessage.source === 'REMOVE_CART_ITEM' || this.props.currentMessage.source === 'MAX_LOAD_FAILED')) &&
              <div>
                <div className="alert alert-danger">
                  {this.props.currentMessage.msg}
                </div>
              </div>
            }
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'ADD_CART_ITEM') &&
              <div>
                <div className="alert alert-danger">
                  {this.props.currentMessage.msg}
                </div>
                {(this.props.currentMessage.productQuantity > 0) &&
                  <div className="alert">
                    Wollen Sie das Produkt mit der maximalen Menge {this.props.currentMessage.productQuantity} in den Warenkorb legen?
                  </div>
                }
                {(this.props.currentMessage.productQuantity <= 0) &&
                  <div className="alert">
                    Der Warenkob ist voll. Reduzieren Sie die Mengen im Warenkorb, wenn sie dieses Produkt in den Warenkorb legen möchten.
                  </div>
                }
              </div>
            }
          </ModalBody>
          <ModalFooter>
            {(this.props.currentMessage && this.props.currentMessage.type === 'INFO') &&
              <Button color="secondary" onClick={() => this.hideAddToCartModal()}>Schließen</Button>
            }
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR'
              && (this.props.currentMessage.source === 'REMOVE_CART_ITEM' || this.props.currentMessage.source === 'MAX_LOAD_FAILED')) &&
              <Button color="secondary" onClick={() => this.hideAddToCartModal()}>Schließen</Button>
            }
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR'
              && this.props.currentMessage.source === 'ADD_CART_ITEM' && this.props.currentMessage.productQuantity > 0) &&
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 mt-2 pl-0 text-left">
                    <Button color="primary" onClick={(e) => this.addToCart(e, this.state.modalOrderItem, this.props.currentMessage.productQuantity)}>
                      <span className="oi oi-cart mr-2" />in den Warenkorb
                    </Button>
                  </div>
                  <div className="col-6 mt-2 pr-0 text-right">
                    <Button color="secondary" onClick={(e) => this.hideAddToCartModal(e)}>Nein</Button>
                  </div>
                </div>
              </div>
            }
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR'
              && this.props.currentMessage.source === 'ADD_CART_ITEM' && this.props.currentMessage.productQuantity <= 0) &&
              <Button color="secondary" onClick={() => this.hideAddToCartModal()}>Schließen</Button>
            }
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CartItemsList.propTypes = {
  currentCart: PropTypes.shape(Cart),
  currentMessage: PropTypes.shape(Message),
  dispatch: PropTypes.func.isRequired,
  cartProducts: PropTypes.arrayOf(PropTypes.shape(Product)),
  onModifyCartItems: PropTypes.func,
  customer: PropTypes.shape(Customer)
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    currentCart: getCurrentCart(state),
    currentMessage: getCurrentMessage(state),
    cartProducts: getCartProducts(state),
    customer: getCustomer(state)
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(CartItemsList)));
