import React, {Component, Fragment} from 'react';

class ScrollToTopComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        };
    }

    componentDidMount() {
        document.querySelector('#root').addEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        const element = document.querySelector('.productList')
        if (element) {
            const top = element.getBoundingClientRect().top;
            if (top < -500) {
                this.setState({
                    isVisible: true
                });
            } else {
                this.setState({
                    isVisible: false
                });
            }
        }
    }

    scrollToTop() {
        document.querySelector('#root').scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const visible = this.state.isVisible;
        return (
            <Fragment>
                {visible &&
                <div className="scroll-to-top" onClick={() => this.scrollToTop()}>
                    <span className="strong oi oi-arrow-thick-top"/>
                </div>
                }
            </Fragment>
        );
    }
}

ScrollToTopComponent.propTypes = {};

export default ScrollToTopComponent;
