import React, {Component} from 'react';
import {Input, Label} from "reactstrap";
import PropTypes from "prop-types";

class CheckBoxComponent extends Component {
    render() {
        const name = this.props.name;
        const checked = this.props.checked;
        const disabled = this.props.disabled;
        const label = this.props.label;
        return (
            <Label check>
                <Input type="checkbox" name={name} onChange={this.props.onChange} checked={checked} disabled={disabled} /> {label}
            </Label>
        );
    }
}

CheckBoxComponent.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func
};

export default CheckBoxComponent;
