import React, {Component, Fragment} from 'react';
import {Button, Input} from "reactstrap";
import {getQuantityPrice} from "../../util/priceHelper";
import {getSelectedCountry} from "../../reducers/I18NReducer";
import {connect} from "react-redux";
import ProductUnits from "./ProductUnitsComponent";
import ProductList from "./ProductList";



class ProductLoadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: undefined,
            load: undefined,
            quantity: undefined
        }
    }

    componentDidMount() {
        const product = this.props.product;
        if (product) {
            this.setProduct(product)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.product !== this.props.product) {
            this.setProduct(this.props.product);
        }
    }

    setProduct(product) {
        this.setState({product: product})
        if (product.data && product.data.load && product.data.load !== true) {
            this.setState({load: product.data.load});
        }
    }

    clear() {
        this.setState({load: "", quantity: ""})
    }

    addProductToCart(e, product, quantity, load) {
        const newProduct = Object.assign({}, product)
        if (!this.props.set){
            newProduct.productNumber = product.productNumber + '_' + this.props.count(product);
            this.props.setQuantity(product.productNumber, null)
            this.clear()
        }

        if (newProduct && load && quantity) {
            this.props.addProductToCart(e, newProduct, quantity, load)
        }
        const single = this.props.single;
        if (single && product && quantity){
            this.props.addProductToCart(e, product, quantity)
        }
    }

    getProductQuantity(product) {
        if (product) {
            return this.props.getProductQuantity(product)
        }
    }

    isLoadProduct(product) {
        return product && product.data && product.data.load;
    }

    handleData(event) {
        const value = event.target.value;
        this.setState({[event.target.name]: value})
    }
    getProductPackagingQuantity(product){
        if(product && ProductList.isPackagingProduct(product)){
            return this.props.getProductQuantity(product) * product.data.leastUnitsPackage;
        }
        if (product) {
            return this.props.getProductQuantity(product)
        }
        return null
    }
    render() {
        const product = this.state.product;
        let quantityValue = this.getProductQuantity(product);
        let loadValue = this.state.load;
        let stock = this.props.stock;
        const OUT_OF_STOCK = 'OUT_OF_STOCK';
        const IN_STOCK = 'IN_STOCK';
        const productQuantity = this.getProductQuantity(product);
        return (
            <Fragment>
                {this.state.product && stock !== OUT_OF_STOCK &&
                <div className="d-flex flex-column">
                    <div className="d-flex flex-md-row flex-column order-1 order-md-0">
                        <div className="col-md-2 col-12 text-center text-md-right">
                            Menge:
                        </div>
                        <div className="col-md-2 col-12">
                            <Input
                                type="text"
                                name="quantity"
                                className="form-control ml-md-1"
                                value={quantityValue}
                                onChange={() => undefined}
                                // onChange={this.handleData.bind(this)}
                                onClick={() => this.props.toggleQuantityModal(product)}
                            />
                        </div>
                        <div className="col-12 col-md-3 small text-center mb-2 mb-md-0">
                                <span>
                                    {{}.toString.call(this.props.getFormattedUnits) === '[object Function]' &&
                                        <ProductUnits formattedUnits={this.props.getFormattedUnits(product, this.getProductPackagingQuantity(product))}
                                                      productQuantity={productQuantity}
                                                      pluralPackagingTypeLabel={this.props.getPluralPackagingTypeLabel(product)}
                                                      singularPackagingTypeLabel={this.props.getSingularPackagingTypeLabel(product)}
                                                      leastUnitsPackage={product.data.leastUnitsPackage}
                                        />
                                    }
                                    {productQuantity > 0 &&
                                    <div className="ml-3 small">
                                        Warenpreis: {getQuantityPrice(product, productQuantity, this.props.selectedCountry)}*<br/>
                                    </div>
                                    }
                                </span>
                        </div>
                        <div className="col-12 col-md-5 text-center text-md-right">
                            {!this.props.set && stock === IN_STOCK && !this.props.productInCurrentCart(product) &&
                            <Button
                                color="primary"
                                onClick={(e) => {
                                    const load = this.state.load;
                                    const product = this.state.product;
                                    const quantity = this.getProductQuantity(product);
                                    this.addProductToCart(e, product, quantity, load)
                                }}
                                disabled={!productQuantity || (this.isLoadProduct(product) && !this.state.load)}
                            >
                                <span className="oi oi-cart mr-2"/> In den Warenkorb
                            </Button>
                            }
                            {(this.props.set || this.props.productInCurrentCart(product)) &&
                            <Button
                                color="primary"
                                href=""
                                onClick={(e) => {
                                    const load = this.state.load;
                                    const product = this.state.product;
                                    const quantity = this.getProductQuantity(product);
                                    this.addProductToCart(e, product, quantity, load)
                                }}
                                disabled={
                                    (!productQuantity || (this.isLoadProduct(product) && !this.state.load) ||
                                        productQuantity === this.state.product.quantity ||
                                        productQuantity === this.props.productInCurrentCartQuantity(product)
                                    )
                                }
                            >
                                <span className="oi oi-cart mr-2"/>Warenkorb ändern
                            </Button>
                            }
                        </div>
                    </div>
                    {!this.props.single &&
                    <div className="d-flex flex-column flex-md-row mt-1 order-md-12 order-0">
                        <div className="col-12 col-md-2 text-center text-md-right">
                            Farbe:**
                        </div>
                        <div className="col-12 col-md-10">
                            <Input
                                type="text"
                                id="load"
                                name="load"
                                className="ml-md-1"
                                value={loadValue}
                                onChange={this.handleData.bind(this)}
                                maxLength="50"
                            />
                        </div>
                    </div>
                    }
                </div>
                }
            </Fragment>
        );
    }
}

ProductLoadComponent.propTypes = {};
function mapStateToProps(state) {
    return {
        selectedCountry: getSelectedCountry(state)
    }
}
export default connect(mapStateToProps)(ProductLoadComponent);
