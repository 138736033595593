import React, {Component} from 'react';
import PropTypes from 'prop-types';

import FullAddress from '../Address/FullAddress';
import {Delivery, Pickup, Project} from "../../proptypes";

class DeliveryAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <p>
        {this.props.pickup &&
          <strong>Abholadresse:</strong>
        }
        {this.props.delivery &&
          <strong>Lieferadresse:</strong>
        }

        {this.props.pickup &&
          <span>
            <FullAddress address={this.props.pickup.facility.address} />
          </span>
        }

        {this.props.delivery &&
          <span>
            <br />
            {this.props.project.name}
            <FullAddress address={this.props.project.address} />
          </span>
        }
      </p>
    );
  }
}


DeliveryAddress.propTypes = {
  pickup: PropTypes.shape(Pickup),
  delivery: PropTypes.shape(Delivery),
  project: PropTypes.shape(Project)
};

export default DeliveryAddress;
