import Navbar from "reactstrap/es/Navbar";
import {Collapse, NavbarToggler} from "reactstrap";
import React from "react";
import * as PropTypes from "prop-types";

export default function Navigation(props) {
    return <Navbar className="p-0 d-flex flex-row flex-lg-column" expand="lg" light>
        <NavbarToggler onClick={props.onClick}/>
        <Collapse isOpen={props.open} navbar>
            {props.user && props.user.role === "ADMIN" && props.adminNavBar}
            {props.user && props.user.role === "USER" && props.userNavBar}
            {props.user && props.user.role === "CUSTOMER_USER" && props.customerUserNavBar}
        </Collapse>
    </Navbar>;
}
Navigation.propTypes = {
    onClick: PropTypes.func,
    open: PropTypes.any,
    user: PropTypes.any,
    adminNavBar: PropTypes.any,
    userNavBar: PropTypes.any,
    customerUserNavBar: PropTypes.any
};
