export function getFormattedDate(date,locale){
    const options = {
        year: 'numeric', month: '2-digit', day: '2-digit'
    };
    return new Intl.DateTimeFormat(locale, options).format(new Date(date));
}
export function getFormattedDateTime(date,locale) {
    const options = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit',
        hour12: false
    };
    return new Intl.DateTimeFormat(locale, options).format(new Date(date));
}