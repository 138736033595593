import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from "./ListComponent";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import Button from "reactstrap/lib/Button";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import {createNewProjectWithOffer} from "../../actions";
import {Alert} from "reactstrap";

class ChooseProjectForOfferList extends Component {
    constructor(props) {
        super(props);
        this.state= {
            modals:{
                deleteProject:false
            }
        }
    }
    updateOffer(e, project){
        const offer = this.props.offer;
        this.props.dispatch(createNewProjectWithOffer(this.props.customer.id,offer.number,project))
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }
    workerInProject(offer, project){
        if(offer && !offer.workerNumber){
            return true;
        }
        if(offer && offer.workerNumber && project && project.worker && project.worker.number === offer.workerNumber){
            return true;
        }
        return false
    }
    getWorkerByWorkerNumber(workerNumber) {
        const customerWorkers = this.props.customerWorkers;
        if (customerWorkers && workerNumber)
            return _.find(customerWorkers, {'number': workerNumber});
    }
    itemRenderer(project){
        return (
            <ListGroupItem className="no-gutters pt-4 pb-4">
                <div className="flex-md-row flex-column d-flex no-gutters">
                    <div className="col-md-1 text-md-left">
                        {project.number}
                    </div>
                    <div className="col-md-5 text-md-left">
                        <div>{project.name}</div>
                        <div><small>{project.worker && project.worker.name}</small></div>

                    </div>
                    <div className="col-md-6 text-md-right">
                        <div className="d-flex float-right flex-column flex-md-row">
                            <div className="d-flex float-right flex-column flex-md-row">
                                {this.workerInProject(this.props.offer, project) &&
                                <Button
                                    color="primary"
                                    onClick={(e) => this.updateOffer(e,project)}
                                    className="mt-2 mt-md-0"
                                >
                                    <FormattedMessage id="chooseprojectforoffer.button.choose"/>
                                    <span
                                        className="oi oi-chevron-right ml-2"/>
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {!this.workerInProject(this.props.offer, project) &&
                    <div>
                        <Alert color="info" className="text-center">
                            <span className="oi oi-info"/> Das Angebot gilt für eine Lieferadresse mit dem <strong>Fachhandwerker:
                                {this.getWorkerByWorkerNumber(this.props.offer.workerNumber) && this.getWorkerByWorkerNumber(this.props.offer.workerNumber).name}</strong> dieser ist nicht der Lieferadresse zugewiesen.
                        </Alert>
                    </div>
                }
            </ListGroupItem>
        )

    }
    toggleRow(index) {
        if(!this.props.offerSelect){
            if (this.state.collapsed !== index) {
                this.setState({collapsed: index})
            } else {
                this.setState({collapsed: undefined})
            }
        }
    }
    hasProjectOrders(project) {
        const projectOrders = this.getProjectOrders(project);
        return projectOrders.length > 0;
    }
    getNumberBox(item, index) {
        return <div className="d-flex flex-md-column flex-row no-gutters text-left">
            <div className="pl-2 pl-md-2">{item.number}</div>
            <div className="pl-2 pl-md-0">
                {this.hasProjectOrders(item) &&
                    <Button color="link" className="mt-0 pt-1" onClick={() => {
                        this.toggleRow(index)
                    }}>
                        <FormattedMessage id="project.list.orderhistory.button"/>
                    </Button>
                }
            </div>
        </div>;
    }

    render() {
        const projects = this.props.projects || [];
        return (
            <ListComponent  itemRenderer={this.itemRenderer.bind(this)} items={projects} />
        );
    }
}

ChooseProjectForOfferList.propTypes = {
    projects: PropTypes.array.isRequired,
    carts: PropTypes.array,
    customerOrders: PropTypes.array,
};

export default ChooseProjectForOfferList;
