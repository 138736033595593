import {callSecureApi} from '../util/apiCaller';
import {isAlreadyExists, isUnauthorized} from '../util/http-errors';

import {
    SET_WORKERS,
    SET_WORKERS_COUNT,
    SET_WORKER
} from './types';
import {logout} from './AuthentificationActions';
import {setCurrentMessage} from './MessagesAction';

export function createWorker(worker) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/worker',
                method: 'post'
            },
            worker)
            .then(res => {
            dispatch(setWorker(res))
            const message = {
                type: 'INFO',
                msg: `Der Handwerker ${res.name} wurde erfolgreich angelegt.`,
                source: 'CREATE_WORKER'
            };
            dispatch(setCurrentMessage(message));
        }).catch(err=>{
            let message;
            if (isAlreadyExists(err)) {
                message = {
                    type: 'ERROR',
                    msg: `Die Nummer ist bereits einem anderen Fachhandwerker zugeordnet.`,
                    source: 'CREATE_WORKER'
                };
            }else{
                message = {
                    type: 'ERROR',
                    msg: `Beim Erstellen des Fachhandwerkers ist ein Fehler aufgetreten`,
                    source: 'CREATE_WORKER'
                };
            }
            dispatch(setCurrentMessage(message));
        })
    }
}

export function loadWorkers() {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/worker',
                method: 'get'
            }
        ).then(res => {
            dispatch(setWorkers(res));
            dispatch(setWorkersCount(res.workers.length));
        })
    }


}

export function loadWorker(workerNumber) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/worker/{workerNumber}',
                query: {'workerNumber': workerNumber},
                method: 'get'
            }
        ).then(res => {
            dispatch(setWorker(res));
        })
    }
}

export function updateWorker(worker) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/worker/{workerNumber}',
                query: {'workerNumber': worker.number},
                method: 'put'
            },
            worker
        ).then(res => {
            dispatch(setWorker(res));
            const message = {
                type: 'INFO',
                msg: `Das Objekt ${res.name} wurde erfolgreich aktualisiert.`,
                source: 'CHANGE_WORKER'
            };
            dispatch(setCurrentMessage(message));
        }).catch(err => {
            console.error("update worker error", err)
            const message = {
                type: 'ERROR',
                msg: `Beim Bearbeiten des Fachhandwerkers ist ein Fehler aufgetreten`,
                source: 'CHANGE_WORKER'
            };
            dispatch(setCurrentMessage(message));
        })
    }
}

export function attachWorkerToCustomer(workerNumber, customerId) {
    return dispatch => {
        return new Promise((resolve, reject) => {

            callSecureApi(
                {
                    endpoint: 'customerservice/v1/worker/{workerNumber}/customer/{customerId}',
                    query: {'workerNumber': workerNumber, 'customerId': customerId},
                    method: 'put'
                }
            ).then(res => {
                resolve(res)
                console.log("attached worker to customer")
            })
                .catch(err => {
                    reject(err);
                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                })
        })
    }

}

export function detachWorkerFromCustomer(workerNumber, customerId) {
    return dispatch => {
        return new Promise((resolve, reject) => {

            callSecureApi({
                endpoint: 'customerservice/v1/worker/{workerNumber}/customer/{customerId}',
                query: {'workerNumber': workerNumber, 'customerId': customerId},
                method: 'delete'
            })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                });
        })
    };
}

export function deleteWorker(workerNumber) {
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/worker/{workerNumber}',
            query: {workerNumber},
            method: 'delete'
        }).then((r) => {
            console.log("worker successfully deleted ", workerNumber, r)
            dispatch(loadWorkers());
        }).catch(err => {
            console.log('error on deleteWorker ', workerNumber, err)
        })
    }
}

export function setWorker(worker) {
    return {
        type: SET_WORKER,
        worker
    }
}

export function setWorkers(workers) {
    return {
        type: SET_WORKERS,
        workers
    }
}
export function setWorkersCount(count) {
    return {
        type: SET_WORKERS_COUNT,
        count
    }
}
