import React, {Component} from 'react';
import Button from "reactstrap/es/Button";
import ListGroup from "reactstrap/lib/ListGroup";
import Collapse from "reactstrap/es/Collapse";
import quickmix from "../../media/logos/quickmix-logo.png";
import akruit from "../../media/logos/akurit-logo.png";
import strasser from "../../media/logos/strasser-logo.png";
import tubag from "../../media/logos/tubag-logo.png";

class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            list: [],
            selected: undefined,
        };
    }

    setSelected(item) {
        this.setState({selected: item})
    }

    toggleCollapse() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    close(){
        this.setState({
            isOpen: false
        })
    }
    isActive(item) {
        const selected = this.state.selected || this.props.selected;
        if (selected) {
            if (item) {
                return Boolean(item.number === selected.number);
            }
        }
        return false;
    }

    getLogo(item) {
        const logos = {
            "quickmix": <img className="logo m-0 w-75" src={quickmix} alt="Quickmix Logo"/>,
            "akurit": <img className="logo m-0 w-75" src={akruit} alt="Akurit Logo"/>,
            "strasser": <img className="logo m-0 w-75" src={strasser} alt="Strasser Logo"/>,
            "tubag": <img className="logo m-0 w-75" src={tubag} alt="Tubag Logo"/>
        }
        return logos[item.name.toLowerCase()];
    }

    getClassName() {
        if (this.props.logos) {
            return "list-group-item text-center list-group-item-action m-0 pt-2 pb-3"
        } else {
            return "list-group-item list-group-item-action"
        }
    }

    getMainClassNames() {
        if (this.props.brands) {
            return "mb-2 brand-list category-list"
        } else {
            return "col-md-4 mb-2 category-list"
        }
    }

    render() {
        const title = this.props.title || "";
        const list = this.props.list || [];
        const onItemClick = this.props.onItemClick

        return (
            <div className={this.getMainClassNames()}>
                {!this.props.brands &&
                    <Button
                        className="btn-block category-title"
                        onClick={this.toggleCollapse.bind(this)}
                    >
                        {title}
                        <span className="float-right">
                            {!this.state.isOpen && <span className="oi oi-chevron-right" />}
                            {this.state.isOpen && <span className="oi oi-chevron-bottom" />}
                        </span>
                    </Button>
                }
                <Collapse isOpen={this.state.isOpen}>
                    <ListGroup tag="div" horizontal={this.props.brands ? "md" :false}>
                        {list.map((item, i) => {
                                  const itemLabel = item.label || item.name;
                                  return (
                                        <Button
                                            tag="a" key={i}
                                            className={this.getClassName()}
                                            onClick={() => {
                                              onItemClick(item);
                                              this.setSelected(item);
                                            } } active={this.isActive(item)}
                                        >
                                            { this.props.logos && this.getLogo(item)}
                                            {!this.props.logos && <span>{itemLabel}</span>}
                                        </Button>
                                    );
                                })}
                    </ListGroup>
                </Collapse>
            </div>
        );
    }
}

export default CategoryList;
