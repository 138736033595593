import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {Button,  Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCartProducts, getCurrentCart} from "../../reducers/CartReducer";
import {getCurrentOrder, loadBlockedDeliveryDays} from "../../reducers/OrderReducer";
import {getAllProducts, getCrossSellProducts} from "../../reducers/ProductsReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCurrentProject} from "../../reducers/ProjectReducer";
import {getEarliestTour} from "../../reducers/TourReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {
  BlockedDate,
  Cart, Customer,
  Message,
  Order,
  Product,
  ProductGroups,
  Project,
  Tour,
  User
} from "../../proptypes";
import {
  PATH_CART_SUMMARY_PAGE,
  PATH_HOME_PAGE,
  PATH_NEW_CART_PAGE,
  PATH_NEW_CART_SCHEDULE_PAGE,
  PATH_PRODUCTS_PAGE
} from "../../routePaths";
import DatePicker from "../../components/Schedule/DatePicker";

import {germanCurrency} from "../../util/priceHelper";
import CartValidator from "../../util/cartValidation";

import {
  fetchBlockedDeliveryDays,
  loadOrder,
  refreshCart,
  removeCurrentMessage,
  setDeliveryCart,
  loadCustomerWorkers,
  fetchProducts,
  loadCurrentProject, fetchCrossSellProductsFromCart
} from "../../actions";
import CartItemsList from "../../components/Cart/CartItemsList";
import {FormattedMessage} from "react-intl";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";
import OrderAdress from "../../components/Address/OrderAdress";
import {CartHeadline} from "../../components/Cart/CartHeadline";
import ProductGridList from "../../components/Product/ProductGridList";



class CartPage extends Component {

  static returnChoice() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
  }

  static orderItemName(orderItem) {
    let itemName = orderItem.data.formatDefinition;
    if (!itemName) {
      itemName = orderItem.name;
    }
    return itemName;
  }

  static bundledOrderItemList(cart) {
    if (cart) {
      const orderItems = cart.orderItems;
      if (orderItems) {
        const orderItemList = _.map(orderItems, orderItem => {
          return orderItem;
        });

        return _.filter(orderItemList, (orderItem) => {
          return orderItem.data.forSale === false;
        });
      }
    }
    return [];
  }

  static getTour(cart) {
    let tour = DatePicker.getConfirmedTour(cart);
    if (!tour) {
      tour = DatePicker.getTour(cart);
    }
    return tour;
  }

  static getFormattedDate(cart) {
    const tour = CartPage.getTour(cart);
    const date = tour.fromDate;

    const options = {
      year: 'numeric', month: '2-digit', day: '2-digit'
    };
    return new Intl.DateTimeFormat('de-DE', options).format(new Date(date));
  }

  static getFormattedTimeLabel(cart) {
    const tour = CartPage.getTour(cart);
    return DatePicker.getHourLabel(tour);
  }

  static getFormattedWeightTonnes(weightKilo) {
    const weightTonnes = weightKilo / 1000;
    const options = {
      minimumFractionDigits: 1, maximumFractionDigits: 1
    };
    return new Intl.NumberFormat('de-DE', options).format(weightTonnes);
  }

  constructor(props) {
    super(props);
    console.log("mounted Cart page")
    this.switchToPage = this.switchToPage.bind(this);
    this.hasSelectedFinalDelivery = this.hasSelectedFinalDelivery.bind(this);
    this.hasMaxedOutCapacity = this.hasMaxedOutCapacity.bind(this);
    // Invoke `setting final delivery` when the click event is fired, but not more than once every 500 ms.
    this.submitFinalDelivery = _.throttle(this.submitFinalDelivery.bind(this), 500, {trailing: false});
    // Invoke `replacing option` when the click event is fired, but not more than once every 1 seconds.
    this.showReplaceOptionModal = this.showReplaceOptionModal.bind(this);
    this.hideReplaceOptionModal = this.hideReplaceOptionModal.bind(this);
    this.toggleProductGroup = this.toggleProductGroup.bind(this);
    this.isBlockedDeliveryDate = this.isBlockedDeliveryDate.bind(this);

    this.state = {
      quantityModal: false,
      replaceOptionModal: false,
      productGroupCollapsed: {}
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const cart = this.props.currentCart;
    if(!this.props.currentProject && cart){
      this.props.dispatch(loadCurrentProject({id:cart.projectId}))
    }
    if (cart) {
      this.props.dispatch(refreshCart(cart.cartId));
      this.props.dispatch(fetchBlockedDeliveryDays());

      const orderId = cart.orderId;
      if (orderId) {
        this.props.dispatch(loadOrder(orderId));
      }

      this.props.dispatch(loadCustomerWorkers(cart.customerId))
      this.props.dispatch(fetchCrossSellProductsFromCart(cart));
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextMessage = nextProps.currentMessage;
    if (nextMessage && nextMessage.source === 'REPLACE_CART_ITEM') {
      this.showReplaceOptionModal();
    }
    if(nextProps.currentCart !== this.props.currentCart){
      this.props.dispatch(fetchCrossSellProductsFromCart(nextProps.currentCart));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentCart.orderItems !== prevProps.currentCart.orderItems) {
      // this.handleModifiedCartItems();
    }
  }

  hideReplaceOptionModal() {
    this.setState({
      replaceOptionModal: false
    });

    this.props.dispatch(removeCurrentMessage());
  }

  showReplaceOptionModal() {
    this.setState({
      replaceOptionModal: true
    });
  }

  submitFinalDelivery(event) {
    event.preventDefault();
    const cart = this.props.currentCart;

    if (cart) {
      const finalDelivery = !this.hasSelectedFinalDelivery();
      this.props.dispatch(setDeliveryCart(cart, {finalDelivery}));
    }
  }

  hasSelectedFinalDelivery() {
    const cart = this.props.currentCart;
    if (cart) {
      if (cart.delivery) {
        const finalDelivery = cart.delivery.finalDelivery;
        if (finalDelivery) {
          return true;
        }
      }
    }
    return false;
  }

  hasMaxedOutCapacity() {
    const cart = this.props.currentCart;
    if (cart) {
      const orderItems = cart.orderItems;
      if (orderItems) {
        const cartOrderItems = _.filter(orderItems, (coi) => {
          return coi.maxedOutQuantity > 0;
        });
        return cartOrderItems.length > 0;
      }
    }
    return false;
  }

  areProductsOutOfStock() {
    const cartProducts = this.props.cartProducts;
    if (cartProducts) {
      const products = _.find(cartProducts, (o) => {
        return o.availability === 'OUT_OF_STOCK';
      });
      return !!products;
    }
    return false;
  }

  isEmptyCart() {
    return !this.props.currentCart.orderItems || this.props.currentCart.orderItems === 0;
  }

  isBlockedDeliveryDate() {
    let found = false;

    const cart = this.props.currentCart;
    if (cart.delivery && !DatePicker.isDeliveryConfirmed(cart)) {
      const tour = CartPage.getTour(cart);
      if (tour) {
        const date = new Date(tour.fromDate);

        if (date) {
          const blockedDeliveryDays = this.props.blockedDeliveryDays;
          if (blockedDeliveryDays) {
            const normalizedDate = date.setHours(0);

            blockedDeliveryDays.forEach(blockedDeliveryDate => {
              const normalizedBlockedDate = blockedDeliveryDate.date.setHours(0);
              if (!found && normalizedBlockedDate === normalizedDate) {
                found = true;
              }
            });
          }
        }
      }
    }
    return found;
  }

  switchToPage(e, pathToPage) {
    this.props.history.push(pathToPage);

    if (e) {
      e.preventDefault();
    }
    return false;
  }

  toggleProductGroup(productGroupId) {
    if (productGroupId) {
      this.setState(currentState => {
        // deep copy current state, in order to not modify the current state object
        const nextState = _.cloneDeep(currentState);

        // invert productGroupCollapsed state for specific product group
        nextState.productGroupCollapsed[productGroupId] = !nextState.productGroupCollapsed[productGroupId];

        return nextState;
      });
    }
  }
  handleModifiedCartItems() {
    const cart = this.props.currentCart;
    const orderItems = cart.orderItems;
    if (orderItems) {
      const productNumbers = orderItems.map(orderItem => orderItem.productNumber);
      this.props.dispatch(fetchProducts(productNumbers));
    }
  }
  getWorkerByWorkerNumber(workerNumber) {
    const customerWorkers = this.props.customerWorkers;
    if (customerWorkers && workerNumber)
      return _.find(customerWorkers, {'number': workerNumber});
  }
  render() {
    if (!this.props.currentUser) {
      return this.switchToPage(null, PATH_HOME_PAGE);
    }

    const role = this.props.currentUser.role;
    if (!role || role === 'ANONYMOUS' || role === 'SCHEDULER') {
      return this.switchToPage(null, PATH_HOME_PAGE);
    }

    if (!this.props.currentCart) {
      return this.switchToPage(null, PATH_HOME_PAGE);
    }

    if (CartValidator.isCartFixed(this.props.currentCart)) {
      return this.switchToPage(null, PATH_CART_SUMMARY_PAGE);
    }

    if (!this.props.currentProject) {
      return <div className="page-content text-center"><span> <FormattedMessage id="cartpage.wait.for.object" /></span></div>;
    }

    if (!this.props.earliestTour) {
      return <div className="page-content text-center"><span><FormattedMessage id="cartpage.wait.for.tour" /></span></div>;
    }

    if (this.props.currentCart.orderItems && !this.props.crossSellProductGroups) {
      return <div className="page-content text-center"><span><FormattedMessage id="cartpage.wait.for.product" /></span></div>;
    }

    return (
      <div className="page-content">
        <div className="container">
          <div className="step-navigation d-flex flex-column flex-md-row text-center">
            <div className="col-12 col-md-4 disabled">
              <span onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>
                <span className="oi oi-arrow-circle-left mr-2"/> zur Produktauswahl
              </span>
            </div>
            <div className="col-12 col-md-4 active">
              <span>1. Warenkorb</span>
            </div>
            <div className="col-12 col-md-4 disabled">
              <span>2. Bestellanforderung</span>
            </div>
          </div>

          <span className="pt-2">
            <h6 className="text-muted text-center m-3">

              <OrderAdress currentProject={this.props.currentProject} currentCart={this.props.currentCart}
                           workerByWorkerNumber={this.getWorkerByWorkerNumber(this.props.currentCart.workerNumber)}/>
              {this.props.currentCart.orderId &&
              <span>
                  {(!this.props.currentOrder || !this.props.currentOrder.shippingNumber) &&
                  <span>
                      <br/>
                      Vorgang: {this.props.currentCart.orderId}
                    </span>
                  }
                {(this.props.currentOrder && this.props.currentOrder.shippingNumber) &&
                <span>
                      <br/>
                      Lieferschein: {this.props.currentOrder.shippingNumber}
                    </span>
                }
                {this.props.currentCart && this.props.currentCart.workerNumber &&
                <span>
                      <br/>
                      Fachhandwerker: {this.getWorkerByWorkerNumber(this.props.currentCart.workerNumber)}
                    </span>
                }
                </span>

              }
            </h6>
          </span>

          <CartHeadline headline={"cartpage.cart.headline"} offerNumber={this.props.currentCart.offerNumber} />

          <div className="row">
            <div className="offset-4 col-4">
              <div className="text-center">
                {this.props.currentCart.pickup &&
                <p>
                  <FormattedMessage
                      id="cartpage.cart.pickup"/> {CartPage.getFormattedDate(this.props.currentCart)}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}
                  <br/>
                  <span><FormattedMessage
                      id="cartpage.cart.pickup.facility"/> {this.props.currentCart.pickup.facility.name}</span>
                </p>
                }

                {this.props.currentCart.delivery &&
                <span>
                  Lieferung: {CartPage.getFormattedDate(this.props.currentCart)}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}
                  </span>
                }
              </div>
            </div>
            <div className="col-4">
              <p className="float-right">
                <Button color="none" className="btn-outline-secondary"
                        onClick={(e) => this.switchToPage(e, PATH_NEW_CART_PAGE)}>
                  Versandart ändern <span className="oi oi-chevron-right ml-2"/>
                </Button>
              </p>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-12">
              <p className="float-right text-primary ">
                <span>aktuelle Menge: </span>
                {(this.props.currentCart.totalPalettes === 0 || this.props.currentCart.totalPalettes > 1) &&
                <span>
                    {this.props.currentCart.totalPalettes} Paletten
                  </span>
                }
                {this.props.currentCart.totalPalettes === 1 &&
                <span>
                    {this.props.currentCart.totalPalettes} Palette
                  </span>
                }
                <span> oder {CartPage.getFormattedWeightTonnes(this.props.currentCart.totalWeight)} t</span>
              </p>
            </div>
          </div>

          <CartItemsList  onModifyCartItems={this.handleModifiedCartItems.bind(this)}/>

          <div className="text-right">
            <Button tag="a" href="" color="secondary"
                    onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>
              <span className="oi oi-plus mr-2"/> Produkte hinzufügen
            </Button>
          </div>

          <div className="row pt-3">
            {/* separator line */}
          </div>

          <div className="pt-3">
            <p className="text-right text-primary">
              <span>aktuelle Menge: </span>
              {(this.props.currentCart.totalPalettes === 0 || this.props.currentCart.totalPalettes > 1) &&
              <span>
                  {this.props.currentCart.totalPalettes} Paletten
                </span>
              }
              {this.props.currentCart.totalPalettes === 1 &&
              <span>
                  {this.props.currentCart.totalPalettes} Palette
                </span>
              }
              <span> oder {CartPage.getFormattedWeightTonnes(this.props.currentCart.totalWeight)} t</span>
            </p>
          </div>

          <div className="row">
            {/* separator line */}
          </div>

          {
            <div>
              <div className="text-right text-primary pt-3">
                Gesamtpreis (netto): {germanCurrency(this.props.currentCart.totalNetPriceSum)}<br/>
              </div>
            </div>
          }

          {this.props.crossSellProductGroups && this.props.crossSellProductGroups.hasOwnProperty('productGroups') &&
          <div>
            <div className="pt-4">
              <h3 className="h4 text-center mb-4">Haben Sie an folgende Produkte gedacht?</h3>
              <div className="productList">
                <ProductGridList
                    propProductGroups={this.props.crossSellProductGroups}
                    currentCart={this.props.currentCart}
                    dispatch={this.props.dispatch}
                />
              </div>
            </div>

            <hr style={{marginTop: '0px'}}/>
          </div>
          }

          {this.isBlockedDeliveryDate() &&
          <div className="pt-3 alert alert-warning">
            <span className="oi oi-warning mr-2"/>
            Der aktuelle Liefertermin&nbsp;
            {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
            ist nicht realisierbar! Bitte aktualisieren sie den&nbsp;
            <Button color="link"
                    onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>Liefertermin</Button>.
          </div>
          }
          {this.areProductsOutOfStock() &&
          <div className="pt-3 alert alert-warning">
            <span className="oi oi-warning mr-2"/>
            Ein oder mehrere Produkte sind nicht mehr auf Lager. Bitte entfernen Sie diese Produkte aus dem Warenkorb.
          </div>
          }
          {/* eslint-disable-next-line no-mixed-operators */}
          {this.isBlockedDeliveryDate() || !DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour) &&
          <div>
            {(!DatePicker.isDeliveryConfirmed(this.props.currentCart) && this.props.currentCart.delivery) &&
            <div className="pt-3 alert alert-warning">
              <span className="oi oi-warning mr-2"/>
              Der aktuelle Liefertermin&nbsp;
              {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
              ist nicht realisierbar! Bitte aktualisieren sie den&nbsp;
              <Button color="link"
                      onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>Liefertermin</Button>.
            </div>
            }
            {(DatePicker.isDeliveryConfirmed(this.props.currentCart) && this.props.currentCart.delivery) &&
            <div className="pt-3 alert alert-warning">
              <span className="oi oi-warning mr-2"/>
              Der bestätigte Liefertermin&nbsp;
              {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
              ist nicht realisierbar! Bitte richten sie sich an unsere Disposition.
            </div>
            }
            {(!DatePicker.isPickupConfirmed(this.props.currentCart) && this.props.currentCart.pickup) &&
            <div className="pt-3 alert alert-warning">
              <span className="oi oi-warning mr-2"/>
              Der aktuelle Termin zur Abholung&nbsp;
              {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
              ist nicht realisierbar! Bitte aktualisieren sie den <Button color="link"
                                                                          onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>Abholtermin</Button>.
            </div>
            }
            {(DatePicker.isPickupConfirmed(this.props.currentCart) && this.props.currentCart.pickup) &&
            <div className="pt-3 alert alert-warning">
              <span className="oi oi-warning mr-2"/>
              Der bestätigte Termin zur Abholung&nbsp;
              {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
              ist nicht realisierbar! Bitte richten sie sich an unsere Disposition.
            </div>
            }
          </div>
          }

          <div className="text-center pt-3">
            {this.props.currentCart.delivery &&
            <Fragment>
                <span>
                  {this.isEmptyCart() &&
                  <div className="alert alert-info">
                    <span className="oi oi-info mr-2"/>
                    Bitte fügen Sie <Button color="link" onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>Produkte
                    für die Lieferung</Button> zum Warenkorb hinzu.
                  </div>
                  }
                </span>
              <span className="text-center">
                  <Button
                      tag="a" href="" color="primary" onClick={(e) => this.switchToPage(e, PATH_CART_SUMMARY_PAGE)}
                      disabled={(this.isBlockedDeliveryDate() || this.isEmptyCart() || !DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour) || this.areProductsOutOfStock())}
                  >
                    Bestellung finalisieren <span className="oi oi-chevron-right ml-2"/>
                  </Button>
              </span>
            </Fragment>
            }
            {this.props.currentCart.pickup &&
            <span>
                {this.isEmptyCart() &&
                <div className="alert alert-info">
                  <span className="oi oi-info mr-2"/>
                  Bitte fügen Sie <Button color="link" onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>Produkte
                  für die Abholung</Button> zum Warenkorb hinzu.
                </div>
                }
              <Button
                  tag="a" href="" color="primary" onClick={(e) => this.switchToPage(e, PATH_CART_SUMMARY_PAGE)}
                  disabled={(this.isBlockedDeliveryDate() || this.isEmptyCart() || !DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour) || (this.areProductsOutOfStock()))}
              >
                  Bestellung finalisieren <span className="oi oi-chevron-right ml-2"/>
                </Button>
            </span>
            }
          </div>

          <div className="text-left pt-3">
            <span>* <small><FormattedMessage id="cartpage.info.pricelist" /></small></span>
          </div>
        </div>

        <Modal isOpen={this.state.replaceOptionModal} fade={false} toggle={() => this.showReplaceOptionModal()}>
          <ModalHeader toggle={() => this.hideReplaceOptionModal()}/>
          <ModalBody className="text-center">
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'REPLACE_CART_ITEM') &&
            <div className="alert alert-danger">
              {this.props.currentMessage.msg}
            </div>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.hideReplaceOptionModal()}>Schließen</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CartPage.propTypes = {
  currentUser: PropTypes.shape(User),
  currentCart: PropTypes.shape(Cart),
  earliestTour: PropTypes.shape(Tour),
  currentOrder: PropTypes.shape(Order),
  currentProject: PropTypes.shape(Project),
  dispatch: PropTypes.func.isRequired,
  currentMessage: PropTypes.shape(Message),
  crossSellProductGroups: PropTypes.shape(ProductGroups),
  blockedDeliveryDays: PropTypes.arrayOf(PropTypes.shape(BlockedDate)),
  cartProducts: PropTypes.arrayOf(PropTypes.shape(Product)),
  customer: PropTypes.shape(Customer),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    currentCart: getCurrentCart(state),
    earliestTour: getEarliestTour(state),
    currentProject: getCurrentProject(state),
    currentMessage: getCurrentMessage(state),
    currentOrder: getCurrentOrder(state),
    crossSellProductGroups: getCrossSellProducts(state),
    blockedDeliveryDays: loadBlockedDeliveryDays(state),
    cartProducts: getCartProducts(state),
    customer: getCustomer(state),
    products: getAllProducts(state),
    customerWorkers: getCustomerWorkers(state)
  };
}

export default withRouter(connect(mapStateToProps)(CartPage));
