import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from "./ListComponent";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import Button from "reactstrap/lib/Button";
import {FormattedMessage} from "react-intl";
import {deleteProject, setCurrentProject, deleteCustomerUserProject} from "../../actions";
import {PATH_ADMIN_PROJECT_EDIT_PAGE} from "../../routePaths";
import _ from "lodash";
import {goToPage} from '../../util/page';
import ModalComponent from "../Modals/ModalComponent";
import {User} from "../../proptypes";
import {getCurrentUser} from "../../reducers/UserReducer";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class UserProjectOverviewList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modals: {
                deleteProject: false
            }
        }
    }

    deleteProject(project) {
        const userId = this.props.currentUser.id;
        if (this.props.currentUser.role === "CUSTOMER_USER") {
            this.props.dispatch(deleteCustomerUserProject(project, userId));
        } else {
            this.props.dispatch(deleteProject(project))
        }
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    itemRenderer(project) {
        return (
            <ListGroupItem className="no-gutters pt-4 pb-4">
                <div className="flex-md-row flex-column d-flex no-gutters">
                    <div className="col-md-1 text-md-left">
                        {project.number}
                    </div>
                    <div className="col-md-5 text-md-left">
                        <div>{project.name}</div>
                        <div><small>{project.worker && project.worker.name}</small></div>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <div className="d-flex float-right flex-column flex-md-row">
                            <div className="d-flex float-right flex-column flex-md-row">
                                <Button size="sm" className="mr-md-5" onClick={() => {
                                    this.props.dispatch(setCurrentProject(project))
                                    goToPage(PATH_ADMIN_PROJECT_EDIT_PAGE)
                                }}>
                                    <span className="oi oi-pencil"/> <FormattedMessage
                                    id="project.list.projectedit.button"/>
                                </Button>
                                <Button size="sm" color="danger" className="mr-md-5 mb-5 mb-md-0"
                                        onClick={() => {
                                            this.setState({selectedProject: project})
                                            this.showModal('deleteProject');
                                        }}>
                                    <span className="oi oi-trash"/> <FormattedMessage id="delete"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        )

    }

    toggleRow(index) {
        if (!this.props.offerSelect) {
            if (this.state.collapsed !== index) {
                this.setState({collapsed: index})
            } else {
                this.setState({collapsed: undefined})
            }
        }
    }

    hasProjectOrders(project) {
        const projectOrders = this.getProjectOrders(project);
        return projectOrders.length > 0;
    }

    getNumberBox(item, index) {
        return <div className="d-flex flex-md-column flex-row no-gutters text-left">
            <div className="pl-2 pl-md-2">{item.number}</div>
            <div className="pl-2 pl-md-0">
                {this.hasProjectOrders(item) &&
                <Button color="link" className="mt-0 pt-1" onClick={() => {
                    this.toggleRow(index)
                }}>
                    <FormattedMessage id="project.list.orderhistory.button"/>
                </Button>
                }
            </div>
        </div>;
    }

    render() {
        const projects = this.props.projects || [];
        return (
            <>
                <ListComponent itemRenderer={this.itemRenderer.bind(this)} items={projects}/>
                <ModalComponent
                    isOpen={this.state.modals.deleteProject}
                    toggle={() =>
                        this.showModal('deleteProject')
                    }
                    hide={() =>
                        this.hideModal('deleteProject')
                    }
                    color="danger"
                    title="project.modal.deleteproject.title"
                    message={'project.modal.deleteproject.message'}
                    onSuccess={() => {
                        const selectedProject = this.state.selectedProject;
                        if (selectedProject) {
                            this.setState({selectedProject: undefined})
                            this.deleteProject(selectedProject)
                            this.hideModal('deleteProject')
                            this.props.resetSearch();
                        }
                    }}
                    successText={'Löschen'}
                    cancelText={'Abbrechen'}
                    onCancel={() => {
                        this.hideModal('deleteProject')
                        this.setState({selectedProject: undefined})
                    }
                    }
                />
            </>
        );
    }
}

UserProjectOverviewList.propTypes = {
    currentUser: PropTypes.shape(User),
    projects: PropTypes.array.isRequired,
    carts: PropTypes.array,
    customerOrders: PropTypes.array
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
    };
}

export default withRouter(connect(mapStateToProps)(UserProjectOverviewList));
