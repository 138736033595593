export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const LOAD_CUSTOMER_USERS = 'LOAD_CUSTOMER_USERS';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
export const LOAD_CUSTOMER = 'LOAD_CUSTOMER';
export const CLEAR_USER_TO_MANAGE = 'CLEAR_USER_TO_MANAGE';
export const SET_USER_TO_MANAGE = 'SET_USER_TO_MANAGE';
export const SET_CUSTOMER_WORKERS = 'SET_CUSTOMER_WORKERS';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SET_MESSAGE = 'SET_MESSAGE';

export const LOAD_CUSTOMER_PROJECTS = 'LOAD_CUSTOMER_PROJECTS';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

export const LOAD_RETAILERS = 'LOAD_RETAILERS';
export const CLEAR_RETAILER = 'CLEAR_RETAILER';

export const SET_CURRENT_LANG = 'SET_CURRENT_LANG';

export const SET_WORKERS = 'SET_WORKERS';
export const SET_WORKERS_COUNT = 'SET_WORKERS_COUNT';
export const SET_WORKER = 'SET_WORKER';

export const LOAD_MAX_TOURS = 'LOAD_MAX_TOURS';
export const LOAD_BLOCKED_DELIVERY_DAYS = 'LOAD_BLOCKED_DELIVERY_DAYS';
export const LOAD_HOLIDAYS = 'LOAD_HOLIDAYS';
export const LOAD_BLOCKED_INDIVIDUAL_DAYS = 'LOAD_BLOCKED_INDIVIDUAL_DAYS';
export const LOAD_BLOCKED_WEEKDAY = 'LOAD_BLOCKED_WEEKDAY';
export const LOAD_BLOCKED_DELIVERY_TOURS = 'LOAD_BLOCKED_DELIVERY_TOURS';
export const SET_CURRENT_CART = 'SET_CURRENT_CART';
export const REMOVE_CURRENT_ORDER = 'REMOVE_CURRENT_ORDER';
export const REMOVE_EARLIEST_TOUR = 'REMOVE_EARLIEST_TOUR';
export const LOAD_CUSTOMER_CARTS = 'LOAD_CUSTOMER_CARTS';
export const SET_EARLIEST_TOUR = 'SET_EARLIEST_TOUR';
export const REMOVE_CURRENT_CART = 'REMOVE_CURRENT_CART';
export const LOAD_FEDERAL_STATES = 'LOAD_FEDERAL_STATES';
export const LOAD_STATE = 'LOAD_STATE';
export const LOAD_CUSTOMER_TRUCKS = 'LOAD_CUSTOMER_TRUCKS';
export const LOAD_CUSTOMER_TRUCK = 'LOAD_CUSTOMER_TRUCK';
export const SET_FACILITIES = 'SET_FACILITIES';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const LOAD_CUSTOMER_ORDERS = 'LOAD_CUSTOMER_ORDERS';

export const SET_OFFER = 'SET_OFFER';
export const SET_OFFERS= 'SET_OFFERS';
export const SET_CHECKLIST= 'SET_CHECKLIST';
export const SET_PRICE_MAP = 'SET_PRICE_MAP';

export const LOAD_ALL_PRODUCT_NUMBERS = 'LOAD_ALL_PRODUCT_NUMBERS';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_DE_CATEGORIES = 'LOAD_DE_CATEGORIES';
export const LOAD_PL_CATEGORIES = 'LOAD_PL_CATEGORIES';
export const LOAD_PRODUCT_GROUPS = 'LOAD_PRODUCT_GROUPS';
export const CLEAR_PRODUCT_GROUPS = 'CLEAR_PRODUCT_GROUPS';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const LOAD_CUSTOMER_PRODUCT_GROUPS = 'LOAD_CUSTOMER_PRODUCT_GROUPS';
export const LOAD_CUSTOMER_PRODUCTS = 'LOAD_CUSTOMER_PRODUCTS';
export const CLEAR_CUSTOMER_PRODUCT_GROUPS = 'CLEAR_CUSTOMER_PRODUCT_GROUPS';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const CLEAR_SEARCH_PRODUCT = 'CLEAR_SEARCH_PRODUCT';
export const CLEAR_SEARCH_BUNDLE_PRODUCT = 'CLEAR_SEARCH_BUNDLE_PRODUCT';
export const LOAD_ORDER_PRODUCTS = 'LOAD_ORDER_PRODUCTS';
export const SET_SEARCH_PRODUCT = 'SET_SEARCH_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_SEARCH_PRODUCTS = 'SET_SEARCH_PRODUCTS';
export const LOAD_PRICE_LIST_CATEGORY = 'LOAD_PRICE_LIST_CATEGORY';

export const CREATE_USER_REGISTRATION = "CREATE_USER_REGISTRATION";
export const LOAD_CUSTOMER_FACILITY = "LOAD_CUSTOMER_FACILITY";
export const CLEAR_CUSTOMER_FACILITY = "CLEAR_CUSTOMER_FACILITY";
export const LOAD_CUSTOMER_FACILITIES = "LOAD_CUSTOMER_FACILITIES";
export const CLEAR_CUSTOMER_FACILITIES = "CLEAR_CUSTOMER_FACILITIES";
export const SET_CURRENT_FACILITY = "SET_CURRENT_FACILITY";

// unused action types but having reducer logic
export const LOAD_USERS = 'LOAD_USERS';
export const REMOVE_CURRENT_PROJECT = 'REMOVE_CURRENT_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const SET_ORDER_REPORT = 'SET_ORDER_REPORT';
export const REMOVE_CURRENT_TRUCK = 'REMOVE_CURRENT_TRUCK';
export const LOAD_CROSS_SELL_PRODUCTS = 'LOAD_CROSS_SELL_PRODUCTS';
export const CREATE_BUNDLE = 'CREATE_BUNDLE';
export const SET_SEARCH_BUNDLE_PRODUCT = 'SET_SEARCH_BUNDLE_PRODUCT';
export const LOAD_PRODUCT_STOCK = 'LOAD_PRODUCT_STOCK';
