import React, {Component} from 'react';
import CategoryForm from "../../components/Form/CategoryForm";

class CategoryCreatePage extends Component {
    render() {
        return (
            <div>
                <CategoryForm create/>
            </div>
        );
    }
}

CategoryCreatePage.propTypes = {};

export default CategoryCreatePage;
