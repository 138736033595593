import React, {Component} from "react";
import BackButton from "../../components/Buttons/BackButton";
import CustomerFacilityForm from "../../components/Form/CustomerFacilityForm";
import PropTypes from "prop-types";
import {Facility} from "../../proptypes";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getCustomerFacility} from "../../reducers/CustomerFacilityReducer";

class EditCustomerFacilityPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h4 className="text-muted text-center">{this.props.facility.name}</h4>
                    <h1 className="h2 text-center mb-4">
                        Lager / Werk bearbeiten
                    </h1>
                    <CustomerFacilityForm edit/>
                </div>
            </div>
        );
    }
}

EditCustomerFacilityPage.propTypes = {
    facility: PropTypes.shape(Facility).isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        facility: getCustomerFacility(state)
    }
}

export default withRouter(connect(mapStateToProps)(EditCustomerFacilityPage));
