import React from 'react';
import SelectComponent from "./Form/SelectComponent";
import {Button} from "reactstrap";
import PropTypes from "prop-types";

function SortComponent ({directionHandler,directionValue,className,...props}) {
    return (
            <div className={"d-flex flex-row "+className}>
                <div className="col-form-label mr-2">Sortierung:</div>
                <SelectComponent {...props}
                    style={{width:150}}
                    labelKey={"label"}
                />
                <div className="ml-2">
                    <Button className="b" onClick={directionHandler}>
                        {directionValue === "ASC" && <span className="oi oi-arrow-thick-bottom"/>}
                        {directionValue === "DESC" && <span className="oi oi-arrow-thick-top"/>}
                    </Button>
                </div>
            </div>
    )
}
SortComponent.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    directionValue: PropTypes.any.isRequired,
    directionHandler: PropTypes.func.isRequired
};
export default SortComponent;
