import React, {Component} from 'react';
import {Alert, Button, Form} from "reactstrap";
import {FormattedMessage} from "react-intl";
import AddressComponent from "./Parts/AddressComponent";
import _ from "lodash";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import {notEmpty} from "../../util/ValidFn";
import {getCurrentUser} from "../../reducers/UserReducer";
import {getCustomerUsers} from "../../reducers/UsersReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCurrentProject} from "../../reducers/ProjectReducer";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {
    createNewProjectWithOffer,
    getAllUsersFromCustomer,
    removeCurrentMessage,
    setOffer,
    loadCustomerWorkers,
    createProject,
    createProjectWithOffer,
    updateProject,
    createCustomerUserProject,
    updateCustomerUserProject
} from "../../actions";
import ModalComponent from "../Modals/ModalComponent";
import {
    PATH_HOME_PAGE,
    PATH_MANAGE_PROJECT_OVERVIEW_PAGE
} from "../../routePaths";
import {getWorker} from "../../reducers/WorkerReducer";
import {getWorkers} from "../../reducers/WorkersReducer";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";
import {getOffer} from "../../reducers/OffersReducer";
import {getCustomerCarts} from "../../reducers/CartsReducer";
class ProjectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: this.initProject(),
            workers: [],
            authorities: [],
            modals: {
                create: false,
                change: false,
                error: false
            },
        }
        this.hasActiveCarts = this.hasActiveCarts.bind(this);
    }

    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }

    componentDidMount() {
        const customer = this.props.customer;
        this.props.dispatch(getAllUsersFromCustomer(customer))
        this.setCustomerNumber();
        this.props.dispatch(loadCustomerWorkers(customer.id));
        if (this.props.edit) {
            const currentProject = this.props.currentProject;
            this.setProject(currentProject)
            if (currentProject.authority)
                this.setAuthority(currentProject.authority)
        }
        const offer = this.props.offer;
        if (this.props.offerSelect) {
            if (offer && offer.address) {
                this.setProjectAddress(offer.address);
            }
            if (offer.workerNumber) {
                this.setWorker(this.getWorkerByWorkerNumber(offer.workerNumber))
            }
            if (offer.name) {
                this.setProjectName(offer.name);
            }
        }
    }
    componentDidUpdate(prevProps) {
        const nextMessage = this.props.currentMessage;
        if (prevProps.currentMessage !== nextMessage) {
            if (nextMessage && (nextMessage.source === 'CREATE_PROJECT' && nextMessage.type === 'INFO')) {
                this.showModal('create');
            }
            if (nextMessage && (nextMessage.source === 'CHANGE_PROJECT' && nextMessage.type === 'INFO')) {
                this.showModal('edit');
            }
            if (nextMessage && (nextMessage.source === 'PROJECT' && nextMessage.type === 'ERROR')) {
                this.showModal('error');
            }
        }
        if(prevProps.customerWorkers !== this.props.customerWorkers && this.props.customerWorkers){
            let customerWorkers = [].concat(this.props.customerWorkers)
            customerWorkers.map((worker)=>{
                return worker.nameNumber=worker.name + ' (' + worker.number + ')'
            })
            this.setState({customerWorkers})
        }
    }
    setProject(nextProject) {
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.project = nextProject;
                return nextState;
            });
        if (nextProject.worker) {
            this.setWorker(nextProject.worker)
        }
    }

    setAuthority(authority) {
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.authority = authority;
                return nextState;
            });
    }

    setWorker(worker) {
        worker.nameNumber=worker.name + ' (' + worker.number + ')'
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.worker = worker;
                return nextState;
            });
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    setCustomerNumber() {
        const project = Object.assign({}, this.state.project);
        project['customerId'] = this.props.customer.id;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.project = project;
            return nextState;
        });
    }

    initProject() {
        return {
            name: '',
            number: '',
            customerId: '',
            retailer: {},
            address: {
                additionalInformation: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                company: '',
                countryCode: '',
                postalCode: ''
            },
            authority: {},
            worker: {},
            userIds: [],
            expires: null
        }
    }



    handleWorker(worker) {
        this.setState({worker});
    }

    handleAuthority(authority) {
        this.setState({authority})
        const project = Object.assign({}, this.state.project);
        project['authority'] = authority;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.project = project;
            return nextState;
        });
    }

    handleProjectData(event) {
        const project = Object.assign({}, this.state.project);
        project[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.project = project;
            return nextState;
        });
    }

    setProjectName(name) {
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.project.name = name;
            return nextState;
        });
    }

    setProjectAddress(address) {
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.project.address = address;
            return nextState;
        });
    }

    projectAddressHandler(event) {
        const address = Object.assign({}, this.state.project.address);
        address[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.project.address = address;
            return nextState;
        });
    }

    isRequiredNotEmpty() {
        const projectName = this.state.project.name;
        const address = this.state.project.address;
        const addressLine1 = address.addressLine1;
        const city = address.city;
        const postalCode = address.postalCode;
        return notEmpty(projectName) && notEmpty(addressLine1) && notEmpty(city) && notEmpty(postalCode)
    }

    isNewFormComplete() {
        return this.isRequiredNotEmpty()
    }

    createProject(event) {
        event.preventDefault();
        const project = this.state.project;
        const customer = this.props.customer;
        const authority = this.state.authority;
        const worker = this.state.worker;
        const userId = this.props.currentUser.id;

        if (this.props.currentUser.role === "CUSTOMER_USER") {
            this.props.dispatch(createCustomerUserProject(project, customer, worker, authority, userId));
        } else {
            if (!this.props.offerSelect)
                this.props.dispatch(createProject(project, customer, worker, authority))
            else {
                const offer = this.props.offer;
                this.props.dispatch(createProjectWithOffer(project, customer, worker, authority, offer))
            }
        }
    }

    updateProject(event) {
        event.preventDefault();
        const project = this.state.project;
        const authority = this.state.authority;
        const worker = this.state.worker;
        const userId = this.props.currentUser.id;

        if (this.props.currentUser.role === "CUSTOMER_USER") {
            this.props.dispatch(updateCustomerUserProject(project, worker, this.props.currentProject.worker, authority, this.props.currentProject.authority, userId));
        } else {
            this.props.dispatch(updateProject(project, worker, this.props.currentProject.worker, authority, this.props.currentProject.authority))
        }
    }

    userList(customerUsers) {
        if (customerUsers) {
            const filteredCustomerUsers = _.filter(customerUsers.users, user => user.role !== 'CUSTOMER_USER');
            return _.map(filteredCustomerUsers, user => {
                return user;
            });
        }
        return [];
    }

    userRenderer({style, option, selectValue}) {
        return (
            <a
                href
                style={style}
                onClick={() => selectValue(option)}
            >
                {option.id} - {option.lastName} - {option.contact.email}
            </a>
        )
    }

    getWorkerByWorkerNumber(workerNumber) {
        const customerWorkers = this.props.customerWorkers;
        if (customerWorkers && workerNumber)
            return _.find(customerWorkers, {'number': workerNumber});
    }

    createProjectWithOffer() {
        this.props.dispatch(createNewProjectWithOffer(this.props.customer.id, this.props.offer.number, this.state.project))
        this.props.dispatch(setOffer(this.props.offer))
    }
    editingDisabled(){
        return (this.props.edit && !this.props.currentProject.temporary) ||this.hasActiveCarts()
    }
    render() {
        let workers = [];
        const customerWorkers =this.state.customerWorkers || [];

        if (customerWorkers && customerWorkers.constructor === Array) {
            workers = customerWorkers
        }

        const offerSelect = this.props.offerSelect;
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {this.props.edit && !offerSelect &&
                        <Button
                            color="success" href="" className="btn-block"
                            onClick={(e) => this.updateProject(e)}
                            disabled={this.hasActiveCarts()|| this.editingDisabled()}
                        >
                            <FormattedMessage id="project.form.button.updateProject"/>
                        </Button>
                        }
                        {this.props.create && !offerSelect &&
                        <Button
                            href=""
                            className="btn-block"
                            onClick={(e) => this.createProject(e)}
                            disabled={!this.isNewFormComplete()}
                        >
                            <FormattedMessage id="project.form.button.createProject"/>
                        </Button>
                        }
                        {offerSelect &&
                        <Button
                            href=""
                            className="btn-block"
                            color="success"
                            onClick={() => this.createProjectWithOffer()}
                            disabled={!this.isNewFormComplete()}
                        >
                            <FormattedMessage id="project.form.button.completeProject"/>
                        </Button>
                        }
                    </div>
                </div>
                {this.hasActiveCarts() &&
                <Alert color="info" className="text-center">
                    <span className="oi oi-info"/> Lieferadresse kann nicht geändert werden, solange Bestellungen aktiv
                    sind. Schließen sie aktive Bestellungen oder stornieren sie Bestellungen, um die Lieferadresse zu
                    ändern.
                </Alert>
                }
                <Form>
                    <div className="d-flex flex-column flex-md-row col-12 mt-2">
                        <div className="col-12 col-md-6">
                            <h2><FormattedMessage id="project.form.headline.baseData"/></h2>
                            <InputComponent
                                title={"project.form.name.title"} type={"text"} name={"name"}
                                placeholder={"project.form.name.placeholder"}
                                value={this.state.project.name}
                                handleChange={this.handleProjectData.bind(this)}
                                formFeedback={"project.form.name.formFeedback"}
                                disabled={this.editingDisabled()}
                                required
                                blurReq={offerSelect}
                            />
                            {this.props.admin &&
                            <InputComponent
                                title={"project.form.number.title"} type={"text"} name={"number"}
                                value={this.state.project.number}
                                handleChange={this.handleProjectData.bind(this)}
                                formFeedback={"project.form.number.formFeedback"}
                                disabled={this.editingDisabled()}
                                required
                            />
                            }
                            {this.props.currentUser.role !== "CUSTOMER_USER" &&
                                <SelectComponent
                                    title={"project.form.authority.title"}
                                    name={"authority"}
                                    options={this.userList(this.props.customerUsers)}
                                    value={this.state.authority}
                                    onChange={this.handleAuthority.bind(this)}
                                    labelKey={"lastName"}
                                    optionRenderer={this.userRenderer}
                                    disabled={this.editingDisabled()}
                                />
                            }
                            {this.props.currentUser.role !== "CUSTOMER_USER" &&
                                <SelectComponent
                                    title={"project.form.worker.title"}
                                    name={"worker"}
                                    options={workers}
                                    value={this.state.worker}
                                    onChange={this.handleWorker.bind(this)}
                                    labelKey={'nameNumber'}
                                    disabled={(this.props.offerSelect && this.props.offer.workerNumber) || this.editingDisabled()}
                                />
                            }
                        </div>
                        <div className="col-12 col-md-6">
                            <AddressComponent
                                rootValue={this.state.project.address}
                                handler={this.projectAddressHandler.bind(this)}
                                hideCompany
                                disabled={this.editingDisabled()}
                                blurReq={this.props.offerSelect}
                            />
                        </div>
                    </div>
                </Form>

                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() => this.showModal('create')}
                    hide={() => this.hideModal('create')}
                    color="success"
                    title="Lieferadresse erstellt"
                    message={'Lieferadresse wurde erstellt'}
                    onSuccess={(e) => {
                            this.switchToPage(e, PATH_HOME_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />

                <ModalComponent
                    isOpen={this.state.modals.edit}
                    toggle={() => this.showModal('edit')}
                    hide={() => this.hideModal('edit')}
                    color="success"
                    title="Lieferadresse aktualisiert"
                    message={'Lieferadresse wurde aktualisiert'}
                    onSuccess={(e) => {
                        this.switchToPage(e, PATH_MANAGE_PROJECT_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />
                {this.props.currentMessage &&
                <ModalComponent
                    isOpen={this.state.modals.error}
                    toggle={() => this.showModal('error')}
                    hide={() => this.hideModal('error')}
                    color="danger"
                    title="Fehler aufgetreten"
                    message={this.props.currentMessage.msg}
                    onSuccess={() => {
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('error')
                    }}
                    successText={'Ok'}
                />
                }
            </div>
        );
    }

    hasActiveCarts() {
        if (this.props.currentProject && this.props.edit) {
            const projectId = this.props.currentProject.id;
            const customerCarts = this.props.customerCarts ? this.props.customerCarts.carts : undefined;
            return _.find(customerCarts, function (o) {
                    return o.projectId === projectId
                }
            )
        } else {
            return false
        }
    }
}
ProjectForm.propTypes = {};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        customerUsers: getCustomerUsers(state),
        currentMessage: getCurrentMessage(state),
        customer: getCustomer(state),
        currentProject: getCurrentProject(state),
        worker: getWorker(state),
        workers: getWorkers(state),
        customerWorkers: getCustomerWorkers(state),
        offer: getOffer(state),
        customerCarts: getCustomerCarts(state)
    };
}

export default withRouter(connect(mapStateToProps)(ProjectForm));
