import fetch from 'isomorphic-fetch';
import config from '../config/config';
import {SET_MESSAGE} from '../actions/types';
import {goToPage} from './page';
import {PATH_LOGIN_PAGE} from "../routePaths";
import Utils from "./Utils";

export const API_URL = (typeof window === 'undefined' || process.env.NODE_ENV === 'test')
  ? process.env.BASE_URL || (`http://localhost:${config.port}/api`)
  : '/api';

export const ContentType = {
  APPLICATION_JSON: 'application/json',
  APPLICATION_X_WWW_FORM_URLENCODED: 'application/x-www-form-urlencoded',
  APPLICATION_OCTET_STREAM: 'application/octet-stream',
  IMAGE_PNG: 'image/png',
  MULTIPART_FORM_DATA: 'multipart/form-data'
};

// Local store keys
export const SessionStorageKeys = {
  AUTH_TOKEN: 'auth_token',
  REFRESH_TOKEN: 'refresh_token',
  AUTH_TOKEN_EXPIRES: 'expires_in'
};

export function storeAuthToken({access_token,expires, expires_in, refresh_token, token_type}) {
  sessionStorage.setItem(SessionStorageKeys.AUTH_TOKEN, access_token);
  sessionStorage.setItem(SessionStorageKeys.REFRESH_TOKEN, refresh_token);
  sessionStorage.setItem(SessionStorageKeys.AUTH_TOKEN_EXPIRES, expires);
  console.info(`token stored, token expiresAt: ${expires_in}`); // eslint-disable-line no-console
}

export function clearAuthToken() {
  sessionStorage.removeItem(SessionStorageKeys.AUTH_TOKEN);
  sessionStorage.removeItem(SessionStorageKeys.REFRESH_TOKEN);
  sessionStorage.removeItem(SessionStorageKeys.AUTH_TOKEN_EXPIRES);
}

let refreshTokenPromise = null;
export function callSecureApi(options, body) {
  if (testSessionStorageAvailable === false) {
    return Promise.reject({error: 'unauthorized', msg: 'local storage unavailable'});
  }
  return refreshTokenIfRequired()
    .then(() => {
      const authToken = sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN);
      if (authToken) {
        const headers = Object.assign({}, options.headers, {'Authorization': `Bearer ${authToken}`}); // eslint-disable-line quote-props
        return callApi({
          ...options,
          headers
        }, body);
      }

      const message = {
        type: 'INFO',
        msg: 'Aktuelle Session abgelaufen',
        source: 'SESSION_CLOSED'
      };
      Utils.getStore().dispatch({
        type: SET_MESSAGE,
        message
      });
      goToPage(PATH_LOGIN_PAGE);
      return Promise.reject({error: 'unauthorized', msg: 'auth token not found in store'});
    });
}

export function callApi(options, body = undefined) {
  let url = createAPIUrl(options);
  const method = options.method || 'get';
  const contentType = options.contentType || ContentType.APPLICATION_JSON;

  let headers;

  if (options.contentType === ContentType.MULTIPART_FORM_DATA) {
    headers = Object.assign({}, options.headers);
  } else {
    headers = Object.assign({'content-type': contentType}, options.headers);
  }
  let encodedBody;
  if (body) {
    if (options.contentType === ContentType.APPLICATION_X_WWW_FORM_URLENCODED) {
      encodedBody = createQueryString(body);
    } else if (options.contentType === ContentType.IMAGE_PNG || options.contentType === ContentType.APPLICATION_OCTET_STREAM) {
      encodedBody = URL.createObjectURL(body);
    } else if (options.contentType === ContentType.MULTIPART_FORM_DATA) {
      encodedBody = new FormData();
      encodedBody.append('multipartFile', body, body.name);
    } else {
      encodedBody = JSON.stringify(body);
    }
  }
  return fetch(url, {
    headers,
    method,
    body: encodedBody
  })
  .then(response => {
    return response.json()
      .then(json => ({json, response}))
      .catch(() => ({json: {}, response}));
  })
  .then(({json, response}) => {
    if (response.ok) {
      return json;
    }
    return Promise.reject(json);
  })
}
export function mergeQueryStringIntoEndopoint(endpoint, query){
  const queryParamKeys = Object.keys(query)
  queryParamKeys.forEach((key)=>{
    if(endpoint.includes(key)){
      endpoint = endpoint.replace('{'+key+'}',query[key]);
    }
    //?query ?facilityName=Musterstadt
    else{
      endpoint = endpoint.concat('?'+key+'='+query[key])
    }
  })
  return endpoint

}


export function createAPIUrl(options) {
  let url = `${API_URL}/`;
  if (options.query) {
    url += mergeQueryStringIntoEndopoint(options.endpoint, options.query);
  }else{
    url+=options.endpoint
  }
  return url;
}



function createQueryString(params) {
  return Object
    .keys(params)
    .filter(k => {
      return typeof params[k] !== 'undefined';
    })
    .map(k => {
      if (Array.isArray(params[k])) {
        return params[k]
          .map(val =>  {
            return `${encodeURIComponent(k)}=${encodeURIComponent(val)}`
          })
          .join('&');
      }

      console.log(`${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`);

      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
    })
    .join('&');
}

export function refreshTokenIfRequired() {
  const refresh_token = sessionStorage.getItem(SessionStorageKeys.REFRESH_TOKEN);

  if (refresh_token) {
    const expiresIn = sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN_EXPIRES);
    const threshold = 500;
    const actualTimestamp = new Date().getTime() + threshold;
    if (expiresIn < actualTimestamp) {
      console.info(`refreshing token expiredAt: ${expiresIn}`); // eslint-disable-line no-console

      if (refreshTokenPromise) {
        return refreshTokenPromise;
      }

      refreshTokenPromise = callApi({
        endpoint: 'customerservice/v1/token/refresh',
        method: 'post'
      }, {
        refresh_token
      })
        .then(res => {
          storeAuthToken(res);
          refreshTokenPromise = null;
        })
        .catch(e => {
          console.error('unable to refresh token', e); // eslint-disable-line no-console
          refreshTokenPromise = null;
          return Promise.reject('unable to refresh token');
        });

      return refreshTokenPromise;
    }
  }
  return Promise.resolve();
}

function testSessionStorageAvailable() {
  const testKey = 'test';
  try {
    sessionStorage.setItem(testKey, test);
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}
