let proxyConfig = {};
//TODO: Modify Pathes
if (process.env.NODE_ENV === 'production') {
  // proxyConfig = {
  //   port: process.env.PORT || 8093,
  //   apiProxyConfiguration: {
  //     '/api/customerservice': 'https://shop.sievert.de',
  //     '/api/productservice': 'https://shop.sievert.de',
  //     '/api/orderservice': 'https://shop.sievert.de'
  //   }
  // };
} else {
  proxyConfig = {
    port: process.env.PORT || 8093,
    // apiProxyConfiguration: {
    //   '/api/customerservice': 'http://localhost:8092',
    //   '/api/productservice': 'http://localhost:8091',
    //   '/api/orderservice': 'http://localhost:8090'
    // }
    apiProxyConfiguration: {
      '/api/customerservice': 'https://shop.sievert.de',
      '/api/productservice': 'https://shop.sievert.de',
      '/api/orderservice': 'https://shop.sievert.de'
    }
  };
}

// noinspection UnnecessaryLocalVariableJS
const config = proxyConfig;
export default config;
