import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Product} from "../../proptypes";


class ProductGridDetail extends Component {
  static productName(product) {
    let productName = product.data.formatDefinition;
    if (!productName) {
      productName = '';
    }
    return productName;
  }

  render() {
    return (
      <div>
        <small>
            {this.props.propProduct.data.formatDefinitions["DE"]}
        </small>
      </div>
    );
  }
}

ProductGridDetail.propTypes = {
  propProduct: PropTypes.shape(Product)
};

export default ProductGridDetail;
