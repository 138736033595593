import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import CartPage from './CartPage';
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCurrentOrder, loadBlockedDeliveryDays} from "../../reducers/OrderReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCurrentProject} from "../../reducers/ProjectReducer";
import {getEarliestTour} from "../../reducers/TourReducer";
import {getCurrentCart} from "../../reducers/CartReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {BlockedDate, Cart, Customer, Message, Order, Project, Tour, User} from "../../proptypes";
import DatePicker from "../../components/Schedule/DatePicker";
import CartValidator from "../../util/cartValidation";
import {germanCurrency} from "../../util/priceHelper";
import {PATH_CART_PAGE, PATH_HOME_PAGE, PATH_NEW_CART_SCHEDULE_PAGE, PATH_PRODUCTS_PAGE} from "../../routePaths";
import {createOrder, fetchBlockedDeliveryDays, loadOrder, refreshCart, removeCurrentMessage} from "../../actions";
import CartSummaryItemsList from "../../components/Cart/CartSummaryItemsList";
import DeliveryAddress from "../../components/Cart/DeliveryAddress";
import WorkerAddress from "../../components/Cart/WorkerAddress";
import {FormattedMessage} from "react-intl";
import OrderAdress from "../../components/Address/OrderAdress";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";
import {CartHeadline} from "../../components/Cart/CartHeadline";

class CartSummaryPage extends Component {

  static getTour(cart) {
    let tour = DatePicker.getConfirmedTour(cart);
    if (!tour) {
      tour = DatePicker.getTour(cart);
    }
    return tour;
  }

  constructor(props) {
    super(props);

    this.createOrder = this.createOrder.bind(this);
    this.switchToPage = this.switchToPage.bind(this);
    this.hasSelectedFinalDelivery = this.hasSelectedFinalDelivery.bind(this);
    this.hasMaxedOutCapacity = this.hasMaxedOutCapacity.bind(this);
    this.getReturnFullQuantity = this.getReturnFullQuantity.bind(this);
    this.getReturnEmptyQuantity = this.getReturnEmptyQuantity.bind(this);
    this.getReturnBagQuantity = this.getReturnBagQuantity.bind(this);
    this.showOrderFailedModal = this.showOrderFailedModal.bind(this);
    this.hideOrderFailedModal = this.hideOrderFailedModal.bind(this);

    this.state = {
      orderFailedModal: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const cart = this.props.currentCart;
    if (cart) {
      this.props.dispatch(refreshCart(cart.cartId));
      this.props.dispatch(fetchBlockedDeliveryDays());

      const orderId = cart.orderId;
      if (orderId) {
        this.props.dispatch(loadOrder(orderId));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextMessage = nextProps.currentMessage;
    if (nextMessage && nextMessage.source === 'CREATE_ORDER_FAILED') {
      this.showOrderFailedModal();
    }
  }

  getReturnFullQuantity() {
    const cart = this.props.currentCart;
    if (cart) {
      const returnItems = cart.returnItems;
      if (returnItems) {
        return returnItems.palettesFull === undefined ? 0 : returnItems.palettesFull;
      }
    }
    return 0;
  }

  getReturnEmptyQuantity() {
    const cart = this.props.currentCart;
    if (cart) {
      const returnItems = cart.returnItems;
      if (returnItems) {
        return returnItems.palettesEmpty === undefined ? 0 : returnItems.palettesEmpty;
      }
    }
    return 0;
  }

  getReturnBagQuantity() {
    const cart = this.props.currentCart;
    if (cart) {
      const returnItems = cart.returnItems;
      if (returnItems) {
        return returnItems.bigBags === undefined ? 0 : returnItems.bigBags;
      }
    }
    return 0;
  }

  createOrder(event) {
    event.preventDefault();

    const cart = this.props.currentCart;
    if (cart) {
      this.props.dispatch(createOrder(cart)).then(()=>{
        this.switchToPage(event,PATH_HOME_PAGE)
      });
    }
  }

  hasSelectedFinalDelivery() {
    const cart = this.props.currentCart;
    if (cart) {
      if (cart.delivery) {
        const finalDelivery = cart.delivery.finalDelivery;
        if (finalDelivery) {
          return true;
        }
      }
    }
    return false;
  }

  hasMaxedOutCapacity() {
    const cart = this.props.currentCart;
    if (cart) {
      const cartOrderItems = _.filter(cart.orderItems, (coi) => {
        return coi.maxedOutQuantity > 0;
      });
      return cartOrderItems.length > 0;
    }
    return false;
  }

  showOrderFailedModal() {
    this.setState({
      orderFailedModal: true
    });
  }

  hideOrderFailedModal() {
    this.setState({
      orderFailedModal: false
    });

    this.props.dispatch(removeCurrentMessage());
  }

  isBlockedDeliveryDate() {
    let found = false;

    const cart = this.props.currentCart;
    if (cart.delivery && !DatePicker.isDeliveryConfirmed(cart)) {
      const tour = CartSummaryPage.getTour(cart);
      if (tour) {
        const date = new Date(tour.fromDate);

        if (date) {
          const blockedDeliveryDays = this.props.blockedDeliveryDays;
          if (blockedDeliveryDays) {
            const normalizedDate = date.setHours(0);

            blockedDeliveryDays.forEach(blockedDeliveryDate => {
              const normalizedBlockedDate = blockedDeliveryDate.date.setHours(0);
              if (!found && normalizedBlockedDate === normalizedDate) {
                found = true;
              }
            });
          }
        }
      }
    }
    return found;
  }

  switchToPage(e, pathToPage) {
    this.props.history.push(pathToPage);

    if (e) {
      e.preventDefault();
    }
    return false;
  }
  getWorkerByWorkerNumber(workerNumber) {
    const customerWorkers = this.props.customerWorkers;
    if (customerWorkers && workerNumber)
      return _.find(customerWorkers, {'number': workerNumber});
  }
  render() {
    if (!this.props.currentUser) {
      return this.switchToPage(null, PATH_HOME_PAGE);
    }

    if (!this.props.currentCart) {
      return this.switchToPage(null, PATH_HOME_PAGE);
    }

    if (!this.props.currentProject) {
      return <div className="page-content text-center"><span>Bitte auf Objekt warten ...</span></div>;
    }

    if (!this.props.earliestTour) {
      return <div className="page-content text-center"><span>Bitte auf Tour warten ...</span></div>;
    }

    return (
      <div className="page-content">
        <div className="container">
          <div className="step-navigation d-flex flex-column flex-md-row text-center">
            {!CartValidator.isCartFixed(this.props.currentCart) &&
              <div className="col-12 col-md-4 disabled">
                <span onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>
                  <span className="oi oi-arrow-circle-left mr-2" /> zur Produktauswahl
                </span>
              </div>
            }
            {CartValidator.isCartFixed(this.props.currentCart) &&
              <div className="col-12 col-md-4 disabled">
                <span onClick={(e) => this.switchToPage(e, PATH_HOME_PAGE)}>
                  <span className="oi oi-arrow-circle-left mr-2" /> zur Bestellübersicht
                </span>
              </div>
            }
            {!CartValidator.isCartFixed(this.props.currentCart) &&
              <div className="col-12 col-md-4 disabled">
                <span onClick={(e) => this.switchToPage(e, PATH_CART_PAGE)}>
                  1. Warenkorb <span className="oi oi-check ml-2" />
                </span>
              </div>
            }
            {!CartValidator.isCartFixed(this.props.currentCart) &&
              <div className="col-12 col-md-4 active">
                <span>2. Bestellanforderung</span>
              </div>
            }
          </div>

          <span className="pt-2">
            <h6 className="text-muted text-center m-3">
               <OrderAdress currentProject={this.props.currentProject} currentCart={this.props.currentCart}
                            workerByWorkerNumber={this.getWorkerByWorkerNumber(this.props.currentCart.workerNumber)}/>
              {this.props.currentCart.orderId &&
                <span>
                  {(!this.props.currentOrder || !this.props.currentOrder.shippingNumber) &&
                    <span>
                      <br />
                      Vorgang: {this.props.currentCart.orderId}
                    </span>
                  }
                  {(this.props.currentOrder && this.props.currentOrder.shippingNumber) &&
                    <span>
                      <br />
                      Lieferschein: {this.props.currentOrder.shippingNumber}
                    </span>
                  }
                </span>
              }
            </h6>
          </span>

          <CartHeadline headline={"cartsummarypage.headline"} offerNumber={this.props.currentCart.offerNumber} />

          <div className="row">
            <span className="col-md-4 delivery-address">
              <DeliveryAddress pickup={this.props.currentCart.pickup} delivery={this.props.currentCart.delivery} project={this.props.currentProject} />
            </span>

            <span className="col-md-4">
              {this.props.currentCart.pickup &&
                <span>
                  Abholung: {CartPage.getFormattedDate(this.props.currentCart)}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}
                  <br /><br />
                  <strong>{this.props.currentCart.pickup.additionalInformation}</strong>
                </span>
              }

              {this.props.currentCart.delivery &&
                <span>
                  Lieferung: {CartPage.getFormattedDate(this.props.currentCart)}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}
                  <br /><br />
                  <strong>{this.props.currentCart.delivery.additionalInformation}</strong>
                </span>
              }
            </span>

            {this.props.currentProject.worker &&
              <span className="col-md-4 worker-address">
                <WorkerAddress worker={this.getWorkerByWorkerNumber(this.props.currentCart.workerNumber)|| this.props.currentProject.worker} />
              </span>
            }
          </div>

          <div className="pt-3">
            <p className="text-right text-primary">
              <span>aktuelle Menge: </span>
              {(this.props.currentCart.totalPalettes === 0 || this.props.currentCart.totalPalettes > 1) &&
                <span>
                  {this.props.currentCart.totalPalettes} Paletten
                </span>
              }
              {this.props.currentCart.totalPalettes === 1 &&
                <span>
                  {this.props.currentCart.totalPalettes} Palette
                </span>
              }
              <span> oder {CartPage.getFormattedWeightTonnes(this.props.currentCart.totalWeight)} t</span>
            </p>
          </div>

          <div className="row">
            {/* separator line */}
          </div>

          <CartSummaryItemsList />

          <div>
            <p className="text-right text-primary">
              <span>aktuelle Menge: </span>
              {(this.props.currentCart.totalPalettes === 0 || this.props.currentCart.totalPalettes > 1) &&
                <span>
                  {this.props.currentCart.totalPalettes} Paletten
                </span>
              }
              {this.props.currentCart.totalPalettes === 1 &&
                <span>
                  {this.props.currentCart.totalPalettes} Palette
                </span>
              }
              <span> oder {CartPage.getFormattedWeightTonnes(this.props.currentCart.totalWeight)} t</span>
            </p>
          </div>

          {
            <div>
              <hr style={{borderTop: '1px solid rgba(0, 0, 0, 0.1)', marginLeft: '0px', marginRight: '0px'}} />
              <div className="text-right mb-2 text-primary">
                Gesamtpreis (netto): {germanCurrency(this.props.currentCart.totalNetPriceSum)}<br />
              </div>
              <div className="alert alert-info">
                <span className="oi oi-info mr-2" />
                Die Rechnung geht Ihnen auf dem Postweg nach Verarbeitung Ihrer Bestellanforderung und
                Auftragsbestätigung zu. Die nach der Auftragserteilung bei Klick auf "Bestellanforderung versenden"
                versendete Email ist eine Bestätigung Ihrer Bestellanforderung und enthält eine Bestellübersicht. Die
                Bestellübersicht ist nicht die Rechnung und bedarf zum Abschluss unserer Auftragsbestätigung und Ihrer
                Gegenbestätigung bzw. unserer Lieferung.
              </div>
            </div>
          }

          <div className="row" >
            {/* separator line */}
          </div>

          {(!CartValidator.isCartFixed(this.props.currentCart) && !DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour)) &&
            <div>
              {(!DatePicker.isDeliveryConfirmed(this.props.currentCart) && this.props.currentCart.delivery) &&
                <div className="pt-3 alert alert-warning">
                  <span className="oi oi-warning mr-2" />
                  Der aktuelle Liefertermin&nbsp;
                  {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                  ist nicht realisierbar! Bitte aktualisieren sie den&nbsp;
                  <span color="primary" onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>Liefertermin</span>.
                </div>
              }
              {(DatePicker.isDeliveryConfirmed(this.props.currentCart) && this.props.currentCart.delivery) &&
                <div className="pt-3 alert alert-warning">
                  <span className="oi oi-warning mr-2" />
                  Der bestätigte Liefertermin&nbsp;
                  {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                  ist nicht realisierbar! Bitte richten sie sich an unsere Disposition.
                </div>
              }
              {(!DatePicker.isPickupConfirmed(this.props.currentCart) && this.props.currentCart.pickup) &&
                <div className="pt-3 alert alert-warning">
                  <span className="oi oi-warning mr-2" />
                  Der aktuelle Termin zur Abholung&nbsp;
                  {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                  ist nicht realisierbar! Bitte aktualisieren sie den&nbsp;
                  <span color="primary" onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>Abholtermin</span>.
                </div>
              }
              {(DatePicker.isPickupConfirmed(this.props.currentCart) && this.props.currentCart.pickup) &&
                <div className="pt-3 alert alert-warning">
                  <span className="oi oi-warning mr-2" />
                  Der bestätigte Termin zur Abholung&nbsp;
                  {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                  ist nicht realisierbar! Bitte richten sie sich an unsere Disposition.
                </div>
              }
            </div>
          }

          {this.props.currentCart.locked &&
            <div>
              <div className="alert alert-info">
                <span className="oi oi-info mr-2" /> Die Bestellung wird bearbeitet. Sie können auf die Bestellung nach Abschluß der Bearbeitung wieder zugreifen.
              </div>
            </div>
          }

          {!CartValidator.isCartFixed(this.props.currentCart) &&
            <div className="text-center pt-3">
              <div className="text-center">
                <div className="alert alert-info">
                  <span className="oi oi-info mr-2"/>
                  Mit Ihrer Bestellung erklären Sie sich mit unseren <a rel='noreferrer noopener' target="_blank" href="./sievert_agb.pdf">Allgemeinen Geschäftsbedingungen </a> für den Verkauf von
                  Trockenbaustoffen und sonstigen Produkten sowie unseren <a rel='noreferrer noopener' target="_blank" href="./sievert_terms.pdf">Nutzungsbedingungen</a> einverstanden.
                </div>
              </div>
              {(this.isBlockedDeliveryDate()) &&
                <div className="pt-3 alert alert-warning">
                  <span className="oi oi-warning mr-2" />
                  Der aktuelle Liefertermin&nbsp;
                  {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {CartPage.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                  ist nicht realisierbar! Bitte aktualisieren sie den&nbsp;
                  <span color="primary" onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>Liefertermin</span>.
                </div>
              }
              {this.props.currentCart.orderItems &&
                <div>
                  <Button
                    tag="a" href="" color="primary" className="btn-lg"
                    disabled={this.isBlockedDeliveryDate() ||
                    (!DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour))}
                    onClick={(e) => this.createOrder(e)}
                  >
                    <span className="oi oi-check mr-2" /> Bestellanforderung senden
                  </Button>
                </div>
              }
            </div>
          }

          <div className="text-left pt-3">
            <span>* <small><FormattedMessage id="cartsummary.price.info"/></small></span>
          </div>
        </div>

        <Modal isOpen={this.state.orderFailedModal} fade={false} toggle={() => this.showOrderFailedModal()}>
          <ModalHeader toggle={() => this.hideOrderFailedModal()} />
          <ModalBody className="text-center">
            {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'CREATE_ORDER_FAILED') &&
              <div className="alert alert-danger">
                {this.props.currentMessage.msg}
              </div>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.hideOrderFailedModal()}><FormattedMessage id="close" /></Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

// Actions required to provide data for this component to render in sever side.
// ProductsPage.need = [];

CartSummaryPage.propTypes = {
  currentUser: PropTypes.shape(User),
  currentCart: PropTypes.shape(Cart),
  earliestTour: PropTypes.shape(Tour),
  currentOrder: PropTypes.shape(Order),
  currentProject: PropTypes.shape(Project),
  currentMessage: PropTypes.shape(Message),
  blockedDeliveryDays: PropTypes.arrayOf(PropTypes.shape(BlockedDate)),
  customer: PropTypes.shape(Customer),
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    currentCart: getCurrentCart(state),
    earliestTour: getEarliestTour(state),
    currentProject: getCurrentProject(state),
    currentOrder: getCurrentOrder(state),
    currentMessage: getCurrentMessage(state),
    blockedDeliveryDays: loadBlockedDeliveryDays(state),
    customer: getCustomer(state),
    customerWorkers: getCustomerWorkers(state)
  };
}

export default withRouter(connect(mapStateToProps)(CartSummaryPage));
