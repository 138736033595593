import React from "react";
import {FormFeedback, FormGroup, FormText, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Barcoder from 'barcoder';
class InputComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTouched: false,
            invalid: false
        }
        this.minLength = this.minLength.bind(this)
    }
    componentDidMount() {
        if(this.props.blurReq){
            this.handleBlur()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value){
            if(this.props.isValid){
                this.props.isValid(!this.state.invalid)
            }
        }
    }

    handleBlur() {
        this.setState({isTouched: true});
    }

    isValidNumber() {
        const number = this.props.value;
        const numberRegex = /^\d*\.?\d*$/;
        return numberRegex.test(number);
    }

    isValidEmail() {
        const email = this.props.value;
        if (email && email.length < 5) {
            return false
        }
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailLower = String(email).toLowerCase();
        return emailRegex.test(emailLower);
    }

    isValidPhone() {
        const phone = this.props.value;
        if (phone.length < 1) {
            return false;
        }
        const phoneRegex = /^(((((((00|\+)49[ \-/]?)|0)[1-9][0-9]{1,4})[ \-/]?)|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)[ \-/]?))[0-9]{1,7}([ \-/]?[0-9]{1,5})?)$/;
        return phoneRegex.test(phone);
    }

    isValidPassword() {
        return true;
    }
    isValidEan13() {
        const ean = this.props.value;
        return Barcoder.validate(ean)
    }
    minLength(min) {
        return this.props.value ? this.props.value.length >= min : false;
    }

    minValue(min) {
        return this.props.value ? this.props.value >= min : false;
    }

    isValid() {
        const min = this.props.min === undefined ? 3 : this.props.min;
        switch (this.props.type) {
            case 'text':
                return this.minLength(min);
            case 'number':
                return this.isValidNumber() && this.minValue(min);
            case 'email':
                return this.isValidEmail();
            case 'tel':
                return this.isValidPhone();
            case 'password':
                return this.isValidPassword();
            case 'ean13':
                return this.isValidEan13();
            default:
                return true;
        }
    }

    isTouched() {
        return this.state.isTouched;
    }

    render() {
        const maxLength = this.props.maxLength || false;
        const type = this.props.type || "text";
        const placeholder = this.props.placeholder ? this.props.intl.formatMessage({id: this.props.placeholder}) : undefined;
        const min = this.props.type === 'number' ? this.props.min : undefined;
        const step = this.props.type === 'number' ? this.props.step : undefined;
        let required = this.props.required ||false;
        const title = required
            ? this.props.intl.formatMessage({id: this.props.title}) + '*'
            : this.props.intl.formatMessage({id: this.props.title});
        let invalid = false;
        if (required || (!required && this.props.value && this.props.value.length !== 0)) {
            invalid = !this.isValid() && this.isTouched()
            if(this.state.invalid !== invalid)
                this.setState({invalid});
        }

        return (
            <FormGroup>
                {this.props.title && <Label for={this.props.name}>{title}</Label>}
                <Input
                    id={this.props.name}
                    name={this.props.name}
                    type={type}
                    value={this.props.value}
                    onChange={this.props.handleChange}
                    placeholder={placeholder}
                    min={min}
                    step={step}
                    maxLength={maxLength}
                    invalid={invalid}
                    onBlur={this.handleBlur.bind(this)}
                    disabled={this.props.disabled}
                />
                {this.props.formFeedback &&
                <FormFeedback>{this.props.intl.formatMessage({id: this.props.formFeedback})}</FormFeedback>
                }
                {this.props.formText &&
                <FormText>{this.props.intl.formatMessage({id: this.props.formText})}</FormText>
                }
            </FormGroup>
        )
    }
}

InputComponent.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    type: PropTypes.string,
    handleChange: PropTypes.func,
    maxLength: PropTypes.number,
    formFeedback: PropTypes.any,
    min: PropTypes.number
};
export default injectIntl(InputComponent)
