import {LOAD_CUSTOMER_PROJECTS, CLEAR_PROJECTS, LOGOUT} from '../actions/types';

// Initial State
const initialState = {
  stateProjects: null
};

const ProjectsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateProjects: null
      };
    }
    case LOAD_CUSTOMER_PROJECTS: {
      return {
        ...state,
        stateProjects: action.projects
      };
    }
    case CLEAR_PROJECTS: {
      return {
        ...state,
        stateProjects: null
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getUserProjects = (state) => state.projects.stateProjects;
export const getCustomerProjects = (state) => state.projects.stateProjects;

// Export Reducer
export default ProjectsReducer;
