import React, {Component} from 'react';
import CustomerForm from "../../components/Form/CustomerForm";
import BackButton from "../../components/Buttons/BackButton";

class CreateCustomerPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h1 className="h2 text-center mb-4">
                        Händler erstellen
                    </h1>
                    <CustomerForm create/>
                </div>
            </div>
        );
    }
}

CreateCustomerPage.propTypes = {};

export default CreateCustomerPage;
