import {callSecureApi, ContentType, createAPIUrl} from '../../util/apiCaller';
import {isBadRequest, isConflict} from '../../util/http-errors';

import {setCurrentMessage} from '../MessagesAction';

export function uploadProductImage(productNumber, file) {
    if (!productNumber) {
        console.info('Unable to upload product image, productNumber is null');
        return;
    }
    if (!file) {
        console.info('Unable to upload image for product number=' + productNumber + 'file is null');
        return;
    }

    console.info('upload image for product=' + productNumber);
    return (dispatch) => {
        callSecureApi({
            endpoint: 'productservice/v1/product/{productNumber}/asset/{assetType}',
            query: {'productNumber': productNumber, 'assetType': 'IMAGE'},
            method: 'post',
            contentType: ContentType.MULTIPART_FORM_DATA
        }, file)
            .catch(err => {
                console.error('unable upload product image', err);
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Das Produktbild konnte nicht aktualisiert werden. Bitte prüfen Sie, ob es sich um ein PNG-Bild handelt.`,
                        source: 'UPLOAD_PRODUCT_IMG'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function getProductImageUrl(productNumber) {
    if (!productNumber) {
        console.info('skip fetching image from product, productNumber is null');
        return;
    }

    const options = ({
        endpoint: 'productservice/v1/product/{productNumber}/asset/IMAGE',
        query: {'productNumber': productNumber},
        method: 'get',
        contentType: ContentType.IMAGE_PNG
    });

    return createAPIUrl(options);
}
