import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Form} from "reactstrap";
import {FormattedMessage} from "react-intl";
import AddressComponent from "./Parts/AddressComponent";
import InputComponent from "./InputComponent";
import {getWorker} from "../../reducers/WorkerReducer";
import _ from "lodash";
import ContactComponent from "./Parts/ContactComponent";
import {getCustomer} from "../../reducers/CustomerReducer";
import {removeCurrentMessage, createWorker, updateWorker} from "../../actions";
import {notEmpty} from "../../util/ValidFn";
import ModalComponent from "../Modals/ModalComponent";
import {PATH_ADMIN_WORKER_OVERVIEW_PAGE} from "../../routePaths";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {withRouter} from "react-router";

class WorkerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            worker: this.getInitialWorker(),
            modals: {
                create: false,
                edit: false,
                error: false
            }
        }
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setWorker(this.props.worker)

        }
    }

    componentDidUpdate(prevProps) {
        const nextMessage = this.props.currentMessage;
        if (nextMessage !== prevProps.currentMessage) {
            if (nextMessage && (nextMessage.source === 'CHANGE_WORKER' && nextMessage.type === 'INFO')) {
                this.showModal('edit');
            }
            if (nextMessage && (nextMessage.source === 'CREATE_WORKER' && nextMessage.type === 'INFO')) {
                this.showModal('create');
            }
            if (nextMessage && (nextMessage.type === 'ERROR')) {
                this.showModal('error');
            }
        }
    }
    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }
    getInitialWorker() {
        return {
            "accounting": {
                "accountant": 'undefined',
                "bank": undefined,
                "bic": 'undefined',
                "iban": 'undefined',
                "jurisdiction": undefined,
                "registerNumber": undefined,
                "saleTaxId": undefined,
                "taxNumber": undefined
            },
            "address": {
                "additionalInformation": undefined,
                "addressLine1": undefined,
                "addressLine2": undefined,
                "addressLine3": undefined,
                "city": undefined,
                "company": undefined,
                "countryCode": undefined,
                "postalCode": undefined
            },
            "contact": {
                "email": undefined,
                "fax": undefined,
                "mobile": undefined,
                "phone": undefined
            },
            "customerNumbers": [],
            "name": undefined,
            "number": undefined
        }
    }

    handleWorkerData(event) {
        const worker = Object.assign({}, this.state.worker);
        worker[event.target.name] = event.target.value;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.worker = worker;
            return nextState;
        });
    }

    isRequiredNotEmpty() {
        const accountant = this.state.worker.accounting.accountant;
        const bic = this.state.worker.accounting.bic;
        const iban = this.state.worker.accounting.iban;
        const addressLine1 = this.state.worker.address.addressLine1;
        const city = this.state.worker.address.city;
        const postalCode = this.state.worker.address.postalCode;
        const phone = this.state.worker.contact.phone;
        const name = this.state.worker.name;
        const number = this.state.worker.number;

        return notEmpty(accountant) && notEmpty(bic) && notEmpty(iban) && notEmpty(addressLine1) && notEmpty(city)
            && notEmpty(postalCode) && notEmpty(phone) && notEmpty(name) && notEmpty(number)
    }

    isNewFormComplete() {
        return this.isRequiredNotEmpty();
    }

    handleAddress(event) {
        const address = Object.assign({}, this.state.worker.address);
        address[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.worker.address = address;
            return nextState;
        });
    }

    handleContact(event) {
        const contact = Object.assign({}, this.state.worker.contact);
        contact[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.worker.contact = contact;
            return nextState;
        });
    }

    handleAccounting(event) {
        const accounting = Object.assign({}, this.state.worker.accounting);
        accounting[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.worker.accounting = accounting;
            return nextState;
        });
    }

    setWorker(worker) {
        this.setState({worker})
    }

    updateWorker() {
        this.props.dispatch(updateWorker(this.state.worker));
    }

    createWorker() {
        this.props.dispatch(createWorker(this.state.worker))
    }

    render() {
        const worker = this.state.worker;
        const edit = this.props.edit;
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {edit && false &&
                        <Button
                            color="success" href="" className="btn-block"
                            onClick={(e) => this.updateWorker(e)}
                            disabled={true}
                        >
                            <FormattedMessage id="worker.form.button.updateWorker"/>
                        </Button>
                        }
                        {this.props.create &&
                        <Button
                            href=""
                            className="btn-block"
                            onClick={(e) => this.createWorker(e)}
                            disabled={!this.isNewFormComplete()}
                        >
                            <FormattedMessage id="worker.form.button.createWorker"/>
                        </Button>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-start flex-md-row col-12 mt-2">
                    <Form className="col-12 col-md-4">
                        <h2><FormattedMessage id="worker.form.headline.base"/></h2>
                        <InputComponent
                            title={'Firmenname'}
                            type={'text'}
                            name={'name'}
                            value={worker.name}
                            handleChange={this.handleWorkerData.bind(this)}
                            formFeedback={'worker.form.name.formFeedback'}
                            disabled={edit}
                            required
                        />
                        <InputComponent
                            title={'Handwerkernummer'}
                            name={'number'}
                            type={'number'}
                            value={worker.number}
                            handleChange={this.handleWorkerData.bind(this)}
                            formFeedback={'worker.form.number.formFeedback'}
                            disabled={edit}
                            required
                        />
                    </Form>
                    <Form className="col-12 col-md-4">
                        <AddressComponent
                            handler={this.handleAddress.bind(this)}
                            rootValue={worker.address}
                            disabled={edit}
                        />
                    </Form>
                    <Form className="col-12 col-md-4">
                        <h2><FormattedMessage id="worker.form.headline.contact"/></h2>
                        <ContactComponent
                            state={worker.contact}
                            handler={this.handleContact.bind(this)}
                            disabled={edit}
                        />
                    </Form>
                </div>

                <ModalComponent
                    isOpen={this.state.modals.edit}
                    toggle={() => this.showModal('edit')}
                    hide={() => this.hideModal('edit')}
                    color="success"
                    title="Handwerker aktualisiert"
                    message={'Handwerker wurde aktualisiert"'}
                    onSuccess={(e) => {
                        this.switchToPage(e, PATH_ADMIN_WORKER_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    disabled={edit}
                    successText={'Ok'}
                />

                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() => this.showModal('create')}
                    hide={() => this.hideModal('create')}
                    color="success"
                    title="Handwerker erstellt"
                    message={'Handwerker wurde erstellt'}
                    onSuccess={(e) => {
                        this.switchToPage(e, PATH_ADMIN_WORKER_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />
                {this.props.currentMessage && this.props.currentMessage.msg &&
                <ModalComponent
                    isOpen={this.state.modals.error}
                    toggle={() => this.showModal('error')}
                    hide={() => this.hideModal('error')}
                    color="danger"
                    title="Fehler aufgetreten"
                    message={this.props.currentMessage.msg}
                    onSuccess={() => {
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('error')
                    }}
                    successText={'Ok'}
                />
                }
            </div>
        );
    }
}

WorkerForm.propTypes = {};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        worker: getWorker(state),
        currentMessage: getCurrentMessage(state),
    }
}

export default withRouter(connect(mapStateToProps)(WorkerForm));
