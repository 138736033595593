export const PACKAGINGTYPES = {
    PAL: {value:'PAL',label:'packaging.types.pal'},
    SA: {value:'SA',label:'packaging.types.sa'},
    TO: {value:'TO',label:'packaging.types.to'},
    EIM: {value:'EIM',label:'packaging.types.eim'},
    KG: {value:'KG',label:'packaging.types.kg'},
    KGR: {value:'KGR',label:'packaging.types.kgr'},
    PAK: {value:'PAK',label:'packaging.types.pak'},
    ST: {value:'ST',label:'packaging.types.st'},
    KAR: {value:'KAR',label:'packaging.types.kar'},
    KT: {value:'KT',label:'packaging.types.kt'},
    KAN: {value:'KAN',label:'packaging.types.kan'},
    L: {value:'L',label:'packaging.types.l'},
    VPE: {value:'VPE',label:'packaging.types.vpe'},
    BEU: {value:'BEU',label:'packaging.types.beu'},
    BEC: {value:'BEC',label:'packaging.types.bec'},
    LTR: {value:'LTR',label:'packaging.types.ltr'},
    FLA: {value:'FLA',label:'packaging.types.fla'},
    DOS: {value:'DOS',label:'packaging.types.dos'},
    M2: {value:'M2',label:'packaging.types.m2'},
    M3: {value:'M3',label:'packaging.types.m3'},
    SET: {value:'SET',label:'packaging.types.set'},
    M: {value:'M',label:'packaging.types.pal.m'},
    FAS: {value:'FAS',label:'packaging.types.fass'},
    ROL: {value:'ROL',label:'packaging.types.rol'},
    PAA: {value:'PAA',label:'packaging.types.paa'},
}
