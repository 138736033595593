import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Product} from "../../proptypes";

const lang = "DE";

class ProductDetail extends Component {

    static productName(product) {
        if (product.data.formatDefinitions && product.data.formatDefinitions[lang]) {
            return product.data.formatDefinitions[lang]
        }
        if (product.names && product.names[lang]) {
            return product.names[lang];
        }
        return ""
    }
    formatNumber(number){
        const options = {
            minimumFractionDigits: 2, maximumFractionDigits: 2
        };
        return new Intl.NumberFormat('de-DE', options).format(number)
    }
    render() {
        return (
            <div>
                <strong>{ProductDetail.productName(this.props.propProduct)}</strong>
                <br/>
                {this.props.propProduct.data.materialUsageM2 &&
                    <span>
                        <small>Ergiebigkeit: {this.formatNumber(this.props.propProduct.data.materialUsageM2)} m<sup>2</sup></small>
                    </span>
                }
                {this.props.propProduct.data.materialUsageLFM &&
                    <span>
                        <small>Ergiebigkeit: {this.formatNumber(this.props.propProduct.data.materialUsageLFM)} lfm</small>
                    </span>
                }
            </div>
        );
    }
}

ProductDetail.propTypes = {
  propProduct: PropTypes.shape(Product)
};

export default ProductDetail;
