import {callSecureApi} from '../../util/apiCaller';
import {isBadRequest, isConflict, isUnauthorized} from '../../util/http-errors';
import {PATH_NEW_CART_SCHEDULE_PAGE} from "../../routePaths";
import {goToPage} from '../../util/page';

import {
    SET_CURRENT_ORDER,
    LOAD_CUSTOMER_ORDERS,
    REMOVE_CURRENT_ORDER,
    LOAD_STATE,
    LOAD_ORDER_PRODUCTS
} from '../types';
import {logout} from '../AuthentificationActions';
import {setCurrentMessage} from '../MessagesAction';
import {refreshCart, removeCurrentCart, removeEarliestTour} from './Cart';

export function cancelOrder(order, user) {
    return dispatch => {
        if (!order) {
            console.info('skip cancel order, order is null');
            return;
        }

        console.info('cancel order orderId=' + order.orderId);
        callSecureApi({
            endpoint: 'orderservice/v1/order/{orderId}',
            query: {'orderId': order.orderId},
            method: 'delete'
        })
            .then(() => {
                dispatch(loadCustomerOrders(user));
            })
            .catch(err => {
                console.error('unable cancel order', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function deleteOrder(order) {
    return dispatch => {
        if (!order) {
            console.info('skip deleting order, order is null');
            return;
        }
        const orderId = order.orderId;

        console.info('deleting order id=' + orderId);
        callSecureApi({
            endpoint: 'orderservice/v1/order/{orderId}',
            query: {'orderId': orderId},
            method: 'delete'
        })
            .then((res) => {
                dispatch(setCurrentOrder(res));

                const message = {
                    type: 'INFO',
                    msg: `Die Bestellung ${orderId} wurde erfolgreich zur Löschung freigegeben.`,
                    source: 'DELETE_ORDER'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to update order', err);
                const message = {
                    type: 'ERROR',
                    msg: `Die Bestellung ${orderId} konnte nicht zur Löschung freigegeben werden.`,
                    source: 'DELETE_ORDER'
                };
                dispatch(setCurrentMessage(message));
            });
    };
}
export function loadCustomerOrders(user) {
    return dispatch => {
        if (!user) {
            console.info('skip loading customer orders, user is null');
            return;
        }

        console.info('loading customer orders customerId=' + user.customerId);
        callSecureApi({
            endpoint: 'orderservice/v1/order/customer/{customerId}',
            query: {'customerId': user.customerId}
        })
            .then(res => {
                dispatch(setCustomerOrders(res));

            })
            .catch(err => {
                console.error('unable to load customer orders', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function loadCustomerOrdersByCustomer(customer) {
    return dispatch => {
        if (!customer) {
            console.info('skip loading customer orders, customer is null');
            return;
        }

        console.info('loading customer orders customerId=' + customer.id);
        callSecureApi({
            endpoint: 'orderservice/v1/order/customer/{customerId}}',
            query: {'customerId': customer.id}
        })
            .then(res => {
                dispatch(setCustomerOrders(res));
            })
            .catch(err => {
                console.error('unable to load customer orders', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function loadOrders(startUTCMillis, endUTCMillis) {
    return dispatch => {
        if (!startUTCMillis) {
            console.info('skip loading time framed orders, startUTCMillis is null');
            return;
        }

        console.info('loading time framed orders startUTCMillis=' + startUTCMillis + ' endUTCMillis=' + endUTCMillis);
        callSecureApi({
            endpoint: 'orderservice/v1/order/from/{startUTCMillis}/until/{endUTCMillis}}',
            query: {'startUTCMillis': startUTCMillis, 'endUTCMillis': endUTCMillis}
        })
            .then(res => {
                dispatch(setCustomerOrders(res));

            })
            .catch(err => {
                console.error('unable to load time framed orders', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function lockOrder(order, startUTCMillis, endUTCMillis) {
    return dispatch => {
        if (!order) {
            console.info('skip locking order, order is null');
            return;
        }

        console.info('locking order id=' + order.orderId);
        callSecureApi({
            endpoint: 'orderservice/v1/order/{orderId}/lock',
            query: {'orderId': order.orderId},
            method: 'put'
        })
            .then((res) => {
                dispatch(setCurrentOrder(res));
            })
            .then(() => {
                dispatch(loadOrders(startUTCMillis, endUTCMillis));
            })
            .catch(err => {
                console.error('unable to lock order', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err) || isConflict(err)) {
                    const message = {type: 'ERROR', msg: `Die Bestellung ist breits in Bearbeitung.`};
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function unlockOrder(order, startUTCMillis, endUTCMillis) {
    return dispatch => {
        if (!order) {
            console.info('skip unlocking order, order is null');
            return;
        }

        console.info('unlocking order id=' + order.orderId);
        callSecureApi({
            endpoint: 'orderservice/v1/order/{orderId}/lock',
            query: {'orderId': order.orderId},
            method: 'delete'
        })
            .then((res) => {
                dispatch(removeCurrentOrder(res));
                dispatch(removeCurrentCart(res));
                dispatch(removeEarliestTour());
            })
            .then(() => {
                dispatch(loadOrders(startUTCMillis, endUTCMillis));
            })
            .catch(err => {
                console.error('unable to unlock order', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function setOrderShippingNumber(order, orderNumber, shippingNumber, startUTCMillis, endUTCMillis) {
    return dispatch => {
        let body;
        if (orderNumber === undefined && shippingNumber === undefined) {
            body = undefined;
        } else {
            body = {orderNumber: orderNumber, shippingNumber: shippingNumber};
        }

        updateOrder(order, body)
            .then(() => {
                dispatch(unlockOrder(order, startUTCMillis, endUTCMillis));
            });
    };
}

function updateOrder(order, body) {
    if (!order || body === undefined) {
        console.info('skip updating order id=' + (order ? order.orderId : 'null'));
        return Promise.resolve('skip updating the order');
    }

    console.info('updating order id=' + order.orderId + ' body=' + JSON.stringify(body));
    return callSecureApi({
        endpoint: 'orderservice/v1/order/{orderId}',
        query: {'orderId': order.orderId},
        method: 'put'
    }, body)
        .catch(err => {
            console.error('unable to update order', err);
            return Promise.reject('unable to update order');
        });
}
export function setCurrentOrder(order) {
    return {
        type: SET_CURRENT_ORDER,
        order
    };
}

export function setCustomerOrders(orders) {
    return {
        type: LOAD_CUSTOMER_ORDERS,
        orders
    };
}

export function loadOrder(orderId) {
    return dispatch => {
        if (!orderId) {
            console.info('skip loading order by id, orderId is null');
            return;
        }

        console.info('loading order by orderId=' + orderId);
        callSecureApi({
            endpoint: 'orderservice/v1/order/{orderId}',
            query: {'orderId': orderId}
        })
            .then(res => {
                dispatch(setCurrentOrder(res));
            })
            .catch(err => {
                console.error('unable to load order', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function createOrder(cart) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            if (!cart) {
                console.info('skip creating order from cart, cart is null');
                return;
            }

            const cartId = cart.cartId;
            console.info('creating order from cartId=' + cartId);

            callSecureApi({
                endpoint: 'orderservice/v1/order/{cartId}',
                query: {'cartId': cartId},
                method: 'post'
            })
                .then(res => {
                    dispatch(removeCurrentCart(cart));
                    dispatch(removeEarliestTour());
                    dispatch(setCurrentOrder(res));
                    resolve();
                })
                .catch(err => {
                    console.error('unable to create order from cart', err);
                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                    if (isBadRequest(err)) {
                        let message;
                        if (err.stock && err.product) {
                            message = {
                                type: 'ERROR',
                                msg: `Die Bestellung konnte nicht durchgefüht werden. Der Art-Nr. ${err.product} übersteigt die aktuell maximale Bestellmenge von ${err.stock}.`,
                                source: 'CREATE_ORDER_FAILED'
                            };
                        } else {
                            message = {
                                type: 'ERROR',
                                msg: `Die Bestellung konnte nicht durchgefüht werden.`,
                                source: 'CREATE_ORDER_FAILED'
                            };
                        }
                        dispatch(setCurrentMessage(message));
                    }
                    if (isConflict(err)) {
                        const message = {
                            type: 'ERROR',
                            msg: `Die Bestellung konnte nicht durchgefüht werden. Bitte wählen Sie einen anderen Liefertermin.`,
                            source: 'CREATE_ORDER_FAILED'
                        };
                        dispatch(setCurrentMessage(message));
                        goToPage(PATH_NEW_CART_SCHEDULE_PAGE);
                    }
                    dispatch(refreshCart(cartId));
                    reject();
                });
        })
    };
}
export function removeCurrentOrder(order) {
    console.info('removing current order');
    return {
        type: REMOVE_CURRENT_ORDER,
        order
    };
}
export function setState(state) {
    return {
        type: LOAD_STATE,
        state
    };
}



export function setOrderProducts(products) {
    return {
        type: LOAD_ORDER_PRODUCTS,
        products
    };
}
