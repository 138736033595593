import {callSecureApi} from '../util/apiCaller';
import {isAlreadyExists, isBadRequest, isUnauthorized} from '../util/http-errors';
import {isEmpty} from '../util/object';

import {
    LOAD_CUSTOMER_PROJECTS,
    SET_CURRENT_PROJECT
} from './types';
import {logout} from './AuthentificationActions';
import {setCurrentMessage} from './MessagesAction';

export function loadCustomerProjects(customerId) {
    return dispatch => {
        if (!customerId) {
            console.info('skip loading customer projects, customer number is null');
            return;
        }

        console.info('loading projects from customer number=' + customerId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/customer/{customerId}',
            query: {'customerId': customerId}
        })
            .then(res => {
                dispatch(setCustomerProjects(res.projects));
            })
            .catch(err => {
                console.error('unable to load customer projects', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function createProjectWithOffer(project, customer, worker, authority,offer) {
    return dispatch => {
        const customerId = customer.id;

        console.info('creating project for customer number=' + customerId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/customer/{customerId}/offer/{offerNumber}',
            query: {'customerId': customerId,offerNumber:offer.number},
            method: 'post'
        }, project)
            .then(res => {
                if (!isEmpty(worker)) {
                    const projectId = res.id;
                    const workerNumber = worker.number;
                    dispatch(attachWorkerToProject(workerNumber, projectId));
                }
                if (!isEmpty(authority)) {
                    dispatch(attachAuthority(res, authority));
                }
                dispatch(setCurrentProject(res));
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${project.name} wurde erfolgreich angelegt.`,
                    source: 'CREATE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to create project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben.`,
                        source: 'PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Projekt Nummer oder der Name existiert bereits.`,
                        source: 'PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}
export function createProject(project, customer, worker, authority) {
    return dispatch => {
        const customerId = customer.id;

        console.info('creating project for customer number=' + customerId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'post'
        }, project)
            .then(res => {
                if (!isEmpty(worker)) {
                    const projectId = res.id;
                    const workerNumber = worker.number;
                    dispatch(attachWorkerToProject(workerNumber, projectId));
                }
                if (!isEmpty(authority)) {
                    dispatch(attachAuthority(res, authority));
                }
                dispatch(setCurrentProject(res));
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${project.name} wurde erfolgreich angelegt.`,
                    source: 'CREATE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to create project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben.`,
                        source: 'PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Projekt Nummer oder der Name existiert bereits.`,
                        source: 'PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function createCustomerUserProject(project, customer, worker, authority, userId) {
    return dispatch => {
        const customerId = customer.id;

        console.info('creating project for customer number=' + customerId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/customer/{customerId}/{userId}',
            query: {'customerId': customerId, 'userId': userId},
            method: 'post'
        }, project)
            .then(res => {
                if (!isEmpty(worker)) {
                    const projectId = res.id;
                    const workerNumber = worker.number;
                    dispatch(attachWorkerToProject(workerNumber, projectId));
                }
                if (!isEmpty(authority)) {
                    dispatch(attachAuthority(res, authority));
                }
                dispatch(setCurrentProject(res));
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${project.name} wurde erfolgreich angelegt.`,
                    source: 'CREATE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to create project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben.`,
                        source: 'PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Projekt Nummer oder der Name existiert bereits.`,
                        source: 'PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function updateCustomerUserProject(project, worker, initialWorker, authority, initialAuthority, userId) {
    return dispatch => {
        const projectId = project.id;
        const customerId = project.customerId;

        console.info('updating project id=' + projectId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}/{userId}',
            query: {'projectId': projectId, 'userId': userId},
            method: 'put'
        }, project)
            .then(res => {
                if ((isEmpty(initialWorker) && !isEmpty(worker))
                    || (!isEmpty(initialWorker) && !isEmpty(worker) && (initialWorker.number !== worker.number))) {
                    dispatch(attachWorkerToProject(worker.number,projectId));
                } else if (!isEmpty(initialWorker) && !isEmpty(worker)) {
                    dispatch(detachWorkerFromProject(worker.number, projectId));
                } else if ((isEmpty(initialAuthority) && !isEmpty(authority))
                    || (!isEmpty(initialAuthority) && !isEmpty(authority) && (initialAuthority.id !== authority.id))) {
                    dispatch(attachAuthority(res, authority));
                } else if (!isEmpty(initialAuthority) && isEmpty(authority)) {
                    dispatch(detachAuthority(res));
                } else {
                    dispatch(setCurrentProject(res));
                }
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${project.name} wurde erfolgreich aktualisiert.`,
                    source: 'CHANGE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to update project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben.`,
                        source: 'CHANGE_PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein Objekt mit diesen Daten exisitiert bereits.`,
                        source: 'CHANGE_PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function deleteCustomerUserProject(project, userId) {
    return dispatch => {
        const projectId = project.id;
        const customerId = project.customerId;
        const projectName = project.name;

        console.info('deleting project id=' + projectId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}/{userId}',
            query: {'projectId': projectId, 'userId': userId},
            method: 'delete'
        })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${projectName} wurde erfolgreich gelöscht.`,
                    source: 'DELETE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to delete project ', err);
                const message = {
                    type: 'ERROR',
                    msg: `Das Objekt konnte nicht gelöscht werden.`,
                    source: 'DELETE_PROJECT'
                };
                dispatch(setCurrentMessage(message));

                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function loadCurrentProject(project) {
    if(!project){
        console.error('cannot load curren project no parameter project')
        return
    }
    return dispatch => {
        if (!project) {
            console.info('skip loading current project, project is null');
            return;
        }

        const projectId = project.id;

        console.info('loading current project id=' + projectId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}',
            query: {'projectId': projectId}
        })
            .then(res => {
                dispatch(setCurrentProject(res));

            })
            .catch(err => {
                console.error('unable to load customer project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function attachAuthority(project, authority) {
    return dispatch => {
        const projectId = project.id;
        const userId = authority.id;

        console.info('attach authority user= ' + userId + ' to project');
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}/authority/{userId}',
            query: {'projectId': projectId, 'userId': userId},
            method: 'put'
        })
            .then(res => {
                dispatch(setCurrentProject(res));
            })
            .catch(err => {
                console.error('unable to attach authority user to project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function updateProject(project, worker, initialWorker, authority, initialAuthority) {
    return dispatch => {
        const projectId = project.id;
        const customerId = project.customerId;

        console.info('updating project id=' + projectId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}',
            query: {'projectId': projectId},
            method: 'put'
        }, project)
            .then(res => {
                if ((isEmpty(initialWorker) && !isEmpty(worker))
                    || (!isEmpty(initialWorker) && !isEmpty(worker) && (initialWorker.number !== worker.number))) {
                    dispatch(attachWorkerToProject(worker.number,projectId));
                } else if (!isEmpty(initialWorker) && !isEmpty(worker)) {
                    dispatch(detachWorkerFromProject(worker.number, projectId));
                } else if ((isEmpty(initialAuthority) && !isEmpty(authority))
                    || (!isEmpty(initialAuthority) && !isEmpty(authority) && (initialAuthority.id !== authority.id))) {
                    dispatch(attachAuthority(res, authority));
                } else if (!isEmpty(initialAuthority) && isEmpty(authority)) {
                    dispatch(detachAuthority(res));
                } else {
                    dispatch(setCurrentProject(res));
                }
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${project.name} wurde erfolgreich aktualisiert.`,
                    source: 'CHANGE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to update project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben.`,
                        source: 'CHANGE_PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein Objekt mit diesen Daten exisitiert bereits.`,
                        source: 'CHANGE_PROJECT'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function detachAuthority(project) {
    return dispatch => {
        const projectId = project.id;

        console.info('detach authority user from project');
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}/authority',
            query: {'projectId': projectId},
            method: 'delete'
        })
            .then(res => {
                dispatch(setCurrentProject(res));
            })
            .catch(err => {
                console.error('unable to detach authority user from project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function deleteProject(project) {
    return dispatch => {
        const projectId = project.id;
        const customerId = project.customerId;
        const projectName = project.name;

        console.info('deleting project id=' + projectId);
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}',
            query: {'projectId': projectId},
            method: 'delete'
        })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Das Objekt ${projectName} wurde erfolgreich gelöscht.`,
                    source: 'DELETE_PROJECT'
                };
                dispatch(setCurrentMessage(message));
                dispatch(loadCustomerProjects(customerId));
            })
            .catch(err => {
                console.error('unable to delete project ', err);
                const message = {
                    type: 'ERROR',
                    msg: `Das Objekt konnte nicht gelöscht werden.`,
                    source: 'DELETE_PROJECT'
                };
                dispatch(setCurrentMessage(message));

                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function attachCustomerUsersToProject(userIds, customer, project) {
    return dispatch => {
        const projectId = project.id;

        console.info('attach users=' + userIds + ' to project');
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}/user',
            query: {'projectId': projectId},
            method: 'put'
        }, {userIds})
            .then(res => {
                dispatch(setCurrentProject(res));
            })
            .catch(err => {
                console.error('unable to attach users to project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function detachCustomerUsersFromProject(userIds, customer, project) {
    return dispatch => {
        const projectId = project.id;

        console.info('detach users= ' + userIds + ' from project');
        callSecureApi({
            endpoint: 'customerservice/v1/project/{projectId}/user',
            query: {'projectId': projectId},
            method: 'delete'
        }, {userIds})
            .then(res => {
                dispatch(setCurrentProject(res));
            })
            .catch(err => {
                console.error('unable to detach users from project', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}
export function attachWorkerToProject(workerNumber, projectId) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/project/{projectId}/worker/{workerNumber}',
                query: {'workerNumber': workerNumber, 'projectId': projectId},
                method: 'put'
            }
        ).then(res => {
            console.log("attached worker to project",res)
            dispatch(loadCurrentProject({id:projectId}))
        })
            .catch(err => {
                console.error("Unable to attach worker to project", err)
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            })
    }
}

export function detachWorkerFromProject(workerNumber, projectId) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/project/{projectId}/worker/{workerNumber}',
                query: {'workerNumber': workerNumber, 'projectId': projectId},
                method: 'delete'
            }
        ).then(res => {
            dispatch(loadCurrentProject({id:projectId}))
            console.log("attached worker to project",res)
        })
            .catch(err => {
                console.error("Unable to detach worker from project", err)
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            })
    }
}

function setCustomerProjects(projects) {
    return {
        type: LOAD_CUSTOMER_PROJECTS,
        projects
    };
}

export function setCurrentProject(project) {
    return {
        type: SET_CURRENT_PROJECT,
        project
    };
}

