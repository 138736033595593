import React, {Component} from 'react';
import InputComponent from "../InputComponent";

class ContactComponent extends Component {
    constructor(props){
        super(props)

        this.state={
            isValid: [],
            validPhone: true
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.state !== this.props.state && this.props.isValid){
            this.props.isValid((this.state.validPhone))
        }
    }

    generateValidator(){
        const fn = function(callback){
            return callback
        };
        let isValid = this.state.isValid;
        isValid.push(fn)
        this.setState({isValid})
        return fn
    }
    isValidPhone(cb){
        this.setState({validPhone:cb})
    }
    checkValid(){
        console.log(this.state.isValid)
        this.state.isValid.forEach(f=>{
            console.log(f);
        })
    }

    render() {
        const state = this.props.state;
        const handler = this.props.handler;
        const emailRequired = this.props.emailRequired;
        const disabled = this.props.disabled;
        return (
            <React.Fragment>
                {!emailRequired &&
                <InputComponent
                    name={'email'}
                    type={'email'}
                    title={'contact.form.email.title'}
                    value={state.email}
                    disabled={disabled}
                    handleChange={handler.bind(this)}
                />
                }
                {emailRequired &&
                <InputComponent
                    name={'email'}
                    type={'email'}
                    title={'contact.form.email.title'}
                    value={state.email}
                    handleChange={handler.bind(this)}
                    disabled={disabled}
                    required
                    formFeedback={"Bitte geben Sie eine gültige Email-Adresse an!"}
                />
                }
                <InputComponent
                    name={'fax'}
                    type={'fax'}
                    title={'contact.form.fax.title'}
                    disabled={disabled}
                    value={state.fax}
                    handleChange={handler.bind(this)}
                />
                <InputComponent
                    name={'mobile'}
                    type={'mobile'}
                    title={'contact.form.mobile.title'}
                    disabled={disabled}
                    value={state.mobile}
                    handleChange={handler.bind(this)}
                />
                <InputComponent
                    name={'phone'}
                    type={'tel'}
                    title={'contact.form.phone.title'}
                    value={state.phone || ""}
                    handleChange={handler.bind(this)}
                    disabled={disabled}
                    required
                    formFeedback={'contact.form.phone.formFeedBack'}
                    isValid={this.isValidPhone.bind(this)}
                />

            </React.Fragment>
        );
    }
}

ContactComponent.propTypes = {};

export default ContactComponent;
