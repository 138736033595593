import React, {Component} from 'react';
import PropTypes from 'prop-types';

import FullAddress from '../Address/FullAddress';
import {Worker} from "../../proptypes";

class WorkerAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <p>
        <strong>Fachhandwerker:</strong>

        {this.props.worker &&
          <span>
            <FullAddress address={this.props.worker.address} />
          </span>
        }
      </p>
    );
  }
}


WorkerAddress.propTypes = {
  worker: PropTypes.shape(Worker),
};

export default WorkerAddress;
