import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {loadBlockedDeliveryDays} from "../../reducers/OrderReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCurrentProject} from "../../reducers/ProjectReducer";
import {getEarliestTour} from "../../reducers/TourReducer";
import {getCurrentCart} from "../../reducers/CartReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {BlockedDate, Cart, Message, Project, Tour, User} from "../../proptypes";
import {PATH_NEW_CART_PAGE, PATH_NEW_CART_SCHEDULE_PAGE, PATH_PRODUCTS_PAGE} from "../../routePaths";
import DatePicker from "../../components/Schedule/DatePicker";
import {fetchBlockedDeliveryTours, loadEarliestTourForCart, removeCurrentMessage, loadCurrentProject} from "../../actions";

class NewCartSchedulePage extends Component {
    static getTour(cart) {
        let tour = DatePicker.getConfirmedTour(cart);
        if (!tour) {
            tour = DatePicker.getTour(cart);
        }
        return tour;
    }

    static getFormattedTimeLabel(cart) {
        const tour = NewCartSchedulePage.getTour(cart);
        return DatePicker.getHourLabel(tour);
    }

    constructor(...props) {
        super(...props);

        this.switchToPage = this.switchToPage.bind(this);
        this.showOrderFailedModal = this.showOrderFailedModal.bind(this);
        this.hideOrderFailedModal = this.hideOrderFailedModal.bind(this);
        this.isBlockedDeliveryDate = this.isBlockedDeliveryDate.bind(this);

        this.state = {
            deliveryActive: true
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const cart = this.props.currentCart;
        if (cart) {
            this.props.dispatch(loadEarliestTourForCart(cart.cartId));
            this.props.dispatch(fetchBlockedDeliveryTours());
        }
        const project = this.props.currentProject
        if (!project && cart) {
            this.props.dispatch(loadCurrentProject({id: cart.projectId}))
        }
    }

    componentWillReceiveProps(nextProps) {
        const nextMessage = nextProps.currentMessage;
        if (nextMessage && nextMessage.source === 'CREATE_ORDER_FAILED') {
            this.showOrderFailedModal();
        }
    }

    showOrderFailedModal() {
        this.setState({
            orderFailedModal: true
        });
    }

    hideOrderFailedModal() {
        this.setState({
            orderFailedModal: false
        });

        this.props.dispatch(removeCurrentMessage());
    }

    isBlockedDeliveryDate() {
        let found = false;

        const cart = this.props.currentCart;
        if (cart.delivery && !DatePicker.isDeliveryConfirmed(cart)) {
            const tour = NewCartSchedulePage.getTour(cart);
            if (tour) {
                const date = new Date(tour.fromDate);

                if (date) {
                    const blockedDeliveryDays = this.props.blockedDeliveryDays;
                    if (blockedDeliveryDays) {
                        const normalizedDate = date.setHours(0);

                        blockedDeliveryDays.forEach(blockedDeliveryDate => {
                            const normalizedBlockedDate = blockedDeliveryDate.date.setHours(0);
                            if (!found && normalizedBlockedDate === normalizedDate) {
                                found = true;
                            }
                        });
                    }
                }
            }
        }
        return found;
    }

    switchToPage(e, pathToPage) {
        e.preventDefault();
        this.props.history.push(pathToPage);
    }

    render() {
        const beforeRender = this.props.currentProject && this.props.currentCart;
        return (

            <div className="page-content">
                {beforeRender &&
                <div className="container">
                    <div className="step-navigation d-flex flex-column flex-md-row text-center">
                        <div className="col-12 col-md-4 disabled">
              <span onClick={(e) => this.switchToPage(e, PATH_NEW_CART_PAGE)}>
                <span className="oi oi-arrow-circle-left mr-2"/> zurück zur Versandauswahl
              </span>
                        </div>
                        <div className="col-12 col-md-4 active">
              <span onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>
                  Wunschterminauswahl
              </span>
                        </div>
                        <div className="col-md-4 col-12 disabled">
              <span onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>
                  zur Produktauswahl <span className="oi oi-arrow-circle-right mr-2"/>
              </span>
                        </div>
                    </div>

                    <span className="pt-2">
            <h6 className="text-muted text-center m-3">{this.props.currentProject.name}, {this.props.currentProject.address.postalCode} {this.props.currentProject.address.city}</h6>
          </span>

                    <h1 className="h2 text-center mb-4">
                        <span className="d-none d-sm-inline">Schritt</span> <span>2: Wunschterminauswahl</span>
                    </h1>

                    {this.props.currentCart.delivery &&
                    <p className="text-center">Bitte wählen Sie einen Termin für die Lieferung:</p>
                    }
                    {this.props.currentCart.pickup &&
                    <p className="text-center">Bitte wählen Sie einen Termin für die Abholung:</p>
                    }

                    <div>
                        <DatePicker/>

                        <div className="row border-0">
                            <div className="col">
                                <div className="alert alert-info">
                                    <span className="oi oi-info mr-2"/> Festgelegte Liefer- bzw. Abholtermine können
                                    erst nach
                                    Auftragsbestätigung zugesagt werden.
                                </div>

                                {(this.isBlockedDeliveryDate()) &&
                                <div className="alert alert-warning">
                                    <span className="oi oi-warning mr-2"/>
                                    Der aktuelle Liefertermin&nbsp;
                                    {DatePicker.getFormattedDate(DatePicker.getTourFromDate(this.props.currentCart))}, {DatePicker.getFormattedTimeLabel(this.props.currentCart)}&nbsp;
                                    ist nicht mehr realisierbar. Bitte wählen sie ein Datum.
                                </div>
                                }
                            </div>
                        </div>

                        <div className="row mt-4">
                            {this.props.currentCart.delivery &&
                            <div className="col-md-6 mb-3 order-2 order-md-1">
                                <Button tag="a" color="secondary" className="btn-block" href=""
                                        onClick={(e) => this.switchToPage(e, PATH_NEW_CART_PAGE)}>
                                    <span className="oi oi-chevron-left mr-2"/>
                                    zur Versandauswahl
                                </Button>
                            </div>
                            }
                            {this.props.currentCart.pickup &&
                            <div className="col-md-6 mb-3 order-2 order-md-1">
                                <Button tag="a" color="secondary" className="btn-block" href=""
                                        onClick={(e) => this.switchToPage(e, PATH_NEW_CART_PAGE)}>
                                    <span className="oi oi-chevron-left mr-2"/>
                                    zur Versandauswahl
                                </Button>
                            </div>
                            }
                            <div className="col-md-6 mb-3 order-1 order-md-2">
                                <Button
                                    tag="a" color="primary" className="btn-block"
                                    disabled={(this.isBlockedDeliveryDate() ||
                                        (!DatePicker.hasValidScheduleDate(this.props.currentCart, this.props.earliestTour) &&
                                            !(DatePicker.isDeliveryConfirmed(this.props.currentCart) || DatePicker.isPickupConfirmed(this.props.currentCart))))
                                    }
                                    onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}
                                >
                                    zur Produkt&shy;auswahl
                                    <span className="oi oi-chevron-right ml-2"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <Modal isOpen={this.state.orderFailedModal} fade={false} toggle={() => this.showOrderFailedModal()}>
                    <ModalHeader toggle={() => this.hideOrderFailedModal()}/>
                    <ModalBody className="text-center">
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'CREATE_ORDER_FAILED') &&
                        <div className="alert alert-danger">
                            {this.props.currentMessage.msg}
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.hideOrderFailedModal()}>Schließen</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

// Actions required to provide data for this component to render in sever side.
// HomePage.need = [];

NewCartSchedulePage.propTypes = {
    currentCart: PropTypes.shape(Cart),
    earliestTour: PropTypes.shape(Tour),
    currentProject: PropTypes.shape(Project),
    currentUser: PropTypes.shape(User),
    dispatch: PropTypes.func.isRequired,
    currentMessage: PropTypes.shape(Message),
    blockedDeliveryDays: PropTypes.arrayOf(PropTypes.shape(BlockedDate)),
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        currentCart: getCurrentCart(state),
        earliestTour: getEarliestTour(state),
        currentProject: getCurrentProject(state),
        currentMessage: getCurrentMessage(state),
        blockedDeliveryDays: loadBlockedDeliveryDays(state)
    };
}

export default withRouter(connect(mapStateToProps)(NewCartSchedulePage));
