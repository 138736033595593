import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Cart, Category, Project, User} from "../../proptypes";
import {
    PATH_CART_SUMMARY_PAGE,
    PATH_HOME_PAGE,
    PATH_NEW_CART_PAGE,
    PATH_NEW_CART_SCHEDULE_PAGE,
    PATH_PRODUCTS_PAGE,
} from "../../routePaths";
import CartValidator from "../../util/cartValidation";
import {getCategories} from "../../reducers/CategoryReducer";
import {getCurrentProject} from "../../reducers/ProjectReducer";
import {getCurrentCart} from "../../reducers/CartReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import CategoryList from "../../components/Product/CategoryList";
import ProductList from "../../components/Product/ProductList";
import {getAllProducts, getProductGroups} from "../../reducers/ProductsReducer";
import SearchBar from "../../components/SearchBar";
import {Alert, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import ScrollToTopComponent from "../../components/Utils/ScrollToTopComponent";
import {FormattedMessage} from "react-intl";
import {
    fetchProductsFromCategory,
    loadProductGroupsSearchWithCart, loadFilteredRootCategoryTree,
    setProductGroups,
    setSearchResultProducts,
    fetchOffer, loadCustomerProductGroups, setCurrentMessage
} from "../../actions";
import {getOffer} from "../../reducers/OffersReducer";
import {CartHeadline} from "../../components/Cart/CartHeadline";
import BrandBar from "../../components/Product/BrandBar";
import classnames from "classnames";
import {getCustomerProductGroups} from "../../reducers/ProductReducer";
import Toastie from "../../components/Toastie";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import SortComponent from "../../components/SortComponent";
import _ from "lodash";

class ProductsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: null,
            brands: null,
            category: null,
            categories: null,
            subCategory: null,
            subCategories: null,
            filteredProducts: undefined,
            products: [],
            searchTerm: "",
            activeTab: '0',
            sortDirection: 'ASC',
            sortOption: {label: 'Name', value: 'NAME'},
        };
        this.options=[
            {label: "Name", value: "NAME"},
            {label: "Art.-Nr", value: "NUMBER"}
        ];
        this.value="";
        this.directionValue=undefined;

        this.brandCollapse = React.createRef();
        this.categoryCollapse = React.createRef();
        this.subCategoryCollapse = React.createRef();
    }

    async componentDidMount() {
        this.props.dispatch(setProductGroups(undefined))
        await this.props.dispatch(loadFilteredRootCategoryTree(this.props.currentUser.customerId))
        this.props.dispatch(loadCustomerProductGroups(this.props.currentUser.customerId))
        if(this.props.brands && this.props.brands.stateCategories)
            this.handleBrandFilter(this.props.brands.stateCategories[0]);
        const currentCart = this.props.currentCart;
        if(currentCart && currentCart.offerNumber){
            this.props.dispatch(fetchOffer(currentCart.offerNumber))
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState) {
        let prevBrandState = prevState.brand;
        let brandState = this.state.brand;
        const currentCart = this.props.currentCart;
        if (prevBrandState !== brandState) {
            if (brandState && brandState.number) {
                this.clearProductGroups();
                this.setCategories(brandState)
                this.props.dispatch(fetchProductsFromCategory(brandState.number,currentCart))
            }
        }
        let prevCategoryState = prevState.category;
        let categoryState = this.state.category;
        if (prevCategoryState !== categoryState) {
            if (this.state.category) {
                if (categoryState.number) {
                    this.clearProductGroups();
                    this.setSubCategories(categoryState)
                    this.props.dispatch(fetchProductsFromCategory(categoryState.number,currentCart))
                }
            }
        }
        const subCategory = this.state.subCategory;
        if (prevState.subCategory !== subCategory) {
            if (subCategory.number) {
                this.clearProductGroups();
                this.props.dispatch(fetchProductsFromCategory(subCategory.number,currentCart))
            }
        }
        if(prevState.productGroups !== this.props.productGroups){
            window.scrollTo(0,0)
        }
    }

    clearProductGroups() {
        this.props.dispatch(setProductGroups(undefined))
    }

    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);
    }

    setCategories(brand) {
        const categories = brand.children;
        this.setState({categories: categories})
    }

    setSubCategories(category) {
        const subCategories = category.children;
        this.setState({subCategories: subCategories})
    }

    setBrand(brand) {
        this.setState({brand})
    }

    setCategory(category) {
        this.setState({category})
    }

    setSubCategory(subCategory) {
        this.setState({subCategory})
    }

    handleBrandFilter(brand) {
        this.setState({
            brand: brand,
            category: {},
            categories: [],
            subCategory: {},
            subCategories: []
        })
        window.scrollTo(0, 0);
        this.resetSearch();
    }
    closeCollapse(){
        this.categoryCollapse.current.close()
        this.subCategoryCollapse.current.close()
    }
    loadProductGroupSearch(event) {
        if(event)
            event.preventDefault();
        const search = this.state.searchTerm;
        this.props.dispatch(loadProductGroupsSearchWithCart(search,true,this.props.currentCart));
        this.setState({search:true})
        this.closeCollapse()
        return false
    }

    handleSearchInput(event) {
        const search = event.target.value;
        this.setState({
            searchTerm: search
        });
    }

    resetSearch() {
        this.setState({searchTerm: ""})
        this.props.dispatch(setSearchResultProducts(undefined))
        this.setState({search:false})
    }
    async handleSaleClick(){
          await this.setState({searchTerm:this.props.currentCart.offerNumber})
          await this.setState({brand:undefined})
            this.loadProductGroupSearch()
    }
    setActiveTab(tab) {
        this.setState({activeTab:tab})
    }
    toggle (tab) {
        if(this.state.activeTab !== tab) this.setActiveTab(tab);
    }
    onSortChange(option) {
        if (option) {
            this.setState({sortOption: option})
        }
    }
    sortProducts(options) {
        let sortedOptions;
        let productGroups = options.productGroups;
        switch (this.state.sortOption.value ) {
            case "NAME":
                sortedOptions = _.sortBy(productGroups, [function(o) {
                    return o.products[0].names["DE"]
                }])
                break;
            case "NUMBER":
                sortedOptions = _.sortBy(productGroups, [function (o) {
                    return o.products[0].number;
                }])
                break;
            default:
                sortedOptions = []
                break;
        }

        if (this.state.sortDirection === 'DESC')
            return sortedOptions.reverse();
        else
            return sortedOptions;
    }
    directionHandler() {
        const sortDirection = this.state.sortDirection;
        if (sortDirection === "ASC")
            this.setState({sortDirection: "DESC"})
        else
            this.setState({sortDirection: "ASC"})
    }

    render() {
        if (!this.props.currentUser) {
            return this.switchToPage(null, PATH_HOME_PAGE);
        }

        const role = this.props.currentUser.role;
        if (!role) {
            return this.switchToPage(null, PATH_HOME_PAGE);
        }

        if (!this.props.currentCart) {
            return <div className="page-content text-center"><span>Bitte auf Warenkorb warten ...</span></div>;
        }

        if (CartValidator.isCartFixed(this.props.currentCart)) {
            return this.switchToPage(null, PATH_CART_SUMMARY_PAGE);
        }

        if (!this.props.currentProject) {
            return <div className="page-content text-center"><span>Bitte auf Objekt warten ...</span></div>;
        }

        const brands = this.props.brands || {stateCategories: []};
        const brandChildren = brands.stateCategories|| [];
        const categories = this.state.categories || [];
        const subCategories = this.state.subCategories || [];
        const productGroups = this.props.productGroups;
        const offerNumber = this.props.currentCart.offerNumber;
        const propProductGroups = {productGroups:this.sortProducts(this.props.customerProductGroups || {})};
        return (
            <div className="page-content">
                <div className="container">
                    <div className="step-navigation d-flex flex-column flex-md-row text-center">
                        <div className="col-12 col-md-4">
                          <span onClick={(e) => this.switchToPage(e, PATH_NEW_CART_PAGE)}>
                            <span className="oi oi-arrow-circle-left mr-2"/> zurück zur Versandauswahl
                          </span>
                        </div>
                        <div className="col-12 col-md-4 disabled">
                            <span onClick={(e) => this.switchToPage(e, PATH_NEW_CART_SCHEDULE_PAGE)}>
                                <span className="oi oi-arrow-circle-left mr-2"/> zurück zur Wunschterminauswahl
                            </span>
                        </div>
                        <div className="col-12 col-md-4 active">
                            <span onClick={(e) => this.switchToPage(e, PATH_PRODUCTS_PAGE)}>
                                Produktauswahl
                            </span>
                        </div>
                    </div>

                    <span className="pt-2">
                        <h6 className="text-muted text-center m-3">{this.props.currentProject.name}, {this.props.currentProject.address.postalCode} {this.props.currentProject.address.city}</h6>
                    </span>

                    <CartHeadline headline={"productpage.headline"} offerNumber={offerNumber}/>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames("cursor-pointer",{active: this.state.activeTab === '0'})}
                                onClick={() => {
                                    this.toggle('0');
                                    this.resetSearch()
                                    this.handleBrandFilter(this.props.brands.stateCategories[0]);
                                }}
                            >
                                Produkte
                            </NavLink>
                        </NavItem>
                        {this.props.currentCart.offerNumber && <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === '1'})}
                                onClick={() => {
                                    this.toggle('1');
                                    this.handleSaleClick()
                                }}
                            >
                                Angebot
                            </NavLink>
                        </NavItem>
                        }
                        {this.props.currentUser.role !== 'CUSTOMER_USER' &&
                            <NavItem>
                                <NavLink
                                    className={classnames("cursor-pointer", {active: this.state.activeTab === '2'})}
                                    onClick={() => {
                                        this.toggle('2');
                                    }}
                                >
                                    Favoriten
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="0">
                            {this.getProductList(offerNumber, brandChildren, categories, subCategories, productGroups)}
                        </TabPane>
                        <TabPane tabId="1">
                            <ProductList
                                search={this.state.search}
                                propProductGroups={productGroups}
                                currentCart={this.props.currentCart}
                                dispatch={this.props.dispatch}
                                offerProductNumbers={this.props.currentOffer ? this.props.currentOffer.offerItems : []}
                                offer
                            />
                        </TabPane>

                        <TabPane tabId="2">
                            <SortComponent
                                className={"mt-4"}
                                options={this.options}
                                onChange={this.onSortChange.bind(this)}
                                value={this.state.sortOption}
                                directionValue={this.state.sortDirection}
                                directionHandler={this.directionHandler.bind(this)}
                            />
                            <ProductList
                                propProductGroups={propProductGroups}
                                currentCart={this.props.currentCart}
                                dispatch={this.props.dispatch}
                                expanded={true}
                            />
                        </TabPane>
                    </TabContent>
                    <div className="text-left pt-3">
                        <span>* <small><FormattedMessage id="product.info.pricelist"/></small></span> <br/>
                        <span>** <small><FormattedMessage id="product.info.pricelist.load"/></small></span>
                    </div>
                </div>
                <ScrollToTopComponent/>
                {this.props.currentMessage && this.props.currentMessage.type === 'TOAST' &&
                    <Toastie dispatch={this.props.dispatch} message={this.props.currentMessage.msg} isOpen={this.props.currentMessage && this.props.currentMessage.type === 'TOAST'} onToastFinished={()=>{this.props.dispatch(setCurrentMessage({}))}}/>
                }
            </div>
        );
    }

    getProductList(offerNumber, brandChildren, categories, subCategories, productGroups) {
        return <>
            <SearchBar
                handleSearch={this.loadProductGroupSearch.bind(this)}
                searchTerm={this.state.searchTerm}
                onChange={this.handleSearchInput.bind(this)}
                onReset={this.resetSearch.bind(this)}
                placeholder={"Artikelnummer oder Bezeichnung"}
            />

            <div className="d-flex flex-row w-100">
                <BrandBar list={offerNumber ? brandChildren.concat([{name: 'Angebote'}]) : brandChildren} logos
                          title={"Marke"}
                          onItemClick={this.handleBrandFilter.bind(this)}
                          onSaleClick={this.handleSaleClick.bind(this)}
                          selected={this.state.brand}
                          ref={this.brandCollapse}
                          horizontal
                          brands
                />
            </div>

            <div className="d-flex flex-column flex-md-row">
                <CategoryList list={categories} title={"Produkthauptgruppe"} onItemClick={this.setCategory.bind(this)}
                              ref={this.categoryCollapse}/>
                <CategoryList list={subCategories} title={"Produktuntergruppe"}
                              onItemClick={this.setSubCategory.bind(this)}
                              ref={this.subCategoryCollapse}/>
            </div>
            {this.state.brand && this.state.brand.name.toLowerCase() === 'strasser' &&
            <Alert color="info" className="text-center">
                <span className="float-left oi oi-info"/> Der Verkauf unserer Produkte der Marke strasser ist uns
                exklusiv
                nur innerhalb der Bundesrepublik Deutschland erlaubt.
            </Alert>
            }
            <ProductList
                search={this.state.search}
                propProductGroups={productGroups}
                currentCart={this.props.currentCart}
                dispatch={this.props.dispatch}
            />
        </>;
    }
}

ProductsPage.propTypes = {
    currentCart: PropTypes.shape(Cart),
    currentProject: PropTypes.shape(Project),
    currentUser: PropTypes.shape(User),
    brands: PropTypes.shape(Category),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        currentCart: getCurrentCart(state),
        currentOffer:getOffer(state),
        currentProject: getCurrentProject(state),
        brands: getCategories(state) || [],
        products: getAllProducts(state),
        productGroups: getProductGroups(state),
        customerProductGroups: getCustomerProductGroups(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(ProductsPage));
