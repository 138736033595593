import React from 'react';
import {Router} from 'react-router';
import history from './history';

import routes from "./routes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {IntlProvider} from "react-intl";
import './app.scss';
import de from './i18n/messages/DE-de.json'
import pl from './i18n/messages/PL-pl.json'
import {connect} from "react-redux";
import {getCurrentLocale} from "./reducers/SettingsReducer";
import {setCurrentLocale} from "./actions";

// const locale = 'GERMAN';
const messages = {'de-DE': de, 'pl-PL': pl};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locale: 'de-DE'
        }
    }
    componentDidMount() {
        this.props.dispatch(setCurrentLocale(this.state.locale))
    }
    shutUp(){

    }
    render() {
        const locale = this.props.locale;
        return (
            <IntlProvider defaultLocale={locale} locale={locale} messages={messages[locale]} onError={this.shutUp}>
                    <Router history={history}>
                        <div className="App">
                            <Header/>
                            {routes}
                            <Footer/>
                        </div>
                    </Router>
            </IntlProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        locale: getCurrentLocale(state)
    };
}

export default connect(mapStateToProps)(App);
