import history from '../history';

export function goToPage(pathToPage, state) {
    console.info('goto page=' + pathToPage, state);
    if(pathToPage && state){
        history.push(pathToPage,state);
    }
    if(pathToPage && !state){
        history.push(pathToPage);
    }
}