import React, {Component} from 'react';

import BackButton from "../../components/Buttons/BackButton";
import CustomerForm from "../../components/Form/CustomerForm";
import {getCustomer} from "../../reducers/CustomerReducer";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Customer} from "../../proptypes";

class UserEditCustomerPage extends Component {
    constructor(props) {
        super(props);
        console.log("mount UserEditCustomerPage");
    }
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h4 className="text-muted text-center">{this.props.customer.name}</h4>
                    <h1 className="headline">
                        Händler bearbeiten
                    </h1>
                    <CustomerForm user edit/>
                </div>
            </div>
        );
    }
}
UserEditCustomerPage.propTypes = {
    customer: PropTypes.shape(Customer).isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
function mapStateToProps(state) {
    return {
        customer: getCustomer(state)
    }
}
export default connect(mapStateToProps)(UserEditCustomerPage);
