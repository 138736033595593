import React, {Component} from 'react';
import InputComponent from "../InputComponent";
import {FormGroup, Input, Label} from "reactstrap";

class AddressComponent extends Component {
    render() {
        const handler = this.props.handler;
        const rootValue = this.props.rootValue;
        const hideCompany = this.props.hideCompany || false;
        const disabled = this.props.disabled || false;
        const blurReq= this.props.blurReq || false;
        return (
            <React.Fragment>
                    <h2>Adresse</h2>
                    {!hideCompany &&
                        <InputComponent title='customer.form.companyTitle' name='company' type='text'
                                        placeholder={'customer.form.companyPlaceholder'}
                                        value={rootValue.company}
                                        handleChange={handler.bind(this)}
                                        maxLength={64}
                                        disabled={disabled}
                        />
                    }
                    <InputComponent title='customer.form.addressLine1Title' name='addressLine1' type="text"
                                    placeholder="customer.form.addressLine1Placeholder"
                                    formFeedback="customer.form.addressLine1FormFeedback"
                                    value={rootValue.addressLine1}
                                    handleChange={handler.bind(this)}
                                    maxLength={35}
                                    blurReq={blurReq}
                                    required
                                    disabled={disabled}
                    />

                    <InputComponent title="customer.form.addressLine2Title" name='addressLine2' type='text'
                                    placeholder={'customer.form.addressLine2Placeholder'}
                                    value={rootValue.addressLine2}
                                    handleChange={handler.bind(this)}
                                    maxLength={64}
                                    disabled={disabled}
                    />
                    <InputComponent title="customer.form.addressLine3Title" name='addressLine3' type='text'
                                    placeholder={'customer.form.addressLine3Placeholder'}
                                    value={rootValue.addressLine3}
                                    handleChange={handler.bind(this)}
                                    maxLength={64}
                                    disabled={disabled}
                    />
                    <InputComponent title="customer.form.additionalInformationTitle" name='additionalInformation'
                                    type='text'
                                    placeholder={'customer.form.additionalInformationPlaceholder'}
                                    value={rootValue.additionalInformation}
                                    handleChange={handler.bind(this)}
                                    maxLength={64}
                                    disabled={disabled}
                    />

                    <InputComponent title="customer.form.postalCodeTitle" name='postalCode' type="text"
                                    placeholder="customer.form.postalCodePlaceholder"
                                    formFeedback="customer.form.postalCodeFormFeedback"
                                    value={rootValue.postalCode}
                                    handleChange={handler.bind(this)}
                                    maxLength={35}
                                    required
                                    disabled={disabled}
                                    blurReq={blurReq}
                    />
                    <InputComponent title="customer.form.cityTitle" name='city' type="text"
                                    placeholder="customer.form.cityPlaceholder"
                                    formFeedback="customer.form.cityFormFeedback"
                                    value={rootValue.city}
                                    handleChange={handler.bind(this)}
                                    maxLength={35}
                                    required
                                    blurReq={blurReq}
                                    disabled={disabled}
                    />
                <FormGroup>
                    <Label for="country">Land</Label>
                    <Input
                        type="select" name="country" id="country" placeholder="Land"
                        value={rootValue.country}
                        onChange={handler.bind(this)}
                        disabled={disabled}
                    >
                        <option value="DE">Deutschland</option>
                        <option value="PL">Polen</option>
                    </Input>
                </FormGroup>
            </React.Fragment>
        );
    }
}

AddressComponent.propTypes = {};

export default AddressComponent;
