import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import ProjectForm from "../../components/Form/ProjectForm";
import Auth from "../../util/Auth";

class CustomerEditProjectPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h1 className="headline">
                        Lieferadresse aktualisieren
                    </h1>
                    <ProjectForm edit admin={Auth.isAdmin()}/>
                </div>
            </div>
        );
    }
}

CustomerEditProjectPage.propTypes = {};

export default CustomerEditProjectPage;
