import React, {Component} from 'react';
import UserForm from "../../components/Form/UserForm";
import {withRouter} from "react-router";
import BackButton from "../../components/Buttons/BackButton";
import PropTypes from 'prop-types';
import {Customer} from "../../proptypes";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {connect} from "react-redux";
class EditUserPage extends Component {
    editUserHandler(){
        console.log('change User')
    }
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h4 className="text-muted text-center">{this.props.customer.name}</h4>
                    <h1 className="h2 text-center mb-4">
                        Benutzer Bearbeiten
                    </h1>
                    <UserForm edit/>
                </div>
            </div>
        );
    }
}

EditUserPage.propTypes = {
    customer: PropTypes.shape(Customer).isRequired
};
function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        currentMessage: getCurrentMessage(state)
    };
}
export default withRouter(connect(mapStateToProps)(EditUserPage));
