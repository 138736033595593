import {LOGOUT,SET_PRICE_MAP} from '../actions/types';

const initialState = {
  priceMap: null
};

const PriceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        priceMap: null
      };
    }
    case SET_PRICE_MAP:{
      return {
        ...state,
        priceMap: action.priceMap
      }
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getPriceMap = (state) => state.price.priceMap;

// Export Reducer
export default PriceReducer;
