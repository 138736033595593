export const PATH_HOME_PAGE = '/';
export const PATH_LOGIN_PAGE = '/login';
export const PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE = '/customers';
export const PATH_ADMIN_MANAGE_CUSTOMER_PAGE = '/customers/manage';
export const PATH_ADMIN_EDIT_CUSTOMER_PAGE = '/customers/manage/edit';
export const PATH_ADMIN_CREATE_CUSTOMER_PAGE = '/customers/manage/create';
export const PATH_ADMIN_USER_CUSTOMER_PAGE = '/customers/manage/user';
export const PATH_ADMIN_USER_EDIT_PAGE = '/customers/manage/user/edit';
export const PATH_ADMIN_USER_CREATE_PAGE = '/customers/manage/user/create';
export const PATH_ADMIN_SETTINGS_PAGE = '/settings/admin';
export const PATH_ADMIN_PRODUCT_OVERVIEW_PAGE = '/products/';
export const PATH_ADMIN_PRODUCT_CREATE_PAGE = '/product/create';
export const PATH_ADMIN_PRODUCT_EDIT_PAGE = '/product/edit';
export const PATH_USER_HOME_PAGE = '/home';
export const PATH_USER_SETTINGS_PAGE = '/settings/user';
export const PATH_ADMIN_CATEGORIES_OVERVIEW_PAGE = '/categories/';
export const PATH_ADMIN_CATEGORY_CREATE_PAGE = '/category/create';
export const PATH_ADMIN_CATEGORY_EDIT_PAGE = '/category/edit';
export const PATH_MANAGE_PROJECT_OVERVIEW_PAGE = '/projects';
export const PATH_USER_PROJECT_OVERVIEW_PAGE = '/projectOverview';
export const PATH_ADMIN_PROJECT_SETTINGS_OVERVIEW_PAGE = '/projects/settings';
export const PATH_ADMIN_PROJECT_CREATE_PAGE = '/projects/create';
export const PATH_PROJECT_OFFER_CHOOSE_PAGE = '/projects/choose/offer';
export const PATH_OFFER_PROJECT_CREATE_PAGE = '/projects/create/offer';
export const PATH_ADMIN_PROJECT_EDIT_PAGE = '/projects/edit';
export const PATH_ADMIN_WORKER_CUSTOMER_PAGE = '/customers/manage/worker';
export const PATH_ADMIN_WORKER_OVERVIEW_PAGE = '/workers';
export const PATH_ADMIN_WORKER_EDIT_PAGE = '/worker/edit';
export const PATH_ADMIN_WORKER_CREATE_PAGE = '/worker/create';
export const PATH_ADMIN_WORKER_MANAGE_PAGE = '/worker/manage';
export const PATH_NEW_CART_PAGE='/new-cart';
export const PATH_CART_SUMMARY_PAGE = '/cart-summary';
export const PATH_PRODUCTS_PAGE = '/productsPage';
export const PATH_NEW_CART_SCHEDULE_PAGE = '/new-cart-schedule';
export const PATH_CART_PAGE = '/cart';
export const PATH_CONSTRUCTOR_TRUCK = '/settings/customer-trucks/truck';
export const PATH_ADMIN_PRODUCT_PAGE = '/admin/product';
export const PATH_USER_EDIT_CUSTOMER_PAGE = '/user/customer/edit';
export const PATH_USER_MANAGE_USER_PAGE = '/user/users/manage';
export const PATH_ADMIN_ORGANIZATION_OVERVIEW = '/organizations';
export const PATH_ADMIN_ORGANIZATION_CREATE_PAGE = '/organizations/create';
export const PATH_ADMIN_ORGANIZATION_EDIT_PAGE = '/organizations/edit';
export const PATH_OFFER_OVERVIEW = '/offers/overview';
export const PATH_CHANGE_CREDENTIALS = '/settings/credentials';
export const PATH_USER_REGISTRATION_PAGE = '/login/registration';
export const PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE =  '/settings/user/facility';
export const PATH_CUSTOMER_FACILITY_CREATE_PAGE =  '/settings/user/facility/create';
export const PATH_CUSTOMER_FACILITY_EDIT_PAGE =  '/settings/user/facility/edit';
