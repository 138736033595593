import {SET_WORKERS, SET_WORKERS_COUNT} from '../actions/types';

const initialState = {
    workers: [],
    count: undefined
};

const WorkersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_WORKERS: {
            return {
                ...state,
                workers: action.workers
            };
        }
        case SET_WORKERS_COUNT: {
            return {
                ...state,
                count: action.count
            };
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getWorkers = (state) => state.workers.workers.workers
export const getWorkersCount = (state) => state.workers.count

// Export Reducer
export default WorkersReducer;
