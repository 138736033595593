import {
    CLEAR_CUSTOMER_PRODUCT_GROUPS,
    CLEAR_PRODUCT_GROUPS,
    CREATE_BUNDLE,
    LOAD_ALL_PRODUCT_NUMBERS,
    LOAD_CROSS_SELL_PRODUCTS,
    LOAD_PRODUCT_GROUPS,
    SET_PRODUCTS,
    SET_SEARCH_PRODUCTS
} from '../actions/types';

const initialState = {
  stateProductGroups: null,
  stateCustomerProductGroups: null,
  stateCrossSellProductGroups: {},
  productNumbers: null
};

const ProductsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_PRODUCT_GROUPS: {
      return {
        ...state,
        stateProductGroups: null,
        stateCustomerProductGroups: null,
        stateCrossSellProductGroups: null
      };
    }
    case LOAD_PRODUCT_GROUPS: {
      return {
        ...state,
        stateProductGroups: action.productGroups
      };
    }
    case CLEAR_CUSTOMER_PRODUCT_GROUPS: {
      return {
        ...state,
        stateCustomerProductGroups: null
      };
    }
    case LOAD_CROSS_SELL_PRODUCTS: {
      return {
        ...state,
        stateCrossSellProductGroups: action.crossSellProductGroups
      };
    }
    case CREATE_BUNDLE: {
      return {
        ...state,
        stateProductBundle: action.stateProductBundle
      };
    }
    case LOAD_ALL_PRODUCT_NUMBERS: {
      return {
        ...state,
        productNumbers: action.productNumbers
      };
    }
    case SET_PRODUCTS:{
      return{
        ...state,
        products: action.products
      }
    }
    case SET_SEARCH_PRODUCTS:{
      return{
        ...state,
        searchProducts: action.products
      }
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getProductGroups = (state) => state.productGroups.stateProductGroups;
export const getCrossSellProducts = (state) => state.productGroups.stateCrossSellProductGroups;
export const loadAllProductNumbers = (state) => {
  return state.productGroups.productNumbers;
}
export const getAllProducts = (state) => state.productGroups.products
export const getSearchProducts = (state) => state.productGroups.searchProducts

// Export Reducer
export default ProductsReducer;
