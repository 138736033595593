import React from 'react';
import {getFormattedDate} from "../util/TimeUtils";
import DatePicker from "./Schedule/DatePicker";

function CartOrderHoc(Component) {
    return class extends React.Component {
        getTour(order) {
            let tour = this.getConfirmedTour(order);
            if (!tour) {
                tour = this.getScheduledTour(order);
            }
            return tour;
        }

        getConfirmedTour(order) {
            if (order) {
                let tour;
                if (order.delivery) {
                    tour = order.delivery.confirmedTour;
                } else {
                    tour = order.pickup.confirmedTour;
                }
                if (tour) {
                    return tour;
                }
            }
            return null;
        }

        getScheduledTour(order) {
            if (order) {
                let tour;
                if (order.delivery) {
                    tour = order.delivery.scheduledTour;
                } else {
                    tour = order.pickup.scheduledTour;
                }
                return tour;
            }
            return null;
        }

        getTourDate(order) {
            const tour = this.getTour(order);
            const date = tour.fromDate;
            return getFormattedDate(date, 'de-DE');
        }

        getFormattedTimeLabel(order) {
            const tour = this.getTour(order);
            return DatePicker.getHourLabel(tour);
        }

        render() {
            return (
                <Component getTour={this.getTour} getConfirmedTour={this.getConfirmedTour}
                           getScheduledTour={this.getScheduledTour}
                           getTourDate={this.getTourDate}
                           getFormattedTimeLabel={this.getFormattedTimeLabel} {...this.props}/>
            );
        }
    }
}
export default CartOrderHoc;