import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {
    clearCustomer,
    createCustomer,
    getAllOrganizations,
    loadCurrentUser,
    removeCurrentMessage,
    updateCustomer
} from '../../actions';
import {Button, Form, Input, Label} from 'reactstrap';
import {Customer, Message, User} from '../../proptypes';
import {getCurrentUser} from '../../reducers/UserReducer';
import {getCurrentMessage} from '../../reducers/MessageReducer';
import {getCustomer} from "../../reducers/CustomerReducer";
import InputComponent from "./InputComponent";
import {isValidNumber, isValidPhone, notEmpty} from "../../util/ValidFn";
import ModalComponent from "../Modals/ModalComponent";
import {
    PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE,
    PATH_ADMIN_MANAGE_CUSTOMER_PAGE,
    PATH_USER_SETTINGS_PAGE
} from "../../routePaths";
import AddressComponent from "./Parts/AddressComponent";
import {getOrganizations} from "../../reducers/OrganizationsReducer";
import SelectComponent from "./SelectComponent";

class CustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: this.getInitCustomer(),
            creditLine: null,
            touched: {
                name: false,
                number: false,
                addressLine1: false,
                postalCode: false,
                city: false,
                email: false,
                phone: false,
                mobile: false,
                fax: false
            },
            modals: {
                create: false,
                change: false,
                error: false
            },
            initialCustomerNumber: '',
            salesRepresentative: false,
        };

        this.handleCustomerBasicDataChange = this.handleCustomerBasicDataChange.bind(this);
        this.handleCustomerAddressDataChange = this.handleCustomerAddressDataChange.bind(this);
        this.handleCustomerContactDataChange = this.handleCustomerContactDataChange.bind(this);
        this.createCustomer = _.throttle(this.createCustomer.bind(this), 1000, {trailing: false});
        this.updateCustomer = _.throttle(this.updateCustomer.bind(this), 1000, {trailing: false});
        this.switchToPage = this.switchToPage.bind(this);
        this.clearFieldsOnNewCustomer = this.clearFieldsOnNewCustomer.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getAllOrganizations())
        if (this.props.edit) {
            const currentUser = this.props.currentUser;
            if (!currentUser) {
                this.props.dispatch(loadCurrentUser());
            }
            const customer = this.props.customer
            if (customer.salesOrganization) {
                this.setState({organization: customer.salesOrganization})

            } else {
                this.setState({organization: this.getInitSalesOrganization()})
            }
            if (customer) {
                this.setCustomer(customer);
            }
        } else {
            this.clearFieldsOnNewCustomer()
        }
    }

    componentWillReceiveProps(nextProps) {
        const nextCustomer = nextProps.customer;
        if (nextCustomer) {
            this.setCustomer(nextCustomer);
        }
        const nextMessage = nextProps.currentMessage;
        if (nextMessage && (nextMessage.source === 'CHANGE_CUSTOMER' && nextMessage.type === 'INFO')) {
            this.showModal('change');
        }
        if (nextMessage && (nextMessage.source === 'CREATE_CUSTOMER' && nextMessage.type === 'INFO')) {
            this.showModal('create');
        }
        if (nextMessage && (nextMessage.type === 'ERROR')) {
            this.showModal('error');
        }
    }

    getInitCustomer() {
        return {
            name: '',
            number: '',
            isCompany: true,
            address: {
                additionalInformation: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                company: '',
                countryCode: '',
                postalCode: ''
            },
            contact: {
                email: '',
                fax: '',
                mobile: '',
                phone: ''
            },
            salesOrganization: this.getInitSalesOrganization(),
            salesRepresentative: false,
        }
    }

    getInitSalesOrganization() {
        return {
            contact: {
                email: undefined,
                fax: undefined,
                mobile: undefined,
                phone: undefined
            },
            customerNumbers: undefined,
            name: undefined,
            number: undefined
        }
    }

    setCustomer(nextCustomer) {
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.customer = nextCustomer;
                nextState.initialCustomerNumber = nextCustomer.number;
                return nextState;
            });
    }

    handleCustomerBasicDataChange(event) {
        const customer = Object.assign({}, this.state.customer);
        const name = event.target.name;
        let value = event.target.value;
        if(name === 'creditLine' && value < -1){
            value = -1
        }
        customer[name] = value;
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.customer = customer;
            return nextState;
        });
    }

    handleCustomerAddressDataChange(event) {
        const address = Object.assign({}, this.state.customer.address);
        address[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.customer.address = address;
            return nextState;
        });
    }

    handleCustomerContactDataChange(event) {
        const contact = Object.assign({}, this.state.customer.contact);
        contact[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.customer.contact = contact;
            return nextState;
        });
    }

    handleCustomerAccountDataChange(event) {
        const accounting = Object.assign({}, this.state.customer.accounting);
        accounting[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.customer.accounting = accounting;
            return nextState;
        });
    }

    createCustomer(event, customer,organization) {
        this.props.dispatch(createCustomer(customer, organization));
    }

    updateCustomer(event, customer, organization) {
        this.props.dispatch(updateCustomer(customer, organization));
    }

    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    clearFieldsOnNewCustomer(event) {
        this.setState({
            customer: this.getInitCustomer()
        });
        this.props.dispatch(clearCustomer());
        this.props.dispatch(removeCurrentMessage());
        if (event)
            event.preventDefault();
    }

    isRequiredNotEmpty() {
        const customer = this.state.customer;
        const address = customer.address;
        return notEmpty(address.addressLine1) && notEmpty(address.city) && notEmpty(address.postalCode)
            && notEmpty(customer.name) && notEmpty(customer.number)
    }

    isRequiredValid() {
        const customer = this.state.customer;

        return isValidPhone(customer.contact?.phone)
            && isValidNumber(customer.number)

    }

    isFormComplete() {
        return this.isRequiredValid() && this.isRequiredNotEmpty()
    }

    salesOrganizationHandler(organization) {
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.organization = organization;
            return nextState;
        });
    }

    render() {
        const customer = this.state.customer;
        const organizations = (this.props.organizations && this.props.organizations.organizations) ? this.props.organizations.organizations : []
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {this.props.edit &&
                        <Button
                            href="" className="btn-block"
                            onClick={(e) => this.updateCustomer(e, this.state.customer, this.state.organization)}
                            disabled={!this.isFormComplete()}
                        >
                            Händler aktualisieren
                        </Button>
                        }
                        {this.props.create &&
                        <Button
                            href=""
                            className="btn-block"
                            onClick={(e) => this.createCustomer(e, this.state.customer,this.state.organization)}
                            disabled={!this.isFormComplete()}
                        >
                            Händler erstellen
                        </Button>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-start flex-md-row col-12 mt-2">
                    <Form className="col-12 col-md-4">
                        <h2>Basisdaten</h2>
                        <InputComponent title="customer.form.nameTitle" type="text" name="name"
                                        placeholder={"customer.form.namePlaceholder"}
                                        value={this.state.customer.name}
                                        handleChange={this.handleCustomerBasicDataChange.bind(this)}
                                        formFeedback={"customer.form.nameFormFeedback"}
                                        maxLength={64}
                                        min={3}
                                        required
                                        disabled={this.props.user}
                        />
                        <InputComponent title="customer.form.numberTitle" type="number" name="number"
                                        placeholder={"customer.form.numberPlaceholder"}
                                        value={this.state.customer.number}
                                        handleChange={this.handleCustomerBasicDataChange.bind(this)}
                                        maxLength={64}
                                        min={3}
                                        formFeedback={"customer.form.numberFormFeedback"}
                                        required
                                        disabled={this.props.user}
                        />
                        {!this.props.user &&
                        <InputComponent title="customer.form.creditLine" name="creditLine"
                                        value={this.state.customer.creditLine}
                                        handleChange={this.handleCustomerBasicDataChange.bind(this)}
                                        type={"currency"}
                        />
                        }
                        {!this.props.user &&
                        <SelectComponent
                            title={"Verkaufsorganisation"}
                            name={"organization"}
                            options={organizations}
                            value={this.state.organization}
                            labelKey={"name"}
                            onChange={this.salesOrganizationHandler.bind(this)}
                        />
                        }
                        {(this.props.user && this.state.organization) &&
                        <InputComponent title="Verkaufsorganisation"
                                        name="organization"
                                        value={this.state.organization.name}
                                        disabled={this.props.user}
                        />
                        }
                        <Form>
                                <Label for="salesRepresentative"> Handelsvertreter </Label>
                                <Input
                                    type="select" name="salesRepresentative" id="salesRepresentative"
                                    placeholder="Handelsvertreter"
                                    value={this.state.customer.salesRepresentative}
                                    onChange={this.handleCustomerBasicDataChange.bind(this)}
                                    disabled={this.props.currentUser.role !== "ADMIN"}
                                >
                                    <option value="true">Ja</option>
                                    <option value="false">Nein</option>
                                </Input>
                        </Form>
                    </Form>
                    <Form className="col-12 col-md-4">
                        <AddressComponent
                            handler={this.handleCustomerAddressDataChange.bind(this)}
                            rootValue={customer.address} disabled={this.props.user}
                        />
                    </Form>
                    <Form className="col-12 col-md-4">
                        <h2>Kontakt</h2>
                        <InputComponent title="customer.form.emailTitle" name='email' type="email"
                                        placeholder="customer.form.emailPlaceholder"
                                        formFeedback="customer.form.emailFormFeedback"
                                        value={this.state.customer.contact.email}
                                        handleChange={this.handleCustomerContactDataChange.bind(this)}
                                        maxLength={64}
                                        formText="customer.form.emailFormText"
                                        required
                        />
                        <InputComponent title="customer.form.phoneTitle" name='phone' type="tel"
                                        placeholder="customer.form.phonePlaceholder"
                                        formFeedback="customer.form.phoneFormFeedback"
                                        value={this.state.customer.contact.phone}
                                        handleChange={this.handleCustomerContactDataChange.bind(this)}
                                        maxLength={64}
                                        required
                        />
                        <InputComponent title="customer.form.mobileTitle" name='mobile' type="tel"
                                        placeholder="customer.form.mobilePlaceholder"
                                        formFeedback="customer.form.mobileFormFeedback"
                                        value={this.state.customer.contact.mobile}
                                        handleChange={this.handleCustomerContactDataChange.bind(this)}
                                        maxLength={64}
                        />
                        <InputComponent title="customer.form.faxTitle" name='fax' type="tel"
                                        placeholder="customer.form.faxPlaceholder"
                                        formFeedback="customer.form.faxFormFeedback"
                                        value={this.state.customer.contact.fax}
                                        handleChange={this.handleCustomerContactDataChange.bind(this)}
                                        maxLength={64}
                        />
                    </Form>
                </div>

                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() => this.showModal('create')}
                    hide={() => this.hideModal('create')}
                    title="Händler anlegen erfolgreich"
                    color="success"
                    message={'Anlegen des Händlers erfolgreich. Möchten Sie einen weiteren Händler anlegen?'}
                    onCancel={(e) => {
                        this.switchToPage(e, PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    onSuccess={(e) => {
                        this.clearFieldsOnNewCustomer(e);
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('create')
                    }}
                    successText={'Ja'}
                    cancelText={'Nein'}
                />

                <ModalComponent
                    isOpen={this.state.modals.change}
                    toggle={() => this.showModal('change')}
                    hide={() => this.hideModal('change')}
                    color="success"
                    title="Händler aktualisiert"
                    message={'Händler aktualisiert'}
                    onSuccess={(e) => {
                        if (!this.props.user) {
                            this.switchToPage(e, PATH_ADMIN_MANAGE_CUSTOMER_PAGE);
                        } else {
                            this.switchToPage(e, PATH_USER_SETTINGS_PAGE)
                        }
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />

                {this.props.currentMessage &&
                <ModalComponent
                    isOpen={this.state.modals.error}
                    toggle={() => this.showModal('error')}
                    hide={() => this.hideModal('error')}
                    color="danger"
                    title="Fehler aufgetreten"
                    message={this.props.currentMessage.msg}
                    onSuccess={() => {
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('error')
                    }}
                    successText={'Ok'}
                />
                }
            </div>
        );
    }
}

CustomerForm.propTypes = {
    currentUser: PropTypes.shape(User).isRequired,
    customer: PropTypes.shape(Customer).isRequired,
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
CustomerForm.defaultProps = {
    customer: {}
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        customer: getCustomer(state),
        currentMessage: getCurrentMessage(state),
        organizations: getOrganizations(state),
    };
}

export default withRouter(connect(mapStateToProps)(CustomerForm));
