import {callSecureApi} from '../util/apiCaller';
import {isUnauthorized} from '../util/http-errors';

import {
    CLEAR_RETAILER,
    LOAD_RETAILERS
} from './types';
import {logout} from './AuthentificationActions';

export function loadRetailers() {
    return dispatch => {
        console.info('loading all retailers');
        callSecureApi({
            endpoint: 'customerservice/v1/retailer',
            method: 'get'
        })
            .then(res => {
                dispatch(setRetailers(res));
            })
            .catch(err => {
                console.error('unable to load retailers', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}


export function clearRetailer() {
    return {
        type: CLEAR_RETAILER
    };
}
export function setRetailers(retailers) {
    return {
        type: LOAD_RETAILERS,
        retailers
    };
}
