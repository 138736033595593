import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getCurrentUser} from "../../reducers/UserReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {Customer, Message, User} from "../../proptypes";
import Input from "reactstrap/es/Input";
import Form from "reactstrap/lib/Form";
import _ from "lodash";
import Table from "reactstrap/es/Table";
import {
    PATH_ADMIN_CREATE_CUSTOMER_PAGE,
    PATH_ADMIN_MANAGE_CUSTOMER_PAGE
} from "../../routePaths";
import classNames from 'classnames';
import './CustomerOverviewPage.scss'
import {clearCustomer, loadCustomer, loadCustomers} from "../../actions";
import {getCustomers} from "../../reducers/CustomersReducer";

class CustomerOverviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightedRow: 0,
            customerOpen: {},
            searchTerm: '',
            filteredCustomers: []
        };
        this.goToCustomerManagePage = this.goToCustomerManagePage.bind(this);
        this.goToCustomerCreatePage = this.goToCustomerCreatePage.bind(this);
        this.highlightRow = this.highlightRow.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(loadCustomers());
        this.props.dispatch(clearCustomer());
    }

    handleSearchInput(event) {
        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            searchTerm: search
        });
        this.filteredCustomerList(search, this.props.customers);
    }

    filteredCustomerList(searchTerm, customers) {
        let localCustomers = customers;
        if (searchTerm) {

            localCustomers = _.filter(customers, (customer) => {
                const name = customer.name || "";
                const vkorg = customer.salesOrganization ? customer.salesOrganization.name : "";
                const id = customer.number || "";
                const city = customer.address ? customer.address.city : "";

                return name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                    || id.indexOf(searchTerm) !== -1
                    || vkorg.indexOf(searchTerm) !== -1
                    || city.indexOf(searchTerm) !== -1
            });

        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filteredCustomers = localCustomers;
            return nextState;
        });
    }

    goToCustomerManagePage(customer) {
        this.props.dispatch(loadCustomer(customer.id));
        this.props.history.push(PATH_ADMIN_MANAGE_CUSTOMER_PAGE)
    }

    goToCustomerCreatePage() {
        this.props.history.push(PATH_ADMIN_CREATE_CUSTOMER_PAGE)
    }

    highlightRow(index) {
        this.setState({highlightedRow: index})
    }


    render() {
        const customers = this.props.customers || [];
        const showCustomers = this.state.searchTerm.length > 0 ? this.state.filteredCustomers : customers;

        return (
            <div className="page-content">
                <div className="container">
                    <h1 className="headline">
                        Händler-Übersicht
                    </h1>
                    <div className="mb-4"><Button color="primary" onClick={() => this.goToCustomerCreatePage()} size="sm">Neuen Händler
                        anlegen</Button></div>
                    <div>
                        <div className="col-12 mb-3">
                            <Form onSubmit={(e) => this.handleSearchInput(e)}>
                                <div className="input-group">
                                    <Input
                                        type="text" name="searchTerm" placeholder="Filter nach: Name, VkOrg, KD.-Nr. oder Ort"
                                        value={this.state.searchTerm} onChange={(e) => this.handleSearchInput(e)}
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                    <Row>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>VkOrg</th>
                                        <th>KD.-Nr.</th>
                                        <th>Name</th>
                                        <th>Ort</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                            {showCustomers.map((customer, index) => {
                                return (
                                    <tr style={{height: 67}}
                                        key={index}
                                        className={classNames({highlight: (this.state.highlightedRow === index)})}
                                        onMouseEnter={() => this.highlightRow(index)}>
                                        <td>{customer.salesOrganization && customer.salesOrganization.name}</td>
                                        <td>{customer.number}</td>
                                        <td>{customer.name}</td>
                                        <td>{customer.address && customer.address.city}</td>
                                        <td>{(this.state.highlightedRow === index) &&
                                            <span className="float-right">
                                            <Button size="sm" className="mr-3" onClick={() => {
                                                this.goToCustomerManagePage(customer)
                                            }}>Verwalten</Button>
                                            </span>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Row>
                </div>
            </div>
        );
    }
}

CustomerOverviewPage.propTypes = {
    currentUser: PropTypes.shape(User).isRequired,
    customers: PropTypes.arrayOf(PropTypes.shape(Customer)).isRequired,
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};


function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        customers: getCustomers(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(CustomerOverviewPage));
