import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import  {FormText, Button, Form, FormGroup} from 'reactstrap';

import {loadCurrentUser, login, removeCurrentMessage, resetPassword, tokenLogin} from '../actions';
import {PATH_HOME_PAGE, PATH_USER_REGISTRATION_PAGE} from '../routePaths';

import {getCurrentUser} from '../reducers/UserReducer';
import {getCurrentMessage} from '../reducers/MessageReducer';
import {Message} from '../proptypes';
import ModalComponent from "../components/Modals/ModalComponent";
import {SessionStorageKeys} from "../util/apiCaller";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.isValidEmail = this.isValidEmail.bind(this);
        this.isEmailEmpty = this.isEmailEmpty.bind(this);
        this.isPasswordValid = this.isPasswordValid.bind(this);
        this.isPasswordEmpty = this.isPasswordEmpty.bind(this);
        this.handleEmailInput = this.handleEmailInput.bind(this);
        this.handlePasswordInput = this.handlePasswordInput.bind(this);
        this.openPasswordReset = this.openPasswordReset.bind(this);
        this.resetPassword = _.throttle(this.resetPassword.bind(this), 1000, {trailing: false});
        this.login = _.throttle(this.login.bind(this),100);
        this.goToUserRegistrationPage = this.goToUserRegistrationPage.bind(this);

        this.state = {
            password: '',
            email: '',
            reset: false,
            modals:{
                info:false
            }
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const sessionToken = sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN);
        if(sessionToken){
            this.props.dispatch(tokenLogin());
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.currentMessage !== this.props.currentMessage){
            if(this.props.currentMessage && this.props.currentMessage.type === 'INFO' && this.props.currentMessage.source === 'SESSION_CLOSED'){
                this.showModal('info')
            }

        }
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    isEmailEmpty() {
        const email = this.state.email;
        return email ? email.length === 0 : true;
    }

    isValidEmail() {
        if (this.isEmailEmpty()) {
            return false;
        }

        const email = this.state.email.trim()
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailLower = String(email).toLowerCase();
        return emailRegex.test(emailLower);
    }

    isPasswordEmpty() {
        const password = this.state.password;
        return password ? password.length === 0 : true;
    }

    isPasswordValid() {
        if (!this.isPasswordEmpty()) {
            const password = this.state.password.trim();
            return password.length > 3;
        }
        return false;
    }

    login(event) {
        event.preventDefault();

        const email = this.state.email;
        const password = this.state.password;

        this.setState({
            reset: false
        });
        this.props.dispatch(login({email, password})).then((res,err) => {
          if(res){
            this.props.dispatch(loadCurrentUser()).then(() => this.props.history.push(PATH_HOME_PAGE))
          }
          if(err){
              console.error("Login error",err)
          }
        })

    }
    openPasswordReset(event) {
        event.preventDefault();

        this.setState({
            reset: true,
            password: ''
        });
    }

    resetPassword(event) {
        event.preventDefault();

        const email = this.state.email;
        this.props.dispatch(resetPassword(email));

        this.setState({
            reset: false
        });
    }

    handleEmailInput(event) {
        const value = event.target.value;
        this.setState({
            email: value,
            reset: false
        });

        if (this.props.currentMessage) {
            this.props.dispatch(removeCurrentMessage());
        }
    }

    handlePasswordInput(event) {
        const value = event.target.value;
        this.setState({
            password: value,
            reset: false
        });

        if (this.props.currentMessage) {
            this.props.dispatch(removeCurrentMessage());
        }
    }

    goToUserRegistrationPage(event) {
        event.preventDefault();
        this.props.history.push(PATH_USER_REGISTRATION_PAGE)
    }

    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <div>
                        <div className="ml-auto col-md-6 mr-auto">
                            <Form>
                                <div className="pt-3 alert alert-info text-center">
                                    <span className="oi oi-info mr-2" />Liebe Kunden, liebe Kundinnen, bitte nutzen Sie zukünftig unsere alternativen Bestellwege. Ihre Sievert-Ansprechpartner stehen Ihnen jederzeit zur Verfügung.
                                </div>

                                {this.state.reset &&
                                <FormGroup>
                                    <Button
                                        className="btn btn-primary btn-block" color="primary" onClick={(e) => this.resetPassword(e)}
                                    >
                                        Passwort zurück setzen!
                                    </Button>
                                    <FormText className="text-center h6 pt-1">Ein neues Passwort wird an Ihre hinterlegte Kontakt Emailadresse versendet.</FormText>
                                </FormGroup>
                                }
                                {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR') &&
                                <div className="pt-3 alert alert-danger text-center">
                                    <span className="oi oi-warning mr-2" /> {this.props.currentMessage.msg} <br/>
                                    <Button color="link" onClick={(e) => this.openPasswordReset(e)}><u>Sie haben Ihr Passwort vergessen?</u></Button>
                                </div>
                                }
                                {(this.props.currentMessage && this.props.currentMessage.type === 'INFO') &&
                                <div className="pt-3 alert alert-info text-center">
                                    <span className="oi oi-info mr-2"/> {this.props.currentMessage.msg}
                                </div>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.info}
                    toggle={() =>
                        this.showModal('info')
                    }
                    hide={() =>
                        this.hideModal('info')
                    }
                    color="success"
                    title="Session abgelaufen"
                    message="Ihre Session ist abgelaufen, bitte loggen Sie sich neu ein."
                    onSuccess={() => {
                        this.hideModal('info')
                    }}
                    successText="Ok"
                />
            </div>
        );
    }
}

LoginPage.propTypes = {
    isUserLoggedIn: PropTypes.bool,
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isUserLoggedIn: !!getCurrentUser(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(LoginPage));
