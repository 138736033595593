import React, {Component, Fragment} from 'react';
import {germanCurrency} from "../util/priceHelper";
import {PACKAGINGTYPES} from "../enums/PackagingTypes";
import {injectIntl} from "react-intl";
import CartItemsList from "./Cart/CartItemsList";
import {getPriceMap} from "../reducers/PriceReducer";
import {connect} from "react-redux";
import {getSelectedCountry} from "../reducers/I18NReducer";
import {getCheckList} from "../reducers/OffersReducer";
import {getProductImageUrl, setCheckList} from "../actions";

class ItemList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkList:[]
        }
    }

    componentDidMount() {
        this.setState({checkList:this.props.checkList})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.checkList !== prevProps.checkList){
            this.setState({checkList: this.props.checkList});
        }
    }

    getSingularPackagingTypeLabel(product, prefix) {
        if (product.packaging && product.packaging.type) {
            const singularLabel = this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label});
            if (prefix) {
                return prefix + singularLabel
            }
            return singularLabel
        }
        if (prefix) {
            return prefix + 'Verpackungseinheit'
        }
        return 'Verpackungseinheit'
    }

    onChange(event){
        let checkList = this.state.checkList ||[];
        const checked = event.target.checked;
        const name = event.target.name;
        if(checked){
            if(!checkList.includes(name))
                checkList.push(name);
        }else{
            checkList.splice(checkList.indexOf(name),1);
        }
        this.props.dispatch(setCheckList(checkList));
        this.forceUpdate()
    }
    render() {
        const items = this.props.items ||[];
        return (
            <Fragment>
                {items.map((item,i ) => {
                    return (
                            <div className="offset-1 col-11 mb-3" key={i}>
                                <div className="d-flex flex-column flex-md-row mb-4 pb-3">
                                    <div className="col-12 col-md-2">
                                        <img
                                            alt={CartItemsList.orderItemName(item)} className="ml-3 mr-3"
                                            src={getProductImageUrl(item.productNumber)} style={{height: '100px'}}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3 d-flex mt-2 ml-2 flex-column">
                                        <div>
                                            <strong>{item.name ||item.data.formatDefinition}</strong>
                                        </div>
                                        <div>
                                            <small>
                                            Art-Nr. {item.productNumber}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 mt-2">
                                        <span>Warenpreis: </span> {germanCurrency(item.data.price)} pro {this.getSingularPackagingTypeLabel(item)}<br/>
                                    </div>
                                    <div className="col-md-3 col-12 text-center text-md-right mt-2">
                                           <span> Abnahmemenge: {item.quantity || "Keine Angabe"} </span>
                                    </div>
                                </div>
                            </div>
                        )
                })
                }
            </Fragment>
        );
    }
}

ItemList.propTypes = {};
function mapStateToProps(state) {
    return {
        priceMap:getPriceMap(state),
        selectedCountry:getSelectedCountry(state),
        checkList: getCheckList(state)
    };
}
export default connect(mapStateToProps)(injectIntl(ItemList));
