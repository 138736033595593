import {CREATE_USER_REGISTRATION} from '../actions/types';

const initialState = {
    stateRegistration: null
};

const UserRegistrationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CREATE_USER_REGISTRATION: {
            return {
                ...state,
                stateRegistration: action.registration
            }
        }
        default: {
            return state;
        }
    }
}

/* Selectors */
export const getCurrentUserRegistration = (state) => state.registration.stateRegistration;

// Export Reducer
export default UserRegistrationReducer;
