import React, {Component} from 'react';
import {Button, Form} from "reactstrap";
import InputComponent from "./InputComponent";
import _ from "lodash";
import {createCategory} from "../../actions";
import {PATH_ADMIN_CATEGORIES_OVERVIEW_PAGE, PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE} from "../../routePaths";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {removeCurrentMessage} from "../../actions";
import ModalComponent from "../Modals/ModalComponent";

class CategoryForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: {}
        }
    }

    handleDataChange(event) {
        const category = Object.assign({}, this.state.category);
        category[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.category = category;
            return nextState;
        });
    }

    isFormComplete() {
        return true;
    }

    updateCategory() {

    }

    goTo(path){
        this.props.history.push(path);
    }
    createCategory() {
        this.props.dispatch(createCategory(this.state.category));
        this.goTo(PATH_ADMIN_CATEGORIES_OVERVIEW_PAGE);
    }

    render() {
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {this.props.edit &&
                        <Button
                            href="" className="btn-block"
                            onClick={(e) => this.updateCategory(e)}
                            disabled={!this.isFormComplete()}
                        >
                            Kategorie aktualisieren
                        </Button>
                        }
                        {this.props.create &&
                        <Button
                            href=""
                            className="btn-block"
                            onClick={(e) => this.createCategory(e)}
                            disabled={!this.isFormComplete()}
                        >
                            Kategorie erstellen
                        </Button>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-start flex-md-row col-12 mt-2">
                    <Form className="col-12">
                        <InputComponent title="category.form.name.title" type="text" name="name"
                                        value={this.state.category.name}
                                        handleChange={this.handleDataChange.bind(this)}
                                        formFeedback={"customer.form.name.formFeedback"}
                                        required
                        />
                        <InputComponent title="category.form.label.title" type="text" name="label"
                                        value={this.state.category.label}
                                        handleChange={this.handleDataChange.bind(this)}
                                        formText={"customer.form.label.formText"}
                        />
                        <InputComponent title="category.form.description.title" type="text" name="description"
                                        value={this.state.category.description}
                                        handleChange={this.handleDataChange.bind(this)}
                                        formText={"customer.form.label.formText"}
                        />
                        <InputComponent title="category.form.sort.title" type="number" name="sort"
                                        value={this.state.category.sort}
                                        handleChange={this.handleDataChange.bind(this)}
                                        formText={"customer.form.label.formText"}
                        />
                    </Form>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() => this.showModal('create')}
                    hide={() => this.hideModal('create')}
                    title="Kategorie erfolgreich angelegt"
                    color="success"
                    message={'Anlegen des Händlers erfolgreich. Möchten Sie einen weiteren Händler anlegen?'}
                    onCancel={(e) => {
                        this.switchToPage(e, PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    onSuccess={(e) => {
                        this.clearFieldsOnNewCustomer(e);
                        this.props.dispatch(removeCurrentMessage());
                    }}
                    successText={'Ja'}
                    cancelText={'Nein'}
                />
            </div>
        );
    }
}

CategoryForm.propTypes = {};
function mapStateToProps(state) {
    return {};
}
export default withRouter(connect(mapStateToProps)(CategoryForm));
