import React, {Component} from 'react';
import Button from "reactstrap/es/Button";
import {changePassword, loadCurrentUser, removeCurrentMessage, suspendAccount} from "../actions";
import {getCurrentUser} from "../reducers/UserReducer";
import {connect} from "react-redux";
import {Message, User} from "../proptypes";
import PropTypes from "prop-types"
import {withRouter} from "react-router";
import ModalComponent from "../components/Modals/ModalComponent";
import {PATH_LOGIN_PAGE} from "../routePaths";
import Form from "reactstrap/es/Form";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Input from "reactstrap/es/Input";
import FormText from "reactstrap/es/FormText";
import FormFeedback from "reactstrap/es/FormFeedback";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import Modal from "reactstrap/es/Modal";
import {getCurrentMessage} from "../reducers/MessageReducer";

class AdminSettingsPage extends Component {

    constructor(props) {
        super(props);

        this.deactivateAccount = this.deactivateAccount.bind(this);
        this.showSuspendAccountModal = this.showSuspendAccountModal.bind(this);
        this.hideSuspendAccountModal = this.hideSuspendAccountModal.bind(this);
        this.suspendAccount = this.suspendAccount.bind(this);
        this.changePassword = this.changePassword.bind(this);

        this.state = {
            showSuspendAccountModal: false,
            password: '',
            repeat: '',
            showPasswordChangeModal: false
        }
    }

    componentDidMount() {
        this.props.dispatch(loadCurrentUser())
    }

    componentWillReceiveProps(nextProps) {
        const nextMessage = nextProps.currentMessage;
        if (nextMessage && nextMessage.source === 'CHANGE_PASSWORD') {
            this.showPasswordChangeModal();

            if (nextMessage.type === 'INFO') {
                this.setState({
                    password: '',
                    repeat: ''
                });
            }
        }
    }

    showPasswordChangeModal() {
        this.setState({
            showPasswordChangeModal: true
        });
    }

    hidePasswordChangeModal() {
        this.setState({
            showPasswordChangeModal: false
        });

        this.props.dispatch(removeCurrentMessage());
    }

    isPasswordEmpty() {
        const password = this.state.password;
        return password ? password.length === 0 : true;
    }

    isPasswordValid() {
        if (!this.isPasswordEmpty()) {
            const password = this.state.password;
            return password.length > 3;
        }
        return false;
    }

    isRepeatPasswordEmpty() {
        const repeatPass = this.state.repeat;
        return repeatPass ? repeatPass.length === 0 : true;
    }

    isRepeatPasswordValid() {
        if (this.isPasswordValid() && !this.isRepeatPasswordEmpty()) {
            const password = this.state.password;
            const repeat = this.state.repeat;
            return password === repeat;
        }
        return false;
    }

    handlePasswordChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'password') {
            this.setState({
                password: value
            });
        }
        if (name === 'repeat') {
            this.setState({
                repeat: value
            });
        }
    }

    changePassword(event) {
        event.preventDefault();

        const user = this.props.currentUser;
        const email = user.credentials.email;
        const password = this.state.password;
        const credentials = {email, password};

        this.props.dispatch(changePassword(user, credentials));
    }

    deactivateAccount(e) {
        e.preventDefault();
        this.showSuspendAccountModal();
    }

    showSuspendAccountModal() {
        this.setState({
            showSuspendAccountModal: true
        });
    }

    hideSuspendAccountModal() {
        this.setState({
            showSuspendAccountModal: false
        });
    }

    suspendAccount() {
        const userId = this.props.currentUser.id;
        this.props.dispatch(suspendAccount(userId));
    }

    goToPage(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <div className="page-content">
                <div className="container ">
                        <h1 className="headline">
                            Einstellungen
                        </h1>

                    <div className="row mb-3">
                        <div className="col">
                            <h5>
                                Passwort ändern:
                            </h5>
                        </div>

                        <Form className="col-md-6">
                            <FormGroup>
                                <Label for="email">E-Mail-Adresse</Label>
                                <Input
                                    type="text" name="email" id="email" disabled
                                    defaultValue={this.props.currentUser.credentials.email}
                                />
                                <FormText>Wenn Sie ihre Email für die Anmeldung ändern möchten, richten Sie sich bitte
                                    an unseren Support</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">neues Passwort:</Label>
                                <Input
                                    type="password" name="password" id="password"
                                    invalid={!this.isPasswordEmpty() && !this.isPasswordValid()}
                                    value={this.state.password} onChange={this.handlePasswordChange.bind(this)}
                                />
                                <FormFeedback>Ein Passwort besteht aus mindestens vier Zeichen, beachten Sie bitte Groß-
                                    und Kleinschreibung</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="repeat">Passwort wiederholen:</Label>
                                <Input
                                    type="password" name="repeat" id="repeat"
                                    invalid={!this.isRepeatPasswordEmpty() && !this.isRepeatPasswordValid()}
                                    value={this.state.repeat} onChange={this.handlePasswordChange.bind(this)}
                                />
                                <FormFeedback>Die Passwörter sind unterschiedlich</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    className="btn btn-primary btn-block" type="submit" color="primary"
                                    disabled={!this.isRepeatPasswordValid()} onClick={(e) => this.changePassword(e)}
                                >
                                    Passwort ändern
                                </Button>
                            </FormGroup>
                        </Form>
                        <div className="col"/>
                    </div>

                    <div className="d-flex flex-column offset-3 col-6">
                        <Button onClick={(e) => {
                            this.deactivateAccount(e)
                        }} className="mb-2">Account deaktivieren</Button>
                    </div>
                </div>
                {/* Suspend Account Modal*/}
                <ModalComponent
                    isOpen={this.state.showSuspendAccountModal}
                    toggle={this.showSuspendAccountModal}
                    hide={this.hideSuspendAccountModal}
                    color="danger"
                    title="Account deaktivieren"
                    message="Sind Sie sicher, dass Sie ihren Account deaktivieren möchten?"
                    onSuccess={() => {
                        this.hideSuspendAccountModal();
                        this.suspendAccount();
                        this.goToPage(PATH_LOGIN_PAGE)
                    }}
                    onCancel={() => this.hideSuspendAccountModal()}
                    cancelText="Nein"
                    successText="Account deaktivieren"
                />

                <Modal isOpen={this.state.showPasswordChangeModal} fade={false}
                       toggle={() => this.showPasswordChangeModal()}>
                    <ModalHeader toggle={() => this.hidePasswordChangeModal()}/>
                    <ModalBody className="text-center">
                        {(this.props.currentMessage && this.props.currentMessage.type === 'INFO' && this.props.currentMessage.source === 'CHANGE_PASSWORD') &&
                        <div className="alert alert-success">
                            {this.props.currentMessage.msg}
                        </div>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'CHANGE_PASSWORD') &&
                        <div className="alert alert-danger">
                            {this.props.currentMessage.msg}
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.hidePasswordChangeModal()}>Schließen</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

AdminSettingsPage.propTypes = {
    currentUser: PropTypes.shape(User),
    currentMessage: PropTypes.shape(Message)
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(AdminSettingsPage));
