import React, {Component} from 'react';
import ContactComponent from "./Parts/ContactComponent";
import InputComponent from "./InputComponent";
import {Button} from "reactstrap";
import _ from "lodash";
import {createOrganization, getOrganization, removeCurrentMessage, updateOrganization} from "../../actions";
import {FormattedMessage} from "react-intl";
import ModalComponent from "../Modals/ModalComponent";
import {PATH_ADMIN_ORGANIZATION_OVERVIEW} from "../../routePaths";
import {getCurrentOrganization} from "../../reducers/OrganizationReducer";
import {connect} from "react-redux";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {isValidPhone, notEmpty} from "../../util/ValidFn";
import {withRouter} from "react-router";

class OrganizationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organization :{
                name: undefined,
                number: undefined,
                contact: {
                    email: undefined,
                    fax:undefined,
                    mobile:undefined,
                    phone:undefined
                }
            },
            isContactValid:true,
            modals: {
                create: false,
                change: false,
                error: false
            },
        }
    }
    componentDidMount() {
        if (this.props.edit) {
            console.log(this.props);
            let organization = this.props.organization;
            if(!organization.contact)
            organization.contact = {
                "phone": undefined
            }
            this.props.dispatch(getOrganization(this.props.organization.number))
        }
    }
    componentDidUpdate(prevProps) {
        const nextMessage = this.props.currentMessage;
        if (nextMessage !== prevProps.currentMessage) {
            if (nextMessage && (nextMessage.source === 'CHANGE_SO' && nextMessage.type === 'INFO')) {
                this.showModal('edit');
            }
            if (nextMessage && (nextMessage.source === 'CREATE_SO' && nextMessage.type === 'INFO')) {
                this.showModal('create');
            }
            if (nextMessage && (nextMessage.type === 'ERROR' && nextMessage.source ==='SO')) {
                this.showModal('error');
            }
        }
        if(this.props.organization !== prevProps.organization){
           this.setOrganization(this.props.organization)
        }
    }
    setOrganization(organization) {
        this.setState({organization})
    }

    updateSalesOrganization() {
        this.props.dispatch(updateOrganization(this.state.organization));
    }

    createSalesOrganization() {
        this.props.dispatch(createOrganization(this.state.organization))
    }
    switchToPage(e, pathToPage) {
        this.props.history.push(pathToPage);

        if (e) {
            e.preventDefault();
        }
        return false;
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }
    handleSoData(event){
        const so = Object.assign({}, this.state.organization);
        so[event.target.name] = event.target.value;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.organization = so;
            return nextState;
        });
    }
    handleContact(event) {
        const contact = Object.assign({}, this.state.organization.contact);
        contact[event.target.name] = event.target.value;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.organization.contact = contact;
            return nextState;
        });
    }
    isNewFormComplete(){
        const organization = this.state.organization;
        const contact = organization.contact

        return notEmpty(organization.name) && notEmpty(organization.number) && notEmpty(contact.email)  && notEmpty(contact.phone)
            && isValidPhone(contact.phone) && this.state.isContactValid
    }
    isContactValid(cb){
        this.setState({isContactValid: cb})
    }
    render() {
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {this.props.edit &&
                        <Button
                            color="success" href="" className="btn-block"
                            onClick={(e) => this.updateSalesOrganization(e)}
                            disabled={!this.isNewFormComplete()}
                        >
                            <FormattedMessage id="worker.form.button.updateWorker"/>
                        </Button>
                        }
                        {this.props.create &&
                        <Button
                            href=""
                            className="btn-block"
                            onClick={(e) => this.createSalesOrganization(e)}
                            disabled={!this.isNewFormComplete()}
                        >
                            <FormattedMessage id="worker.form.button.createWorker"/>
                        </Button>
                        }
                    </div>
                </div>

            {/*    TODO: customerNumbers*/}
            <InputComponent
                title={"salesOrganization.form.name.title"}
                type={"text"}
                name={"name"}
                value={this.state.organization.name}
                handleChange={this.handleSoData.bind(this)}
                formFeedback={"salesOrganization.form.name.formFeedback"}
                required
            />
            <InputComponent
                title={"salesOrganization.form.number.title"}
                type={"text"}
                name={"number"}
                value={this.state.organization.number}
                handleChange={this.handleSoData.bind(this)}
                formFeedback={"salesOrganization.form.number.formFeedback"}
                required
            />
            <ContactComponent
                state={this.state.organization.contact}
                handler={this.handleContact.bind(this)}
                isValid={this.isContactValid.bind(this)}
                emailRequired
            />
            <ModalComponent
                isOpen={this.state.modals.edit}
                toggle={() => this.showModal('edit')}
                hide={() => this.hideModal('edit')}
                color="success"
                title="Verkaufsorganisation aktualisiert"
                message={'Verkaufsorganisation wurde aktualisiert"'}
                onSuccess={(e) => {
                    this.switchToPage(e, PATH_ADMIN_ORGANIZATION_OVERVIEW);
                    this.props.dispatch(removeCurrentMessage())
                }}
                successText={'Ok'}
            />

            <ModalComponent
                isOpen={this.state.modals.create}
                toggle={() => this.showModal('create')}
                hide={() => this.hideModal('create')}
                color="success"
                title="Verkaufsorganisation erstellt"
                message={'Verkaufsorganisation wurde erstellt'}
                onSuccess={(e) => {
                    this.switchToPage(e, PATH_ADMIN_ORGANIZATION_OVERVIEW);
                    this.props.dispatch(removeCurrentMessage())
                }}
                successText={'Ok'}
            />
                {this.props.currentMessage && this.props.currentMessage.msg &&
                    <ModalComponent
                        isOpen={this.state.modals.error}
                        toggle={() => this.showModal('error')}
                        hide={() => this.hideModal('error')}
                        color="danger"
                        title="Fehler aufgetreten"
                        message={this.props.currentMessage.msg}
                        onSuccess={() => {
                            this.props.dispatch(removeCurrentMessage());
                            this.hideModal('error')
                        }}
                        successText={'Ok'}
                    />
                }
            </div>
        );
    }

}

OrganizationForm.propTypes = {};
function mapStateToProps(state) {
    return {
        organization:getCurrentOrganization(state).organization,
        currentMessage: getCurrentMessage(state),
    }
}
export default withRouter(connect(mapStateToProps)(OrganizationForm));
