import React, {Component} from 'react';
import Button from "reactstrap/lib/Button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {PATH_ADMIN_PRODUCT_CREATE_PAGE, PATH_ADMIN_PRODUCT_EDIT_PAGE} from "../../routePaths";
import Select from "react-virtualized-select";
import {injectIntl} from "react-intl";
import Form from "reactstrap/lib/Form";
import Table from "reactstrap/es/Table";
import Row from "reactstrap/lib/Row";
import classNames from "classnames";
import {getCategories} from "../../reducers/CategoryReducer";
import _ from "lodash";
import {Label} from "reactstrap";
import FormGroup from "reactstrap/es/FormGroup";
import {getAllProducts, getSearchProducts} from "../../reducers/ProductsReducer";
import SearchBar from "../../components/SearchBar";
import ModalComponent from "../../components/Modals/ModalComponent";
import {
    deleteProduct,
    loadAllProducts,
    loadProductGroupsSearch, loadRootCategoryTree,
    removeCurrentMessage,
    setSearchResultProducts
} from "../../actions";
import {fetchProduct} from "../../actions";
import CheckBoxComponent from "../../components/Form/CheckBoxComponent";

class ProductOverviewPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: "DE",
            searchTerm: "",
            filter: {
                brand: undefined,
                category: undefined,
                subCategory: undefined
            },
            categories: [],
            subCategories: [],
            products: [],
            highlightedRow: 0,
            filteredProducts: undefined,
            modals: {
                delete: false,
                error: false
            },
            selectedProductNumber: undefined,
            noSale: false,
            noCategory: false,
            noSaleProducts: undefined
        }
    }

    componentDidMount() {
        this.resetSearch();
        this.props.dispatch(loadRootCategoryTree())
        this.props.dispatch(loadAllProducts())
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        let prevBrandState = prevState.filter.brand;
        let brandState = this.state.filter.brand;
        if (prevBrandState !== brandState) {
            if (brandState)
                this.setCategories(brandState)
        }
        let prevCategoryState = prevState.filter.category;
        let categoryState = this.state.filter.category;
        if (prevCategoryState !== categoryState) {
            if (this.state.filter.category)
                this.setSubCategories(categoryState)
        }
        let prevProducts = prevProps.products
        let products = this.props.products;
        if (prevProducts !== products) {
            this.setState({products: products})
        }

        if (this.state.noSale !== prevState.noSale) {
            if (this.state.noSale) {
                this.filterProductsByNoSale()
            } else {
               this.setState({noSaleProducts: undefined})
            }
        }
        if (this.state.noCategory !== prevState.noCategory) {
            if (this.state.noCategory) {
                this.filterProductsByNoCategory()
            }
        }
    }

    highlightRow(index) {
        this.setState({highlightedRow: index})
    }

    setCategories(brand) {
        const categories = brand.children;
        categories.map(
            (c) => {
                if (!c.label)
                    return c.label = c.name
                return c
            }
        )
        this.setState({categories: categories})
    }

    setSubCategories(category) {
        const subCategories = category.children;
        if (subCategories) {
            subCategories.map(
                (c) => {
                    if (!c.label)
                        return c.label = c.name
                    return c
                }
            )
        }
        this.setState({subCategories: subCategories})
    }

    goToPage(path, product) {
        if (product) {
            this.props.dispatch(fetchProduct(product.productNumber))
        }
        this.props.history.push(path);
    }

    handleBrandFilter(brand) {
        const filter = Object.assign({}, this.state.filter);
        filter.brand = brand;
        filter.category = {}
        filter.subCategory = {}
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.filter = filter;
                return nextState;
            });
        if (brand) {
            const productNumbers = this.recursiveFindProducts(brand)
            this.filterProductListByProductNumbers(productNumbers);
        } else {
            this.setState({filteredProducts: undefined})
            this.setState({products: this.props.products})
        }
    }

    handleCategoryFilter(category) {
        const filter = Object.assign({}, this.state.filter);
        filter.category = category;
        filter.subCategory = {}
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.filter = filter;
                return nextState;
            });
        if (category) {
            const productNumbers = this.recursiveFindProducts(category)
            this.filterProductListByProductNumbers(productNumbers);
        }
    }

    handleSubCategoryFilter(subCategory) {
        const filter = Object.assign({}, this.state.filter);
        filter.subCategory = subCategory;
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.filter = filter;
                return nextState;
            });
        if (subCategory) {
            const productNumbers = this.recursiveFindProducts(subCategory)
            this.filterProductListByProductNumbers(productNumbers);
        }
    }

    recursiveFindProducts(brand) {
        let productNumbers = [];
        rec(brand);

        function rec(node) {
            if (node.children && node.children.length > 0) {
                node.children.forEach((child) => {
                    rec(child)
                })
            }
            if (node.productNumbers)
                productNumbers = productNumbers.concat(node.productNumbers)
        }

        return productNumbers
    }

    filterProductListByProductNumbers(productNumbers) {
        const products = this.props.products;
        let filteredProducts;
        filteredProducts = _.filter(products, (product) => {
            return productNumbers.includes(product.productNumber);
        });
        this.setState({filteredProducts: filteredProducts})
    }

    loadProductGroupSearch(event) {
        event.preventDefault();
        const search = this.state.searchTerm;
        this.setState({
            searchTerm: search
        });

        this.props.dispatch(loadProductGroupsSearch(search));

        return false;
    }

    handleSearchInput(event) {
        const search = event.target.value;
        this.setState({
            searchTerm: search
        });
    }

    resetSearch() {
        this.setState({searchTerm: ""})
        this.props.dispatch(setSearchResultProducts(undefined))
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    filterProductsByNoCategory() {
        const noCategoryProducts = _.filter(this.state.products, (product) => {
            return product.categoryNumbers.length === 0;
        })
        console.log(noCategoryProducts);
        this.setState({products: noCategoryProducts})
    }

    filterProductsByNoSale() {
        const products = this.state.filteredProducts || this.state.products || []
        const noSaleProducts = _.filter(products, (product) => {
            return product.data.forSales["DE"] === false
        })
        this.setState({noSaleProducts:noSaleProducts})
    }

    onCheckChange(e) {
        const target = e.target;
        const name = target.name;
        this.setState({[name]: target.checked})

    }

    render() {
        const brands = this.props.brands || [];
        const categories = this.state.categories || [];
        const subCategories = this.state.subCategories || [];
        const products = this.props.searchProducts || this.state.noSaleProducts || this.state.filteredProducts || this.state.products || [];
        const lang = this.state.lang;
        return (
            <div className="page-content">
                <div className="container">
                    <h1 className="headline">
                        Produkt-Übersicht
                    </h1>
                    <div className="mb-4">
                        <Button color="primary" onClick={() => this.goToPage(PATH_ADMIN_PRODUCT_CREATE_PAGE)} size="sm">
                            Neues Produkt anlegen
                        </Button>
                    </div>
                    <SearchBar
                        handleSearch={this.loadProductGroupSearch.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                    />
                    <div>
                        <Form inline className="d-flex flex-row m-2 justify-content-between">
                            <FormGroup className="m-2">
                                <Label className="mb-2 mr-sm-2 mb-sm-0">Marke:</Label>
                                <Select style={{minWidth: 400}} name={"brand"} id={"brand"} options={brands}
                                        value={this.state.filter.brand}
                                        onChange={this.handleBrandFilter.bind(this)}
                                        disabled={this.state.noCategory}
                                />
                            </FormGroup>
                            <FormGroup className="m-1">
                                <Label className="mb-2 mr-sm-2 mb-sm-0">Produkthauptgruppe:</Label>
                                <Select style={{minWidth: 400}} name={"category"} id={"category"} options={categories}
                                        value={this.state.filter.category}
                                        onChange={this.handleCategoryFilter.bind(this)}
                                        disabled={this.state.noCategory}
                                />
                            </FormGroup>
                        </Form>
                        <Form inline className="d-flex flex-row m-2 justify-content-end">
                            <FormGroup className="m-2">
                                <Label className="mb-2 mr-sm-2 mb-sm-0">Produktuntergruppe:</Label>
                                <Select style={{minWidth: 400}} name={"subCategory"} id={"subCategory"}
                                        options={subCategories}
                                        value={this.state.filter.subCategory}
                                        onChange={this.handleSubCategoryFilter.bind(this)}
                                        disabled={this.state.noCategory}
                                />
                            </FormGroup>
                        </Form>
                    </div>
                    <div>
                        <Form inline className="d-flex flex-row m-2">
                            <FormGroup className="mr-4">
                                <CheckBoxComponent
                                    name={"noCategory"}
                                    checked={this.state.noCategory}
                                    label={"ohne Kategorie"}
                                    onChange={(e) => {
                                        this.onCheckChange(e)
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <CheckBoxComponent
                                    name={"noSale"}
                                    checked={this.state.noSale}
                                    label={"Für den Verkauf nicht Freigegeben"}
                                    onChange={(e) => {
                                        this.onCheckChange(e)
                                    }}
                                />
                            </FormGroup>
                        </Form>

                    </div>
                    <Row>
                        <Table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {products.map((product, index) => {
                                let name = product.names[lang];
                                let productDefinition = ""
                                if (product.data.formatDefinitions)
                                    productDefinition = product.data.formatDefinitions[lang] || "";
                                return (
                                    <tr style={{height: 67}}
                                        key={index}
                                        className={classNames({highlight: (this.state.highlightedRow === index)})}
                                        onMouseEnter={() => this.highlightRow(index)}
                                    >
                                        <th scope="row">{product.productNumber}</th>
                                        <td>{name} <br/>
                                            <small>{productDefinition}</small>
                                        </td>
                                        <td>
                                            {(this.state.highlightedRow === index) &&
                                            <div className="float-right">
                                                    <span>
                                                        <Button onClick={() => {
                                                            this.goToPage(PATH_ADMIN_PRODUCT_EDIT_PAGE, product)
                                                        }}>
                                                            <span className="oi oi-pencil"/> bearbeiten
                                                        </Button>
                                                    </span>
                                                <span className="ml-2">
                                                        <Button onClick={() => {
                                                            this.showModal('delete')
                                                            this.setState({'selectedProductNumber': product.productNumber})
                                                        }}>
                                                            <span className="oi oi-trash"/> löschen
                                                        </Button>
                                                    </span>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Row>
                </div>

                <ModalComponent
                    isOpen={this.state.modals.delete}
                    toggle={() =>
                        this.showModal('delete')
                    }
                    hide={() =>
                        this.hideModal('delete')
                    }
                    color="danger"
                    title="Produkt löschen"
                    message={'Wollen Sie dieses Produkt wirklich löschen?'}
                    onSuccess={(e) => {
                        this.props.dispatch(deleteProduct(this.state.selectedProductNumber))
                        this.props.dispatch(removeCurrentMessage())
                        this.hideModal('delete')
                        this.resetSearch();
                        e.preventDefault();
                    }
                    }
                    successText={'Produkt löschen'}
                    cancelText={'Abbrechen'}
                    onCancel={() => this.hideModal('delete')}
                />
            </div>
        );
    }
}

ProductOverviewPage.propTypes = {};

function mapStateToProps(state) {
    return {
        brands: getCategories(state).stateCategories || [],
        products: getAllProducts(state),
        searchProducts: getSearchProducts(state)
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(ProductOverviewPage)));
