class Auth {
    constructor() {
        this.role = undefined;
        this.user = undefined;
    }

    getUser() {
        return this.user;
    }

    setUser(user) {
        this.user = user;
        this.setRole(user.role)
    }

    getRole() {
        return this.role
    }

    setRole(role) {
        this.role = role;
    }

    isAdmin() {
        return (this.role === 'ADMIN')
    }

    logOut() {
        this.user = undefined;
        this.role = undefined;
    }
}

export default new Auth();
