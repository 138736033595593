import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import {injectIntl} from "react-intl";
import OrganizationForm from "../../components/Form/OrganizationForm";
import {PATH_ADMIN_ORGANIZATION_OVERVIEW} from "../../routePaths";

class EditOrganizationPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton path={PATH_ADMIN_ORGANIZATION_OVERVIEW}/>
                    <h1 className="h2 text-center mb-4">
                        Verkaufsorganisation editieren
                    </h1>
                    <OrganizationForm edit/>
                </div>
            </div>
        );
    }
}

EditOrganizationPage.propTypes = {};

export default injectIntl(EditOrganizationPage);
