import {LOGOUT, CLEAR_CUSTOMER, LOAD_CUSTOMER} from '../actions/types';

const initialState = {
  stateCustomer: null
};

const CustomerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateCustomer: null
      };
    }
    case LOAD_CUSTOMER: {
      return {
        ...state,
        stateCustomer: action.customer
      };
    }
    case CLEAR_CUSTOMER: {
      return {
        ...state,
        stateCustomer: null
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCustomer = (state) => (state.customer ? state.customer.stateCustomer : null);

export default CustomerReducer;
