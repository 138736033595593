import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {getCurrentMessage} from '../../reducers/MessageReducer';
import {getCustomer} from '../../reducers/CustomerReducer';

import {
    Button,
    Collapse,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';

import {getScaledPrice} from '../../util/priceHelper';
import {
    addToCart,
    attachProductToCustomerCategory,
    detachProductFromCustomerCategory,
    getProductImageUrl,
    removeCurrentMessage
} from "../../actions";
import ProductDetail from "./ProductDetail";
import {Cart, Category, Customer, Message, ProductGroups} from "../../proptypes";
import {PACKAGINGTYPES} from "../../enums/PackagingTypes";
import {injectIntl} from "react-intl";
import ProductLoadComponent from "./ProductLoadComponent";
import ScalePriceChart from "./ScalePriceChart";
import {getCustomerProductGroups, getCustomerProductNumbers} from "../../reducers/ProductReducer";
import FavoriteComponent from "../FavoriteComponent";
import {getCurrentUser} from "../../reducers/UserReducer";

export const MAX_AMOUNT_OF_UNITS_ADD_TO_CART = 4000;

class ProductList extends Component {

    static isPaletteProduct(product) {
        if (product) {
            const data = product.data;
            return data.leastUnitsPackage >= 1 && data.leastUnitsPackage === data.unitsPerPackage;
        }
        return false;
    }

    static isSingleProduct(product) {
        if (product) {
            const data = product.data;
            return data.leastUnitsPackage === 1 && data.leastUnitsPackage < data.unitsPerPackage;
        }
        return false;
    }

    static isPackagingProduct(product) {
        if (product) {
            const data = product.data;
            return data.unitsPerPackage > 1 && data.unitsPerPackage >= data.leastUnitsPackage;
        }
        return false;
    }

    static productName(product) {
        return product ? product.name : '';
    }

    static productNameFormat(product) {
        if (product) {
            const data = product.data;
            let itemName = data.formatDefinition;
            if (!itemName) {
                itemName = data.specifiedSize;
            }
            return !itemName ? '' : ` - ${itemName}`;
        }
        return '';
    }

    static calculateUsageLFM(product, length) {
        if (product) {
            const data = product.data;
            if (data.leastUnitsPackage === data.unitsPerPackage) {
                const usagePalette = data.usagePerPalette;
                return Math.ceil(length / usagePalette);
            }

            const usageLFM = data.materialUsageLFM;
            return Math.ceil(length / usageLFM);
        }
        return null;
    }

    static calculateUsageM2(product, square) {
        if (product) {
            const data = product.data;
            if (data.leastUnitsPackage === data.unitsPerPackage) {
                const usagePalette = data.usagePerPalette;
                return Math.ceil(square / usagePalette);
            }

            const usageM2 = data.materialUsageM2;
            return Math.ceil(square / usageM2);
        }
        return null;
    }

    static quantityChoice() {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
    }

    static productsList(productGroup) {
        const productList = productGroup.products;

        return _.filter(productList, (product) => {
            if(product.data.forSales)
                return product.data.forSales["DE"] === true;
            else
                return false;
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            quantityModal: false,
            addToCartModal: false,
            productQuantity: {},
            modalProduct: null,
            propProductGroupCollapsed: {110111020: false},
            lang: 'DE',
            country: 'DE',
            open: null,
            openScaleChart:undefined,
            endItems:100,
            offerProductNumbers: []
        };
    }
    componentDidMount(){
        document.querySelector('#root').addEventListener('scroll',this.handleScroll.bind(this));
    }
    componentWillUnmount() {
        document.querySelector('#root').removeEventListener('scroll',this.handleScroll.bind(this));
    }

    componentDidUpdate(prevProps) {
        const nextMessage = this.props.currentMessage;
        if (nextMessage && nextMessage !== prevProps.currentMessage && (nextMessage.source === 'ADD_CART_ITEM' || nextMessage.source === 'MAX_LOAD_FAILED')) {
            this.showAddToCartModal();
            if (nextMessage.type === 'INFO') {
                // auto close message on info message
                window.setTimeout(() => {
                    this.hideAddToCartModal();
                }, 1500);
            }
        }

        const productGroups = this.productGroupList(this.props.propProductGroups);
        if (this.props.propProductGroups !== prevProps.propProductGroups) {
            this.setState({endItems:50})
            productGroups.map((productGroup) => {
                ProductList.productsList(productGroup)
                    .map((product) => {
                        const quantity = this.productInCurrentCartQuantity(product);

                        const productQuantity = this.getProductQuantity(product);
                        if (!productQuantity) {
                            this.setProductQuantity(product.productNumber, quantity);
                        }
                        return true;
                    });
                return true;
            });
        }
        if(this.props.offerProductNumbers !== prevProps.offerProductNumbers){
            const offerProductNumbers = this.props.offerProductNumbers;
            const numbers = _.map(offerProductNumbers,'productNumber')
            this.setState({offerProductNumbers:numbers})
        }
    }

    getQuantityModalHeader(product) {
        if (product) {
            return this.getPluralPackagingTypeLabel(product, 'Anzahl der ');
        }
        return '';
    }

    getQuantityModalLabel(product) {
        if (product) {
            return this.getPluralPackagingTypeLabel(product, '');
        }
        return '';
    }

    setProductQuantity(productNumber, quantity) {
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.productQuantity[productNumber] = quantity;

            return nextState;
        });
    }

    setModalQuantity(event) {
        const quantity = event.target.value;
        this.updateModalQuantity(quantity);
    }

    setModalQuantityLength(event) {
        const length = event.target.value;
        if (length > 0) {
            const product = this.state.modalProduct;
            if (product) {
                const quantity = ProductList.calculateUsageLFM(product, length);
                if (quantity) {
                    this.updateModalQuantity(quantity);
                }
            }
        }
    }

    setModalQuantitySquare(event) {
        const square = event.target.value;
        if (square > 0) {
            const product = this.state.modalProduct;
            if (product) {
                const quantity = ProductList.calculateUsageM2(product, square);
                if (quantity) {
                    this.updateModalQuantity(quantity);
                }
            }
        }
    }

    static getFormattedUsage(product, quantity) {
        const data = product.data;
        let usage = 0;

        if (ProductList.isPackagingProduct(product)) {
            // as a common rule the shop is unit based, but since sievert calculates m^2 and LFM on purchase unit (e.g. PAK, PAL, ROL)
            if (data.materialUsageM2) {
                usage = data.materialUsageM2 * quantity;

            } else if (data.materialUsageLFM) {
                usage = data.materialUsageLFM * quantity;
            }

        } else {
            if (data.materialUsageM2) {
                usage = data.materialUsageM2 * quantity;

            } else if (data.materialUsageLFM) {
                usage = data.materialUsageLFM * quantity;
            }
        }

        if (usage < 0.1) {
            return '';
        }

        const options = {
            minimumFractionDigits: 1, maximumFractionDigits: 1
        };
        return new Intl.NumberFormat('de-DE', options).format(usage);
    }

    static getFormattedUnits(product, quantity) {
        let unitsQuantity;
        if (ProductList.isSingleProduct(product) || ProductList.isPackagingProduct(product)) {
            unitsQuantity = quantity;
        } else {
            unitsQuantity = quantity * product.data.leastUnitsPackage
        }

        const options = {
            minimumFractionDigits: 0, maximumFractionDigits: 0
        };
        return new Intl.NumberFormat('de-DE', options).format(unitsQuantity);
    }

    setModalQuantityPalettes(event) {
        const palettes = event.target.value;
        if (palettes > 0) {
            const product = this.state.modalProduct;
            if (product) {
                const quantity = this.getFullPalettesQuantity(product, palettes);
                if (quantity) {
                    this.updateModalQuantity(quantity);
                }
            }
        }
    }

    getProductQuantity(product) {
        let quantity = '';
        if (product) {
            quantity = this.state.productQuantity[product.productNumber];
            if (!quantity) {
                // a controlled input must not be initialized with undefined or null
                quantity = '';
            }
        }
        return quantity;
    }

    getFullPalettesQuantity(product, quantity) {
        if (product && quantity) {
            let unitsPalettesQuantity;
            if (ProductList.isPaletteProduct(product)) {
                unitsPalettesQuantity = quantity;
            } else {
                unitsPalettesQuantity = quantity * product.data.unitsPerPackage
            }
            return unitsPalettesQuantity;
        }
        return null;
    }

    updateModalQuantity(quantity) {
        if (quantity) {
            this.setState(currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);

                nextState.modalQuantity = quantity;

                return nextState;
            });
        }
    }

    toggleQuantityModal(product) {
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.quantityModal = !nextState.quantityModal;

            if (product) {
                nextState.modalProduct = product;
            }

            return nextState;
        });
    }

    showAddToCartModal() {
        this.setState({
            addToCartModal: true
        });
    }

    hideAddToCartModal() {
        this.setState({
            addToCartModal: false
        });

        this.props.dispatch(removeCurrentMessage());
    }

    productGroupList(propProductGroups) {
        if (propProductGroups) {
            const groupList = propProductGroups.productGroups;
            // const groupList = _.map(groups, group => {
            //     return group;
            // });

            return _.filter(groupList, (group) => {
                const products = ProductList.productsList(group);
                return products.length > 0;
            });
        }
        return [];
    }

    hasProductGroups(propProductGroups) {
        const productGroupList = this.productGroupList(propProductGroups);
        return productGroupList.length > 0;
    }

    submitModalFormQuantity(event) {
        event.preventDefault();
        let quantity = this.state.modalQuantity;
        if (quantity > MAX_AMOUNT_OF_UNITS_ADD_TO_CART) {
            quantity = MAX_AMOUNT_OF_UNITS_ADD_TO_CART;
        }

        if (quantity > 0) {
            const product = this.state.modalProduct;
            if (product) {
                this.setProductQuantity(product.productNumber, quantity);
            }
        }

        this.updateModalQuantity(0);
        this.toggleQuantityModal();
    }

    removeProductQuantity(event) {
        event.preventDefault();

        const product = this.state.modalProduct;
        this.setProductQuantity(product.productNumber, null);

        this.updateModalQuantity(0);
        this.toggleQuantityModal();
    }

    resetProductQuantity(event) {
        event.preventDefault();

        const product = this.state.modalProduct;

        const quantity = this.productInCurrentCartQuantity(product);
        this.setProductQuantity(product.productNumber, quantity);

        this.updateModalQuantity(quantity);
        this.toggleQuantityModal();
    }

    submitModalButtonQuantity(event, quantity) {
        if (event) {
            const product = this.state.modalProduct;
            if (product) {
                this.setProductQuantity(product.productNumber, quantity);
                this.toggleQuantityModal();
            }
        }
    }

    isOpenProductGroup(productGroupId) {
        const category = this.props.propCategory;
        if (!category || category.customerId === 'search-term' || category.customerId) {
            //TODO: STATE -> PROPS TEST
            return !this.state.propProductGroupCollapsed[productGroupId];
        }
        //TODO: STATE -> PROPS TEST
        return this.state.propProductGroupCollapsed[productGroupId];
    }

    toggleProductGroup(productGroupId) {

        this.toggleCollapse(productGroupId);
    }

    toggleCollapse(productGroupId) {
        if (this.state.open === productGroupId) {
            this.setState({open: null})
        } else {
            this.setState({open: productGroupId})
        }
    }

    addToCart(e, product, overrideQuantity, load) {
        e.preventDefault();

        if (product) {
            let quantity;
            if (overrideQuantity) {
                quantity = overrideQuantity;
            } else {
                quantity = this.getProductQuantity(product);
            }

            if (quantity) {
                const cart = this.props.currentCart;
                if (cart) {
                    const productNumber = product.productNumber;
                    if (load)
                        this.props.dispatch(addToCart(cart, productNumber, quantity, load));
                    else {
                        this.props.dispatch(addToCart(cart, productNumber, quantity));
                    }
                    this.setProductQuantity(productNumber, quantity);
                }
            }
        }
        return false;
    }

    productInCurrentCart(product) {
        const currentCart = this.props.currentCart;
        if (currentCart && product) {
            const products = _.filter(currentCart.orderItems, (orderItem) => {
                return product.productNumber === orderItem.productNumber;
            });
            if (products.length > 0) {
                return products[0];
            }
        }
        return null;
    }

    productInCurrentCartQuantity(product) {
        const orderItemInCart = this.productInCurrentCart(product);
        if (orderItemInCart) {
            let quantity = orderItemInCart.quantityApproved;
            if (!quantity) {
                quantity = orderItemInCart.maxedOutQuantity;
            }
            if (!quantity) {
                quantity = orderItemInCart.quantity;
            }
            if (quantity) {
                return quantity;
            }
        }
        return 0;
    }

    getDescription(product) {
        if (product && product.detail && product.detail.descriptions && product.detail.descriptions["DE"]) {
            return product.detail.descriptions["DE"]
        }
    }

    getProductGroupName(productGroup) {
        const lang = this.state.lang;
        if (productGroup.imageProductNames && productGroup.imageProductNames[lang]) {
            return productGroup.imageProductNames[lang]
        }
        if (productGroup.products && productGroup.products[0] && productGroup.products[0].names[lang]) {
            return productGroup.products[0].names[lang]
        }
        return "no name found"
    }

    getProductExternalUrl(productGroup) {
        const lang = this.state.lang;
        if (productGroup.imageProductExternalUrls && productGroup.imageProductExternalUrls[lang]) {
            return productGroup.imageProductExternalUrls[lang]
        }
        return null
    }

    getPluralPackagingTypeLabel(product, prefix) {
        if (product.packaging && product.packaging.type) {
            const pluralLabel = this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label + '.plural'});
            if (prefix) {
                return prefix + pluralLabel
            }
            return pluralLabel
        }
        if (prefix) {
            return prefix + 'Verpackungseinheiten'
        }
        return 'Verpackungseinheiten'
    }

    getSingularPackagingTypeLabel(product, prefix) {
        if (product.packaging && product.packaging.type) {
            const singularLabel = this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label});
            if (prefix) {
                return prefix + singularLabel
            }
            return singularLabel
        }
        if (prefix) {
            return prefix + 'Verpackungseinheit'
        }
        return 'Verpackungseinheit'
    }

    getProductVariantCount(product) {
        /*
        search in orderItems max number
        */
        const keys = _.keys(this.state.productQuantity);
        let max = 0;
        const productNumber = product.productNumber;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key.includes(productNumber+'_')) {
                key.indexOf('_')
                let nr = key.slice(key.indexOf('_')+1,key.length)
                max = Math.max(nr,max)
            }
        }
        return max+1;
    }

    getLoadProducts(product) {
        const productNumber = product.productNumber;
        const currentCart = this.props.currentCart;
        const orderItems = currentCart.orderItems;
        const variants = [];
        if (currentCart && orderItems) {
            for (let i = 0; i < orderItems.length; i++) {
                const orderItem = orderItems[i];
                if (orderItem.productNumber.includes(productNumber)) {
                    if(!this.state.productQuantity[orderItem.productNumber])
                        this.setProductQuantity(orderItem.productNumber, orderItem.quantity)
                    variants.push(orderItem)
                }
            }
        }
        return _.orderBy(variants,['productNumber'],['asc']);
    }
    isScalePrice(priceMapForProduct) {
        if (priceMapForProduct !== undefined && priceMapForProduct !== null)
            return Object.keys(priceMapForProduct[this.state.country]).length > 1;
        else
            return false;
    }

    toggleScalePriceChart(index) {
        if(this.state.openScaleChart !== index)
            this.setState({openScaleChart:index})
        else
            this.setState({openScaleChart:undefined})
    }
    handleScroll(){
        const productList = document.querySelector('.productList')
        const maxStep = 25;
        if(productList){
            let productListHeight = productList.offsetHeight;
            const top = productList.getBoundingClientRect().top;
            const threshold = Math.abs(top) > productListHeight*0.8
            if (threshold) {
                if(this.hasMoreItems())
                    this.setState({endItems: this.state.endItems+this.getStep(maxStep)})
            }
        }
    }
    getStep(maxStep){
        if(this.productGroupList(this.props.propProductGroups).length > this.state.endItems + maxStep){
            return maxStep
        }else if (this.productGroupList(this.props.propProductGroups).length > this.state.endItems){
            return this.productGroupList(this.props.propProductGroups).length - this.state.endItems
        }else{
            return 0
        }
    }
    hasMoreItems() {
        return this.productGroupList(this.props.propProductGroups).length > this.state.endItems;
    }
    isOfferItem(product){
        return this.state.offerProductNumbers.includes(product.productNumber);
    }
    render() {
        if (!this.props.propProductGroups) {
            return <div className="text-center"><span>Bitte wählen Sie eine Produktgruppe aus der Preisliste.</span>
            </div>;
        }

        if (!this.hasProductGroups(this.props.propProductGroups)) {
            return <div className="text-center"><span>Keine Produkte gefunden.</span></div>;
        }

        const groupList = this.productGroupList(this.props.propProductGroups);


        return (
            <div className="row productList mt-2" style={{borderBottom: "n one"}} onScroll={this.handleScroll}>
                <div className="col">
                    {/* loop over all product groups */
                        groupList.slice(0,this.state.endItems)
                            .map((productGroup, i) => {
                                return (
                                    <div className="border-bottom" key={i}>
                                        <div className="d-flex flex-row mb-3">
                                            <div className="col-2 text-center" style={{height: '100px'}}>
                                                {productGroup.imageProductNumber &&
                                                <span className="rounded mx-auto d-block"
                                                      onClick={(e) => this.toggleProductGroup(e, productGroup.productGroup)}>
                                                  <img alt={productGroup.imageProductName} className="h-50 w-50"
                                                       src={getProductImageUrl(productGroup.imageProductNumber)}/>
                                                </span>
                                                }
                                            </div>
                                            <div className="col-10 mt-2 mb-2">
                          <span className="d-block font-weight-bold"
                                onClick={() => this.toggleProductGroup(productGroup.productGroup)}>
                            {this.getProductGroupName(productGroup)}
                              <br/>
                              {productGroup.products &&
                              <span>
                                <small>{this.getDescription(productGroup.products[0])}</small>
                              </span>
                              }
                              <span className="float-right">
                                {this.state.open !== productGroup.productGroup && !this.props.search && <span className="oi oi-chevron-right"/>}
                                {this.state.open === productGroup.productGroup &&!this.props.search &&  <span className="oi oi-chevron-bottom"/>}
                            </span>
                            <br/>
                              {this.getProductExternalUrl(productGroup) &&
                              <small>
                                  <a target="_blank" rel="noopener noreferrer"
                                     href={this.getProductExternalUrl(productGroup)}> weitere Produktinformationen</a>
                              </small>
                              }
                          </span>
                            </div>
                        </div>

                        <Collapse
                            isOpen={(this.state.open === productGroup.productGroup) || this.props.search ||this.props.expanded}>
                            {
                                ProductList.productsList(productGroup)
                                    .map((product, j) => {
                                        const lang = "DE";
                                        const priceMapForProduct = product.data.prices || {};
                                        return (
                                            <div className="mb-3" key={j}>
                                                <div className="d-flex flex-column flex-md-row mb-4 pb-3"
                                                     style={{marginLeft: '0px', marginRight: '0px'}}>
                                                    {this.props.currentUser.role !== 'CUSTOMER_USER' &&
                                                        <FavoriteComponent onFavoriteClick={() => {
                                                            this.isFavorite(product) ? this.removeProductFromCustomer(product.productNumber) : this.addProductToCustomer(product.productNumber)
                                                        }} favorite={this.isFavorite(product)}/>
                                                    }
                                                    <div className="col-12 col-md-1 text-center">
                                                        Art-Nr. {product.productNumber}
                                                    </div>
                                                    <div className="col-12 col-md-3 text-center">
                                                        <ProductDetail propProduct={product}/>
                                                        <div className="small">
                                                            {priceMapForProduct && priceMapForProduct[this.state.country] && !this.isScalePrice(priceMapForProduct) &&
                                                                <span>Warenpreis: </span>
                                                            }
                                                            {priceMapForProduct && priceMapForProduct[this.state.country] && this.isScalePrice(priceMapForProduct) &&
                                                            <Button onClick={() => {
                                                                this.toggleScalePriceChart(j)
                                                            }} color="link">
                                                                {this.state.openScaleChart !== j &&
                                                                <span className="oi oi-chevron-right"/>}
                                                                {this.state.openScaleChart === j &&
                                                                <span className="oi oi-chevron-bottom"/>}
                                                                Staffelpreis: </Button>
                                                            }
                                                            {getScaledPrice(product, this.getProductQuantity(product) || 1, this.state.country)} pro {this.getSingularPackagingTypeLabel(product)}*<br/>
                                                            {this.isOfferItem(product) && <><span><span
                                                                className="oi oi-tag"/> Angebot</span><br/></>}
                                                        </div>

                                                        {priceMapForProduct && priceMapForProduct[this.state.country] && this.isScalePrice(priceMapForProduct) &&
                                                        <ScalePriceChart
                                                            openScaleChart={this.state.openScaleChart}
                                                            j={j}
                                                            product={product}
                                                            country={this.state.country}
                                                            packagingLabel={this.getSingularPackagingTypeLabel(product)}
                                                        />
                                                        }
                                                    </div>

                                                    {product.data.forSales[lang] && product.data && !product.data.load &&
                                                    <div className="col-12 col-md-7">
                                                        <ProductLoadComponent
                                                            single
                                                            count={this.getProductVariantCount.bind(this)}
                                                            product={product}
                                                            getProductQuantity={this.getProductQuantity.bind(this)}
                                                            addProductToCart={this.addToCart.bind(this)}
                                                            productInCurrentCartQuantity={this.productInCurrentCartQuantity.bind(this)}
                                                            toggleQuantityModal={this.toggleQuantityModal.bind(this)}
                                                            setQuantity={this.setProductQuantity.bind(this)}
                                                            getPluralPackagingTypeLabel={this.getPluralPackagingTypeLabel.bind(this)}
                                                            getSingularPackagingTypeLabel={this.getSingularPackagingTypeLabel.bind(this)}
                                                            stock={product.availabilities[lang]}
                                                            productInCurrentCart={this.productInCurrentCart.bind(this)}
                                                            getFormattedUnits={ProductList.getFormattedUnits}
                                                        />
                                                    </div>
                                                    }
                                                    {product.data && product.data.load &&
                                                    <div className="flex-row col-12 col-md-7 text-center">
                                                        <ProductLoadComponent
                                                            count={this.getProductVariantCount.bind(this)}
                                                            product={product}
                                                            getProductQuantity={this.getProductQuantity.bind(this)}
                                                            addProductToCart={this.addToCart.bind(this)}
                                                            productInCurrentCartQuantity={this.productInCurrentCartQuantity.bind(this)}
                                                            toggleQuantityModal={this.toggleQuantityModal.bind(this)}
                                                            setQuantity={this.setProductQuantity.bind(this)}
                                                            getPluralPackagingTypeLabel={this.getPluralPackagingTypeLabel.bind(this)}
                                                            getSingularPackagingTypeLabel={this.getSingularPackagingTypeLabel.bind(this)}
                                                            stock={product.availabilities[lang]}
                                                            productInCurrentCart={this.productInCurrentCart.bind(this)}
                                                        />
                                                    </div>
                                                    }
                                                </div>
                                                {product.data && product.data.load &&
                                                this.getLoadProducts(product).map((variant, i) => {
                                                    return (
                                                        <div className="d-flex flex-column flex-md-row mb-4" key={i}>
                                                            <div className="col-12 col-md-2 text-center">
                                                                Art-Nr. {variant.productNumber}
                                                            </div>
                                                            <div className="col-12 col-md-3 text-center">
                                                                <strong>{variant.name}</strong>
                                                                <div className="small">
                                                                    <span>Warenpreis: </span> {getScaledPrice(variant,  1, this.state.country)} pro {this.getSingularPackagingTypeLabel(variant)}*<br/>
                                                                </div>
                                                            </div>
                                                            <div className="flex-row col-12 col-md-7 text-center">
                                                                <ProductLoadComponent set
                                                                                      count={this.getProductVariantCount.bind(this)}
                                                                                      product={variant}
                                                                                      getProductQuantity={this.getProductQuantity.bind(this)}
                                                                                      addProductToCart={this.addToCart.bind(this)}
                                                                                      productInCurrentCartQuantity={this.productInCurrentCartQuantity.bind(this)}
                                                                                      toggleQuantityModal={this.toggleQuantityModal.bind(this)}
                                                                                      setQuantity={this.setProductQuantity.bind(this)}
                                                                                      getPluralPackagingTypeLabel={this.getPluralPackagingTypeLabel.bind(this)}
                                                                                      getSingularPackagingTypeLabel={this.getSingularPackagingTypeLabel.bind(this)}
                                                                                      stock={product.availabilities[lang]}
                                                                                      productInCurrentCart={this.productInCurrentCart.bind(this)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }

                                            </div>
                                        );
                                    })}
                        </Collapse>
                    </div>
                        );
                    })}
                </div>
            <Modal isOpen={this.state.quantityModal} fade={false} toggle={() => this.toggleQuantityModal()}>
                <ModalHeader tag="h5" toggle={() => this.toggleQuantityModal()}>
                    Bitte wählen Sie die {this.getQuantityModalHeader(this.state.modalProduct)} <br/>
                    <span className="h6 text-muted text-right">
                        {ProductList.productName(this.state.modalProduct)} {ProductList.productNameFormat(this.state.modalProduct)}
                    </span>
                </ModalHeader>
                <ModalBody className="text-center">
                        <div className="row mb-3">
                            {/* loop over all quantity choices */
                                ProductList.quantityChoice()
                                    .map((quantity, k) => {
                                        return (
                                            <div key={k} className="col-4 col-md-2 mb-2">
                                                <Button key={k} className="btn-outline-secondary btn-block"
                                                        onClick={(event) => this.submitModalButtonQuantity(event, quantity)}>
                                                    {quantity}
                                                </Button>
                                            </div>
                                        );
                                    })}
                        </div>
                        <Form className="form-inline" onSubmit={(event) => this.submitModalFormQuantity(event)}>
                            <Label className="mt-2 mr-2"
                                   for="modal-quantity">{this.getQuantityModalLabel(this.state.modalProduct)}</Label>
                            <Input
                                className="ml-xs-2 mt-2" type="number" min="0" name="quantity" id="modal-quantity"
                                onChange={this.setModalQuantity.bind(this)}
                                defaultValue={this.getProductQuantity(this.state.modalProduct)}
                            />
                            <div className="col pr-0 text-right">
                                <Button color="primary" className="btn-primary mt-2 mr-2"
                                        type="submit">Übernehmen</Button>
                                {!this.productInCurrentCart(this.state.modalProduct) &&
                                <Button color="secondary" className="btn-secondary mt-2"
                                        onClick={(event) => this.removeProductQuantity(event)}>
                                    <span className="oi oi-trash"/>
                                </Button>
                                }
                                {this.productInCurrentCart(this.state.modalProduct) &&
                                <Button color="secondary" className="btn-secondary mt-2"
                                        onClick={(event) => this.resetProductQuantity(event)}>
                                    <span className="oi oi-trash"/>
                                </Button>
                                }
                            </div>
                        </Form>

                        {(this.state.modalProduct && (this.state.modalProduct.data.materialUsageLFM || this.state.modalProduct.data.materialUsageM2)) &&
                        <hr/>
                        }

                        {(this.state.modalProduct && this.state.modalProduct.data.materialUsageLFM) &&
                        <Form className="form-inline text-left"
                              onSubmit={(event) => this.submitModalFormQuantity(event)}>
                            <Label className="mt-2 mr-2" for="modal-length">Länge:</Label>
                            <Input
                                className="ml-xs-2 mt-2" type="number" min="0" step="0.1" name="length"
                                id="modal-length"
                                onChange={this.setModalQuantityLength.bind(this)}
                            />
                            <div className="col pr-0 text-right">
                                <Button color="primary" className="btn-primary mt-2" type="submit"><span
                                    className="pl-1">lfm</span> übernehmen</Button>
                            </div>
                        </Form>
                        }
                        {(this.state.modalProduct && this.state.modalProduct.data.materialUsageM2) &&
                        <Form className="form-inline text-left"
                              onSubmit={(event) => this.submitModalFormQuantity(event)}>
                            <Label className="mt-2 mr-2" for="modal-square">Fläche:</Label>
                            <Input
                                className="ml-xs-2 mt-2" type="number" min="0" step="0.1" name="square"
                                id="modal-square"
                                onChange={this.setModalQuantitySquare.bind(this)}
                            />
                            <div className="col pr-0 text-right">
                                <Button color="primary" className="btn-primary mt-2"
                                        type="submit"><span>m<sup>2</sup></span> übernehmen</Button>
                            </div>
                        </Form>
                        }
                        {(this.state.modalProduct && ProductList.isSingleProduct(this.state.modalProduct)) &&
                        <Form className="form-inline text-left"
                              onSubmit={(event) => this.submitModalFormQuantity(event)}>
                            <Label className="mt-2 mr-2" for="modal-palettes">Paletten</Label>
                            <Input
                                className="ml-xs-2 mt-2" type="number" min="0" step="1" name="palettes"
                                id="modal-palettes"
                                onChange={this.setModalQuantityPalettes.bind(this)}
                            />
                            <div className="col pr-0 text-right">
                                <Button color="primary" className="btn-primary mt-2"
                                        type="submit"><span>Paletten</span> übernehmen</Button>
                            </div>
                        </Form>
                        }
                    { this.state.modalProduct && this.state.modalProduct.data.prices && this.state.modalProduct.data.prices[this.state.country] && this.isScalePrice(this.state.modalProduct.data.prices) &&
                        <div className="pt-4">
                            <ScalePriceChart
                                openScaleChart={0}
                                j={0}
                                product={this.state.modalProduct}
                                country={this.state.country}
                                packagingLabel={this.getSingularPackagingTypeLabel(this.state.modalProduct)}
                            />
                        </div>
                    }
                    </ModalBody>
            </Modal>

            <Modal isOpen={this.state.addToCartModal} fade={false} toggle={() => this.showAddToCartModal()}>
                <ModalHeader toggle={() => this.hideAddToCartModal()}/>
                <ModalBody className="text-center">
                        {(this.props.currentMessage && this.props.currentMessage.type === 'INFO') &&
                        <div className="alert alert-success">
                            {this.props.currentMessage.msg}
                        </div>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'MAX_LOAD_FAILED') &&
                        <div>
                            <div className="alert alert-danger">
                                {this.props.currentMessage.msg}
                            </div>
                        </div>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'ADD_CART_ITEM') &&
                        <div>
                            <div className="alert alert-danger">
                                {this.props.currentMessage.msg}
                            </div>
                            {(this.props.currentMessage.productQuantity > 0) &&
                            <div className="alert">
                                Wollen Sie das Produkt mit der maximalen
                                Menge {this.props.currentMessage.productQuantity} in den Warenkorb legen?
                            </div>
                            }
                            {(this.props.currentMessage.productQuantity <= 0) &&
                            <div className="alert">
                                Der Warenkob ist voll. Reduzieren Sie die Mengen im Warenkorb, wenn sie dieses Produkt
                                in den Warenkorb legen möchten.
                            </div>
                            }
                        </div>
                        }
                    </ModalBody>
                <ModalFooter>
                        {(this.props.currentMessage && this.props.currentMessage.type === 'INFO') &&
                        <Button color="secondary" onClick={() => this.hideAddToCartModal()}>Schließen</Button>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR' && this.props.currentMessage.source === 'MAX_LOAD_FAILED') &&
                        <Button color="secondary" onClick={() => this.hideAddToCartModal()}>Schließen</Button>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR'
                            && this.props.currentMessage.source === 'ADD_CART_ITEM' && this.props.currentMessage.productQuantity > 0) &&
                        <div className="container-fluid">
                            <div className="row border-0">
                                <div className="col-6 mt-2 pl-0 text-left">
                                    <Button color="primary"
                                            onClick={(e) => this.addToCart(e, this.state.modalProduct, this.props.currentMessage.productQuantity)}>
                                        <span className="oi oi-cart mr-2"/>in den Warenkorb
                                    </Button>
                                </div>
                                <div className="col-6 mt-2 pr-0 text-right">
                                    <Button color="secondary" onClick={(e) => this.hideAddToCartModal(e)}>Nein</Button>
                                </div>
                            </div>
                        </div>
                        }
                        {(this.props.currentMessage && this.props.currentMessage.type === 'ERROR'
                            && this.props.currentMessage.source === 'ADD_CART_ITEM' && this.props.currentMessage.productQuantity <= 0) &&
                        <Button color="secondary" onClick={() => this.hideAddToCartModal()}>Schließen</Button>
                        }
                    </ModalFooter>
            </Modal>
        </div>
        );
    }

    addProductToCustomer(productNumber) {
        this.props.dispatch(attachProductToCustomerCategory(this.props.customer.id,productNumber))
        this.setState({toastMessage:`Produkt ${productNumber} wurde zu den Favoriten hinzugefügt`})
        this.setState({toastIsOpen:true})
    }
    removeProductFromCustomer(productNumber) {
        this.props.dispatch(detachProductFromCustomerCategory(this.props.customer.id,productNumber))
    }
    isFavorite(product) {
        return this.props.customerProductNumbers && this.props.customerProductNumbers.productNumbers.includes(product.productNumber)
    }
}

ProductList.propTypes = {
    propProductGroupCollapsed: PropTypes.object.isRequired,
    propProductGroups: PropTypes.shape(ProductGroups),
    propCategory: PropTypes.shape(Category),
    currentCart: PropTypes.shape(Cart),
    dispatch: PropTypes.func.isRequired,
    onProductGroupCollapsed: PropTypes.func.isRequired,
    currentMessage: PropTypes.shape(Message),
    customer: PropTypes.shape(Customer),
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
    return {
        currentMessage: getCurrentMessage(state),
        customer: getCustomer(state),
        customerProductGroups: getCustomerProductGroups(state),
        customerProductNumbers: getCustomerProductNumbers(state),
        currentUser: getCurrentUser(state)
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(ProductList)));
