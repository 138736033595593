import _ from 'lodash';

import Utils from '../../util/Utils';
import {callApi, callSecureApi} from '../../util/apiCaller';
import {isUnauthorized} from '../../util/http-errors';

import {
    LOAD_ALL_PRODUCT_NUMBERS,
    LOAD_CATEGORIES,
    LOAD_DE_CATEGORIES,
    LOAD_PL_CATEGORIES,
    LOAD_PRICE_LIST_CATEGORY,
    LOAD_PRODUCT_GROUPS,
    SET_PRODUCTS,
} from '../types';

import {logout} from '../AuthentificationActions';
import {setCurrentMessage} from '../MessagesAction';
import {setCustomerProductGroups, setCustomerProductNumbers} from "./Product";

const ROOT_CATEGORIES = {
    DE: 'ROOT_SIEVERT_DE',
    PL: 'ROOT_SIEVERT_PL_TEST'
}

export function loadCategories() {
    return dispatch => {
        callApi({
            endpoint: 'productservice/v1/category/',
            method: 'get'
        })
            .then(res => {
                res.categories.map(
                    (c) => {
                        if (!c.label)
                            return c.label = c.name
                        return c
                    }
                )
                dispatch(setCategories(res.categories));
            })
            .catch(err => {
                console.error('unable to load categories', err);
            });
    };
}

export function createCategory(category) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'productservice/v1/category',
                method: 'post'
            }, category
        )
            .then(res => {
                const message = {
                    type: 'INFO',
                    msg: 'Die Kategorie wurde erfolgreich angelegt',
                    source: 'CREATE_CATEGORY'
                }
                dispatch(setCurrentMessage(message));
                console.log('category created', res)
            })
            .catch(err => {
                console.error('unable to create category', err);
            })
    }
}

export function attachProductToCustomerCategory(customerId, productNumber) {
    return dispatch => {
        if (!customerId) {
            console.info('skip attaching product to customer no customerId');
            return;
        }

        callSecureApi({
            endpoint: 'productservice/v1/product/customer/{customerId}',
            query: {'customerId': customerId},
            method:'put'
        },{productNumbers:[productNumber]})
            .then(res => {
                const productGroups = res.productGroups
                dispatch(setCustomerProductGroups({productGroups}));
                const products = _.flatten(productGroups.map(productGroup => productGroup.products));
                const productNumbers = products.map(product => product.productNumber);
                dispatch(setCustomerProductNumbers({productNumbers}));
                dispatch(setCurrentMessage({msg:`Produkt ${productNumber} wurde zu den Favoriten hinzugefügt`, type:'TOAST'}))
            })
            .catch(err => {
                console.error('unable to load customer categories', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function detachProductFromCustomerCategory(customerId,productNumber) {
    return dispatch => {
        if (!customerId) {
            console.info('skip attaching product to customer no customerId');
            return;
        }

        callSecureApi({
            endpoint: 'productservice/v1/product/customer/{customerId}',
            method:'delete',
            query: {'customerId': customerId}
        },{productNumbers:[productNumber]})
            .then(res => {
                const productGroups = res.productGroups
                if(productGroups){
                    dispatch(setCustomerProductGroups({productGroups}));
                    const products = _.flatten(productGroups.map(productGroup => productGroup.products));
                    const productNumbers = products.map(product => product.productNumber);
                    dispatch(setCustomerProductNumbers({productNumbers}));
                    dispatch(setCurrentMessage({msg:`Produkt ${productNumber} wurde aus den Favoriten entfernt`, type:'TOAST'}))
                }else{
                    dispatch(setCustomerProductGroups(undefined));
                    dispatch(setCustomerProductNumbers(undefined));
                    dispatch(setCurrentMessage({msg:`Produkt ${productNumber} wurde aus den Favoriten entfernt`, type:'TOAST'}))
                }

            })
            .catch(err => {
                console.error('unable to load customer categories', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function detachProductToCategory(categoryId, productNumbers) {
    return () => {
        callSecureApi({
            endpoint: 'productservice/v1/category/{categoryId}/products',
            method: 'delete',
            query: {'categoryId': categoryId}
        }, {'productNumbers': productNumbers})
            .then(() => {
                console.info('products detached to category id=' + categoryId);
            })
            .catch(err => {
                console.error('unable to detach products to category', err);
            });
    };
}

export function attachProductToCategory(categoryNumber, productNumbers) {
    return () => {
        callSecureApi({
            endpoint: 'productservice/v1/category/{categoryNumber}/products',
            method: 'put',
            query: {'categoryNumber': categoryNumber}
        }, {'productNumbers': productNumbers})
            .then(() => {
                console.info('products attached to category id=' + categoryNumber);
            })
            .catch(err => {
                console.error('unable to attach products to category', err);
            });
    }
}

export const ROOT_CATEGORY_ID = 'ROOT_SIEVERT_DE';

export function loadAllProducts() {
    return dispatch => {
        callSecureApi({
                endpoint: 'productservice/v1/product/category/{categoryId}/tree',
                query: {'categoryId': ROOT_CATEGORY_ID},
                method: 'get'
            }
        )
            .then((res) => {
                    const productGroups = res.productGroups;
                    dispatch(setProductGroups(res))
                    if (productGroups) {
                        let products = productGroups.map(
                            (productGroup) => {
                                return productGroup.products
                            })
                        products = _.flatten(products);
                        dispatch(setProducts(products));
                    }
                }
            ).catch(err => {
            console.error('unable to load category tree', err);
        });
    }
}

export function getAllProductNumbers() {
    const rootCategoryId = ROOT_CATEGORY_ID;
    return dispatch => {
        callApi(
            {
                endpoint: 'productservice/v1/product/category/{categoryId}/tree',
                query: {'categoryId': rootCategoryId}
            }
        )
            .then(res => {
                const productGroups = res.productGroups;
                let productNumbers = [];
                if (productGroups) {
                    productGroups.forEach(function (productGroup) {
                        productGroup.products.forEach(function (product) {
                            productNumbers = productNumbers.concat(product.productNumber);
                        });
                    });
                    dispatch(setProductNumbers(productNumbers));
                }

            });
    };
}

export function loadFilteredRootCategoryTree(customerId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            callSecureApi({
                    endpoint: 'productservice/v1/category/customer/{customerId}/tree',
                    query: {'customerId': customerId},
                    method: 'get'
                }
            )
                .then((res) => {
                        res.children.map(
                            (c) => {
                                if (!c.label)
                                    return c.label = c.name
                                return c
                            }
                        )
                        const filteredCategories = _.reject(res.children, {name: 'NO-NAME'});
                        dispatch(setCategories(filteredCategories));
                        resolve();
                    }
                ).catch(err => {
                console.error('unable to load category tree', err);
                reject();
            });
        })
    }
}

// is still used for admin pages
export function loadRootCategoryTree() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            callSecureApi({
                    endpoint: 'productservice/v1/category/{rootId}/tree',
                    query: {'rootId': ROOT_CATEGORY_ID},
                    method: 'get'
                }
            )
                .then((res) => {
                        res.children.map(
                            (c) => {
                                if (!c.label)
                                    return c.label = c.name
                                return c
                            }
                        )
                        const filteredCategories = _.reject(res.children, {name: 'NO-NAME'});
                        dispatch(setCategories(filteredCategories));
                        resolve();
                    }
                ).catch(err => {
                console.error('unable to load category tree', err);
                reject();
            });
        })
    }
}

export function loadProductsWithCart(cart) {
    if (!cart) {
        console.error('loadRootCategoryTreeWithProjectId no cart')
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            callSecureApi({
                    endpoint: 'productservice/v1/product/cart/{cartId}/category/{rootId}/tree',
                    query: {'rootId': ROOT_CATEGORY_ID, 'cartId': cart.cartId},
                    method: 'get'
                }
            )
                .then((res) => {
                        const productGroups = res.productGroups;
                        dispatch(setProductGroups(res))
                        if (productGroups) {
                            let products = productGroups.map(
                                (productGroup) => {
                                    return productGroup.products
                                })
                            products = _.flatten(products);
                            dispatch(setProducts(products));
                        }
                    }
                ).catch(err => {
                console.error('unable to load category tree', err);
                reject();
            });
        })
    }
}

export function loadRootCategoryTreeByCountry(country) {
    const rootCategory = ROOT_CATEGORIES[country]
    return dispatch => {
        return new Promise((resolve, reject) => {
            callSecureApi({
                    endpoint: 'productservice/v1/category/{rootId}/tree',
                    query: {'rootId': rootCategory},
                    method: 'get'
                }
            )
                .then((res) => {
                        res.children.map(
                            (c) => {
                                if (!c.label)
                                    return c.label = c.name
                                return c
                            }
                        )

                        switch (country) {
                            case 'DE':
                                Utils.createLeafIdMap(res.children, 'DE')
                                dispatch(setDECategories(res.children));
                                break;
                            case 'PL':
                                Utils.createLeafIdMap(res.children, 'PL')
                                dispatch(setPLCategories(res.children));
                                break;
                            default:
                                break;
                        }
                        resolve();
                    }
                ).catch(err => {
                console.error('unable to load category tree', err);
                reject(err);
            });
        })
    }
}

export function fetchProductsFromCategory(categoryNumber, cart) {
    if (!categoryNumber || !cart) {
        console.error('fetchProductsFromCategory: wrong parameter')
    }
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'productservice/v1/product/cart/{cartId}/category/{categoryNumber}/tree',
                query: {'categoryNumber': categoryNumber, 'cartId': cart.cartId},
                method: 'get'
            }
        ).then(res => {
            dispatch(setProductGroups(res));
        });
    };
}

export function loadPriceListCategory() {
    return dispatch => {
        console.info('loading price list category');

        callApi({
            endpoint: 'productservice/v1/category/{categoryId}',
            query: {'categoryId': ROOT_CATEGORY_ID}
        })
            .then(res => {
                res.children.map(
                    (c) => {
                        if (!c.label)
                            return c.label = c.name
                        return c
                    }
                )
                dispatch(setPriceList(res));
            })
            .catch(err => {
                console.error('unable to load price list category', err);
            });
    };
}

export function loadProductGroups(category) {
    return dispatch => {
        if (!category) {
            console.info('skip loading product groups, category is null');
            return;
        }

        console.info('loading product groups category id=' + category.id);
        callSecureApi({
            endpoint: 'productservice/v1/product/category/{categoryId}/tree/customer',
            query: {'categoryId': category.id}
        })
            .then(res => {
                const productGroups = res.productGroups;
                dispatch(setProductGroups({productGroups}));

            })
            .catch(err => {
                console.error('unable to load product groups', err);
            });
    };
}

export function setProducts(products) {
    return {
        type: SET_PRODUCTS,
        products
    }
}

export function setProductGroups(productGroups) {
    return {
        type: LOAD_PRODUCT_GROUPS,
        productGroups
    };
}

export function setPriceList(category) {
    return {
        type: LOAD_PRICE_LIST_CATEGORY,
        category
    };
}

export function setCategories(category) {
    return {
        type: LOAD_CATEGORIES,
        category
    };
}

export function setDECategories(category) {
    return {
        type: LOAD_DE_CATEGORIES,
        category
    };
}

export function setPLCategories(category) {
    return {
        type: LOAD_PL_CATEGORIES,
        category
    };
}

export function setProductNumbers(productNumbers) {
    return {
        type: LOAD_ALL_PRODUCT_NUMBERS,
        productNumbers
    }
}
