
// Initial State
import {
    CLEAR_PRODUCT,
    CLEAR_SEARCH_BUNDLE_PRODUCT,
    CLEAR_SEARCH_PRODUCT, LOAD_CUSTOMER_PRODUCT_GROUPS, LOAD_CUSTOMER_PRODUCTS,
    LOAD_PRODUCT,
    LOAD_PRODUCT_STOCK,
    SET_SEARCH_BUNDLE_PRODUCT,
    SET_SEARCH_PRODUCT
} from '../actions/types';

const initialState = {
    stateProduct: null,
    stateSearchProduct: null,
    stateSearchBundleProduct: null,
    stateCustomerProductGroups: null,
    stateCustomerProducts: null
};

const ProductReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_PRODUCT: {
            return {
                ...state,
                stateProduct: null,
                stateSearchProduct: null
            };
        }
        case CLEAR_SEARCH_PRODUCT: {
            return {
                ...state,
                stateSearchProduct: null
            };
        }
        case LOAD_PRODUCT: {
            return {
                ...state,
                stateProduct: action.product
            };
        }
        case SET_SEARCH_PRODUCT: {
            return {
                ...state,
                stateSearchProduct: action.product
            };
        }
        case SET_SEARCH_BUNDLE_PRODUCT: {
            return {
                ...state,
                stateSearchBundleProduct: action.searchResult
            };
        }
        case CLEAR_SEARCH_BUNDLE_PRODUCT: {
            return {
                ...state,
                stateSearchBundleProduct: null
            };
        }
        case LOAD_PRODUCT_STOCK: {
            return {
                ...state,
                productStock: action.productStock
            };
        }
        case LOAD_CUSTOMER_PRODUCT_GROUPS: {
            return {
                ...state,
                stateCustomerProductGroups: action.customerProductGroups
            }
        }
        case LOAD_CUSTOMER_PRODUCTS: {
            return {
                ...state,
                stateCustomerProductNumbers: action.customerProductNumbers
            }
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getCurrentProduct = (state) => state.product.stateProduct;
export const getSearchProduct = (state) => state.product.stateSearchProduct;
export const getSearchBundleProduct = (state) => state.product.stateSearchBundleProduct;
export const getProductStock = (state) => state.product.productStock;
export const getCustomerProductGroups = (state) => state.product.stateCustomerProductGroups;
export const getCustomerProductNumbers = (state) => state.product.stateCustomerProductNumbers;
// Export Reducer
export default ProductReducer;
