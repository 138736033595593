import React, {Component} from 'react';
import CustomerFacilityForm from "../../components/Form/CustomerFacilityForm";
import BackButton from "../../components/Buttons/BackButton";

class CreateCustomerFacilityPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h1 className="h2 text-center mb-4">
                        Lager / Werk erstellen
                    </h1>
                    <CustomerFacilityForm create/>
                </div>
            </div>
        );
    }
}

CreateCustomerFacilityPage.propTypes = {};

export default CreateCustomerFacilityPage;
