import {SET_WORKER} from '../actions/types';

const initialState = {
    stateWorker: {}
};

const WorkerReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_WORKER: {
            return {
                ...state,
                stateWorker: action.worker
            };
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getWorker = (state) => state.worker.stateWorker


// Export Reducer
export default WorkerReducer;
