import React, {Component} from 'react';
import {withRouter} from "react-router";
import UserForm from "../../components/Form/UserForm";
import BackButton from "../../components/Buttons/BackButton";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {Customer} from "../../proptypes";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class CreateUserPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h4 className="text-muted text-center">{this.props.customer.name}</h4>
                    <h1 className="text-center mb-4">
                        <span>Benutzer anlegen</span>
                    </h1>
                    <UserForm create/>
                </div>
            </div>
        );
    }
}

CreateUserPage.propTypes = {
    customer: PropTypes.shape(Customer).isRequired
};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(CreateUserPage));
