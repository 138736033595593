import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import ProductForm from "../../components/Form/ProductForm";
import {PATH_ADMIN_PRODUCT_OVERVIEW_PAGE} from "../../routePaths";
import {Nav, NavItem, NavLink} from "reactstrap";
import * as classnames from "classnames";
import {getCountries} from "../../reducers/I18NReducer";
import {connect} from "react-redux";
import ModalComponent from "../../components/Modals/ModalComponent";
import _ from "lodash";
import {FormattedMessage, injectIntl} from "react-intl";

class EditProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeCountry : 'DE',
            nextCountry:null,
            formChanged: false,
            modals: {
                changeCountry: false
            }
        }
    }
    setActiveCountry(country) {
        this.setState({'activeCountry': country})
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }
    setFormChanged(){
        this.setState({formChanged:true})
    }
    getCountryTabs() {
        const countries = this.props.countries || []
        return <Nav tabs>
            {countries.map((country) => {
                return (
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeCountry === country})}
                            onClick={() => {
                                if (this.state.activeCountry !== country) {
                                    if(this.state.formChanged){
                                        this.setState({nextCountry: country})
                                        this.showModal('changeCountry')
                                        this.setState({formChanged:false})
                                    }else{
                                        this.setActiveCountry(country)
                                    }
                                }
                            }}>
                            {country}
                        </NavLink>
                    </NavItem>
                )
            })}
        </Nav>
    }
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton path={PATH_ADMIN_PRODUCT_OVERVIEW_PAGE}/>
                    <h1 className="h2 text-center mb-4">
                        <FormattedMessage id="createproduct.headline"/>
                    </h1>
                    <ProductForm changed={this.setFormChanged.bind(this)} country='DE' edit/>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.changeCountry}
                    toggle={() => this.showModal('changeCountry')}
                    hide={() => this.hideModal('changeCountry')}
                    color="warning"
                    title={this.props.intl.formatMessage({id:"createproduct.modal.changecounry.title"})}
                    message={this.props.intl.formatMessage({id:"createproduct.modal.changecounry.message"})}
                    onSuccess={() => {
                        this.hideModal('changeCountry')
                        this.setActiveCountry(this.state.nextCountry)
                    }}
                    onCancel={()=>{
                        this.hideModal('changeCountry')
                    }}
                    successText={this.props.intl.formatMessage({id:"createproduct.modal.changecounry.successText"})}
                    cancelText={this.props.intl.formatMessage({id:"createproduct.modal.changecounry.cancelText"})}
                />
            </div>
        );
    }
}

EditProductPage.propTypes = {};
function mapStateToProps(state) {
    return {
        countries: getCountries(state)
    };
}

export default connect(mapStateToProps)(injectIntl(EditProductPage));
