import React, {Component} from "react";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import classNames from 'classnames';
import {injectIntl} from "react-intl";
class ModalComponent extends Component {
    render() {
        const cancelText = this.props.cancelText ? this.props.intl.formatMessage({id:this.props.cancelText}) : undefined;
        const cancelHandler = this.props.onCancel;
        const successText = this.props.successText ? this.props.intl.formatMessage({id:this.props.successText}) : undefined
        const message = this.props.message ? this.props.intl.formatMessage({id:this.props.message}) : undefined
        const title = this.props.title ? this.props.intl.formatMessage({id:this.props.title}) : undefined
        const color = this.props.color || "success";
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} fade={false}>
                <ModalHeader toggle={this.props.hide}>{title}</ModalHeader>
                <ModalBody className="text-center">
                    <Alert color={color}>{message}</Alert>
                </ModalBody>
                <ModalFooter className="mt-2 mb-2">
                    <div className="container-fluid col-12 d-flex flex-row">
                        <div className={classNames({'col-6':this.props.onCancel,'col-12':!this.props.onCancel})}>
                            <Button color="primary" className="btn-block"
                                    onClick={this.props.onSuccess}>{successText}</Button>
                        </div>
                        {cancelText && cancelHandler &&
                        <div className="col-6">
                            <Button color="danger" className="btn-block"
                                    onClick={this.props.onCancel}>{this.props.cancelText}</Button>
                        </div>
                        }
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

export default injectIntl(ModalComponent)
