import {SET_CHECKLIST, SET_OFFER, SET_OFFERS} from '../actions/types';


const initialState = {
    stateOffers: null,
    stateOffer:null,
    checkList:[]
};

const OffersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_OFFERS: {
            return {
                ...state,
                stateOffers: action.offers
            };
        }
        case SET_OFFER: {
            return {
                ...state,
                stateOffer: action.offer
            };
        }
        case SET_CHECKLIST:{
            return {
                ...state,
                checkList: action.checklist
            }
        }
        default: {
            return state;
        }
    }
}

export default OffersReducer

export const getOffers = (state) => state.offers.stateOffers;
export const getOffer = (state) => state.offers.stateOffer;
export const getCheckList = (state) => state.offers.checkList;
