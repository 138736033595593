import {callApi, callSecureApi, clearAuthToken, storeAuthToken} from '../util/apiCaller';
import Auth from '../util/Auth'
import {goToPage} from '../util/page';
import {PATH_HOME_PAGE, PATH_LOGIN_PAGE} from '../routePaths';

import {LOGOUT, SET_CURRENT_USER} from './types';
import {setCurrentMessage} from './MessagesAction';

export function login({email, password}) {
    console.info('login user');

    return dispatch => {
        return callApi({
            endpoint: 'customerservice/v1/token/create',
            method: 'post'
        }, {
            email,
            password
        })
        .then(res => {
            storeAuthToken(res);
            console.log("login ", res)
            return res;
        })
        .catch(() => {
            const message = {type: 'ERROR', msg: `Die Logindaten sind ungültig!`};
            dispatch(setCurrentMessage(message));
        });
    };
}
export function tokenLogin(){
    return dispatch => {
            dispatch(loadCurrentUser())
                .then(()=>{
                    goToPage(PATH_HOME_PAGE);
                })
                .catch(()=>{
                    clearAuthToken();
                })
    }
}
export function loadCurrentUser() {
    return dispatch => {
        console.info('loading current user');
        return callSecureApi({
            endpoint: 'customerservice/v1/user/whoami'
        })
            .then(user => {
                Auth.setUser(user);
                dispatch(setCurrentUser(user));
                return user
            })
            .catch(err => {
                console.error('unable to load current user', err);
                dispatch(logout());
                return err
            });
    };
}

export function resetPassword(email) {
    console.info('reset password email=' + email);

    return dispatch => {
        callApi({
            endpoint: 'customerservice/v1/user/password',
            method: 'put'
        }, {
            email
        })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Ein neues Passwort ist an die hinterlegte Kontakt E-Email-Adresse versendet worden.`,
                    source: 'RESET_PASSWORD'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(() => {
                const message = {
                    type: 'ERROR',
                    msg: `Das Passwort konnte nicht zurück gesetzt werden!`,
                    source: 'RESET_PASSWORD'
                };
                dispatch(setCurrentMessage(message));
            });
    };
}

export function changePassword(user, credentials) {
    return dispatch => {
        if (!user) {
            console.info('skip changing password, user is null');
            return;
        }

        console.info('changing password for user id=' + user.id);
        callSecureApi({
            endpoint: 'customerservice/v1/user/{userId}/credentials',
            query: {'userId': user.id},
            method: 'put'
        }, credentials)
            .then(() => {
                const message = {type: 'INFO', msg: `Das Passwort wurde geändert.`, source: 'CHANGE_PASSWORD'};
                dispatch(setCurrentMessage(message));
            })
            .catch(() => {
                const message = {
                    type: 'ERROR',
                    msg: `Das Passwort konnte nicht geändert werden!`,
                    source: 'CHANGE_PASSWORD'
                };
                dispatch(setCurrentMessage(message));
            });
    };
}

export function logout() {
    console.info('logging out');
    callSecureApi({
            endpoint: 'customerservice/v1/token/revoke',
            method: 'delete'
        })

    clearAuthToken();
    goToPage(PATH_LOGIN_PAGE);

    return {
        type: LOGOUT
    };
}

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    };
}
