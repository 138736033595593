import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import {Form, FormGroup, Input, Label} from 'reactstrap';

import {getCurrentCart} from '../../reducers/CartReducer';
import {getCurrentMessage} from '../../reducers/MessageReducer';
import {getCustomer} from '../../reducers/CustomerReducer';
import {Cart, Customer, Message} from "../../proptypes";
import {PACKAGINGTYPES} from "../../enums/PackagingTypes";
import {injectIntl} from "react-intl";
import {germanCurrency} from "../../util/priceHelper";
import ProductUnits from "../Product/ProductUnitsComponent";
import {getProductImageUrl} from "../../actions";
import ProductList from "../Product/ProductList";

class CartSummaryItemsList extends Component {

  static isCustomTruck(pickup) {
    if (pickup) {
      const truck = pickup.truck;
      if (truck) {
        return truck.type === 'CUSTOM';
      }
    }
    return false;
  }

  static optionOrderItemList(bundledOrderItem) {
    if (bundledOrderItem) {
      const bundles = bundledOrderItem.bundles;
      if (bundles) {
        const options = [];
        _.each(bundles, (bundle) => {
          if (bundle.option) {
            options.push(bundle.option.orderItem);
          }
        });
        return options;
      }
    }
    return [];
  }

  static orderItemList(cart) {
    if (cart) {
      const orderItems = cart.orderItems;
      const orderItemList = _.map(orderItems, orderItem => {
        return orderItem;
      });

      return _.filter(orderItemList, (oi) => {
        return oi.quantity >= 0;
      });
    }
    return [];
  }

  static bundledOrderItemList(orderItem) {
    const forSale = orderItem.data.forSale;
    if (forSale) {
      const bundles = orderItem.bundles;
      if (bundles) {
        return _.map(bundles, bundle => {
          if (bundle.orderItem) {
            return bundle.orderItem;
          }
          return null;
        });
      }
    }
    return [];
  }

  static quantityChoice() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
  }

  static orderItemName(orderItem) {
    return orderItem.name;
  }

  static orderItemNameFormat(orderItem) {
    let itemName = orderItem.data.formatDefinition;
    if (!itemName) {
      itemName = orderItem.data.specifiedSize;
    }
    return !itemName ? '' : ` - ${itemName}`;
  }

  constructor(props) {
    super(props);

    this.getProductQuantity = this.getProductQuantity.bind(this);
    this.setOrderItemQuantities = this.setOrderItemQuantities.bind(this);
    this.hasMaxedOutCapacity = this.hasMaxedOutCapacity.bind(this);

    this.state = {
      productQuantity: {}
    };
  }

  componentDidMount() {
    this.setOrderItemQuantities(this.props.currentCart);
  }

  componentWillReceiveProps(nextProps) {
    const currentCart = nextProps.currentCart;
    if (currentCart) {
      this.setOrderItemQuantities(currentCart);
    }
  }

  setOrderItemQuantities(cart) {
    if (cart) {
      CartSummaryItemsList.orderItemList(cart)
        .map((orderItem) => {
          return this.setOrderItemQuantity(orderItem);
        });
    }
  }

  setOrderItemQuantity(orderItem) {
    const productNumber = orderItem.productNumber;
    let quantity = orderItem.quantityApproved;
    if (quantity === undefined) {
      quantity = orderItem.maxedOutQuantity === undefined ? orderItem.quantity : orderItem.maxedOutQuantity;
    }

    this.setState(currentState => {
      // deep copy current state, in order to not modify the current state object
      const nextState = _.cloneDeep(currentState);

      nextState.productQuantity[productNumber] = quantity;

      return nextState;
    });
  }

  getProductQuantity(orderItem) {
    let quantity = this.state.productQuantity[orderItem.productNumber];
    if (quantity === undefined) {
      // a controlled input must not be initialized with undefined or null
      quantity = '';
    }
    return quantity;
  }

  hasMaxedOutCapacity(orderItem) {
    const cart = this.props.currentCart;
    if (cart) {
      const cartOrderItems = _.filter(cart.orderItems, (coi) => {
        return coi.productNumber === orderItem.productNumber;
      });

      if (cartOrderItems && cartOrderItems.length > 0) {
        const cartOrderItem = cartOrderItems[0];
        if (cartOrderItem.maxedOutQuantity) {
          return Boolean(cartOrderItem.maxedOutQuantity > 0);
        }
      }
    }
    return false;
  }


  getPluralPackagingTypeLabel(product, prefix) {
    if (product.packaging && product.packaging.type) {
      const pluralLabel = this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label + '.plural'});
      if (!prefix)
        return pluralLabel
      else
        return prefix + pluralLabel
    }
    if (!prefix)
      return 'Verpackungseinheiten'
    else
      return prefix + 'Verpackungseinheiten'
  }

  getSingularPackagingTypeLabel(product) {
    if (product.packaging && product.packaging.type) {
      return this.props.intl.formatMessage({id: PACKAGINGTYPES[product.packaging.type].label})
    }
    return 'Verpackungseinheiten'
  }

  render() {
    if (!this.props.currentCart || !this.props.currentCart.orderItems) {
      return (
        <div className="mb-4 text-center">
          <span className="text-center">
            <stron>keine Produkte im Warenkorb</stron>
          </span>
        </div>
      );
    }

    return (
      <div>
        {/* loop over all order items */
          CartSummaryItemsList.orderItemList(this.props.currentCart)
            .map((orderItem, i) => {
              return (
                <div className="row mb-4 mt-4" key={i}>
                  <div style={{height: '100px'}} className="text-center col-xl-2 order-xl-1 col-md-5 order-md-1 col-4 order-1 mb-2">
                    <img
                      alt={CartSummaryItemsList.orderItemName(orderItem)} className="mh-100 mw-100"
                      src={getProductImageUrl(orderItem.productNumber)}
                    />
                  </div>
                  {(this.getProductQuantity(orderItem) > 0 && orderItem.data.grossPrice > 0) ?
                    <div className="small font-weight-bold col-xl-2 order-3">
                        Warenpreis: {germanCurrency(orderItem.data.price)}*<br />
                    </div>
                    : <div className="col-xl-2 order-3"></div>
                  }
                  <div className="col-xl-4 order-xl-2 col-md-7 col-sm-6 order-md-2 col-12 order-2 mb-2">
                    <strong>{CartSummaryItemsList.orderItemName(orderItem)}</strong>
                    <br />
                    Art-Nr. {orderItem.productNumber} <small>{CartSummaryItemsList.orderItemNameFormat(orderItem)}</small>
                    <br />
                    {orderItem && orderItem.data && orderItem.data.load &&
                    <small>Variante: {orderItem.data.load}</small>
                    }
                    {(orderItem) &&
                    CartSummaryItemsList.bundledOrderItemList(orderItem)
                      .map((bundledOrderItem, j) => {
                        return (
                          <span key={j}>
                            <br />
                            {bundledOrderItem.quantity > 0 &&
                              <small>
                                {bundledOrderItem.quantity} x {CartSummaryItemsList.orderItemName(bundledOrderItem)} - Art-Nr. {bundledOrderItem.productNumber} enthalten
                              </small>
                            }
                            {bundledOrderItem.quantity === undefined &&
                              <small>
                                wird nicht geliefert: {CartSummaryItemsList.orderItemName(bundledOrderItem)} - Art-Nr. {bundledOrderItem.productNumber}
                              </small>
                            }
                            {bundledOrderItem.quantity === 0 &&
                              <span>
                                {/* loop over all options of a bundled order item */
                                  CartSummaryItemsList.optionOrderItemList(orderItem)
                                    .map((optionOrderItem, o) => {
                                      return (
                                        <small key={o}>
                                          {CartSummaryItemsList.orderItemName(bundledOrderItem)} - Art-Nr. {bundledOrderItem.productNumber} ersetzt durch
                                          <br />
                                          {CartSummaryItemsList.orderItemName(optionOrderItem)} - Art-Nr. {optionOrderItem.productNumber}
                                        </small>
                                      );
                                    })
                                }
                              </span>
                            }
                          </span>
                        );
                      })}
                  </div>

                  <div className="col-xl-3 order-xl-3 col-md-5 order-md-3 col-12 order-4">
                    <Form className="form-inline mt-1">
                      <Label for={orderItem.productNumber}>Menge:</Label>
                      <Input
                        type="tel" id={orderItem.productNumber} name="quantity" className="form-control ml-2 mr-2" style={{width: '75px'}} disabled
                        value={this.getProductQuantity(orderItem)}
                      />
                    </Form>

                    <div className="mt-2">
                      <small>
                        {(this.getProductQuantity(orderItem) > 0 && (orderItem.data.materialUsageM2 || orderItem.data.materialUsageLFM)) &&
                          <span>
                            {ProductList.getFormattedUnits(orderItem,this.getProductQuantity(orderItem))}&nbsp;
                            {orderItem.quantity > 1 && this.getPluralPackagingTypeLabel(orderItem)}
                            {orderItem.quantity === 1 && this.getSingularPackagingTypeLabel(orderItem)}
                            &nbsp;({ProductList.getFormattedUsage(orderItem, this.getProductQuantity(orderItem))}
                            {orderItem.data.materialUsageM2 && <span> m<sup>2</sup>)</span>}{orderItem.data.materialUsageLFM && <span> lfm)</span>}
                          </span>
                        }
                        {(this.getProductQuantity(orderItem) > 0 && !(orderItem.data.materialUsageM2 || orderItem.data.materialUsageLFM)) &&
                        <span>
                            <ProductUnits order
                                          formattedUnits={ProductList.getFormattedUnits(orderItem, this.getProductQuantity(orderItem))}
                                          productQuantity={orderItem.quantity}
                                          pluralPackagingTypeLabel={this.getPluralPackagingTypeLabel(orderItem)}
                                          singularPackagingTypeLabel={this.getSingularPackagingTypeLabel(orderItem)}
                                          leastUnitsPackage={orderItem.data.leastUnitsPackage}
                            />
                            </span>
                        }
                      </small>
                    </div>
                  </div>

                  <div className="col-xl-2 order-xl-4 col-md-7 order-md-4 col-12 order-3">
                    {((this.props.currentCart.delivery || CartSummaryItemsList.isCustomTruck(this.props.currentCart.pickup))
                      && orderItem.data.forSale && orderItem.maxedOutQuantity && orderItem.maxedOutQuantity > 0) &&
                      <Form className="mt-2">
                        <FormGroup check inline>
                          <Label check>
                            <Input type="checkbox" name="finalDelivery" value="true" checked={this.hasMaxedOutCapacity(orderItem)} disabled /> Ausladung
                          </Label>
                        </FormGroup>
                      </Form>
                  }
                  </div>
                </div>
              );
            })}
      </div>
    );
  }
}

CartSummaryItemsList.propTypes = {
  currentCart: PropTypes.shape(Cart),
  currentMessage: PropTypes.shape(Message),
  dispatch: PropTypes.func.isRequired,
  customer: PropTypes.shape(Customer)
};

// https://github.com/reactjs/redux/blob/master/docs/basics/UsageWithReact.md
// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    currentCart: getCurrentCart(state),
    currentMessage: getCurrentMessage(state),
    customer: getCustomer(state)
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(CartSummaryItemsList)));
