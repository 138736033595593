import {callSecureApi} from '../../util/apiCaller';

import {
    CLEAR_CUSTOMER_FACILITIES,
    CLEAR_CUSTOMER_FACILITY,
    LOAD_CUSTOMER_FACILITIES,
    LOAD_CUSTOMER_FACILITY,
    SET_CURRENT_FACILITY,
    SET_FACILITIES
} from '../types';
import {setCurrentMessage} from "../MessagesAction";

export function getAllFacilities() {
    return dispatch => {
        console.info('loading all facilities');
        callSecureApi({
            endpoint: 'orderservice/v1/facility',
            method: 'get'
        }).then(res => {
            dispatch(setFacilities(res.facilities))
        }).catch(err => {
            console.error('unable to get facitlies', err)
        })
    }
}

export function getCustomerFacilities(customerId) {
    return dispatch => {
        if (!customerId) {
            console.info('skip loading facilities, customer id is null ');
            return;
        }
        console.info('loading facilities for customer id = ' + customerId);
        callSecureApi({
            endpoint: 'orderservice/v1/facility/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'get'
        }).then(res => {
            dispatch(setCustomerFacilities(res.facilities))
        }).catch(err => {
            dispatch(setCustomerFacilities([]))
            console.error('unable to load facilities', err)
        })
    }
}

export function createCustomerFacility(customerId, facility) {
    return dispatch => {
        if (!customerId) {
            console.info('skip loading facilities, customer id is null ');
            return;
        }
        console.info('create facility for customer id = ' + customerId);
        callSecureApi({
            endpoint: 'orderservice/v1/facility/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'post'
        }, facility).then(facility => {
            const message = {
                type: 'INFO',
                msg: `Das Werk / Lager wurde erfolgreich erstellt.`,
                source: 'CREATE_CUSTOMER_FACILITY'
            };
            dispatch(setCurrentMessage(message));
            dispatch(setFacilities(facility))
        }).catch(err => {
            const message = {
                type: 'ERROR',
                msg: `Das Werk / Lager konnte nicht erstellt werden. Bitte probieren Sie es später nochmal.`,
                source: 'CREATE_CUSTOMER_FACILITY'
            };
            dispatch(setCurrentMessage(message));
            console.error('unable to create facility', err)
        })
    }
}

export function updateCustomerFacility(facilityId, facility) {
    return dispatch => {
        if (!facilityId) {
            console.info('skip updating facility, facility id is null ');
            return;
        }
        console.info('update facility with id = ' + facilityId);
        callSecureApi({
            endpoint: 'orderservice/v1/facility/customer/{facilityId}',
            query: {'facilityId': facilityId},
            method: 'put'
        }, facility).then(facility => {
            const message = {
                type: 'INFO',
                msg: `Das Werk / Lager wurde erfolgreich bearbeitet.`,
                source: 'CHANGE_CUSTOMER_FACILITY'
            };
            dispatch(setCurrentMessage(message));
            dispatch(setFacilities(facility));
            dispatch(getCustomerFacilities(facility.customerId));
        }).catch(err => {
            const message = {
                type: 'ERROR',
                msg: `Das Werk / Lager konnte nicht bearbeitet werden.`,
                source: 'CHANGE_CUSTOMER_FACILITY'
            };
            dispatch(setCurrentMessage(message));
            console.error('unable to update facility', err)
        })
    }
}

export function deleteCustomerFacility(facilityId) {
    return dispatch => {
        if (!facilityId) {
            console.info('skip deleting facilities, facility id is null ');
            return;
        }
        console.info('delete facility with id = ' + facilityId);
        callSecureApi({
            endpoint: 'orderservice/v1/facility/customer/{facilityId}',
            query: {'facilityId': facilityId},
            method: 'delete'
        }).then(facility => {
            dispatch(setFacilities(facility))
            dispatch(getCustomerFacilities(facility.customerId))
        }).catch(err => {
            const message = {
                type: 'ERROR',
                msg: `Das Werk / Lager konnte nicht gelöscht werden.`,
                source: 'DELETE_CUSTOMER_FACILITY'
            };
            dispatch(setCurrentMessage(message));
            console.error('unable to delete facility', err)
        })
    }
}

export function setCustomerFacility(facility) {
    return {
        type: LOAD_CUSTOMER_FACILITY,
        facility
    };
}

export function setCustomerFacilities(facilities) {
    return {
        type: LOAD_CUSTOMER_FACILITIES,
        facilities
    }
}

export function clearCustomerFacility() {
    return {
        type: CLEAR_CUSTOMER_FACILITY
    };
}

export function clearCustomerFacilities() {
    return {
        type: CLEAR_CUSTOMER_FACILITIES
    }
}

export function setFacilities(facilities) {
    return {
        type: SET_FACILITIES,
        facilities
    }
}

export function setCurrentFacility(facility) {
    return {
        type: SET_CURRENT_FACILITY,
        facility
    }
}
