import {REMOVE_MESSAGE, SET_MESSAGE} from './types';

export function setCurrentMessage(message) {
    return {
        type: SET_MESSAGE,
        message
    };
}

export function removeCurrentMessage(message) {
    return {
        type: REMOVE_MESSAGE,
        message
    };
}