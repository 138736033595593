import {SET_ORGANIZATIONS} from '../actions/types';

const initialState = {
    organizations: undefined
};

const OrganizationsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ORGANIZATIONS:{
            return {
                ...state,
                organizations: action.organizations
            }
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getOrganizations = (state) => state.organizations;


// Export Reducer
export default OrganizationsReducer;
