import {
    LOGOUT,
    LOAD_CUSTOMER_FACILITIES,
    CLEAR_CUSTOMER_FACILITIES
} from '../actions/types';

// Initial State
const initialState = {
    stateFacilities: null
};

const CustomerFacilitiesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGOUT: {
            return {
                ...state,
                stateFacilities: null
            };
        }
        case LOAD_CUSTOMER_FACILITIES: {
            return {
                ...state,
                stateFacilities: action.facilities
            };
        }
        case CLEAR_CUSTOMER_FACILITIES: {
            return {
                ...state,
                stateFacilities: null
            };
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getCustomFacilities = (state) => state.facilities.stateFacilities;

// Export Reducer
export default CustomerFacilitiesReducer;
