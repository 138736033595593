import _ from 'lodash'

class Utils {
    constructor() {
        this.store = undefined;
        this.leafIdMap = {
            DE: {},
            PL: {}
        };
        this.leafSq =  {
            DE: {},
            PL:{}
        };
    }
    setStore(store){
        this.store = store;
    }
    getStore(){
        return this.store
    }
    createLeafIdMap(categories,country){
        let leafIdMap = this.leafIdMap[country];
        let leafSq = this.leafSq[country];

        for (let i = 0; i < categories.length; i++) {
            const category  = categories[i];
            let tuple = {0:undefined, 1:undefined};
            tuple[0]= category.number;
            recursiveCategory(category,leafIdMap,tuple)
        }

        function recursiveCategory(category,leafIdMap,tuple) {
            let noRefTuple = Object.assign({},tuple)
            if(!leafIdMap[category.number]){
                if(!category.label)
                    category.label = category.name
                leafIdMap[category.number] = category
            }
            const children = category.children;
            if(children){
                children.forEach((child)=>{
                    noRefTuple[1]= category.number;
                    recursiveCategory(child,leafIdMap,noRefTuple)
                })
            }else{
                leafSq[category.number] = noRefTuple;
            }
        }
    }
    getCategoryMap(country){
        if(!country){
            console.error('no country parameter')
            return null
        }
        return this.leafIdMap[country];
    }
    getLeafSq(country){
        if(!country){
            console.error('no country parameter')
            return null
        }
        return this.leafSq[country]
    }
    deepObjectDiff(object, base) {
        function changes(object, base) {
            return _.transform(object, function (result, value, key) {
                if (!_.isEqual(value, base[key])) {
                    result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                }
            });
        }

        return changes(object, base);
    }

}

export default new Utils();
