import {callSecureApi} from '../../util/apiCaller';

import {SET_PRICE_MAP} from '../types';

export function getAllProductPrices() {
    return dispatch => {
        callSecureApi({
            endpoint: 'priceservice/v1/price',
            method: 'get'
        }).then(res => {
            if (res)
                dispatch(setPriceMap(res.prices))
        }).catch(err => {
            console.error('unable to get prices ', err)
        })
    }
}

export function setPriceMap(priceMap) {
    return {
        type: SET_PRICE_MAP,
        priceMap
    };
}
