import {SET_CURRENT_LANG} from '../actions/types';
const initialState = {
    stateLocale:'DE-de'
};
const SettingsReducer = (state = initialState, action = {}) => {
    switch(action.type){
        case SET_CURRENT_LANG:{
            return {
                ...state,
                stateLocale: action.locale
            }
        }
        default: {
            return state;
        }
    }
};
/* Selectors */
export const getCurrentLocale = (state) => state.settings.stateLocale;

export default SettingsReducer
