import React, {Component} from 'react';
import Button from "reactstrap/es/Button";
import {withRouter} from "react-router";
import {
    PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE,
    PATH_ADMIN_EDIT_CUSTOMER_PAGE,
    PATH_MANAGE_PROJECT_OVERVIEW_PAGE,
    PATH_ADMIN_USER_CUSTOMER_PAGE,
    PATH_ADMIN_WORKER_MANAGE_PAGE, PATH_OFFER_OVERVIEW, PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE
} from "../../routePaths";

import {getCurrentUser} from "../../reducers/UserReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {getCustomer} from "../../reducers/CustomerReducer";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import BackButton from "../../components/Buttons/BackButton";
import _ from "lodash";
import ModalComponent from "../../components/Modals/ModalComponent";
import {deleteCustomer} from "../../actions";
import {Customer} from "../../proptypes";
import {FormattedMessage} from "react-intl";

class ManageCustomerMenuPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modals: {
                deleteCustomer: false
            }
        }
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    deleteCustomerModalOnSuccessHandler(e) {
        e.preventDefault();
        const customer = this.props.customer;
        this.props.dispatch(deleteCustomer(customer.id)).then(() => {
            this.props.history.push(PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE);
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container ">
                    <BackButton path={PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE}/>
                    {this.props.customer &&
                    <span>
                        <h4 className="text-muted text-center">{this.props.customer.name}</h4>
                        <h1 className="headline">
                            Händler verwalten
                        </h1>
                        <div className="d-flex flex-column offset-3 col-6">
                            <span className="pb-3 text-center">
                                <Button className="w-100" onClick={() => {
                                    this.props.history.push(PATH_ADMIN_EDIT_CUSTOMER_PAGE)
                                }}>
                                    Händlerdaten ändern
                                </Button>
                            </span>

                            <span className="pb-3 text-center">
                                <Button className="w-100" onClick={() => {
                                    this.props.history.push(PATH_MANAGE_PROJECT_OVERVIEW_PAGE)
                                }} >
                                    Lieferadressen verwalten
                                </Button>
                            </span>

                            <span className="pb-3 text-center">
                                <Button className="w-100" onClick={() => {
                                    this.props.history.push(PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE)
                                }}>
                                    <FormattedMessage id="settings.facility.menu"/>
                                </Button>
                            </span>

                            <span className="pb-3 text-center">
                                <Button className="w-100" onClick={() => {
                                    this.props.history.push(PATH_ADMIN_USER_CUSTOMER_PAGE)
                                }}>
                                    Benutzer verwalten
                                </Button>
                            </span>

                            <span className="pb-3 text-center">
                                <Button className="w-100" onClick={() => {
                                    this.props.history.push(PATH_ADMIN_WORKER_MANAGE_PAGE)
                                }}>
                                    Handwerker zuweisen
                                </Button>
                            </span>
                            <span className="pb-3 text-center">
                                <Button className="w-100" onClick={() => {
                                    this.props.history.push(PATH_OFFER_OVERVIEW)
                                }}>
                                    Angebote
                                </Button>
                            </span>
                            <span className="pb-3 text-center">
                                <Button className="w-100" color="danger"
                                        onClick={() => {
                                            this.showModal('deleteCustomer')
                                        }}>
                                    Händler löschen
                                </Button>
                            </span>
                        </div>
                    </span>
                    }
                </div>

                <ModalComponent
                    isOpen={this.state.modals.deleteCustomer}
                    toggle={() => this.showModal('deleteCustomer')}
                    hide={() => this.hideModal('deleteCustomer')}
                    color="danger"
                    title="Händler Löschen"
                    message={'Möchten Sie diesen Händler löschen?'}
                    onCancel={()=>{
                        this.hideModal('deleteCustomer')
                    }}
                    cancelText='Abbrechen'
                    onSuccess={(e) => {
                        this.deleteCustomerModalOnSuccessHandler(e)
                    }}
                    successText={'Händler löschen'}
                />
            </div>
        );
    }
}

ManageCustomerMenuPage.propTypes = {
    customer: PropTypes.shape(Customer).isRequired,
};

ManageCustomerMenuPage.defaultProps = {
    customer: {}
};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        currentUser: getCurrentUser(state),
        currentMessage: getCurrentMessage(state)
    };
}

export default withRouter(connect(mapStateToProps)(ManageCustomerMenuPage));
