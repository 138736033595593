import React, {Component} from 'react';
import NavItem from "reactstrap/lib/NavItem";
import Nav from "reactstrap/es/Nav";
import {getCurrentLocale} from "../../reducers/SettingsReducer";
import {connect} from "react-redux";
import {logout} from "../../actions";
import "./Header.scss";
import {
    PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE, PATH_ADMIN_ORGANIZATION_OVERVIEW,
    PATH_ADMIN_PRODUCT_OVERVIEW_PAGE,
    PATH_ADMIN_SETTINGS_PAGE,
    PATH_ADMIN_WORKER_OVERVIEW_PAGE,
    PATH_CART_PAGE,
    PATH_LOGIN_PAGE, PATH_OFFER_OVERVIEW, PATH_USER_PROJECT_OVERVIEW_PAGE,
    PATH_USER_SETTINGS_PAGE
} from "../../routePaths";
import {getCurrentUser} from "../../reducers/UserReducer";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";
import CartValidator from "../../util/cartValidation";
import {getCurrentCart} from "../../reducers/CartReducer";
import BannerComponent from "./BannerComponent";
import Logo from "./LogoComponent";
import Navigation from "./NavigationComponent";
import UserCartButton from "./UserCartButton";



class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            setIsOpen: false,
            currentLang: 'De'
        }
        this.toggle = this.toggle.bind(this);
    }

    logout() {
        this.props.dispatch(logout())
    }

    getAdminNavBar() {
        return <Nav className="d-flex flex-column flex-lg-row mt-3 ml-3" navbar>
            <NavItem>
                <NavLink to={PATH_ADMIN_CUSTOMER_OVERVIEW_PAGE}>Händler</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_ADMIN_PRODUCT_OVERVIEW_PAGE}>Produkt</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_ADMIN_WORKER_OVERVIEW_PAGE}>Handwerker</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_ADMIN_ORGANIZATION_OVERVIEW}>VKOrg</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_ADMIN_SETTINGS_PAGE}>Einstellungen</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_LOGIN_PAGE} onClick={(e) => this.logout(e)}>Logout</NavLink>
            </NavItem>
        </Nav>
    }

    getUserNavBar() {
        return <Nav className="d-flex flex-column flex-lg-row mt-3 ml-3" navbar>
            <NavItem>
                <NavLink to={PATH_USER_PROJECT_OVERVIEW_PAGE}>Bestellungen</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_OFFER_OVERVIEW}>Angebote</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_USER_SETTINGS_PAGE}>Einstellungen</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_LOGIN_PAGE} onClick={(e) => this.logout(e)}>Logout</NavLink>
            </NavItem>
        </Nav>
    }

    getCustomerUserNavBar() {
        return <Nav className="d-flex flex-column flex-lg-row mt-3 ml-3" navbar>
            <NavItem>
                <NavLink to={PATH_USER_PROJECT_OVERVIEW_PAGE}>Bestellungen</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_USER_SETTINGS_PAGE}>Einstellungen</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={PATH_LOGIN_PAGE} onClick={(e) => this.logout(e)}>Logout</NavLink>
            </NavItem>
        </Nav>
    }

        getCurrentLangTranslate(lang) {
        switch (lang) {
            case 'GERMAN':
                return this.props.intl.formatMessage({id: 'lang.de'});
            case 'POLISH':
                return this.props.intl.formatMessage({id: 'lang.pl'});
            default:
                return this.props.intl.formatMessage({id: 'lang.de'});
        }
    }

    toggle() {
        this.setState({isOpen: !this.state.isOpen});
    }

    showCart() {
        const user = this.props.user;
        if (user) {
            const hasCartRole = user.role === 'USER' || user.role === "CUSTOMER_USER";
            if (hasCartRole) {
                const cart = this.props.currentCart;
                if (cart) {
                    const cartNotFixed = !CartValidator.isCartFixed(this.props.currentCart);
                    if (cartNotFixed) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    switchToPage(e, pathToPage) {
        e.preventDefault();
        this.props.history.push(pathToPage);
        return false;
    }

    render() {
        const user = this.props.user;
        const currentUser = this.props.user;
        return (
            <header className="header bg-white mb-3 container">
                <div className="d-flex flex-row flex-lg-column mt-4">
                    <div className="d-flex flex-row flex-lg-row mb-4">
                        <div className="col-6 col-lg-2 order-1 order-lg-0">
                            <Logo/>
                        </div>
                        <div className="col-2 col-lg-8 order-0 order-lg-1 mt-2 mt-lg-0 menu align-self-lg-end">
                            <Navigation onClick={this.toggle} open={this.state.isOpen} user={user}
                                        adminNavBar={this.getAdminNavBar()}
                                        userNavBar={this.getUserNavBar()}
                                        customerUserNavBar={this.getCustomerUserNavBar()}
                            />
                        </div>
                        <div className="col-12 col-lg-2 order-2 align-self-lg-end">
                            <UserCartButton currentUser={currentUser} b={this.showCart()}
                                                  onClick={(e) => this.switchToPage(e, PATH_CART_PAGE)}
                                                  currentCart={this.props.currentCart}/>
                        </div>
                        {/*<div className="col-4 order-3 navbar nav lang-dropdown "/>*/}
                    </div>
                    <BannerComponent/>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentLocale: getCurrentLocale(state),
        currentCart: getCurrentCart(state),
        user: getCurrentUser(state)
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(Header)));
