export function roundNumber2Decimal(num) {
    return (Math.round((num + Number.EPSILON) * 100) / 100);
}

export function germanCurrency(num) {
    let curr;
    try {
        curr = num.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
    } catch {
        curr = 0;
    }
    return curr;
}

export const PRICE_PER_UNITS = 1000;

// return calculated price for a product for given quantity
export function getQuantityPrice(product, productQuantity, country) {
    if (!product || !productQuantity || !country) {
        return null;
    }
    const productPriceMap = product.data.prices;
    if (productPriceMap && productPriceMap[country]) {
        const quantity = !productQuantity ? 1 : productQuantity;
        let scalePrice = getScalePrice(product, productQuantity, country);
        const quantityPrice = scalePrice * quantity;
        return germanCurrency(quantityPrice);
    }
}

// return scaled price for given quantity
export function getScaledPrice(product, productQuantity, country) {
    if (!product || !productQuantity || !country) {
        return null;
    }
    const productPriceMap = product.data.prices;
    if (productPriceMap && productPriceMap[country]) {
        const scaledPrice = getScalePrice(product, productQuantity, country);
        return germanCurrency(scaledPrice);
    }
}

function getScalePrice(product, productQuantity, country) {
    const productPriceMap = product.data.prices;
    const ranges = Object.keys(productPriceMap[country]);
    const range = getRangeByValue(ranges, productQuantity);
    const prices = productPriceMap[country][range]
    const unitPrice = prices / PRICE_PER_UNITS;
    const leastUnitsPackage = product.data.leastUnitsPackage ? product.data.leastUnitsPackage : 1;
    return unitPrice * (leastUnitsPackage);
}

function getRangeByValue(ranges, value) {
    for (let i = 0; i < ranges.length; i++) {
        const actualRange = parseInt(ranges[i]);
        if (actualRange > value) {
            const lastRange = parseInt(ranges[i - 1]);
            if (lastRange !== undefined) {
                return lastRange;
            }
            return actualRange
        }
        if (i === ranges.length - 1) {
            return ranges[ranges.length - 1]
        }
    }
}
