import React, {Component, Fragment} from 'react';
import ListComponent from "./ListComponent";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {PATH_CART_PAGE, PATH_OFFER_OVERVIEW} from "../../routePaths";
import {getFormattedDateTime} from "../../util/TimeUtils";
import CartOrderHoc from "../CartOrderHOC";
import Button from "reactstrap/lib/Button";
import {deleteCart, removeCurrentOrder, reuseCart, loadCurrentProject} from "../../actions";
import {withRouter} from "react-router";
import _ from "lodash";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Modal from "reactstrap/lib/Modal";
import {Alert, ModalHeader} from "reactstrap";

class CartList extends Component {
    constructor(props) {
        super(props);
        this.state={
            modals:{
                cancelCart:false
            },
            deleteCartData: {},
        }
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }
    isProjectExpired(project) {
        const expires = project.expires;
        if (expires !== undefined) {
            const now = new Date().getTime();
            return now > expires;
        }
        return false;
    }
    reuseCart(e,project, cart) {
        e.stopPropagation();
        this.props.dispatch(removeCurrentOrder());
        this.props.dispatch(loadCurrentProject(project));
        this.props.dispatch(reuseCart(cart)).then(() => {
            this.props.history.push(PATH_CART_PAGE);
        })
    }
    isWorkerBlocked(project) {
        return project.worker && project.worker.blocked;
    }
    showDeleteCartModal(e, cart, project) {
        e.stopPropagation();
        const modals = Object.assign({}, this.state.modals);
        modals['cancelCart'] = true;
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            nextState.deleteCartData = {cart, project};

            return nextState;
        });

        e.preventDefault();
    }
    closeDeleteCartModal(e) {
        this.hideModal('cancelCart')
    }
    deleteCart(e) {
        const deleteCartData = this.state.deleteCartData;
        const user = this.props.currentUser;

        if (deleteCartData) {
            const cart = deleteCartData.cart;
            if (cart) {
                this.props.dispatch(deleteCart(cart, user));
                this.hideModal('cancelCart');
            }
        }
    }
    itemRenderer(cart,index){
        const project = this.props.project;
        return (
            <ListGroupItem key={cart.cartId} className="border-0">
                <div className="no-gutters d-flex flex-column">
                    <div className="offset-md-3 col-9">
                    {cart.delivery &&
                    <Fragment>
                        <span>
                            <FormattedMessage id="project.list.planneddelivery.text" />
                            {this.props.getTourDate(cart)}, {this.props.getFormattedTimeLabel(cart)}
                        </span>
                        <br/>
                    </Fragment>
                    }
                    {cart.pickup &&
                    <Fragment>
                        <span>
                            <FormattedMessage id="project.list.plannedpickup.text" />
                            {this.props.getTourDate(cart)}, {this.props.getFormattedTimeLabel(cart)}
                        </span>
                        <br/>
                    </Fragment>
                    }
                    {cart.offerNumber &&
                    <span>
                        <Link to={{pathname:PATH_OFFER_OVERVIEW,state:{offerNumber:cart.offerNumber}}}><FormattedMessage id="project.list.offernumber.text" /> {cart.offerNumber}</Link>
                        <br/>
                    </span>
                    }
                    <span>
                        <FormattedMessage id="project.list.creator.text" />
                        {cart.issuedBy.firstName} {cart.issuedBy.lastName}
                    </span>
                    <br/>
                    <span>
                        <FormattedMessage id="project.list.lastchange.text" />
                        {getFormattedDateTime(cart.updatedAt,'de-DE')}
                    </span>
                    </div>
                    <div className="d-flex float-right flex-column flex-md-row ml-md-auto">

                        <Button className="mr-md-2" onClick={(e) =>
                            this.showDeleteCartModal(e, cart, project)}
                        >
                            <span
                                className="oi oi-trash"/> <FormattedMessage id="project.list.cancelorder.button" />
                        </Button>
                        <Button
                            color="primary"
                            disabled={this.isProjectExpired(project) ||this.isWorkerBlocked(project)}
                            onClick={(e) => this.reuseCart(e,project, cart)}
                            className="mt-2 mt-md-0"
                        >
                            <FormattedMessage id="project.list.continueorder.button" />
                            <span
                                className="oi oi-chevron-right ml-2"/>
                        </Button>
                    </div>
                </div>


                <Modal isOpen={this.state.modals.cancelCart} fade={false} toggle={(e) => this.hideModal('cancelCart')}>
                    <ModalHeader toggle={this.props.hide}>Warenkorb entfernen</ModalHeader>
                    <ModalBody className="text-center">
                        {/*
                        <FormattedHTMLMessage id="app.text"
                      defaultMessage="Edit <code>src/App.js</code> and save to reload.<br/>Now with {what}!"
                      description="Welcome header on app main page"
                      values={{ what: 'react-intl' }}/>
                        */}
                        <Alert color="warning">Soll der Warenkorb endgültig gelöscht werden?</Alert>
                    </ModalBody>
                    <ModalFooter  className="mt-2 mb-2">
                        <div className="container-fluid">
                            <div className="container-fluid col-12 d-flex flex-row">
                                <div className="col-6">
                                    <Button color="primary" className="btn-block" onClick={(e) => this.deleteCart(e)}>Ja</Button>
                                </div>

                                <div className="col-6">
                                    <Button color="secondary" className="btn-block" onClick={(e) => this.closeDeleteCartModal(e)}>Nein</Button>
                                </div>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            </ListGroupItem>
        )
    }
    render() {
        return (
            <Fragment>
                {this.props.carts.length>0 && <p className="offset-md-3 offset-1 h6 mb-0  mt-2">Laufende Bestellungen:</p>}
                <ListComponent itemRenderer={this.itemRenderer.bind(this)} items={this.props.carts}/>
            </Fragment>
        );
    }
}

export default withRouter(CartOrderHoc(CartList));
