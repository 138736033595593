import {createStore, applyMiddleware, compose} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export default function configureStore(initialState = {}) {
    const enhancers = [
        applyMiddleware(thunk)
    ];
    let store;
    if (process.env.NODE_ENV === 'development') {
        store = createStore(rootReducer, initialState, composeWithDevTools(...enhancers));
    }else{
        store = createStore(rootReducer, initialState, compose(...enhancers));
    }
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }

    return store;
}
