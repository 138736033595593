import {LOGOUT, SET_EARLIEST_TOUR, REMOVE_EARLIEST_TOUR} from '../actions/types';

// Initial State
const initialState = {
  stateEarliestTour: null
};

const TourReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateEarliestTour: null
      };
    }
    case REMOVE_EARLIEST_TOUR: {
      return {
        ...state,
        stateEarliestTour: null
      };
    }
    case SET_EARLIEST_TOUR: {
      return {
        ...state,
        stateEarliestTour: action.tour
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getEarliestTour = (state) => state.tour.stateEarliestTour;

// Export Reducer
export default TourReducer;
