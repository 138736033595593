import {SET_ORGANIZATION} from '../actions/types';

const initialState = {
    organization: undefined,
};

const OrganizationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ORGANIZATION:{
            return {
                ...state,
                organization: action.organization
            }
        }
        default: {
            return state;
        }
    }
};

/* Selectors */
export const getCurrentOrganization = (state) => state.organization;


// Export Reducer
export default OrganizationReducer;
