import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import WorkerForm from "../../components/Form/WorkerForm";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getWorker} from "../../reducers/WorkerReducer";
import {connect} from "react-redux";

class AdminWorkerEditPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    {/*<h4 className="text-muted text-center">{this.props.customer.name}</h4>*/}
                    <h1 className="headline">
                        Handwerker anzeigen
                    </h1>
                    {this.props.worker && this.props.worker.address &&
                        <WorkerForm edit/>
                    }
                </div>
            </div>
        );
    }
}

AdminWorkerEditPage.propTypes = {};
function mapStateToProps(state) {
    return {
        customer:getCustomer(state),
        worker: getWorker(state)
    }
}
export default connect(mapStateToProps)(AdminWorkerEditPage);
