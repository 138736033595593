import React, {Component} from 'react';
import {Alert, Form, FormGroup, Input, InputGroup, Spinner} from "reactstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getCheckList, getOffer, getOffers} from "../../reducers/OffersReducer";
import {getCustomer} from "../../reducers/CustomerReducer";

import {
    createNewCartFromOffer,
    getOffersFromCustomer,
    loadCustomer,
    loadCustomerCarts,
    setCheckList,
    setCurrentCart,
    loadCustomerWorkers, setOffer, setOffers
} from "../../actions";
import {goToPage} from '../../util/page';
import _ from "lodash";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";
import SearchBar from "../../components/SearchBar";


import ErrorModal from "../../components/Modals/ErrorModalComponent";

import OfferList from "../../components/Offers/OfferList";
import moment from "moment";
import SortComponent from "../../components/SortComponent";
import {getCustomerCarts} from "../../reducers/CartsReducer";
import {PATH_CART_PAGE} from "../../routePaths";
import {getCurrentUser} from "../../reducers/UserReducer";

export const FILTER_STATE_REDEEMED = 'redeemed';
export const FILTER_STATE_EXPIRED = 'expired';
export const FILTER_STATE_UNREDEEMED = 'unredeemed';
export const FILTER_STATE_ALL = 'all';

class CustomerOfferOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: undefined,
            filteredOffers: undefined,
            collapsedOffer: undefined,
            checkList: [],
            sortOption: {label: 'Name', value: 'NAME'},
            sortDirection: 'ASC'
        }
    }

    componentDidMount() {
        const user = this.props.currentUser;
        if (!this.props.customer)
            this.props.dispatch(loadCustomer(user.customerId))
        if(this.props.customer){
            const customerId = this.props.customer.id;
            this.props.dispatch(getOffersFromCustomer(customerId));
            this.props.dispatch(loadCustomerWorkers(customerId));
            this.props.dispatch(loadCustomerCarts(this.props.currentUser));
        }
        const offer = this.props.offer;
        if (offer) {
            this.setState({collapsedOffer: offer.number})
        }

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.key !== this.props.location.key) {
            if(this.props.customer) {
                const customerId = this.props.customer.id;
                this.props.dispatch(setOffers(undefined));
                this.props.dispatch(getOffersFromCustomer(customerId));
            }
        }
        if (prevProps.location.state !== this.props.location.state) {
            this.props.dispatch(setOffers(undefined));
            this.setState({collapsedOffer:undefined})
           if(this.props.location.state === null){
               this.resetSearch();

               this.props.dispatch(setOffer(undefined))
               if(this.props.customer){
               const customerId = this.props.customer.id;
               this.props.dispatch(getOffersFromCustomer(customerId));
               }
           }
        }
        if(this.props.customer){
            const customerId = this.props.customer.id;
            if(!this.props.offers)
                this.props.dispatch(getOffersFromCustomer(customerId));
            if(!this.props.workers)
                this.props.dispatch(loadCustomerWorkers(customerId));
            if(!this.props.carts)
                this.props.dispatch(loadCustomerCarts(this.props.currentUser));
        }
        if (prevProps.offers !== this.props.offers) {
            this.setState({filterActive:FILTER_STATE_UNREDEEMED})
            this.handleFilterState(FILTER_STATE_UNREDEEMED)
            if (this.props.location && this.props.location.state && this.props.location.state.offerNumber) {
                const offerNumber = this.props.location.state.offerNumber;
                this.setState({searchTerm: offerNumber})
                this.filteredOfferList(offerNumber, this.props.offers);
                this.setState({collapsedOffer: offerNumber})
                this.fillOfferChecklist(_.find(this.props.offers, {'number': offerNumber}));

            }
            const selectedOffer = document.getElementById('offerNumber')
            if (selectedOffer) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }

    switchToPage(e, pathToPage) {
        e.preventDefault();
        this.props.history.push(pathToPage);
        return false;
    }

    getWorkerByWorkerNumber(workerNumber) {
        if (this.props.workers && workerNumber)
            return _.find(this.props.workers, {'number': workerNumber}) || {name: 'not found'};
    }

    filteredOfferList(searchTerm, sources) {
        let filterdOffers = sources;
        if (searchTerm) {
            filterdOffers = _.filter(sources, (src) => {
                const name = src.name || "";
                const number = src.number || "";
                const city = src.address.city || "";
                const addressLine1 = src.address.addressLine1 || "";
                const postalCode = src.address.postalCode || "";
                return number.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                    || city.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                    || addressLine1.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                    || postalCode.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                    || name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            });
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filteredOffers = filterdOffers;
            return nextState;
        });
    }

    handleSearchInput(event) {
        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            searchTerm: search
        });
        this.filteredOfferList(search, this.props.offers);
    }

    resetSearch() {
        this.setState({searchTerm: ""})
        this.setState({filteredOffers: undefined})
    }

    createNewCart(e, offer) {
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatch(createNewCartFromOffer(offer.number, this.props.checkList));
    }

    continueCart(e, cart) {
        this.props.dispatch(setCurrentCart(cart))
        goToPage(PATH_CART_PAGE)
    }

    handleFilterState(filterState) {
        if (filterState) {
            const allOffers = this.props.offers;
            if (filterState === FILTER_STATE_ALL) {
                this.setState({filteredOffers: allOffers})
            }
            if (filterState === FILTER_STATE_EXPIRED) {
                this.setState({
                    filteredOffers: _.filter(allOffers, function (offer) {
                        console.log(offer.expires, '<', new Date())
                        return offer.expires ? moment(offer.expires).isBefore(new Date()) : false;

                    })
                })
            }
            if (filterState === FILTER_STATE_UNREDEEMED) {
                this.setState({
                    filteredOffers: _.filter(allOffers, function (offer) {
                        return offer.expires ? moment(offer.expires).isAfter(new Date()): true
                    })
                })
            }
        }
    }

    selectAllProducts(offer) {
        this.props.dispatch(setCheckList(_.map(offer.offerItems, 'productNumber')))
    }

    deselectAllProducts() {
        this.props.dispatch(setCheckList([]))
    }

    submitOfferFilter(event) {
        const filterState = event.target.value;
        const offers = this.props.offers;
        this.setState({filterActive:filterState});
        if (offers) {
            this.handleFilterState(filterState);
        }
    }

    toggleOffer(offer) {
        if (this.state.collapsedOffer !== offer.number) {
            this.setState({collapsedOffer: offer.number})
            this.fillOfferChecklist(offer);
        } else {
            this.setState({collapsedOffer: undefined})
            this.props.dispatch(setCheckList([]))
        }
    }

    fillOfferChecklist(offer) {
        this.props.dispatch(setCheckList(_.map(_.filter(offer.offerItems, {'option': false}), 'productNumber')));
    }

    sortOffers(options) {
        let sortedOptions;
        switch (this.state.sortOption.value) {
            case "NAME":
                sortedOptions = _.sortBy(options, ['name'])
                break;
            case "CITY":
                sortedOptions = _.sortBy(options, [function (o) {
                    return o.address.city;
                }])
                break;
            case "POSTAL":
                sortedOptions = _.sortBy(options, [function (o) {
                    return o.address.postalCode;
                }])
                break;
            case "EXPIRES":
                sortedOptions = _.sortBy(options, ['expires'])
                break;
            default:
                sortedOptions = []
                break;
        }

        if (this.state.sortDirection === 'DESC')
            return sortedOptions.reverse();
        else
            return sortedOptions;
    }

    onSortChange(option) {
        if (option) {
            this.setState({sortOption: option})
        }
    }

    directionHandler() {
        const sortDirection = this.state.sortDirection;
        if (sortDirection === "ASC")
            this.setState({sortDirection: "DESC"})
        else
            this.setState({sortDirection: "ASC"})
    }

    render() {
        const beforeRender = this.props.workers && this.props.offers && this.props.customer && this.props.offers.length > 0;
        const offers = this.sortOffers(this.state.filteredOffers || this.props.offers || [])
        const scrollToOfferNumber = this.props.location && this.props.location.state && this.props.location.state.offerNumber ? this.props.location.state.offerNumber : undefined;
        const carts = this.props.carts ? this.props.carts.carts : undefined;
        return (
            <div className="page-content">
                <div className="container">
                    {this.props.customer && <h4 className="text-muted text-center">{this.props.customer.name}</h4>}
                    <h1 className="h2 text-center mb-4">
                        Angebotsübersicht
                    </h1>
                    <SearchBar
                        filter
                        handleSearch={this.handleSearchInput.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                        placeholder={'Suche nach Angebotsnummer oder Adresse'}
                    />
                    <Form onSubmit={(event) => this.submitOfferFilter(event)}>
                        <FormGroup>
                            <InputGroup>
                                <Input type="select" onChange={this.submitOfferFilter.bind(this)}
                                       value={this.state.filterActive}>
                                    <option value={FILTER_STATE_ALL}>Alle Angebote</option>
                                    <option value={FILTER_STATE_EXPIRED}>Abgelaufene Angebote</option>
                                    <option value={FILTER_STATE_UNREDEEMED}>Einlösbare Angebote</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                    <SortComponent
                        options={this.getOptions()}
                        onChange={this.onSortChange.bind(this)}
                        value={this.state.sortOption}
                        directionValue={this.state.sortDirection}
                        directionHandler={this.directionHandler.bind(this)}
                    />
                    {this.props.offers && this.props.offers.length === 0 &&
                    <Alert color="info" className="text-center"><span className="oi oi-info"/> Keine Angebote vorhanden</Alert>
                    }
                    {!beforeRender && !this.props.offers &&
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Angebote werden geladen...</span>
                        </Spinner>
                    </div>
                    }
                    {beforeRender &&
                    <OfferList toggleOffer={this.toggleOffer.bind(this)}
                               collapsedOffer={this.state.collapsedOffer}
                               onSelectAll={this.selectAllProducts.bind(this)}
                               onDeselect={this.deselectAllProducts.bind(this)}
                               onCreateNewCart={this.createNewCart.bind(this)}
                               onContinueCart={this.continueCart.bind(this)}
                               customerWorkers={this.props.workers}
                               dispatch={this.props.dispatch}
                               scrollTo={scrollToOfferNumber}
                               offers={offers}
                               checkList={this.state.checkList}
                               carts={carts}
                    />
                    }

                </div>
                <ErrorModal source='OFFER_OVERVIEW'/>
            </div>
        );
    }

    getOptions() {
        return [{label: "Name", value: "NAME"}, {label: "Stadt", value: "CITY"}, {
            label: "Plz",
            value: "POSTAL"
        }, {label: "Gültigkeit", value: "EXPIRES"}];
    }
}

CustomerOfferOverview.propTypes = {};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        offers: getOffers(state),
        workers: getCustomerWorkers(state),
        checkList: getCheckList(state),
        offer: getOffer(state),
        carts: getCustomerCarts(state),
        currentUser: getCurrentUser(state)
    }
}

export default withRouter(connect(mapStateToProps)(CustomerOfferOverview));
