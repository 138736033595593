import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import Button from "reactstrap/lib/Button";
import {PATH_OFFER_PROJECT_CREATE_PAGE} from "../../routePaths";
import _ from "lodash";
import SearchBar from "../../components/SearchBar";
import {setProduct} from "../../actions";
import ErrorModal from "../../components/Modals/ErrorModalComponent";
import ChooseProjectForOfferList from "../../components/Projects/ChooseProjectForOfferList";
import {getCustomerProjects} from "../../reducers/ProjectsReducer";
import {getCurrentUser} from "../../reducers/UserReducer";
import {connect} from "react-redux";
import {getOffer} from "../../reducers/OffersReducer";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCustomerWorkers} from "../../reducers/CustomerWorkersReducer";
import OrderAdress from "../../components/Address/OrderAdress";
import {FormattedMessage, injectIntl} from "react-intl";

class CustomerChooseProjectWithOfferPage extends Component {
    constructor(props) {
        super(props);

        this.state={
            searchTerm: undefined
        }
    }
    handleSearchInput(event) {
        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            searchTerm: search
        });
        this.filteredProjectList(search, this.props.projects);
    }

    filteredProjectList(searchTerm, projects) {
        let localProjects = projects;
        if (searchTerm) {
            localProjects = _.filter(projects, (project) => {
                console.log(project);
                return project.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || project.number.indexOf(searchTerm) !== -1;
            });
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filteredProjects = localProjects;
            return nextState;
        });
    }

    resetSearch() {
        this.setState({searchTerm: ""})
    }

    goToPage(path, product) {
        if (product) {
            this.props.dispatch(setProduct(product))
        }
        this.props.history.push(path);
    }
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h6 className="text-muted text-center m-3">
                        <OrderAdress currentProject={this.props.offer}/>
                    </h6>
                    <h1 className="h2 text-center mb-4">
                        Lieferadresse wählen
                    </h1>
                    <div className="float-right row mb-2">
                        <Button color="primary" onClick={() => this.goToPage(PATH_OFFER_PROJECT_CREATE_PAGE)}
                                size="sm"><span className="oi oi-plus" /> <FormattedMessage id="customerchooseprojectwithofferpage.button.completeadress" /></Button>
                    </div>
                    <SearchBar
                        filter
                        handleSearch={this.handleSearchInput.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                    />
                    <ChooseProjectForOfferList dispatch={this.props.dispatch}  customerWorkers={this.props.customerWorkers} customer={this.props.currentCustomer} offer={this.props.offer} projects={this.props.projects} filteredProjects={this.state.filteredProjects} />
                </div>
                <ErrorModal source='OFFER_CHOOSE_PROJECT'/>
            </div>
        );
    }
}

CustomerChooseProjectWithOfferPage.propTypes = {};
function mapStateToProps(state) {
    return {
        projects: getCustomerProjects(state),
        currentUser: getCurrentUser(state),
        offer: getOffer(state),
        currentCustomer:getCustomer(state),
        customerWorkers: getCustomerWorkers(state),
    }
}
export default connect(mapStateToProps)(injectIntl(CustomerChooseProjectWithOfferPage));
