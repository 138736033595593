import {LOAD_CUSTOMER_ORDERS, LOGOUT, SET_ORDER_REPORT} from '../actions/types';

// Initial State
const initialState = {
  stateCustomerOrders: null
};

const OrdersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateCustomerOrders: null
      };
    }
    case LOAD_CUSTOMER_ORDERS: {
      const actionOrders = action.orders;
      if (!actionOrders.orders) {
        return {
          ...state,
          stateCustomerOrders: null
        };
      }
      // console.info('actionOrders=' + JSON.stringify(actionOrders));

      return {
        ...state,
        stateCustomerOrders: actionOrders
      };
    }
    case SET_ORDER_REPORT: {
      const orderReports = action.orderReports.report;
      if (!orderReports) {
        return {
          ...state,
          orderReports: null
        };
      }
      return {
        ...state,
        orderReports
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCustomerOrders = (state) => state.orders.stateCustomerOrders;
export const getOrderReports = (state) => state.orders.orderReports;

// Export Reducer
export default OrdersReducer;
