import React, {Component} from "react";
import BackButton from "../components/Buttons/BackButton";
import UserRegistrationForm from "../components/Form/UserRegistrationForm";

class UserRegistrationPage extends Component {
    render() {
    return (
            <div className="page-content">
                <div className="container">
                    <BackButton/>
                    <h1 className="h2 text-center mb-4">
                        Kunden Registrierung
                    </h1>
                    <UserRegistrationForm create/>
                </div>
            </div>
        );
    }
}

UserRegistrationPage.propTypes = {};

export default UserRegistrationPage;


