import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {User} from "../proptypes";
import {Table} from "reactstrap";
import Button from "reactstrap/es/Button";
import {withRouter} from "react-router";

class UserList extends Component {

    render() {
        const users = this.props.users || [];
        const currentUser = this.props.currentUser;
        return (
            <div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th className="border-0">Login</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Vorname</th>
                        <th className="border-0">Nachname</th>
                        <th className="border-0" />
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>{user.credentials.email}</td>
                                <td>{user.contact.email}</td>
                                <td>{user.firstName}</td>
                                {user.role !== 'CUSTOMER_USER' &&
                                    <td>{user.lastName}</td>
                                }
                                {user.role === 'CUSTOMER_USER' &&
                                    <td>{user.lastName} (EK)</td>
                                }
                                <td className="text-right">
                                    <span>
                                        <Button className="mr-3" onClick={() => this.props.onEdit(user)}>
                                            Benutzer bearbeiten
                                        </Button>
                                    </span>
                                    <span>
                                        <Button color="danger" onClick={() => this.props.onRemove(user)} disabled={currentUser.id === user.id}>
                                            <span className="oi oi-trash"/> löschen
                                        </Button>
                                    </span>
                                </td>
                            </tr>)
                    })}
                    </tbody>
                </Table>
            </div>
        );
    }
}

UserList.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape(User)).isRequired,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
};

export default withRouter(UserList);
