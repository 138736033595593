import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Button} from 'reactstrap';

import {Customer, Message, User} from '../../proptypes';

import {getCurrentMessage} from '../../reducers/MessageReducer';
import {getCustomer} from '../../reducers/CustomerReducer';
import UserList from "../../components/UserList";
import {
    PATH_ADMIN_MANAGE_CUSTOMER_PAGE,
    PATH_ADMIN_USER_CREATE_PAGE,
    PATH_ADMIN_USER_CUSTOMER_PAGE,
    PATH_ADMIN_USER_EDIT_PAGE,
    PATH_USER_SETTINGS_PAGE
} from "../../routePaths";
import BackButton from "../../components/Buttons/BackButton";
import {getCustomerUsers} from "../../reducers/UsersReducer";
import {deleteUser, getAllUsersFromCustomer, setUserToManage} from "../../actions";
import ModalComponent from "../../components/Modals/ModalComponent";
import _ from "lodash";
import {getCurrentUser} from "../../reducers/UserReducer";
import Auth from "../../util/Auth";

class ManageUserPage extends Component {
    constructor(props) {
        super(props);
        this.switchToPage = this.switchToPage.bind(this);

        this.state = {
            customerUsers: [
                {
                    "contact": {
                        "email": "contact@email.com",
                        "fax": "087 62733-110",
                        "mobile": "0179 1234567",
                        "phone": "087 62733-0"
                    },
                    "credentials": {
                        "email": "user@email.com",
                        "password": "password"
                    },
                    "customerNumber": "83572",
                    "expires": 0,
                    "firstName": "Max",
                    "gender": "MALE",
                    "id": 0,
                    "language": "GERMAN",
                    "lastLogin": 0,
                    "lastName": "Mustermann",
                    "role": "ANONYMOUS",
                    "title": "Dr."
                }
            ],
            selectedUser: undefined,
            modals: {
                delete: false,
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(getAllUsersFromCustomer(this.props.customer))
    }

    switchToPage(pathToPage) {
        this.props.history.push(pathToPage);
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    editUserHandler(userToManage) {
        this.props.dispatch(setUserToManage(userToManage));
        this.switchToPage(PATH_ADMIN_USER_EDIT_PAGE)
    }

    removeUserHandler(user) {
        this.setState({selectedUser: user});
        this.showModal('delete')

    }

    deleteModalOnSuccessHandler(e) {
        let user = this.state.selectedUser;
        let customer = this.props.customer;
        this.props.dispatch(deleteUser(user, customer));
        this.hideModal('delete');
        this.switchToPage(e, PATH_ADMIN_USER_CUSTOMER_PAGE);
    }

    render() {
        const users = this.props.customerUsers ? this.props.customerUsers.users : [];
        const currentUser = this.props.currentUser;
        return (
            <div className="page-content">
                <div className="container">
                    {Auth.isAdmin() &&
                        <BackButton path={PATH_ADMIN_MANAGE_CUSTOMER_PAGE}/>
                    }
                    {!Auth.isAdmin() &&
                        <BackButton path={PATH_USER_SETTINGS_PAGE}/>
                    }

                    <h4 className="text-muted text-center">{this.props.customer.name}</h4>
                    <h1 className="h2 text-center mb-4">
                        Benutzerverwaltung
                    </h1>

                    <div className="mb-4">
                        <Button onClick={() => this.switchToPage(PATH_ADMIN_USER_CREATE_PAGE)} size="sm">
                            Neuen Benutzer anlegen
                        </Button>
                    </div>

                    <div className="d-flex flex-column">
                        <UserList currentUser={currentUser} users={users} onEdit={this.editUserHandler.bind(this)}
                                  onRemove={this.removeUserHandler.bind(this)}/>
                    </div>
                </div>

                <ModalComponent
                    isOpen={this.state.modals.delete}
                    toggle={() => this.showModal('delete')}
                    hide={() => this.hideModal('delete')}
                    color="danger"
                    title="Benutzer Löschen"
                    message={'Möchten Sie diesen Benutzer löschen?'}
                    onCancel={()=>{
                        this.setState({selectedUser:{}});
                        this.hideModal('delete')
                    }}
                    cancelText='Abbrechen'
                    onSuccess={(e) => {
                       this.deleteModalOnSuccessHandler(e)
                    }}
                    successText={'Benutzer löschen'}
                />
            </div>
        )
    }
}

ManageUserPage.propTypes = {
    customer: PropTypes.shape(Customer).isRequired,
    currentMessage: PropTypes.shape(Message),
    customerUsers: PropTypes.arrayOf(PropTypes.shape(User)).isRequired,
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        customer: getCustomer(state),
        customerUsers: getCustomerUsers(state),
        currentMessage: getCurrentMessage(state),
        currentUser: getCurrentUser(state)
    };
}

export default withRouter(connect(mapStateToProps)(ManageUserPage));
