import React from 'react';
import {ListGroup} from "reactstrap";
import PropTypes from 'prop-types';

function ListComponent(props) {
    const items= props.items;

    return (
        <ListGroup flush>
            {items && items.map((item,index) => {
                return props.itemRenderer(item,index)
            })}
        </ListGroup>
    );
}

ListComponent.propTypes = {
    itemRenderer:PropTypes.func.isRequired,
    items: PropTypes.array.isRequired
};

export default ListComponent;