import React, {useEffect, useState} from 'react';
import {Toast, ToastBody} from "reactstrap";
import PropTypes from "prop-types";

function Toastie(props) {
    const [isOpen, setIsOpen] = useState(props.isOpen)

    useEffect(()=>{
        let isMounted = true;
        setTimeout(function(){
            if(isMounted) {
                setIsOpen(false)
                if (props.onToastFinished)
                    props.onToastFinished()
            }
        },
            4000);
        return () => { isMounted = false }
    })
    return (
        <div className="toastie alert-info ">
            <Toast color={props.color || 'success'} isOpen={isOpen}  >
                <ToastBody>
                    <div className="d-flex flex-row">
                        <div className="col-1"> <span className='oi oi-info'/> </div>
                        <div className="col-11">{props.message}</div>
                    </div>
                </ToastBody>
            </Toast>
        </div>
    );
}

Toastie.propTypes = {
    color: PropTypes.string,
    message: PropTypes.string.isRequired,
    onToastFinished: PropTypes.func,
    isOpen: PropTypes.bool
};

export default Toastie;
