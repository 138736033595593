import React, {Component} from 'react';
import {Alert, ListGroup, ListGroupItem} from "reactstrap";
import SearchBar from "../SearchBar";
import _ from "lodash";
import Badge from "reactstrap/es/Badge";

class TransferList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredSrc : undefined,
            searchTerm: undefined,
            targetSearchTerm: undefined
        }
    }

    componentDidUpdate(prevProps) {
        if((prevProps.src !== this.props.src) || (prevProps.target !== this.props.target)){
            this.resetSearch()
        }
    }

    highlightRow(index) {
        this.setState({highlightedRow: index})
    }

    filteredList(searchTerm,data, target){
        if(!searchTerm || !data || !target){
            console.error("error: filteredList not enough parameters")
            return null;
        }

        let src = _.filter(data, (src) => {
            return src.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || src.number.indexOf(searchTerm) !== -1;
        });

        if(target === 'source'){
            this.setState({filteredSrc:src})
        }
        if(target === 'target'){
            this.setState({filteredTarget:src})
        }
    }

    handleSearchInput(event) {
        event.preventDefault();

        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }
        this.setState({
            searchTerm: search
        });
        this.filteredList(search, this.props.src, 'source');
    }
    handleTargetSearchInput(event) {
        event.preventDefault();

        let search = this.state.targetSearchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }
        this.setState({
            targetSearchTerm: search
        });
        this.filteredList(search, this.props.target, 'target');
    }
    resetSearch(){
        this.setState({searchTerm:""})
        this.setState({targetSearchTerm:""})
        this.setState({filteredSrc:undefined})
        this.setState({filteredTarget: undefined})
    }
    resetTargetSearch(){
        this.setState({targetSearchTerm:""})
    }
    render() {
        const src = this.state.filteredSrc|| this.props.src ||[]
        const target = this.state.filteredTarget || this.props.target ||[]
        const srcTitle = this.props.srcTitle;
        const targetTitle = this.props.targetTitle;

        return (
            <div className="d-flex flex-row">
                <div className="col-6 align-content-center">
                    <h4>{srcTitle}</h4>
                    <SearchBar
                        filter
                        handleSearch={this.handleSearchInput.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                    />
                    {!this.state.searchTerm && this.props.src.length === 0 &&
                    <Alert className="text-center" color="info"> <span className="oi oi-info"/> Handwerker werden geladen.</Alert>
                    }
                    {!this.state.searchTerm && this.props.src.length > 0 &&
                    <Alert className="text-center" color="info"> <span className="oi oi-info"/> Bitte Benutzen Sie die Suche um Fachhandwerker auszuwählen.</Alert>
                    }
                    {this.state.searchTerm && this.state.searchTerm.length <= 3 &&
                    <Alert className="text-center" color="info"> <span className="oi oi-info"/> Der Suchbegriff muss mindestens 4 Zeichen lang sein!</Alert>
                    }
                    {this.state.searchTerm && this.state.searchTerm.length >3 && src.length > 500  &&
                    <Alert className="text-center" color="info"> <span className="oi oi-info"/> Es wurden mehr als 500 Handwerker gefunden. Bitte grenzen Sie den Suchbegriff weiter ein!</Alert>
                    }
                    {this.state.searchTerm && this.state.searchTerm.length > 3 && src.length < 500 &&
                        <ListGroup>
                            {src.map((item,index) =>
                                <ListGroupItem onClick={()=> this.props.onSelect(item,index)}>{item.name} <small>({item.number})</small> {item.blocked && <small><Badge color="danger">blockiert</Badge></small>} <span className="oi oi-chevron-right pl-2"/> </ListGroupItem>
                            )}
                        </ListGroup>
                    }
                </div>
                <div className="col-6">
                    <h4>{targetTitle}</h4>
                    <SearchBar
                        filter
                        handleSearch={this.handleTargetSearchInput.bind(this)}
                        searchTerm={this.state.targetSearchTerm}
                        onChange={this.handleTargetSearchInput.bind(this)}
                        onReset={this.resetTargetSearch.bind(this)}
                    />
                    <ListGroup>
                        {target.map((item,index) =>
                            <ListGroupItem onClick={()=> this.props.onDeSelect(item,index)}><span className="oi oi-chevron-left pr-2"/>{item.name} <small>({item.number})</small> {item.blocked && <small><Badge color="danger">blockiert</Badge></small>} </ListGroupItem>
                        )}
                    </ListGroup>
                </div>
            </div>
        );
    }
}

TransferList.propTypes = {};

export default TransferList;
