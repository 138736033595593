import React, {Component} from 'react';
import ListGroupItem from "reactstrap/es/ListGroupItem";
import { Collapse, ListGroupItemHeading} from "reactstrap";
import classNames from "classnames";
import moment from "moment";
import de from "moment/locale/de"
import Button from "reactstrap/es/Button";
import {setOffer} from "../../actions";
import {PATH_PROJECT_OFFER_CHOOSE_PAGE} from "../../routePaths";
import ListGroupItemText from "reactstrap/es/ListGroupItemText";
import ListGroup from "reactstrap/es/ListGroup";
import ItemList from "../ItemList";
import _ from "lodash";
import {goToPage} from '../../util/page';

class OfferList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsedOffer:undefined,
        }
    }

    componentDidUpdate(){
        window.scrollTo(0,0);
    }
    getWorkerByWorkerNumber(workerNumber) {
        const customerWorkers = this.props.customerWorkers;
        if (customerWorkers && workerNumber)
            return _.find(customerWorkers, {'number': workerNumber});
        else
            return null
    }
    hasActiveCart(offer){
        return _.find(this.props.carts, {'offerNumber':offer.number});
    }
    getCart(offer){
        return _.find(this.props.carts, {'offerNumber':offer.number});
    }
    render() {
        return (
            <> {this.props.offers.map((offer) => {
                    const name = offer.name || "";
                    const address = offer.address;
                    const addressLine1 = address.addressLine1 ? ' / '+address.addressLine1: "";
                    const postalCode = ' / '+address.postalCode || "";
                    const city = ' / '+address.city || "";
                const collapsedOffer = this.props.collapsedOffer;
                return (
                        <ListGroupItem id={'#'+offer.number} style={{'overflow-y':'auto'}} key={offer.number} ref={this[`${offer.number}_ref`]}>
                            {offer &&
                            <ListGroupItemHeading className="mt-2 d-flex flex-row"
                                                  onClick={() => this.props.toggleOffer(offer)}>
                                <div className="col-1 clickable">
                                            <span
                                                className={classNames("pl-2 float-left oi", {"oi-chevron-right": collapsedOffer !== offer.number},
                                                    {"oi-chevron-bottom": collapsedOffer === offer.number})}/>
                                </div>
                                <div className="col-8">
                                    <h5 className="clickable">{name} {addressLine1} {postalCode} {city}</h5>
                                    <small>Angebotsnummer: {offer.number}</small> <br/>
                                    {offer.workerNumber && this.getWorkerByWorkerNumber(offer.workerNumber) &&
                                    <small className="text-muted">
                                        Fachhandwerker: {this.getWorkerByWorkerNumber(offer.workerNumber).name}
                                        <br/>
                                    </small>
                                    }
                                    {offer.expires &&
                                    <small className="text-muted">
                                        Gültig bis: {moment(offer.expires).format("L", de).toString()}<br/>
                                    </small>
                                    }


                                </div>
                                <div className="col-3">
                                            <span className="float-right">
                                            {!this.hasProject(offer) && this.isNotExpired(offer) &&
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.props.dispatch(setOffer(offer))
                                                    goToPage(PATH_PROJECT_OFFER_CHOOSE_PAGE);
                                                }}>Lieferadresse festlegen <span
                                                className="oi oi-chevron-right"/></Button>
                                            }
                                            {this.hasProject(offer) && !this.hasActiveCart(offer) &&
                                                <div>
                                                    <Button className="float-right"
                                                            onClick={(e) => this.props.onCreateNewCart(e, offer)} color="primary"><span className="oi oi-plus"/> Neue Bestellung</Button>
                                                </div>
                                            }
                                            {this.hasProject(offer) && this.hasActiveCart(offer) &&
                                                <div>
                                                    <Button className="float-right"
                                                            onClick={(e) => this.props.onContinueCart(e, this.getCart(offer))} color="primary">Mit Bestellung fortfahren <span className="oi oi-chevron-right"/> </Button>
                                                </div>
                                            }
                                        </span>
                                </div>


                            </ListGroupItemHeading>
                            }
                            <Collapse isOpen={collapsedOffer === offer.number}>

                                {offer && offer.offerItems &&
                                <ListGroupItemText>
                                    <ListGroup className="col-12 col-md-12">
                                        <ItemList showCheckbox={offer.projectId} checked
                                                  checkList={this.props.checkList} items={offer.offerItems}/>
                                    </ListGroup>
                                </ListGroupItemText>

                                }
                            </Collapse>
                        </ListGroupItem>
                    )
                })
            }
            </>
        );
    }

    hasProject(offer) {
        return offer.projectId;
    }

    isNotExpired(offer) {
        return offer.expires ? moment(offer.expires).isAfter(new Date()) : true;
    }
}

OfferList.propTypes = {};

export default OfferList;
