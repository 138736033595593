import React, {Component} from 'react';
import BackButton from "../../components/Buttons/BackButton";
import {injectIntl} from "react-intl";
import {PATH_ADMIN_ORGANIZATION_OVERVIEW} from "../../routePaths";
import OrganizationForm from "../../components/Form/OrganizationForm";

class CreateOrganizationPage extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <BackButton path={PATH_ADMIN_ORGANIZATION_OVERVIEW}/>
                    <h1 className="h2 text-center mb-4">
                        Verkaufsorganisation erstellen
                    </h1>
                    <OrganizationForm create/>
                </div>
            </div>
        );
    }
}

CreateOrganizationPage.propTypes = {};

export default injectIntl(CreateOrganizationPage);
