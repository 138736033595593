import {
  LOGOUT,
  SET_CURRENT_USER,
  SET_USER_TO_MANAGE,
  CLEAR_USER_TO_MANAGE
} from '../actions/types';

// Initial State
const initialState = {
  stateUser: null,
  stateUserToManage: null
};

const UserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateUser: null,
        stateUserToManage: null
      };
    }
    case SET_CURRENT_USER: {
      return {
        ...state,
        stateUser: action.user
      };
    }
    case SET_USER_TO_MANAGE: {
      return {
        ...state,
        stateUserToManage: action.user
      };
    }
    case CLEAR_USER_TO_MANAGE: {
      return {
        ...state,
        stateUserToManage: null
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCurrentUser = (state) => state.user.stateUser;
export const getUserToManage = (state) => state.user.stateUserToManage;

// Export Reducer
export default UserReducer;
