import React, {Component} from 'react';
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Table from "reactstrap/es/Table";
import classNames from "classnames";
import {connect} from "react-redux";
import SearchBar from "../../components/SearchBar";
import _ from "lodash";
import {PATH_ADMIN_WORKER_CREATE_PAGE, PATH_ADMIN_WORKER_EDIT_PAGE} from "../../routePaths";
import {getWorkers, getWorkersCount} from "../../reducers/WorkersReducer";
import {removeCurrentMessage, deleteWorker, loadWorker, loadWorkers, setWorker} from "../../actions";
import ModalComponent from "../../components/Modals/ModalComponent";
import {Badge} from "reactstrap";
import InfoBox from "../../components/InfoBox";

class AdminWorkerOverviewPage extends Component {
    constructor(props) {
        super(props);
        this.state={
            highlightedRow: 0,
            searchTerm: undefined,
            filteredWorkers: undefined,
            modals: {
                delete: false,
                error: false
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(loadWorkers())
    }
    highlightRow(index) {
        this.setState({highlightedRow: index})
    }
    handleSearchInput(event) {
        let search = this.state.searchTerm;
        if (event.target.value !== undefined) {
            search = event.target.value;
        }

        event.preventDefault();
        this.setState({
            searchTerm: search
        });
        this.filteredWorkerList(search, this.props.workers);
    }
    goToWorkerCreatePage(){
        this.goToPage(PATH_ADMIN_WORKER_CREATE_PAGE)
    }
    goToPage(path, worker) {
        console.log(worker)
        if(worker){
            this.props.dispatch(setWorker(worker))
        }
        this.props.history.push(path);
    }

    filteredWorkerList(searchTerm, workers) {
        let localWorkers = workers;
        if (searchTerm) {
            localWorkers = _.filter(workers, (worker) => {
                return worker.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || worker.number.indexOf(searchTerm) !== -1;
            });
        }
        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.filteredWorkers = localWorkers;
            return nextState;
        });
    }
    resetSearch(){
        this.setState({searchTerm:""})
    }
    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }
    render() {
        const showWorkers = this.state.filteredWorkers || this.props.workers || []
        return (
            <div className="page-content">
                <div className="container">
                    <h1 className="headline">
                        Fachhandwerker anzeigen
                    </h1>

                    {/*<div className="mb-4"><Button color="primary" onClick={() => this.goToWorkerCreatePage()} size="sm">*/}
                    {/*    Neuen Handwerker anlegen</Button></div>*/}
                    <SearchBar
                        filter
                        handleSearch={this.handleSearchInput.bind(this)}
                        searchTerm={this.state.searchTerm}
                        onChange={this.handleSearchInput.bind(this)}
                        onReset={this.resetSearch.bind(this)}
                        placeholder={"Suche nach Namen oder Nummer"}
                    />
                    <Row>
                        {/*Aus Count of Workers suchen*/}
                        <div className="text-center col-12">
                        {!this.state.searchTerm &&
                            <InfoBox> <span className="oi oi-info"/>Bitte benutzen Sie die Suche.</InfoBox>
                        }
                        {this.state.searchTerm && this.state.searchTerm.length <= 3 &&
                            <InfoBox> <span className="oi oi-info"/> Der Suchbegriff muss mindestens 4 Zeichen lang sein!</InfoBox>
                        }
                        {this.state.searchTerm && this.state.searchTerm.length >3 && showWorkers.length > 500  &&
                            <InfoBox> <span className="oi oi-info"/> Es wurden mehr als 500 Handwerker gefunden. Bitte grenzen Sie den Suchbegriff weiter ein!</InfoBox>
                        }
                        {this.state.searchTerm && this.state.searchTerm.length >3 && showWorkers.length === 0  &&
                            <InfoBox> <span className="oi oi-info"/> Es wurden keine Fachhandwerker gefunden.</InfoBox>
                        }
                        </div>
                        {this.state.searchTerm && this.state.searchTerm.length > 3 && showWorkers.length <= 500 && showWorkers.length > 0 &&
                            <Table>
                                <thead>
                                <tr>
                                    <th> #</th>
                                    <th>Name</th>
                                    <th> </th>
                                </tr>
                                </thead>
                                <tbody>
                                {showWorkers.map((worker, index) => {
                                    return (
                                        <tr style={{height: 67}}
                                            key={index}
                                            className={classNames({highlight: (this.state.highlightedRow === index)})}
                                            onMouseEnter={() => this.highlightRow(index)}>
                                            <th scope="row">{worker.number}</th>
                                            <td>{worker.name} {worker.blocked && <Badge color="danger">blockiert</Badge>}
                                            </td>
                                            <td>{(this.state.highlightedRow === index) &&
                                            <span className="float-right">
                                                <Button size="sm" className="mr-3" onClick={() => {
                                                    this.props.dispatch(loadWorker(worker.number))
                                                    this.goToPage(PATH_ADMIN_WORKER_EDIT_PAGE);
                                                }}>anzeigen</Button>
                                            </span>
                                            }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        }
                    </Row>
                </div>
                <ModalComponent
                    isOpen={this.state.modals.delete}
                    toggle={() =>
                        this.showModal('delete')
                    }
                    hide={() =>
                        this.hideModal('delete')
                    }
                    color="danger"
                    title="Fachhandwerker löschen"
                    message={'Wollen Sie diesen Fachhandwerker wirklich löschen?'}
                    onSuccess={(e) => {
                        this.props.dispatch(deleteWorker(this.state.selectedWorker.number))
                        this.props.dispatch(removeCurrentMessage())
                        this.hideModal('delete')
                        e.preventDefault();
                    }
                    }
                    successText={'Fachhandwerker löschen'}
                    cancelText={'Abbrechen'}
                    onCancel={() => this.hideModal('delete')}
                />
            </div>
        );
    }
}

AdminWorkerOverviewPage.propTypes = {};
function mapStateToProps(state) {
    return {
        workers: getWorkers(state),
        workersCount: getWorkersCount(state)
    };
}
export default connect(mapStateToProps)(AdminWorkerOverviewPage);
