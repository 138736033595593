import _ from 'lodash';

import {callApi, callSecureApi} from '../../util/apiCaller';
import {isConflict, isUnauthorized} from '../../util/http-errors';

import {
    CLEAR_PRODUCT_GROUPS,
    LOAD_PRODUCT,
    LOAD_CUSTOMER_PRODUCT_GROUPS,
    CLEAR_CUSTOMER_PRODUCT_GROUPS,
    SET_SEARCH_PRODUCTS,
    LOAD_CROSS_SELL_PRODUCTS,
    CLEAR_SEARCH_BUNDLE_PRODUCT,
    CLEAR_SEARCH_PRODUCT,
    SET_SEARCH_PRODUCT,
    LOAD_CUSTOMER_PRODUCTS
} from '../types';
import {setOrderProducts} from '../orderservice';
import {logout} from '../AuthentificationActions';
import {setCurrentMessage} from '../MessagesAction';
import {uploadProductImage} from './Asset';
import {attachProductToCategory, loadAllProducts, setProductGroups} from './Category';

export function createProduct(product, category, imageFile) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            callSecureApi({
                endpoint: 'productservice/v1/product/',
                method: 'post'
            }, product)
                .then(resp => {
                    const productNumber = resp.productNumber;
                    if (imageFile) {
                        dispatch(uploadProductImage(productNumber, imageFile));
                    }
                    if (category) {
                        dispatch(attachProductToCategory(category.number, [productNumber]))
                        resp.categoryNumbers = [];
                        resp.categoryNumbers.push(category.number);
                    }
                    const message = {
                        type: 'INFO',
                        msg: `Das Produkt mit der Art-Nr. ${productNumber} wurde erstellt.`,
                        source: 'CREATE_PRODUCT'
                    };
                    dispatch(setCurrentMessage(message));
                    resolve(resp);
                })
                .catch(err => {
                    console.error('unable to create product', err);
                    if (isConflict(err)) {
                        const message = {
                            type: 'ERROR',
                            msg: `Das Produkt konnte nicht erstellt werden. Es existiert bereits ein Artikel mit der angegebenen Artikelnummer.`,
                            source: 'CREATE_PRODUCT'
                        };
                        dispatch(setCurrentMessage(message));
                    }
                    reject(err);
                });
        });
    };
}

export function updateProduct(product, imageFile) {
    return dispatch => {
        if (!product) {
            console.info('skip updating product by number, product is null');
            return;
        }

        const productNumber = product.productNumber;
        console.info('updating product number=' + productNumber);

        callSecureApi({
            endpoint: 'productservice/v1/product/{productNumber}',
            query: {'productNumber': productNumber},
            method: 'put'
        }, product)
            .then(res => {
                dispatch(setProduct(res));
                const message = {
                    type: 'INFO',
                    msg: `Der Art-Nr. ${productNumber} wurde aktualisiert.`,
                    source: 'UPDATE_PRODUCT'
                };
                if (imageFile) {
                    dispatch(uploadProductImage(productNumber, imageFile));
                }
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable update product', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isConflict(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Der Art-Nr. ${productNumber} konnte nicht aktualsiert werden. Es existiert bereits ein Artikel mit der angegebenen Artikelnummer.`,
                        source: 'UPDATE_PRODUCT'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function deleteProduct(productNumber) {
    return (dispatch) => {
        callSecureApi({
            endpoint: 'productservice/v1/product/{productNumber}',
            method: 'delete',
            query: {'productNumber': productNumber}
        })
            .then((res) => {
                console.info('product deleted' + productNumber, res);
                dispatch(loadAllProducts())
            })
            .catch(err => {
                console.error('unable to delete product', productNumber, err);
            })
    }
}

export function loadProductGroupsSearch(searchTerm, productGroups) {
    return dispatch => {
        if (!searchTerm) {
            console.info('skip loading product groups, searchterm is null');
            return;
        }

        console.info('loading loading product groups by search term=' + searchTerm);
        callSecureApi({
            endpoint: 'productservice/v1/product/searchTerm/{searchTerm}',
            query: {'searchTerm': searchTerm},
            method: 'get'
        })
            .then(res => {
                handleProductGroups(res, productGroups, dispatch);
            })
    }
}

export function handleProductGroups(res, productGroups, dispatch) {
    if (productGroups) {
        dispatch(setProductGroups(res))
    } else {
        if (res.productGroups) {
            res = _.flattenDeep(res.productGroups.map((productGroup) => {
                return productGroup.products
            }))
        } else {
            res = []
        }
        dispatch(setSearchResultProducts(res))
    }
}

export function loadProductGroupsSearchWithCart(searchTerm, productGroups, cart) {
    return dispatch => {
        if (!searchTerm) {
            console.info('skip loading product groups, searchterm is null');
            return;
        }

        console.info('loading loading product groups by search term=' + searchTerm);
        callSecureApi({
            endpoint: 'productservice/v1/product/cart/{cartId}/searchTerm/{searchTerm}',
            query: {'searchTerm': searchTerm, 'cartId': cart.cartId},
            method: 'get'
        })
            .then(res => {
                handleProductGroups(res, productGroups, dispatch);
            })
    };
}

// todo: we have an API to fetch all products with a single call!
export function fetchProducts(productNumbers) {
    console.info('loading products numbers=' + productNumbers);
    return dispatch => {
        const productPromises = [];
        productNumbers.forEach(productNumber => {
            productPromises.push(
                callApi(
                    {
                        endpoint: 'productservice/v1/product/{productNumber}',
                        query: {'productNumber': productNumber}
                    }
                )
            );
        });
        Promise.all(productPromises)
            .then(res => {
                dispatch(setOrderProducts(res));
            });
    };
}

export function clearProductGroups() {
    console.info('clearing product groups');

    return dispatch => {
        dispatch(cleanProductGroups());
    };
}

export function loadCustomerProductGroups(customerId) {
    return dispatch => {
        if (!customerId) {
            console.info('skip loading customer product groups, customerId is null');
            return;
        }

        console.info('loading customer product groups customer number=' + customerId);
        callSecureApi({
            endpoint: 'productservice/v1/product/customer/{customerId}',
            query: {'customerId': customerId}
        })
            .then(res => {
                const productGroups = res.productGroups;
                if (productGroups) {
                    dispatch(setCustomerProductGroups({productGroups}));
                    const products = _.flatten(productGroups.map(productGroup => productGroup.products));
                    console.log(products);
                    const productNumbers = products.map(product => product.productNumber);
                    console.log("productNumbers ",productNumbers);
                    dispatch(setCustomerProductNumbers({productNumbers}));
                } else {
                    dispatch(cleanCustomerProductGroups());
                }
            })
            .catch(err => {
                console.error('unable to load customer product groups', err);
            });
    };
}
export function fetchProduct(productNumber){
    return dispatch =>{
        callSecureApi({
            endpoint: 'productservice/v1/product/{productNumber}',
            query: {productNumber}
        })
            .then(res => {
                dispatch(setProduct(res))
            })
            .catch(err => {
                console.error('unable to load product', err);
            });
    }
}
export function loadCustomerProjectProductGroups(projectId, customerId) {
    return dispatch => {
        if (!projectId) {
            console.info('skip loading customer project product groups, projectId is null');
            return;
        }

        console.info('loading customer project product groups project id=' + projectId);
        callSecureApi({
            endpoint: 'productservice/v1/product/customer/{customerId}/project/{projectId}',
            query: {'projectId': projectId, 'customerId': customerId}
        })
            .then(res => {
                const productGroups = res.productGroups;
                if (productGroups) {
                    dispatch(setCustomerProductGroups({productGroups}));
                } else {
                    dispatch(cleanCustomerProductGroups());
                }
            })
            .catch(err => {
                console.error('unable to load customer project product groups', err);
            });
    };
}
export function fetchCrossSellProductsFromCart(cart) {
    return function(dispatch) {
        if (cart) {
            const orderItems = cart.orderItems;
            if (orderItems) {
                const orderItemList = _.map(orderItems, orderItem => {
                    return orderItem;
                });

                const filteredOrderItems = _.filter(orderItemList, (oi) => {
                    return oi.quantity > 0;
                });

                const productNumbers = _.map(filteredOrderItems, orderItem => {
                    return orderItem.productNumber;
                });
                dispatch(fetchCrossSellProducts(productNumbers));
            }
        }
    }
}
export function fetchCrossSellProducts(productNumbers) {
    return dispatch => {
        if (productNumbers) {
            console.info('fetch crossSellProducts for given products by productNumbers: ' + productNumbers);
            callSecureApi({
                endpoint: 'productservice/v1/product/crossSell',
                method: 'put'
            }, {productNumbers})
                .then(res => {
                    dispatch(setCrossSellProducts(res));
                })
                .catch(err => {
                    console.error('unable to fetch cross sell products', err);

                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                });
        }
    };
}
export function addCrossSellProducts(product, productNumbers) {
    return dispatch => {
        if (productNumbers) {
            const productNumber = product.productNumber;
            console.info('adding crossSellProducts to product number=' + productNumber + ' for given products by productNumbers: ' + productNumbers);
            callSecureApi({
                endpoint: 'productservice/v1/product/{productNumber}/crossSell',
                query: {'productNumber': productNumber},
                method: 'post'
            }, {productNumbers})
                .then(() => {
                    dispatch(fetchCrossSellProducts([productNumber]));
                })
                .catch(err => {
                    console.error('unable to add cross sell products', err);

                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                });
        }
    };
}
export function removeCrossSellProducts(product, productNumbers) {
    return dispatch => {
        if (productNumbers) {
            const productNumber = product.productNumber;
            console.info('removing crossSellProducts from product number=' + productNumber + ' for given products by productNumbers: ' + productNumbers);
            callSecureApi({
                endpoint: 'productservice/v1/product/{productNumber}/crossSell',
                query: {'productNumber': productNumber},
                method: 'delete'
            }, {productNumbers})
                .then(() => {
                    dispatch(fetchCrossSellProducts([productNumber]));
                })
                .catch(err => {
                    console.error('unable to remove cross sell products', err);

                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                });
        }
    };
}
export function searchProductByNumber(productNumber) {
    return dispatch => {
        if (!productNumber) {
            console.info('skip searching product by number, productNumber is null');
            return;
        }

        console.info('searching product number=' + productNumber);
        callSecureApi({
            endpoint: 'productservice/v1/product/{productNumber}',
            query: {'productNumber': productNumber}
        })
            .then(res => {
                dispatch(setSearchProduct(res));
            })
            .catch(err => {
                console.error('unable to search for product by number', err);
            });
    };
}

export function setSearchProduct(product) {
    return {
        type: SET_SEARCH_PRODUCT,
        product
    };
}
export function cleanSearchBundleProduct() {
    return {
        type: CLEAR_SEARCH_BUNDLE_PRODUCT
    };
}

export function cleanSearchProduct() {
    return {
        type: CLEAR_SEARCH_PRODUCT
    };
}
export function setSearchResultProducts(products) {
    return {
        type: SET_SEARCH_PRODUCTS,
        products
    }
}

export function setProduct(product) {
    return {
        type: LOAD_PRODUCT,
        product
    };
}

export function cleanProductGroups() {
    return {
        type: CLEAR_PRODUCT_GROUPS
    };
}

export function setCustomerProductGroups(customerProductGroups) {
    return {
        type: LOAD_CUSTOMER_PRODUCT_GROUPS,
        customerProductGroups
    };
}
export function setCustomerProductNumbers(customerProductNumbers) {
    return {
        type: LOAD_CUSTOMER_PRODUCTS,
        customerProductNumbers
    };
}
export function cleanCustomerProductGroups() {
    return {
        type: CLEAR_CUSTOMER_PRODUCT_GROUPS
    };
}

function setCrossSellProducts(crossSellProductGroups) {
    return {
        type: LOAD_CROSS_SELL_PRODUCTS,
        crossSellProductGroups
    };
}
