import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from "./Projects/ListComponent";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import Button from "reactstrap/lib/Button";
import {FormattedMessage} from "react-intl";
import {deleteCustomerFacility, setCurrentFacility, updateCustomerFacility} from "../actions";
import {PATH_CUSTOMER_FACILITY_EDIT_PAGE} from "../routePaths";
import _ from "lodash";
import {goToPage} from "../util/page";
import ModalComponent from "./Modals/ModalComponent";
import {User} from "../proptypes";
import {getCurrentUser} from "../reducers/UserReducer";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class CustomerFacilityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currenUser: undefined,
            modals: {
                deleteFacility: false,
                recoverFacility: false,
            }
        }
    }

    recoverFacility(facility) {
        facility.expires = null;
        this.props.dispatch(updateCustomerFacility(facility.id, facility))
    }

    deleteFacility(facilityId) {
        this.props.dispatch(deleteCustomerFacility(facilityId))
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            const nextState = _.cloneDeep(currentState);
            nextState.modals = modals;
            return nextState;
        });
    }

    itemRenderer(facility) {
        if(facility.expires && this.props.currentUser.role !== 'ADMIN' ) {
            return
        }
        return (
            <ListGroupItem className="no-gutters pt-4 pb-4">
                <div className="flex-md-row flex-column d-flex no-gutters">
                    <div className="col-md-1 text-md-left">
                        {facility.id}
                    </div>
                    <div className="col-md-5 text-md-left">
                        {facility.expires &&
                            <div>{facility.name}, (Archiviert)</div>
                        }
                        {!facility.expires &&
                            <div>{facility.name}</div>
                        }
                    </div>
                    <div className="col-md-6 text-md-right">
                        <div className="d-flex float-right flex-column flex-md-row">
                            <div className="d-flex float-right flex-column flex-md-row">
                                {!facility.expires &&
                                    <Button size="sm" color="danger" className="mr-md-5 mb-5 mb-md-0"
                                            onClick={() => {
                                                this.setState({selectedFacility: facility})
                                                this.showModal('deleteFacility');
                                            }}>
                                        <span className="oi oi-trash"/> <FormattedMessage id="delete"/>
                                    </Button>
                                }
                                {!facility.expires &&
                                    <Button size="sm" className="mr-md-5" onClick={() => {
                                        this.props.dispatch(setCurrentFacility(facility))
                                        goToPage(PATH_CUSTOMER_FACILITY_EDIT_PAGE)
                                    }}>
                                        <span className="oi oi-pencil"/> <FormattedMessage
                                        id="facility.list.facilityedit.button"/>
                                    </Button>
                                }
                                {facility.expires &&
                                    <Button size="sm" color="neutral" className="mr-md-5 mb-5 mb-md-0"
                                            onClick={() => {
                                                this.setState({selectedFacility: facility})
                                                this.showModal('recoverFacility');
                                            }}>
                                        <span className="oi oi-arrow-left"/> <FormattedMessage
                                        id="facility.list.recoverfacility.button"/>
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        )

    }

    render() {
        const facilities = this.props.facilities || [];
        return (
            <>
                <ListComponent itemRenderer={this.itemRenderer.bind(this)} items={facilities}/>

                <ModalComponent
                    isOpen={this.state.modals.deleteFacility}
                    toggle={() =>
                        this.showModal('deleteFacility')
                    }
                    hide={() =>
                        this.hideModal('deleteFacility')
                    }
                    color="danger"
                    title="facility.modal.deletefacility.title"
                    message={'facility.modal.deletefacility.message'}
                    onSuccess={() => {
                        const selectedProject = this.state.selectedFacility;
                        if (selectedProject) {
                            this.setState({selectedFacility: undefined})
                            this.deleteFacility(this.state.selectedFacility.id)
                            this.hideModal('deleteFacility')
                            this.props.resetSearch();
                        }
                    }}
                    successText={'Löschen'}
                    cancelText={'Abbrechen'}
                    onCancel={() => {
                        this.hideModal('deleteFacility')
                        this.setState({selectedFacility: undefined})
                    }}
                />
                <ModalComponent
                    isOpen={this.state.modals.recoverFacility}
                    toggle={() =>
                        this.showModal('recoverFacility')
                    }
                    hide={() =>
                        this.hideModal('recoverFacility')
                    }
                    color="danger"
                    title="facility.modal.recoverFacility.title"
                    message={'facility.modal.recoverFacility.message'}
                    onSuccess={() => {
                        const selectedProject = this.state.selectedFacility;
                        if (selectedProject) {
                            this.setState({selectedFacility: undefined})
                            this.recoverFacility(this.state.selectedFacility)
                            this.hideModal('recoverFacility')
                            this.props.resetSearch();
                        }
                    }}
                    successText={'Wiederherstellen'}
                    cancelText={'Abbrechen'}
                    onCancel={() => {
                        this.hideModal('recoverFacility')
                        this.setState({selectedFacility: undefined})
                    }
                    }
                />
            </>
        );
    }
}

CustomerFacilityList.propTypes = {
    facilities: PropTypes.array.isRequired,
    currentUser: PropTypes.shape(User).isRequired,
};

function mapStateToProps(state) {
    return {
        currentUser: getCurrentUser(state),
    };
}

export default withRouter(connect(mapStateToProps)(CustomerFacilityList));
