import {callApi, callSecureApi, ContentType} from '../util/apiCaller';
import {isAlreadyExists, isBadRequest, isUnauthorized} from '../util/http-errors';
import {goToPage} from '../util/page';
import {PATH_OFFER_OVERVIEW} from '../routePaths';

import {
    LOAD_CUSTOMER_USERS,
    CLEAR_CUSTOMER,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMER,
    CLEAR_USER_TO_MANAGE,
    SET_USER_TO_MANAGE,
    SET_CUSTOMER_WORKERS,
    SET_ORGANIZATION,
    SET_ORGANIZATIONS,
    SET_COUNTRIES,
} from './types';

import {logout} from './AuthentificationActions';
import {setCurrentMessage} from './MessagesAction';

export function loadCustomers() {
    return dispatch => {
        console.info('load all customers');
        callSecureApi({
            endpoint: 'customerservice/v1/customer',
            method: 'get'
        })
            .then((res) => {
                dispatch(setCustomers(res));
            })
            .catch(err => {
                    console.error('unable to load customers', err);
                    if (isUnauthorized(err)) {
                        dispatch(logout());
                    }
                }
            );
    };
}

export function deleteCustomer(customerId) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            console.info('delete customers: ', customerId);
            callSecureApi({
                endpoint: 'customerservice/v1/customer/{customerId}',
                method: 'delete',
                query: {'customerId': customerId}
            })
                .then(() => {
                    resolve()
                    dispatch(setCustomer(undefined));

                })
                .catch(err => {
                        console.error('unable to delete customer: ', customerId, ' ', err);
                        if (isUnauthorized(err)) {
                            dispatch(logout());
                        }
                        reject();
                    }
                );
        })
    };
}

export function createUser(user) {
    return dispatch => {
        console.info('creating user with name= ' + user.lastName + ' with role= ' + user.role);
        callSecureApi({
            endpoint: 'customerservice/v1/user',
            method: 'post'
        }, user)
            .then(res => {
                dispatch(setUserToManage(res));
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Der Benutzer ${user.lastName} wurde erfolgreich angelegt. Eine Email mit den Informationen für die Anmeldung wurde an ${user.contact.email} versendet.`,
                    source: 'CREATE_USER'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to create user', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben sowie die Telefonnummern.`,
                        source: 'CHANGE_USER'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Der Benutzer existiert bereits.`,
                        source: 'CHANGE_USER'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function createCustomer(customer, organization) {
    return dispatch => {
        console.info('creating customer');
        callSecureApi({
            endpoint: 'customerservice/v1/customer',
            method: 'post'
        }, customer)
            .then(res => {
                if (organization)
                    dispatch(attachCustomerToOrganization(customer.id, organization.number))
                dispatch(setCustomer(res));
                const message = {
                    type: 'INFO',
                    msg: `Der Händler ${customer.name} wurde erfolgreich angelegt.`,
                    source: 'CREATE_CUSTOMER'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to create customer', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben sowie die Telefonnummern.`,
                        source: 'CHANGE_CUSTOMER'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Die Nummer ist bereits einem anderen Händler zugeordnet.`,
                        source: 'CHANGE_CUSTOMER'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function updateCustomer(customer, organization) {
    console.log("organization ", organization)
    return dispatch => {
        return new Promise((resolve, reject) => {
        const customerId = customer.id;

        console.info('updating customer number=' + customerId);
        callSecureApi({
            endpoint: 'customerservice/v1/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'put'
        }, customer)
            .then(res => {
                if (organization) {
                    if (!customer.salesOrganization || customer.salesOrganization.number !== organization.number) {
                        dispatch(detachCustomerFromOrganization(customerId)).then(()=> {
                            dispatch(attachCustomerToOrganization(customerId, organization.number)).then(() => {
                                dispatch(loadCustomer(customerId));
                                resolve();

                            })
                        })
                    }else{
                        resolve();
                    }
                }

                const message = {
                    type: 'INFO',
                    msg: `Das Händler ${customer.name} wurde erfolgreich aktualisiert.`,
                    source: 'CHANGE_CUSTOMER'
                };
                dispatch(setCurrentMessage(message));
                dispatch(setCustomer(res));
            })
            .catch(err => {
                console.error('unable to update customer', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben sowie die Telefonnummern.`,
                        source: 'CHANGE_CUSTOMER'
                    };
                    dispatch(setCurrentMessage(message));
                }
                reject();
            });
        })
    };
}

export function loadCustomer(customerId) {
    return dispatch => {
        console.info('get customer by id=' + customerId);
        callSecureApi({
            endpoint: 'customerservice/v1/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'get'
        })
            .then(res => {
                dispatch(setCustomer(res));
            })
            .catch(err => {
                console.error('unable to get customer', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function attachUserToCustomer(user, customer) {
    return dispatch => {
        const customerId = customer.id;
        console.info('creating user with name= ' + user.lastName + ' and with role= ' + user.role + ' for customerId= ' + customer.id);
        callSecureApi({
            endpoint: 'customerservice/v1/user/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'post'
        }, user)
            .then(res => {
                dispatch(setUserToManage(res));
            })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Der Benutzer ${user.lastName} wurde erfolgreich angelegt. Eine Email mit den Informationen für die Anmeldung wurde an ${user.contact.email} versendet.`,
                    source: 'CREATE_USER'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to create user', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben sowie die Telefonnummern.`,
                        source: 'CHANGE_USER'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Der Benutzer existiert bereits.`,
                        source: 'CHANGE_USER'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}

export function updateUser(user) {
    return dispatch => {
        const userId = user.id;
        console.info('updating user with id=' + userId);
        callSecureApi({
            endpoint: 'customerservice/v1/user/{userId}',
            query: {'userId': userId},
            method: 'put'
        }, user)
            .then(res => {
                dispatch(setUserToManage(res));
            })
            .then(() => {
                const usertName = user.firstName ? `${user.firstName} ${user.lastName}` : user.lastName;
                const message = {
                    type: 'INFO',
                    msg: `Der Benutzer ${usertName} wurde erfolgreich aktualisiert.`,
                    source: 'CHANGE_USER'
                };
                dispatch(setCurrentMessage(message));
            })
            .catch(err => {
                console.error('unable to update user', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
                if (isBadRequest(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Ein oder mehrere Felder enthalten ungültige Angaben. Bitte überprüfen sie ihre Eingaben sowie die Telefonnummern.`,
                        source: 'CHANGE_USER'
                    };
                    dispatch(setCurrentMessage(message));
                }
                if (isAlreadyExists(err)) {
                    const message = {
                        type: 'ERROR',
                        msg: `Der Benutzer existiert bereits.`,
                        source: 'CHANGE_USER'
                    };
                    dispatch(setCurrentMessage(message));
                }
            });
    };
}
export function updateCredentials(userId,credentials){
    return dispatch => {
        if(userId === undefined){
            return console.error("error: userId is empty")
        }
        if(credentials.email === undefined && credentials.password === undefined){
            return console.error("error: credentials are empty")
        }
        callSecureApi({
            endpoint: 'customerservice/v1/user/{userId}/credentials',
            query: {userId: userId},
            method: 'put'
        }, credentials).then(()=>{
            console.info('Credentials changed')
            const message = {
                type: 'INFO',
                msg: "settings.changecredentials.success",
                source: 'CHANGE_EMAIL_CREDENTIALS'
            };
            dispatch(setCurrentMessage(message));
            setTimeout(()=>dispatch(logout()),3000)
        }).catch(err => {
            const message = {
                type: 'ERROR',
                msg: "settings.changecredentials.error",
                source: 'CHANGE_EMAIL_CREDENTIALS'
            };
            dispatch(setCurrentMessage(message))
            console.error(err)
        })
    }
}
export function suspendAccount(userId) {
    return dispatch => {
        if (userId !== undefined) {
            callSecureApi({
                    endpoint: 'customerservice/v1/user/{userId}/suspend',
                    query: {'userId': userId},
                    method: 'put'
                }
            ).then(() => {
                console.info("User suspended");
                dispatch(logout());
            })
        }
    };
}

export function getAllUsersFromCustomer(customer) {
    return dispatch => {
        const customerId = customer.id;
        console.info('get all users for customerId= ' + customer.id);
        callSecureApi({
            endpoint: 'customerservice/v1/user/customer/{customerId}',
            query: {'customerId': customerId},
            method: 'get'
        })
            .then(res => {
                dispatch(setUsersFromCustomer(res));
            })
            .catch(err => {
                console.error('unable to get users from customer', err);
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function deleteUser(user, customer) {
    const userId = user.id;
    const userLastName = user.lastName;
    return dispatch => {
        console.info('delete user with id=' + userId);
        callSecureApi({
            endpoint: 'customerservice/v1/user/{userId}',
            query: {'userId': userId},
            method: 'delete'
        })
            .then(() => {
                const message = {
                    type: 'INFO',
                    msg: `Der Benutzer ${userLastName} wurde erfolgreich gelöscht.`,
                    source: 'DELETE_USER'
                };
                dispatch(setCurrentMessage(message));
                if (customer) {
                    dispatch(getAllUsersFromCustomer(customer))
                }
            })
            .catch(err => {
                console.error('unable to delete user ', err);
                const message = {
                    type: 'ERROR',
                    msg: `Der Benutzer konnte nicht gelöscht werden.`,
                    source: 'DELETE_USER'
                };
                dispatch(setCurrentMessage(message));
                if (isUnauthorized(err)) {
                    dispatch(logout());
                }
            });
    };
}

export function getAllWorkersFromCustomer(customerId) {
    callSecureApi({
        endpoint: 'customerservice/v1/worker/customer/{customerId}',
        query: {'customerId': customerId},
        method: 'get'
    }).then(r => {
        this.setCustomerWorkers(r)
    })
}

export function getAllOrganizations() {
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/organization',
            method: 'get'
        }).then(organizations => {
            dispatch(setOrganizations(organizations.salesOrganizations))
        })
            .catch(err => {
                console.log("cant load organizations ", err);
            })
    }
}

export function createOrganization(organization) {
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/organization',
            method: 'post'
        },
            organization
        ).then((organization) => {
            dispatch(setOrganization(organization))
            const message = {
                type: 'INFO',
                msg: `Die Verkaufsorganisation wurde erfolgreich erstellt!`,
                source: 'CREATE_SO'
            };
            dispatch(setCurrentMessage(message));
        }).catch(err => {
            console.log("cant create organization ", err)
            const message = {
                type: 'ERROR',
                msg: `Die Verkaufsorganisation konnte nicht erstellt werden!`,
                source: 'SO'
            };
            dispatch(setCurrentMessage(message));
        })
    }
}

export function attachCustomerToOrganization(customerId, organizationNumber) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            callSecureApi({
                endpoint: 'customerservice/v1/organization/{organizationNumber}/customer/{customerId}',
                query: {organizationNumber, customerId},
                method: 'put'
            }).then((res) => {
                console.log("successfully attached customer to organization", res)
                resolve();
            }).catch((err) => {
                console.log("an error occurred on attaching customer to organization ", err)
                reject();
            })
        })
    }
}

export function detachCustomerFromOrganization(customerId) {
    return dispatch => {
        return new Promise((resolve, reject) =>{
            callSecureApi({
                endpoint: 'customerservice/v1/organization/customer/{customerId}',
                query: {customerId},
                method: 'delete'
            }).then((res) => {
                console.log("successfully detached customer from organization", res)
                resolve()
            }).catch((err) => {
                console.log("an error occurred on detaching customer from organization ", err)
                reject()
            })
        })
    }
}

export function getOrganization(organizationNumber) {
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/organization/{organizationNumber}',
            query: {organizationNumber},
            method: 'get'
        }).then((organization) => {
            dispatch(setOrganization(organization))

        }).catch(err => {
            console.log('cant load organization ', err)

        })
    }
}

export function updateOrganization(organization) {
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/organization/{organizationNumber}',
            query: {organizationNumber: organization.number},
            method: 'put'
        }, organization).then((organization) => {
            dispatch(setOrganization(organization))
            const message = {
                type: 'INFO',
                msg: `Verkaufsorganisation wurde erfolgreich aktualisiert!`,
                source: 'CHANGE_SO'
            };
            dispatch(setCurrentMessage(message));
        }).catch(err => {
            console.log('cant update organization ', err)
            const message = {
                type: 'ERROR',
                msg: `Es ist ein Fehler aufgetreten!`,
                source: 'SO'
            };
            dispatch(setCurrentMessage(message));
        })
    }
}

export function deleteOrganization(organizationNumber) {
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/organization/{organizationNumber}',
            query: {organizationNumber},
            method: 'delete'
        }).then((res) => {
            console.log("organization successfully deleted ", organizationNumber, res)
            dispatch(getAllOrganizations());
        }).catch(err => {
            console.error('error on deleteOrganization ', organizationNumber, err)
        })
    }
}
export function getAllCountries() {
    return dispatch => {
        callApi({
            endpoint: 'customerservice/v1/i18n/country',
            method: 'get'
        }).then((res) => {
            dispatch(setCountries(res.countries))

        }).catch(err => {
            console.log('cant load countries ', err)

        })
    }
}

export function createNewProjectWithOffer(customerId, offerNumber,project){
    if(!customerId || !offerNumber || !project){
        console.error('createNewProjectWithOffer parameters not found!')
        return;
    }
    return dispatch => {
        callSecureApi({
            endpoint: 'customerservice/v1/project/offer/{offerNumber}',
            query: {customerId,offerNumber},
            method: 'post'
        },project).then((res) => {
            dispatch(loadCustomerWorkers(customerId));
            goToPage(PATH_OFFER_OVERVIEW,{offerNumber})
            console.log('createdProjectWithOffer ',res)
        }).catch(err => {
            const message = {
                type: 'ERROR',
                msg: `Lieferadresse konnte dem Angebot nicht zugewiesen werden.`,
                source: 'OFFER_CHOOSE_PROJECT'
            };
            dispatch(setCurrentMessage(message))
        console.error(err)
        })
    }
}

export function loadCustomerWorkers(customerId) {
    return dispatch => {
        callSecureApi(
            {
                endpoint: 'customerservice/v1/worker/customer/{customerId}',
                query: {'customerId': customerId},
                method: 'get'
            }
        ).then(res => {
            console.log("customerWorkers ", res);
            if(res.workers)
                dispatch(setCustomerWorkers(res.workers))
            else
                dispatch(setCustomerWorkers([]));
        })
    }
}

export function createNewUserRegistration(userRegistration) {
    console.log(userRegistration)
    return dispatch => {
        return callApi({
            endpoint: 'customerservice/v1/registration',
            method: 'post'
        }, userRegistration)
    }
}

export function uploadRegistrationImage(customerNumber, file) {
    if (!customerNumber) {
        console.info('Unable to upload registration image, customerNumber is null');
        return;
}
    console.info('upload image for registration=' + customerNumber);
    return dispatch => {
        return callApi({
            endpoint: 'customerservice/v1/registration/{customerNumber}/asset',
            query: {'customerNumber': customerNumber},
            method: 'post',
            contentType: ContentType.MULTIPART_FORM_DATA
        }, file)
    }
}


export function setCountries(countries) {
    return {
        type: SET_COUNTRIES,
        countries
    }
}
export function setUsersFromCustomer(users) {
    return {
        type: LOAD_CUSTOMER_USERS,
        users
    };
}

export function setUserToManage(user) {
    return {
        type: SET_USER_TO_MANAGE,
        user
    };
}

export function clearUserToManage() {
    return {
        type: CLEAR_USER_TO_MANAGE
    };
}

export function setCustomers(customers) {
    return {
        type: LOAD_CUSTOMERS,
        customers
    };
}

export function clearCustomer() {
    return {
        type: CLEAR_CUSTOMER
    };
}

export function setCustomer(customer) {
    return {
        type: LOAD_CUSTOMER,
        customer
    };
}

export function setCustomerWorkers(workers) {
    return {
        type: SET_CUSTOMER_WORKERS,
        workers
    }
}

export function setOrganizations(organizations) {
    return {
        type: SET_ORGANIZATIONS,
        organizations
    }
}

export function setOrganization(organization) {
    return {
        type: SET_ORGANIZATION,
        organization
    }
}
