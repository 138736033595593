import {REMOVE_CURRENT_PROJECT, SET_CURRENT_PROJECT, LOGOUT} from '../actions/types';

const initialState = {
  stateProject: null
};

const ProjectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        stateProject: null
      };
    }
    case REMOVE_CURRENT_PROJECT: {
      return {
        ...state,
        stateProject: null
      };
    }
    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        stateProject: action.project
      };
    }
    default: {
      return state;
    }
  }
};

/* Selectors */
export const getCurrentProject = (state) => state.project.stateProject;

// Export Reducer
export default ProjectReducer;
