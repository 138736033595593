export function isBadRequest(err) {
    return err.status === 400;
}

export function isAlreadyExists(err) {
    return isConflict(err);
}

export function isConflict(err) {
    return err.status === 409;
}

export function isUnauthorized(err) {
    return err.error === 'unauthorized' || err.error === 'invalid_token';
}