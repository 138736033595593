import * as PropTypes from "prop-types";
import React from "react";

export default function UserCartButton(props) {
    return <>
        {props.currentUser &&
        <>
            {!props.b &&
            <div>{props.currentUser.firstName} {props.currentUser.lastName}</div>
            }
            {props.b &&
            <div className="d-flex  flex-column cart-button text-center" color="none" onClick={props.onClick}>
                                            <span className="font-weight-bold">
                                              zum Warenkorb <span className="oi oi-cart "/>
                                            </span>
                {props.currentCart.orderItems && props.currentCart.orderItems.length > 0 &&
                <span className="small">
                                                    {props.currentCart.orderItems.length} Artikel
                                            </span>
                }
            </div>
            }
        </>
        }
    </>;
}

UserCartButton.propTypes = {
    currentUser: PropTypes.any,
    b: PropTypes.bool,
    onClick: PropTypes.func,
    currentCart: PropTypes.any
};
