class CartValidator {
  static isCartFixed(cart) {
    if (cart) {
      if (cart.locked) {
        return true;
      }

      const status = cart.status;
      if (status) {
        if (status === 'NEW'
            || status === 'SCHEDULED'
            || status === 'ACCEPTED'
            || status === 'REJECTED'
            || status === 'PROCESSING'
            || status === 'CANCELLED'
            || status === 'ADJUSTED'
            || status === 'READY_FOR_SHIPMENT'
            || status === 'FINALIZED'
            || status === 'SHIPPED'
            || status === 'DELIVERED'
            || status === 'DELETION'
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

export default CartValidator;

