import React, {Component} from 'react';

class CategoriesOverviewPage extends Component {
    render() {
        return (
            <div>
                CategoriesOverview
            </div>
        );
    }
}

CategoriesOverviewPage.propTypes = {};

export default CategoriesOverviewPage;
