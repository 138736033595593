import React, {Component} from "react";
import PropTypes from "prop-types";

class OrderAdress extends Component {
    render() {
        return <>
            {this.props.currentProject.name}, {this.props.currentProject.address.postalCode} {this.props.currentProject.address.city}
            {this.props.currentCart && this.props.currentCart.workerNumber && this.props.workerByWorkerNumber &&
            <span>
                      <br/>
                      Fachhandwerker: {this.props.workerByWorkerNumber.name}
              </span>
            }
        </>;
    }
}

OrderAdress.propTypes = {
    currentProject: PropTypes.any,
    currentCart: PropTypes.any,
    workerByWorkerNumber: PropTypes.any
};

export default OrderAdress
