import React, {Component} from "react";
import {
    clearCustomerFacility,
    removeCurrentMessage,
    loadCurrentUser,
    updateCustomerFacility,
    createCustomerFacility
} from "../../actions";
import _ from "lodash";
import {isValidPhone, notEmpty} from "../../util/ValidFn";
import {Button, Form} from "reactstrap";
import InputComponent from "./InputComponent";
import AddressComponent from "./Parts/AddressComponent";
import ModalComponent from "../Modals/ModalComponent";
import {
        PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE,
    } from "../../routePaths";
import PropTypes from "prop-types";
import {Facility, Message, User} from "../../proptypes";
import {getCurrentUser} from "../../reducers/UserReducer";
import {getCustomer} from "../../reducers/CustomerReducer";
import {getCurrentMessage} from "../../reducers/MessageReducer";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getCustomerFacility} from "../../reducers/CustomerFacilityReducer";

class CustomerFacilityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facility: {
                name: undefined,
                id: null,
                customerId: this.props.currentUser.customerId,
                address: {
                    additionalInformation: undefined,
                    addressLine1: undefined,
                    addressLine2: undefined,
                    addressLine3: undefined,
                    city: undefined,
                    company: undefined,
                    countryCode: undefined,
                    postalCode: undefined
                },
                contact: {
                    email: undefined,
                    fax: undefined,
                    mobile: undefined,
                    phone: false
                }
            },
            isContactValid: true,
            modals: {
                create: false,
                change: false,
                error: false,
                adminError: false
            },
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.edit) {
            const currentUser = this.props.currentUser;
            if (!currentUser) {
                this.props.dispatch(loadCurrentUser());
            }
            const currentFacility = this.props.currentFacility
            if (currentFacility) {
                this.setCustomerFacility(currentFacility);
            }
        } else {
            this.clearFieldsOnNewCustomerFacility()
        }
    }

    componentWillReceiveProps(nextProps) {
        const nextMessage = nextProps.currentMessage;
        if (nextMessage && (nextMessage.source === 'CHANGE_CUSTOMER_FACILITY' && nextMessage.type === 'INFO')) {
            this.showModal('change');
        }
        if (nextMessage && (nextMessage.source === 'CREATE_CUSTOMER_FACILITY' && nextMessage.type === 'INFO')) {
            this.showModal('create');
        }
        if (nextMessage && (nextMessage.type === 'ERROR')) {
            if (this.props.currentUser.role !== 'ADMIN') {
                this.showModal('error');
            } else {
                this.showModal('adminError');
            }
        }
    }

    getInitFacility() {
        return {
            name: '',
            id: null,
            customerId: this.props.currentUser.customerId,
            address: {
                additionalInformation: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                company: '',
                countryCode: '',
                postalCode: ''
            },
            contact: {
                email: '',
                fax: '',
                mobile: '',
                phone: '',
            },
        }
    }

    setCustomerFacility(nextFacility) {
        this.setState(
            currentState => {
                // deep copy current state, in order to not modify the current state object
                const nextState = _.cloneDeep(currentState);
                nextState.facility = nextFacility;
                return nextState;
            });
    }

    handleCustomerFacilityBasicDataChange(event) {
        const facility = Object.assign({}, this.state.facility);
        const name = event.target.name;
        facility[name] = event.target.value;
        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.facility = facility;
            return nextState;
        });
    }

    handleCustomerFacilityAddressDataChange(event) {
        const address = Object.assign({}, this.state.facility.address);
        address[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.facility.address = address;
            return nextState;
        });
    }

    handleCustomerFacilityContactDataChange(event) {
        const contact = Object.assign({}, this.state.facility.contact);
        contact[event.target.name] = event.target.value;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.facility.contact = contact;
            return nextState;
        });
    }

    createCustomerFacility(event, customerId, facility) {
        this.props.dispatch(createCustomerFacility(customerId, facility));
    }

    updateCustomerFacility(event, facility) {
        this.props.dispatch(updateCustomerFacility(facility.id, facility));
    }

    showModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = true;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    hideModal(key) {
        const modals = Object.assign({}, this.state.modals);
        modals[key] = false;

        this.setState(currentState => {
            // deep copy current state, in order to not modify the current state object
            const nextState = _.cloneDeep(currentState);

            nextState.modals = modals;
            return nextState;
        });
    }

    clearFieldsOnNewCustomerFacility(event) {
        this.setState({
            facility: this.getInitFacility(),
        });
        this.props.dispatch(clearCustomerFacility());
        this.props.dispatch(removeCurrentMessage());
        if (event)
            event.preventDefault();
    }

    isRequiredNotEmpty() {
        const facility = this.state.facility;
        const address = facility.address;
        return notEmpty(address.addressLine1) && notEmpty(address.city) && notEmpty(address.postalCode)
            && notEmpty(facility.name) && notEmpty(facility.contact.email) && notEmpty(facility.contact.phone)
    }

    isRequiredValid() {
        const facility = this.state.facility;
        return isValidPhone(facility.contact?.phone)
    }

    isFormComplete() {
        return this.isRequiredValid() && this.isRequiredNotEmpty()
    }

    goToPage(e, path) {
        e.preventDefault();
        this.props.history.push(path);
    }

    render() {
        const facility = this.state.facility;
        return (
            <div className="container d-flex flex-column">
                <div className="d-flex flex-row col-12 mb-4 align-items-end ">
                    <div className="ml-auto mb-2">
                        {this.props.edit &&
                            <Button
                                href="" className="btn-block"
                                onClick={(e) => this.updateCustomerFacility(e, this.state.facility)}
                                disabled={!this.isFormComplete()}
                            >
                                Lager / Werk aktualisieren
                            </Button>
                        }
                        {this.props.create &&
                            <Button
                                href=""
                                className="btn-block"
                                onClick={(e) => this.createCustomerFacility(e, this.props.customer.id, this.state.facility)}
                                disabled={!this.isFormComplete()}
                            >
                                Lager / Werk erstellen
                            </Button>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-start flex-md-row col-12 mt-2">
                    <Form className="col-12 col-md-4">
                        <h2>Basisdaten</h2>
                        <InputComponent title="customer.form.nameTitle" type="text" name="name"
                                        placeholder={"facility.form.basic.name"}
                                        value={this.state.facility.name}
                                        handleChange={this.handleCustomerFacilityBasicDataChange.bind(this)}
                                        formFeedback={"customer.form.nameFormFeedback"}
                                        maxLength={64}
                                        min={3}
                                        required
                        />
                    </Form>
                    <Form className="col-12 col-md-4">
                        <AddressComponent
                            handler={this.handleCustomerFacilityAddressDataChange.bind(this)}
                            rootValue={facility.address}
                        />
                    </Form>
                    <Form className="col-12 col-md-4">
                        <h2>Kontakt</h2>
                        <InputComponent title="customer.form.emailTitle" name='email' type="email"
                                        placeholder="customer.form.emailPlaceholder"
                                        formFeedback="customer.form.emailFormFeedback"
                                        value={this.state.facility.contact.email}
                                        handleChange={this.handleCustomerFacilityContactDataChange.bind(this)}
                                        maxLength={64}
                                        formText="customer.form.emailFormText"
                                        required
                        />
                        <InputComponent title="customer.form.phoneTitle" name='phone' type="tel"
                                        placeholder="customer.form.phonePlaceholder"
                                        formFeedback="customer.form.phoneFormFeedback"
                                        value={this.state.facility.contact.phone}
                                        handleChange={this.handleCustomerFacilityContactDataChange.bind(this)}
                                        maxLength={64}
                                        required
                        />
                        <InputComponent title="customer.form.mobileTitle" name='mobile' type="tel"
                                        placeholder="customer.form.mobilePlaceholder"
                                        formFeedback="customer.form.mobileFormFeedback"
                                        value={this.state.facility.contact.mobile}
                                        handleChange={this.handleCustomerFacilityContactDataChange.bind(this)}
                                        maxLength={64}
                        />
                        <InputComponent title="customer.form.faxTitle" name='fax' type="tel"
                                        placeholder="customer.form.faxPlaceholder"
                                        formFeedback="customer.form.faxFormFeedback"
                                        value={this.state.facility.contact.fax}
                                        handleChange={this.handleCustomerFacilityContactDataChange.bind(this)}
                                        maxLength={64}
                        />
                    </Form>
                </div>

                <ModalComponent
                    isOpen={this.state.modals.create}
                    toggle={() => this.showModal('create')}
                    hide={() => this.hideModal('create')}
                    title="Lager / Werk erfolgreich angelegt"
                    color="success"
                    message={'Anlegen des Lagers / Werks erfolgreich. Möchten Sie eine weiteres Lager / Werk anlegen?'}
                    onCancel={(e) => {
                        this.goToPage(e, PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    onSuccess={(e) => {
                        this.clearFieldsOnNewCustomerFacility(e);
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('create')
                    }}
                    successText={'Ja'}
                    cancelText={'Nein'}
                />

                <ModalComponent
                    isOpen={this.state.modals.change}
                    toggle={() => this.showModal('change')}
                    hide={() => this.hideModal('change')}
                    color="success"
                    title="Lager / Werk aktualisiert"
                    message={'Lager / Werk aktualisiert'}
                    onSuccess={(e) => {
                        this.goToPage(e, PATH_CUSTOMER_FACILITY_OVERVIEW_PAGE);
                        this.props.dispatch(removeCurrentMessage())
                    }}
                    successText={'Ok'}
                />

                {this.props.currentMessage &&
                    <ModalComponent
                        isOpen={this.state.modals.error}
                        toggle={() => this.showModal('error')}
                        hide={() => this.hideModal('error')}
                        color="danger"
                        title="Fehler aufgetreten"
                        message={this.props.currentMessage.msg}
                        onSuccess={() => {
                            this.props.dispatch(removeCurrentMessage());
                            this.hideModal('error')
                        }}
                        successText={'Ok'}
                    />
                }

                <ModalComponent
                    isOpen={this.state.modals.adminError}
                    toggle={() => this.showModal('adminError')}
                    hide={() => this.hideModal('adminError')}
                    color="danger"
                    title="Fehler aufgetreten"
                    message={"Ein Fehler ist aufgetreten. Prüfen Sie ob der Händler als Handelsvertreter eingestellt ist. Es dürfen Lager / Werke nur für Handelsverteter erstellt werden."}
                    onSuccess={() => {
                        this.props.dispatch(removeCurrentMessage());
                        this.hideModal('adminError')
                    }}
                    successText={'Ok'}
                />

            </div>
        );
    }
}

CustomerFacilityForm.propTypes = {
    currentUser: PropTypes.shape(User).isRequired,
    facility: PropTypes.shape(Facility).isRequired,
    currentFacility: PropTypes.shape(Facility),
    currentMessage: PropTypes.shape(Message),
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

CustomerFacilityForm.defaultProps = {
    facility: {}
};

function mapStateToProps(state) {
    return {
        currentFacility: getCustomerFacility(state),
        currentUser: getCurrentUser(state),
        customer: getCustomer(state),
        currentMessage: getCurrentMessage(state),
    };
}

export default withRouter(connect(mapStateToProps)(CustomerFacilityForm));
